<template>
<div class="wrapper">
          <title>Popovers</title>

  <b-container fluid>
    <h1 class="title-section">Popovers</h1>
    <p>The Popover feature, which provides a tooltip-like behavior, can be easily applied to any interactive element via the <code class="text-nowrap" translate="no">&lt;b-popover&gt;</code> component or v-b-popover directive.</p>
    
    <h2 class="title-section">Positioning</h2>
    <p>Four options are available for positioning: top, bottom, right and left. Positioning is relative to the trigger element.</p>

    <b-row>
      <b-col class="mb-5">
        <b-button v-b-popover.hover.bottom="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Popover on bottom</b-button>
        <b-button v-b-popover.hover.top="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary" class="ml-5">Popover on top</b-button>

      </b-col>
    </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button v-b-popover.hover.bottom="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Popover on bottom</b-button>
  <b-button v-b-popover.hover.top="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary" class="ml-5">Popover on top</b-button>
</div></script></code></pre>  

    <b-row class="mt-5">
      <b-col >
        <b-button v-b-popover.hover.right="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Popover on right</b-button>
        <b-button v-b-popover.hover.left="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary" class="ml-5">Popover on left</b-button>
      </b-col>
    </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button v-b-popover.hover.right="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary" class="ml-5">Popover on right</b-button>
  <b-button v-b-popover.hover.left="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary" class="mr-5">Popover on left</b-button>
</div></script></code></pre>  


    <b-row>
      <b-col>
        <h2 class="title-section">Overview</h2>
        <p>Things to know when using popover component:</p>
        <ul> 
          <li>
            Popovers rely on the 3rd party library <a href="https://popper.js.org/" target="_blank" rel="noopener">Popper.js</a> for positioning.
          </li>
          <li>
            Popovers require BootstrapVue's custom SCSS/CSS in order to function correctly, and for variants.
          </li> 
          <li>
            Specify <code class="text-nowrap" translate="no">container</code> as <code class="text-nowrap" translate="no">null</code> (default, appends to <code class="text-nowrap" translate="no">&lt;body&gt;</code>) to avoid rendering problems in more complex components (like input groups, button groups, etc.). You can use <code class="text-nowrap" translate="no">container</code> to optionally specify a different element to append the rendered popover to.
          </li> 
          <li>
            Triggering popovers on hidden elements will not work.
          </li> 
          <li>
            Popovers for <code class="text-nowrap" translate="no">disabled</code> elements must be triggered on a wrapper element.
          </li>
          <li>
            When triggered from hyperlinks that span multiple lines, popovers will be centered. Use <code class="text-nowrap" translate="no">white-space: nowrap;</code> on your <code class="text-nowrap" translate="no">&lt;a&gt;</code>s, <code class="text-nowrap" translate="no">&lt;b-link&gt;</code>s and <code class="text-nowrap" translate="no">&lt;router-link&gt;</code>s to avoid this behavior.
          </li> 
        </ul>

        <h2 class="title-section">Triggers</h2>
        <p>Popovers can be triggered (opened/closed) via any combination of click, hover and focus. The default trigger is click. Or a trigger of manual can be specified, where the popover can only be opened or closed programmatically.</p>
        <p>If a popover has more than one trigger, then all triggers must be cleared before the popover will close. I.e. if a popover has the trigger focus click, and it was opened by focus, and the user then clicks the trigger element, they must click it again and move focus to close the popover.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      </b-col>
    </b-row>

  </b-container>
</div>
</template>

<script>
export default {
  name: "PopoversView",
  mounted() {
    document.title = "Popovers";
    Prism.highlightAll();
  }

}
</script>

<style scoped>

</style>
