<template>
<div class="wrapper">
        <title>Navs & Tabs</title>

  <h1 class="title-section">Navs & Tabs</h1>
  <p style="font-size: 1.5rem">Create a widget of tabbable panes of local content. The tabs component is built upon navs and cards internally, and provides full keyboard navigation control of the tabs.</p>
  <p class="m-40">
    For navigation based tabs (i.e. tabs that would change the URL), use the <a href="/docs/components/nav" class="font-weight-bold">
    <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code></a> component instead.
  </p>

  <h3>Navs</h3>
  <p class="m-30">
    Navigation available in Bootstrap share general markup and styles, from the base 
    <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> class to the 
    <code class="text-nowrap" translate="no">active</code> and <code class="text-nowrap" translate="no">disabled</code> states. 
    Swap modifier props to switch between each style.
  </p>
  
  <b-row>
    <b-col>
      <b-nav>
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item>Another Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Another Link</b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>  

        <h2>Overview</h2>
        <p>
          The base <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> component is built with flexbox and provides a strong foundation 
          for building all types of navigation components. It includes some style overrides (for working with lists), 
          some link padding for larger hit areas, and basic disabled styling. No active states are included in the base nav.
        </p>
        <p><code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> supports the following child components:</p>
        <ul class="m-40"> 
          <li>
            <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> for actionable links (or router-links)
          </li> 
          <li>
            <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> for dropdowns
          </li> 
          <li>
            <code class="text-nowrap" translate="no">&lt;b-nav-text&gt;</code> for plain text content
          </li> 
          <li>
            <code class="text-nowrap" translate="no">&lt;b-nav-form&gt;</code> for inline forms
          </li> 
        </ul>

        <h2>Link appereance</h2>
        <p class="m-40">
          Two style variations are supported: <code class="text-nowrap" translate="no">tabs</code> and 
          <code class="text-nowrap" translate="no">pills</code>, which support <code class="text-nowrap" translate="no">active</code> state styling. 
          These variants are mutually exclusive - use only one style or the other.
        </p>

          <b-row>
    <b-col>
      <h2>Small</h2>
      <p class="m-30">Make the nav smaller by setting the <code class="text-nowrap" translate="no">small</code> prop.</p>

      <div>
        <b-nav small>
          <b-nav-item active>Active</b-nav-item>
          <b-nav-item>Link</b-nav-item>
          <b-nav-item>Another Link</b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </b-nav>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav small>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Another Link</b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>

    </b-col>
  </b-row>

    <b-row >
    <b-col>
        <h3>Vertical alignment</h3>
        <p class="m-30">By default <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> appear on a horizontal line. Stack your navigation by setting the <code class="text-nowrap" translate="no">vertical</code> prop.</p>
          
          <b-nav  class="mr-5 vertical-nav" vertical>
            <b-nav-item active>Active</b-nav-item>
            <b-nav-item>Link</b-nav-item>
            <b-nav-item>Another Link</b-nav-item>
            <b-nav-item disabled>Disabled</b-nav-item>
          </b-nav>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav  class="mr-5 vertical-nav" vertical>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Another Link</b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>



    </b-col>
  </b-row>

  <b-row>
    <b-col>
      <h3>Pill style navs</h3>
      <p class="m-30">Use the pill style by setting the <code class="text-nowrap" translate="no">pills</code> prop.</p>
      
      <b-nav pills>
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item>Another Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav pills>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Another Link</b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>  

<h3 class="m-30">Vertical pill navs</h3>

          <b-nav class="vertical-nav" pills vertical>
            <b-nav-item active>Active</b-nav-item>
            <b-nav-item>Link</b-nav-item>
            <b-nav-item>Another Link</b-nav-item>
            <b-nav-item disabled>Disabled</b-nav-item>
          </b-nav>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav class="vertical-nav" pills vertical>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Another Link</b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>
    </b-col>
  </b-row>

        <h2>Tab style</h2>
        <p class="m-30">Make the nav look like tabs by setting the <code class="text-nowrap" translate="no">tabs</code> prop.</p>

        <div>
          <b-nav tabs>
            <b-nav-item active>This Week</b-nav-item>
            <b-nav-item>This Month</b-nav-item>
            <b-nav-item>This Week</b-nav-item>
          </b-nav>
        </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav tabs>
    <b-nav-item active>This Week</b-nav-item>
    <b-nav-item>This Month</b-nav-item>
    <b-nav-item>This Week</b-nav-item>
  </b-nav>
</div></script></code></pre>

    </b-col>
  </b-row>
  




    <b-row>
    <b-col>
      <h2>Fill and justify</h2>
      <p class="m-40">Force your <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> content to extend the full available width.</p>

      <h3>Fill</h3>
      <p class="m-30">
        To proportionately fill all available space with your <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> components, set the 
        <code class="text-nowrap" translate="no">fill</code> prop. Notice that all horizontal space is occupied, but not every nav item has the same width.
      </p>

      <div>
        <b-nav tabs fill>
          <b-nav-item active>Active</b-nav-item>
          <b-nav-item>Link</b-nav-item>
          <b-nav-item>Link with a long name </b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </b-nav>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav tabs fill>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Link with a long name </b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre> 

    <h3>Justified</h3>
    <p class="m-30">
      For equal-width elements, set the <code class="text-nowrap" translate="no">justified</code> prop instead. 
      All horizontal space will be occupied by nav links, but unlike <code class="text-nowrap" translate="no">fill</code> above, every 
      <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> will be the same width.
    </p>

    <div>
      <b-nav tabs justified>
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item>Link with a long name </b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav tabs justified>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Link with a long name </b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre> 

      <h3>Alignment</h3>
      <p class="m-30">
        To align your <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> components, use the <code class="text-nowrap" translate="no">align</code> prop. 
        Available values are <code class="text-nowrap" translate="no">left</code>, <code class="text-nowrap" translate="no">center</code> and 
        <code class="text-nowrap" translate="no">right</code>.
      </p>

      <div>
        <b-nav tabs align="center">
          <b-nav-item active>Active</b-nav-item>
          <b-nav-item>Link</b-nav-item>
          <b-nav-item>Link with a long name </b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </b-nav>
      </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav tabs align="center">
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>
    <b-nav-item>Link with a long name </b-nav-item>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre> 
    </b-col>
  </b-row>


 <b-row>   

    <b-col>
      <h3>Tabs with dropdowns</h3>
      <p class="m-30">Use <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> to place dropdown items within your nav.</p>
      <b-nav tabs class="mb-5">
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>

        <b-nav-item-dropdown
          no-caret
          id="my-nav-dropdown"
          text="Dropdown"
          toggle-class="nav-link-custom"
          right
        >
          <template v-slot:button-content>
            Dropdown
            <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
          </template>
          <b-dropdown-item>One</b-dropdown-item>
          <b-dropdown-item>Two</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Three</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
      <b-nav class="custom-tabs" tabs>
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>

        <b-nav-item-dropdown
          no-caret
          id="my-nav-dropdown"
          text="Dropdown"
          toggle-class="nav-link-custom"
          right
        >
          <template v-slot:button-content>
            Dropdown
            <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
          </template>
          <b-dropdown-item>One</b-dropdown-item>
          <b-dropdown-item>Two</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Three</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-nav class="tabs-wrapper"  pills>
    <b-nav-item active>Active</b-nav-item>
    <b-nav-item>Link</b-nav-item>

    <b-nav-item-dropdown
      no-caret
      id="my-nav-dropdown"
      text="Dropdown"
      toggle-class="nav-link-custom"
      right
    >
      <template v-slot:button-content>
        Dropdown
        <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
      </template>
      <b-dropdown-item>One</b-dropdown-item>
      <b-dropdown-item>Two</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item>Three</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item disabled>Disabled</b-nav-item>
  </b-nav>
</div></script></code></pre>

    <h2>Accessibility</h2>
    <p>
      If you're using <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> to provide a navigation bar, be sure to add a 
      <code class="text-nowrap" translate="no">role="navigation"</code> to the most logical parent container of 
      <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code>, or wrap a <code class="text-nowrap" translate="no">&lt;nav&gt;</code> 
      element around <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code>. Do <strong>not</strong> add the role to the 
      <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code> itself, as this would prevent it from being announced as an actual 
      list by assistive technologies.
    </p>

    <p>
      When using a <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> in your 
      <code class="text-nowrap" translate="no">&lt;b-nav&gt;</code>, be sure to assign a unique 
      <code class="text-nowrap" translate="no">id</code> prop value to the 
      <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> so that the appropriate 
      <code class="text-nowrap" translate="no">aria-*</code> attributes can be automatically generated.
    </p>

    </b-col>
 </b-row>


</div>
</template>

<script>
import CaretDown from "@/assets/images/icons/angle-down.svg"
import InlineSvg from "vue-inline-svg";
export default {
  name: "NavsView",
  data(){
    return {
      CaretDown
    }
  },
  components:{
    InlineSvg
  },
  mounted(){
    document.title = "Navs & Tabs";
    Prism.highlightAll();
  }


}
</script>

<style scoped>

</style>
