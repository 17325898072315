<template>

<div class="catalog-page">
      <title>Catalog</title>



    <section class="header-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="header">
              <div class="logo d-flex">
                <p>LOGO</p> 
                  <div>One Page <span>Web Shop Template</span></div>
              </div>
              <button class="cart d-flex bg-white align-items-center">
                <svg id="iconfinder_shopping-cart_3324948" xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
                  <circle id="Ellipse_80" data-name="Ellipse 80" cx="1" cy="1" r="1" transform="translate(8 20)" fill="none" stroke="#5561b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <circle id="Ellipse_81" data-name="Ellipse 81" cx="1" cy="1" r="1" transform="translate(19 20)" fill="none" stroke="#5561b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Path_26" data-name="Path 26" d="M1,1H5L7.68,14.39a2,2,0,0,0,2,1.61H19.4a2,2,0,0,0,2-1.61L23,6H6" fill="none" stroke="#5561b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </svg>
                <p>Cart</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>



    <section class="section-1">

        <b-row>
            <b-col class="lg-12">
              <h1 class="title">Catalog</h1>
                <b-nav>
                    <b-nav-item href="#" active>All</b-nav-item>
                    <b-nav-item href="#">Free</b-nav-item>
                    <b-nav-item href="#">Premium</b-nav-item>
                    <b-nav-item href="#" disabled>Special</b-nav-item>
                    <b-nav-item href="#" disabled>On Sale</b-nav-item>  
                </b-nav>

            </b-col>
        </b-row>
        <b-row >
            <b-col lg="4" sm="6" v-for="(card, index) in filteredCards" v-bind:key="index + 'index'">
            <b-card
                :title="card.text" 
                :sub-title="'$' + card.price.toString()"
                :img-src="require('../../src/assets/images/background/image.jpg')"            
                img-top
                style="width: 21.875rem;">
            <app-button variant="primary" @click="addToCart(card.id)">
                <inline-svg :src="PlusIcon"></inline-svg>
            </app-button>
            </b-card>
            </b-col>
        </b-row>

      <div class="d-flex justify-content-between overflow-auto">
        <div class="d-flex justify-content-start">
          <b-pagination :total-rows="rows" :per-page="perPage" v-model="page"></b-pagination>
        </div>
      </div>

      <section class="section-2 mb-5">

          <h2>Cart</h2>

          <b-row>
              <b-col lg="8">
                <div class="cart">
                    <div class="d-flex justify-content-between">
                        <span class="product">Product</span>
                        <span class="price-product">Price</span>
                    </div>

                    <div v-for="item in inCart" :key="item.id">
                                <b-alert dismissible fade show class="d-flex justify-content-between" id="alert" @dismissed="removeItem(item.id)">
                                        <p class="product-content mb-0 d-flex"><img src="../../src/assets/images/background/image.jpg" alt="" class="align-self-start"><span>{{ item.text }}</span></p>
                                        
                                        <p class="mb-0 product-price"> {{'$' + item.price}}</p>

                                </b-alert>
                    </div>

                    <div class="total-price">
                        <p class="text-right">Total:</p>
                        <div class="d-flex justify-content-between">
                        <div>
                            <img src="../../src/assets/images/payment/visa.jpg" alt="" style="margin-right: 5px;">
                            <img src="../../src/assets/images/payment/mastecard.jpg" alt="" style="margin-right: 5px;">
                            <img src="../../src/assets/images/payment/paypal.jpg" alt="">
                        </div>
                        <span class="price me-0">{{'$' + total}}</span>
                        </div>
                    </div>
                </div>
              </b-col>
              <b-col lg="4">
                  <form action="">
                    <p>Product</p>
                    <input type="text" class="form-control rounded-pill" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                    <input type="email" class="form-control rounded-pill" id="exampleFormControlInput1" placeholder="name@example.com">
                    <input type="text" class="form-control rounded-pill" placeholder="+1 (809) 123-45-67" aria-label="Username" aria-describedby="basic-addon1">
                    <app-button variant="primary" type="submit" custom-class="rounded-pill">COMPLETE YOUR ORDER</app-button>           
                  </form>

              </b-col>
          </b-row>
          <b-row>
              <b-col lg="12">
                 <p class="footer">© 2020 trendy.solutions</p>
              </b-col>
          </b-row>

      </section>


    </section>

</div>
    
</template>

<script>
import AppButton from "@/components/AppButton";
import AppTransition from "@/components/app/AppTransition";
import lodash from 'lodash'
import PlusIcon from '@/assets/images/icons/icon-plus.svg'
import InlineSvg from "vue-inline-svg";

export default {
      name: "CatalogView",
      props: ['id', 'text', 'price'],
      data() {
          return {       
              PlusIcon,  
              removedItemID: Number,
              page: 1,
              perPage: 9,  
              perRow: 3, 
              cards: [
                  {id: 1, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 2, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 3, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 4, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 5, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 6, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 7, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 8, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 9, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 10, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 11, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 12, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 13, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 14, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 15, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 16, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 17, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 18, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 19, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 20, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 21, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 22, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 23, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 24, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 25, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 26, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 27, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 28, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 29, text: 'Discount On Sports Equipment' ,price: 20},
                  {id: 30, text: 'Discount On Sports Equipment' ,price: 20},

              ],

              inCart: [
                  {id: 1, card_id:1, text: 'All Week -30% On Breakfast' ,price: 40},
                  {id: 2, card_id:2, text: 'Discount On Weekend Surfing Classes' ,price: 180},

              ],
          }
      },

      methods: {
        groupedCards() {
            return lodash.chunk(this.filteredCards, 3)
        },

        addToCart(id) {
           for(let i = 0; i<this.cards.length;i++){
               if(id == this.cards[i].id) {
                   this.inCart.push({id:Math.ceil(Math.random()*1000000), card_id: this.cards[i].id, text: this.cards[i].text, price: this.cards[i].price})
               }
           }
        },

        removeItem(id) {
            this.inCart = this.inCart.filter((e)=>e.id !== id )
        }

      },

      mounted() {
          document.title = "Catalog";
          this.groupedCards();
          this.addToCart();
      },

      computed: {
        rows() {
            return this.cards.length;
        },

        filteredCards: function () {
            return this.cards.slice((this.page - 1) * this.perPage, (this.page -1) * this.perPage +this.perPage)
        },
        
        total() {
                let totalAmount;
                totalAmount = this.inCart.reduce((acc, cur) => acc + cur.price, 0);
                return totalAmount;
        }
      },

      components: {
          AppButton,
          AppTransition,
          InlineSvg
      },

    
}
</script>