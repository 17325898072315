<template>
<div class="wrapper">
          <title>Pricing</title>

  <h1 class="title-section">Pricing table</h1>

  <div class="container">
          <b-row class="pricing">
          <b-col lg="12" xl="4">
            <b-card no-body>
                <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">BASIC PLAN</h5>
                    <span class="h2">$</span><span class="price">19</span>
                    <p>/MONTH</p>
                  </div>
                </div>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>10 members</span></li>
                    <li class="list-group-item">Admins <span>No access</span></li>
                </ul>
                  <app-button variant="primary">Start with Basic</app-button>
              </b-card>
          </b-col>
          <b-col lg="12" xl="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">STANDARD PLAN</h5>
                    <span class="h2">$</span><span class="price">29</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>50 members</span></li>
                    <li class="list-group-item">Admins <span>5 admins</span></li>
                </ul>
                <app-button variant="primary">Start with Standard</app-button>
              </b-card>
          </b-col>
          <b-col lg="12" xl="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">ENTERRISE PLAN</h5>

                    <span class="h2">$</span><span class="price">99</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>Unlimited</span></li>
                    <li class="list-group-item">Admins <span>Unlimited</span></li>
                </ul>
                <app-button variant="primary">Start with Enterprise</app-button>
              </b-card>
          </b-col>

        </b-row>

</div>

    
    <!-- <div class="pricing">

         <b-card no-body>
            <div class="pricing-header">
              <div class="text-center">
                <h5 class="card-title">BASIC PLAN</h5>
                <span class="h2">$</span><span class="price">19</span>
                <p>/MONTH</p>
              </div>
            </div>

            <ul class="list-group list-group-flush">
                <li class="list-group-item">Unlimited activity</li>
                <li class="list-group-item">Direct messaging</li>
                <li class="list-group-item">Members <span>10 members</span></li>
                <li class="list-group-item">Admins <span>No access</span></li>
            </ul>
              <app-button variant="primary">Start with Basic</app-button>
          </b-card>

          <b-card no-body>
            <div class="pricing-header">
                <div class="text-center">
                  <h5 class="card-title">STANDARD PLAN</h5>
                  <span class="h2">$</span><span class="price">29</span>
                  <p>/MONTH</p>
                </div>
              </div>
  
              <ul class="list-group list-group-flush">
                  <li class="list-group-item">Unlimited activity</li>
                  <li class="list-group-item">Direct messaging</li>
                  <li class="list-group-item">Members <span>50 members</span></li>
                  <li class="list-group-item">Admins <span>5 admins</span></li>
              </ul>
              <app-button variant="primary">Start with Standard</app-button>
            </b-card>

          <b-card no-body>
            <div class="pricing-header">
                <div class="text-center">
                  <h5 class="card-title">ENTERPRISE PLAN</h5>

                  <span class="h2">$</span><span class="price">99</span>
                  <p>/MONTH</p>
                </div>
              </div>
  
              <ul class="list-group list-group-flush">
                  <li class="list-group-item">Unlimited activity</li>
                  <li class="list-group-item">Direct messaging</li>
                  <li class="list-group-item">Members <span>Unlimited</span></li>
                  <li class="list-group-item">Admins <span>Unlimited</span></li>
              </ul>
              <app-button variant="primary">Start with Enterprise</app-button>
            </b-card>

    </div> -->

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="pricing">
      <b-col md="4">
         <b-card no-body>
            <div class="pricing-header">
              <div class="text-center">
                <h5 class="card-title">BASIC PLAN</h5>
                <span class="h2">$</span><span class="price">19</span>
                <p>/MONTH</p>
              </div>
            </div>

            <ul class="list-group list-group-flush">
                <li class="list-group-item">Unlimited activity</li>
                <li class="list-group-item">Direct messaging</li>
                <li class="list-group-item">Members <span>10 members</span></li>
                <li class="list-group-item">Admins <span>No access</span></li>
            </ul>
              <app-button variant="primary">Start with Basic</app-button>
          </b-card>
      </b-col>

      <b-col md="4">
          <b-card no-body>
            <div class="pricing-header">
                <div class="text-center">
                  <h5 class="card-title">STANDARD PLAN</h5>
                  <span class="h2">$</span><span class="price">29</span>
                  <p>/MONTH</p>
                </div>
              </div>
  
              <ul class="list-group list-group-flush">
                  <li class="list-group-item">Unlimited activity</li>
                  <li class="list-group-item">Direct messaging</li>
                  <li class="list-group-item">Members <span>50 members</span></li>
                  <li class="list-group-item">Admins <span>5 admins</span></li>
              </ul>
              <app-button variant="primary">Start with Standard</app-button>
            </b-card>
      </b-col>

      <b-col md="4">
          <b-card no-body>
            <div class="pricing-header">
                <div class="text-center">
                  <h5 class="card-title">ENTERPRISE PLAN</h5>

                  <span class="h2">$</span><span class="price">99</span>
                  <p>/MONTH</p>
                </div>
              </div>
  
              <ul class="list-group list-group-flush">
                  <li class="list-group-item">Unlimited activity</li>
                  <li class="list-group-item">Direct messaging</li>
                  <li class="list-group-item">Members <span>Unlimited</span></li>
                  <li class="list-group-item">Admins <span>Unlimited</span></li>
              </ul>
              <app-button variant="primary"><span>Start with Enterprise</span></app-button>
            </b-card>
        </b-col>
</div></script></code></pre>

</div>

    
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
  name: "PricingView",
  components: {
    AppButton,
  },
  mounted(){
    document.title = "Pricing";
    Prism.highlightAll();
  }

}
</script>

<style scoped>

</style>