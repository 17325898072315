<template>
<div class="example-page-second">
      <title>Landing page 2</title>

    <b-navbar class="mb-5 navbar-wrapper rounded-pill" toggleable="lg" type="light">
        <b-navbar-brand href="#"><span>trendy.</span></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="ml-auto justify-content-end">
          <b-navbar-nav>
            <b-nav-item href="#" active>Home</b-nav-item>
            <b-nav-item href="http://localhost:8080/">Components</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Review</b-nav-item>
            <b-nav-item href="#">Price</b-nav-item>
            <b-nav-item href="#" active pill>Free Trial</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

    <section class="section-1">
        <p class="title text-secondary text-center">SaasKit Creative UI Saas <br> Design & Coding</p>
        <div class="buttons trendy-example">
            <button class="btn btn-primary rounded-pill">Get Free Trial</button>
            <button class="btn btn-light text-secondary rounded-pill">See More Info</button>
        </div>
        <b-row>
          <b-col lg="12">
            <div class="img-container">
                <img src="./../assets/images/landing-page2/picture1.png" alt="First picture">
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4">

              <b-card class="text-center"
              title="Fast and Optimized">
                <b-card-text>
                    Donec semper euismod nisi quis
                    feugiat. Nullam finibus metus eget
                    orci volutpat porta.
                </b-card-text>
                <app-button variant="primary" custom-class="rounded-pill">See more</app-button>

              </b-card>
          </b-col>
          <b-col lg="4">

            <b-card class="text-center"
              title="Marketing Strategist">
                <b-card-text>
                    Donec semper euismod nisi quis
                    feugiat. Nullam finibus metus eget
                    orci volutpat porta.
                </b-card-text>
                <app-button variant="primary" custom-class="rounded-pill">See more</app-button>
              </b-card>
          </b-col>
          <b-col lg="4">
            <b-card class="text-center"
              title="Easy Integration">
                <b-card-text>
                    Donec semper euismod nisi quis
                    feugiat. Nullam finibus metus eget
                    orci volutpat porta.
                </b-card-text>
                <app-button variant="primary" custom-class="rounded-pill">See more</app-button>
              </b-card>
            </b-col>
        </b-row>
    </section>

    <section class="section-2">
        <p class="title text-secondary text-center">Best Features Ever</p>
        <b-row class="first-row">
            <b-col lg="6">
                <p class="text-primary fw-semi-bold">Information about Agency</p>
                <p class="title text-secondary">Best Performance</p>
                <p class="text-grey fw-semi-bold">Donec semper euismod nisi quis feugiat. Nullam finibus
                    metus eget orci volutpat porta. Morbi quis arcu vulputate,
                    dignissim mi ac, varius magna.
                    <br><br>
                    Curabitur mollis bibendum luctus.
                </p>
                <app-button variant="primary" custom-class="rounded-pill">Learn more</app-button>
            </b-col>
            <b-col lg="6">
            <img src="./../assets/images/landing-page2/picture2.png" alt="Second picture" class="second-img">
            </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <img src="./../assets/images/landing-page2/picture3.png" alt="Third picture" class="third-img">
            </b-col>
            <b-col lg="6">
                <p class="text-primary fw-semi-bold">Information about Agency</p>
                <p class="title text-secondary">Best Performance</p>
                <p class="text-grey fw-semi-bold">Donec semper euismod nisi quis feugiat. Nullam finibus
                    metus eget orci volutpat porta. Morbi quis arcu vulputate,
                    dignissim mi ac, varius magna.
                    <br><br>
                    Curabitur mollis bibendum luctus.
                </p>
                <app-button variant="primary" custom-class="rounded-pill">Learn more</app-button>
            </b-col>
        </b-row>
    </section>

    <section class="section-3">
        <p class="title text-secondary">Software Pricing</p>
        <p class="text-grey fw-semi-bold text-center">Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed
            suscipit.
            Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
        </p>


<div class="container">
          <b-row class="pricing">
          <b-col lg="4">
            <b-card no-body>
                <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">BASIC PLAN</h5>
                    <span class="h2">$</span><span class="price">19</span>
                    <p>/MONTH</p>
                  </div>
                </div>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>10 members</span></li>
                    <li class="list-group-item">Admins <span>No access</span></li>
                </ul>
                  <app-button variant="primary">Start with Basic</app-button>
              </b-card>
          </b-col>
          <b-col lg="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">STANDARD PLAN</h5>
                    <span class="h2">$</span><span class="price">29</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>50 members</span></li>
                    <li class="list-group-item">Admins <span>5 admins</span></li>
                </ul>
                <app-button variant="primary">Start with Standard</app-button>
              </b-card>
          </b-col>
          <b-col lg="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">ENTERRISE PLAN</h5>

                    <span class="h2">$</span><span class="price">99</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>Unlimited</span></li>
                    <li class="list-group-item">Admins <span>Unlimited</span></li>
                </ul>
                <app-button variant="primary">Start with Enterprise</app-button>
              </b-card>
          </b-col>

        </b-row>

</div>


    </section>

    <section class="section-4">
        <p class="title text-secondary">Start Your Free Trial</p>
        <p class="text-grey fw-semi-bold text-center">Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed
            suscipit.
            Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
        </p>

        <!-- Form -->
        <div class="form-wrapper">
            <p>Get In Touch With Us To See How Our Company Can Help You</p>
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-input type="text" placeholder="Your name"></b-input>
                </b-col>
                <b-col md="6">
                  <b-input type="text" placeholder="Your email"></b-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-input type="text" placeholder="Phone"></b-input>
                </b-col>
                <b-col md="6">
                  <b-input type="text" placeholder="Subject"></b-input>
                </b-col>
              </b-row>
                <b-textarea rows="3" placeholder="Your message"></b-textarea>


              <app-button variant="primary">Send Message</app-button>


            </b-form>


        </div>
    </section>

</div>
    
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
    name: "LandingViewSecond",
    components: {
        AppButton
    }, 
    mounted() {
          document.title = "Landing page 2";

    }   
}
</script>