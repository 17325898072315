<template>
  <div class="wrapper">
          <title>Navbar</title>

    <div class="mb-5">
      <h2 class="title-section">Navbars</h2>
      <p class="m-30" style="font-size: 1.5rem">The component <code class="text-nowrap" translate="no">&lt;b-navbar&gt;</code> is a wrapper that positions branding, navigation, and other elements into a concise header. <br>
       It's easily extensible and thanks to the <code class="text-nowrap" translate="no">&lt;b-badge&gt;</code> component, it can easily integrate responsive behaviors.</p>
      

      
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Link</b-nav-item>
            <b-nav-item-dropdown no-caret href="#" text="Dropdown">
              <template v-slot:button-content>
                Dropdown
                <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
              </template>
              <b-dropdown-item>One</b-dropdown-item>
              <b-dropdown-item>Two</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item>Three</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item href="#" disabled>Disabled</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2 search-input" placeholder="Search"></b-form-input>
              <b-button  variant="light" size="md" class="my-2 my-sm-0" type="submit">Primary</b-button>
            </b-nav-form>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-navbar class="mb-5" toggleable="lg" type="light" variant="light">
    <b-navbar-brand href="#">NavBar</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#">Home</b-nav-item>
        <b-nav-item href="#">Link</b-nav-item>

        <b-nav-item-dropdown no-caret href="#" text="Dropdown">
          <template v-slot:button-content>
            Dropdown
            <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
          </template>
          <b-dropdown-item>One</b-dropdown-item>
          <b-dropdown-item>Two</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Three</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item href="#" disabled>Disabled</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2 search-input" placeholder="Search"></b-form-input>
          <b-button  variant="light" size="md" class="my-2 my-sm-0" type="submit">Primary</b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div></script></code></pre>

 <h2>Color schemes</h2>
      <p><code class="text-nowrap" translate="no">&lt;b-navbar&gt;</code> supports the standard available background color variants. Set the variant prop to one of the following values to change the background color: primary, secondary, success, info, warning, danger, dark, or light.</p>
      <p class="m-30">Control the text color by setting type prop to light for use with light background color variants, or dark for dark background color variants.</p>
      <b-navbar class="mb-5" toggleable="lg" type="dark" variant="secondary">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item href="#">About</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2 search-input" placeholder="Search"></b-form-input>
              <b-button  variant="primary" size="md" class="my-2 my-sm-0" type="submit">Primary</b-button>
            </b-nav-form>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-navbar class="mb-5" toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse2"></b-navbar-toggle>

        <b-collapse id="nav-collapse2" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item href="#">About</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2 search-input" placeholder="Search"></b-form-input>
              <app-button  variant="secondary" size="md" class="my-2 my-sm-0" type="submit">Primary</app-button>
            </b-nav-form>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-navbar class="mb-5" toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse3"></b-navbar-toggle>

        <b-collapse id="nav-collapse3" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item href="#">About</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2 search-input" placeholder="Search"></b-form-input>
              <b-button  variant="light" size="md" class="my-2 my-sm-0" type="submit">Primary</b-button>
            </b-nav-form>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

<h2>Placements</h2>
<p class="m-30">Control the placement of the navbar by setting one of two props:</p>

      <div>
        <b-table-simple hover responsive>
          <b-thead>
            <b-tr>
              <b-th>prop</b-th>
              <b-th>type</b-th>
              <b-th>default</b-th>
              <b-th>description</b-th>

            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">fixed</code></b-td>
              <b-td>String</b-td>
              <b-td><code class="text-nowrap" translate="no">null</code></b-td>
              <b-td>Set to <code class="text-nowrap" translate="no">top</code> for fixed to the top of the viewport, or <code class="text-nowrap" translate="no">bottom</code> for fixed to the <code class="text-nowrap" translate="no">bottom</code> of the viewport.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">sticky</code></b-td>
              <b-td>Boolean></b-td>
              <b-td><code class="text-nowrap" translate="no">false</code></b-td>
              <b-td>Set to <code class="text-nowrap" translate="no">true</code> to make the navbar stick to the top of the viewport (or parent container that has <code class="text-nowrap" translate="no">position: relative</code> set) when scrolled.</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <p><strong>Notes:</strong></p>
      <ul class="m-40"> 
        <li>
          Fixed positioning uses CSS <code class="text-nowrap" translate="no">position: fixed</code>. 
          You may need to adjust your document top/bottom padding or margin.
        </li> 
        <li>
          CSS <code class="text-nowrap" translate="no">position: sticky</code> 
          (used for <code class="text-nowrap" translate="no">sticky</code>) is not fully supported in every browser. 
          For browsers that do not support <code class="text-nowrap" translate="no">position: sticky</code>, 
          it will fallback natively to <code class="text-nowrap" translate="no">position: relative</code>.
        </li> 
      </ul>

<h2>Supported content</h2>
<p>Navbars come with built-in support for a handful of sub-components. Choose from the following as needed:</p>
<ul class="m-40"> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-navbar-brand&gt;</code> for your company, product, or project name.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> for use with the 
    <code class="text-nowrap" translate="no">&lt;b-collapse is-nav&gt;</code> component.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-collapse is-nav&gt;</code> for grouping and hiding navbar contents by a parent breakpoint.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code> for a full-height and lightweight navigation (including support for dropdowns). 
    The following sub-components inside <code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code> are supported:
    <ul> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> for link (and router-link) action items
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> for nav dropdown menus
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-text&gt;</code> for adding vertically centered strings of text.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-form&gt;</code> for any form controls and actions.
  </li> 
  </ul> 
  </li> 
</ul>

<h3 id="b-navbar-brand" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-navbar-brand&gt;</code>
  <a class="anchorjs-link" href="#b-navbar-brand" aria-labelledby="b-navbar-brand"></a></span>
</h3>
<p class="m-30"> The <code class="text-nowrap" translate="no">&lt;b-navbar-brand&gt;</code> generates a link if 
    <code class="text-nowrap" translate="no">href</code> is provided, or a 
    <code class="text-nowrap" translate="no">&lt;router-link&gt;</code> if <code class="text-nowrap" translate="no">to</code> is provided. 
    If neither is given it renders as a <code class="text-nowrap" translate="no">&lt;div&gt;</code> tag. 
    You can override the tag type by setting the <code class="text-nowrap" translate="no">tag</code> 
    prop to the element you would like rendered:
</p>

<div class="mb-3">
  <!-- As a link -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">trendy.</b-navbar-brand>
  </b-navbar>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <!-- As a link -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">trendy.</b-navbar-brand>
  </b-navbar>
</div></script></code></pre>

<div class="mt-3 mb-3">
  <!-- As a heading -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand tag="h1" class="mb-0">trendy.</b-navbar-brand>
  </b-navbar>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <!-- As a heading -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand tag="h1" class="mb-0">trendy.</b-navbar-brand>
  </b-navbar>
</div></script></code></pre>


  <p class="m-30">
    Adding images to the <code class="text-nowrap" translate="no">&lt;b-navbar-brand&gt;</code> 
    will likely always require custom styles or utilities to properly size. 
    Here are some examples to demonstrate:
  </p>

<div>
  <!-- Just an image -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">
      <img :src="require('../../src/assets/images/nav-bar/navbar_logo.png')" alt="Kitten">
    </b-navbar-brand>
  </b-navbar>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <!-- Just an image -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">
      <img :src="require('../../src/assets/images/nav-bar/navbar_logo.png')" alt="Kitten">
    </b-navbar-brand>
  </b-navbar>
</div></script></code></pre>


<div>
  <!-- Image and text -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">
      <img :src="require('../../src/assets/images/nav-bar/navbar_logo.png')" alt="Kitten">
      trendy.
    </b-navbar-brand>
  </b-navbar>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <!-- Image and text -->
  <b-navbar variant="light" type="light">
    <b-navbar-brand href="#">
      <img :src="require('../../src/assets/images/nav-bar/navbar_logo.png')" alt="Kitten">
      trendy.
    </b-navbar-brand>
  </b-navbar>
</div></script></code></pre>


<h3 id="b-navbar-nav" class="bv-no-focus-ring">
  <span class="bd-content-title"><code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code>
  <a class="anchorjs-link" href="#b-navbar-nav" aria-labelledby="b-navbar-nav"></a></span>
</h3>

<p>
  Navbar navigation links build on the <code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code> parent component and requires the use of 
  <code class="text-nowrap" translate="no">&lt;b-collapse is-nav&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-nav-toggle&gt;</code> 
  toggler for proper responsive styling. Navigation in navbars will also grow to occupy as much horizontal space as possible to keep your navbar contents securely aligned.
</p>

<p><code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code> supports the following child components:</p>
<ul class="m-40"> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> for link (and router-link) action items
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-text&gt;</code> for adding vertically centered strings of text.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> for navbar dropdown menus
  </li> 
  <li>
    <code class="text-nowrap" translate="no">&lt;b-nav-form&gt;</code> for adding simple forms to the navbar.
  </li> 
</ul>

<h3 id="b-nav-item" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code><a class="anchorjs-link" href="#b-nav-item" aria-labelledby="b-nav-item"></a></span>
</h3>
<p>
  <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> is the primary link (and <code class="text-nowrap" translate="no">&lt;router-link&gt;</code>) component. 
  Providing a <code class="text-nowrap" translate="no">to</code> prop value will generate a <code class="text-nowrap" translate="no">&lt;router-link&gt;</code> 
  while providing an <code class="text-nowrap" translate="no">href</code> prop value will generate a standard link.
</p>
<p>
  Set the <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> <code class="text-nowrap" translate="no">active</code> 
  prop will highlight the item as being the active page, Disable a <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code> 
  by setting the prop <code class="text-nowrap" translate="no">disabled</code> to true.
</p>
<p class="m-40">
  Handle click events by specifying a handler for the <code class="text-nowrap" translate="no">@click</code> event on 
  <code class="text-nowrap" translate="no">&lt;b-nav-item&gt;</code>.
</p>

<h3 id="b-nav-text" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-nav-text&gt;</code><a class="anchorjs-link" href="#b-nav-text" aria-labelledby="b-nav-text"></a></span>
</h3>
<p class="m-30">
  Navbars may contain bits of text with the help of <code class="text-nowrap" translate="no">&lt;b-nav-text&gt;</code>. 
  This component adjusts vertical alignment and horizontal spacing for strings of text.
</p>

<div class="mb-3">
  <b-navbar toggleable="sm" type="light" variant="light">
    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

    <b-navbar-brand>NavBar</b-navbar-brand>

    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-text>Navbar text</b-nav-text>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-navbar toggleable="sm" type="light" variant="light">
    <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

    <b-navbar-brand>NavBar</b-navbar-brand>

    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-text>Navbar text</b-nav-text>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div></script></code></pre>

<h3 id="b-nav-item-dropdown" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code>
  <a class="anchorjs-link" href="#b-nav-item-dropdown" aria-labelledby="b-nav-item-dropdown"></a></span>
</h3>
<p class="m-30">
  For <code class="text-nowrap" translate="no">&lt;b-nav-item-dropdown&gt;</code> usage, see the <a href="/docs/components/dropdown" class="font-weight-bold">
  <code class="text-nowrap" translate="no">&lt;b-dropdown&gt;</code></a> docs. Note split dropdowns are not supported in 
  <code class="text-nowrap" translate="no">&lt;b-navbar&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code>.
</p>

<div class="m-40">
  <b-navbar type="dark" variant="primary">
    <b-navbar-nav>
      <b-nav-item href="#">Home</b-nav-item>

      <!-- Navbar dropdowns -->
      <b-nav-item-dropdown text="Lang" right>
        <b-dropdown-item href="#">EN</b-dropdown-item>
        <b-dropdown-item href="#">ES</b-dropdown-item>
        <b-dropdown-item href="#">RU</b-dropdown-item>
        <b-dropdown-item href="#">FA</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown text="User" right>
        <b-dropdown-item href="#">Account</b-dropdown-item>
        <b-dropdown-item href="#">Settings</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</div>



<h3 id="b-nav-form" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-nav-form&gt;</code><a class="anchorjs-link" href="#b-nav-form" aria-labelledby="b-nav-form"></a></span>
</h3>
<p class="m-30">Use <code class="text-nowrap" translate="no">&lt;b-nav-form&gt;</code> to place inline form controls into your navbar</p>

<div class="mb-3">
  <b-navbar type="light" variant="light">
    <b-nav-form>
      <b-form-input class="mr-sm-2 search-form" placeholder="Search"></b-form-input>
      <app-button variant="outline-primary" class="my-2 my-sm-0" type="submit">Search</app-button>
    </b-nav-form>
  </b-navbar>
</div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-navbar type="light" variant="light">
    <b-nav-form>
      <b-form-input class="mr-sm-2" placeholder="Search"></b-form-input>
      <app-button variant="outline-success" class="my-2 my-sm-0" type="submit">Search</app-button>
    </b-nav-form>
  </b-navbar>
</div></script></code></pre>

<p class="m-30">Input group work as well:</p>

<div>
  <b-navbar type="light" variant="light">
    <b-nav-form>
      <b-input-group prepend="@">
        <b-form-input placeholder="Username"></b-form-input>
      </b-input-group>
    </b-nav-form>
  </b-navbar>
</div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-navbar type="light" variant="light">
    <b-nav-form>
      <b-input-group prepend="@">
        <b-form-input placeholder="Username"></b-form-input>
      </b-input-group>
    </b-nav-form>
  </b-navbar>
</div></script></code></pre>

<h3 id="b-navbar-toggle-and-b-collapse-is-nav" class="bv-no-focus-ring"><span class="bd-content-title">
  <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-collapse is-nav&gt;</code>
  <a class="anchorjs-link" href="#b-navbar-toggle-and-b-collapse-is-nav" aria-labelledby="b-navbar-toggle-and-b-collapse-is-nav"></a></span>
</h3>
<p>
  Navbars are not responsive by default, but you can easily modify them to change that. 
  Responsive behavior depends on our <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code> component.
</p>
<p>
  Wrap <code class="text-nowrap" translate="no">&lt;b-navbar-nav&gt;</code> components in a 
  <code class="text-nowrap" translate="no">&lt;b-collapse is-nav&gt;</code> 
  (<strong>remember to set the <code class="text-nowrap" translate="no">is-nav</code> prop!</strong>) to specify content that will collapse based on a particular breakpoint. 
  Assign a document unique <code class="text-nowrap" translate="no">id</code> value on the <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code>.
</p>
<p>
  Use the <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> component to control the collapse component, and set the 
  <code class="text-nowrap" translate="no">target</code> prop of <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> to the 
  <code class="text-nowrap" translate="no">id</code> of <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code>.
</p>
<p>
  Set the <code class="text-nowrap" translate="no">toggleable</code> prop on <code class="text-nowrap" translate="no">&lt;b-navbar&gt;</code> 
  to the desired breakpoint you would like content to automatically expand at. Possible <code class="text-nowrap" translate="no">toggleable</code> 
  values are <code class="text-nowrap" translate="no">sm</code>, <code class="text-nowrap" translate="no">md</code>, 
  <code class="text-nowrap" translate="no">lg</code> and <code class="text-nowrap" translate="no">xl</code>. 
  Setting <code class="text-nowrap" translate="no">toggleable</code> to <code class="text-nowrap" translate="no">true</code> 
  (or an empty string) will set the navbar to be always collapsed, while setting it to <code class="text-nowrap" translate="no">false</code> 
  (the default) will disable collapsing (always expanded).
</p>
<p>
  <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> components are left-aligned by default, but should they follow a sibling element like 
  <code class="text-nowrap" translate="no">&lt;b-navbar-brand&gt;</code>, they'll automatically be aligned to the far right. 
  Reversing your markup will reverse the placement of the toggler.
</p>
<p>
  See the first example on this page for reference, and also refer to <a href="/docs/components/collapse" class="font-weight-bold">
  <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code></a> for details on the collapse component.
</p>
<p>
  Besides being used to control a collapse, the <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> 
  can also be used to toggle visibility of the <a href="/docs/components/sidebar" class="font-weight-bold">
  <code class="text-nowrap" translate="no">&lt;b-sidebar&gt;</code></a> component. Just specify the ID of the 
  <code class="text-nowrap" translate="no">&lt;b-sidebar&gt;</code> via the <code class="text-nowrap" translate="no">target</code> prop.
</p>
<p class="m-30">
  Internally, <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> uses the <a href="/docs/directives/toggle" class="font-weight-bold">
  <code class="text-nowrap" translate="no">v-b-toggle</code> directive</a>.
</p>
      
      
      <b-navbar class="mb-5" toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse4"></b-navbar-toggle>
        <b-collapse id="nav-collapse4" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item href="#" disabled>Disabled</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-navbar class="m-40" toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">NavBar</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse5"></b-navbar-toggle>
        <b-collapse id="nav-collapse5" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item-dropdown no-caret href="#" text="Dropdown">
              <template v-slot:button-content>
                Dropdown action
                <link rel="stylesheet" href="">
                <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here</b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

      <h3 class="m-30">Forms</h3>
      <b-row class="m-40">
        <b-col md="4" lg="3">
          <b-input placeholder="Search"></b-input>
<!--          <b-button>Primary</b-button>-->
        </b-col>
      </b-row>

    <div>
    <h2>Custom navbar toggle</h2>
      <p>
        <code class="text-nowrap" translate="no">&lt;b-navbar-toggle&gt;</code> renders the default Bootstrap v4 <em>hamburger</em> (which is a background SVG image). 
        You can supply your own content (such as an icon) via the optionally scoped <code class="text-nowrap" translate="no">default</code> slot. 
        The default slot scope contains the property <code class="text-nowrap" translate="no">expanded</code>, which will be 
        <code class="text-nowrap" translate="no">true</code> when the collapse is expanded, or <code class="text-nowrap" translate="no">false</code> 
        when the collapse is collapsed.
      </p>
      <p class="m-30">
        Note that the <code class="text-nowrap" translate="no">expanded</code> scope property only works when supplying the 
        <code class="text-nowrap" translate="no">target</code> prop as a <code class="text-nowrap" translate="no">string</code>, 
        and not an <code class="text-nowrap" translate="no">array</code>.
      </p>

       <b-navbar toggleable class="mb-3" type="dark" variant="secondary">

        <b-navbar-toggle target="navbar-toggle-collapse">

        </b-navbar-toggle>
        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item href="#">Home</b-nav-item>
            <b-nav-item href="#">Features</b-nav-item>
            <b-nav-item href="#">Pricing</b-nav-item>
            <b-nav-item-dropdown no-caret href="#" text="Dropdown">
              <template v-slot:button-content>
                Dropdown action
                <link rel="stylesheet" href="">
                <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here</b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-navbar toggleable class="mb-5" type="dark" variant="secondary">

    <b-navbar-toggle target="navbar-toggle-collapse">

    </b-navbar-toggle>
    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#">Home</b-nav-item>
        <b-nav-item href="#">Features</b-nav-item>
        <b-nav-item href="#">Pricing</b-nav-item>
        <b-nav-item-dropdown no-caret href="#" text="Dropdown">
          <template v-slot:button-content>
            Dropdown action
            <link rel="stylesheet" href="">
            <inline-svg class="ml-2" :src="CaretDown"></inline-svg>
          </template>
          <b-dropdown-item>Action</b-dropdown-item>
          <b-dropdown-item>Another action</b-dropdown-item>
          <b-dropdown-item>Something else here</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div></script></code></pre>

      <h2 class="mt-5">Printing</h2>
      <p>Navbars are hidden by default when printing. Force them to be printed by setting the <code class="text-nowrap" translate="no">print</code> prop.</p>

     
    </div>

  </div>
</template>

<script>
import CaretDown from "@/assets/images/icons/angle-down.svg"
import InlineSvg from "vue-inline-svg";
import AppButton from "@/components/AppButton";

export default {
  name: "NavBars",
  data(){
    return{
      CaretDown
    }
  },
  components:{
    InlineSvg,
    AppButton
  },
  mounted() {
    document.title = "Navbar";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

</style>
