<template>
  <div>
    <div class="chart-header">
      <drop-down variant="light" text="WEEK 2">
          <template v-slot:items>
              <b-dropdown-item-button>WEEK 1</b-dropdown-item-button>
              <b-dropdown-item-button>WEEK 2</b-dropdown-item-button>
              <b-dropdown-item-button>WEEK 3</b-dropdown-item-button>
          </template>
      </drop-down>
      <drop-down variant="light" text="FEBRUARY 2022">
          <template v-slot:items>
              <b-dropdown-item-button>JANUARY 2022</b-dropdown-item-button>
              <b-dropdown-item-button>FEBRUARY 2022</b-dropdown-item-button>
              <b-dropdown-item-button>MARCH 2022</b-dropdown-item-button>
          </template>
      </drop-down>

    </div>
    <BarChart v-bind="barChartProps" :width="width" :height="height" :plugins=[ChartDataLabels] />
  </div>
</template>

<script >
import { Chart, registerables } from 'chart.js';
import  ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';;
import DropDown from "@/components/DropDown";

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    BarChart,
    Chart,
    ChartDataLabels,
    DropDown
  },

  data(){
    return {
      height: 320,
      width: 540,
      ChartDataLabels
    }
  },
 
  setup() {
    const data = ref([7, 9, 12, 9, 15, 10, 12]);

    const chartData = computed(() => ({
    labels: ['SUN', 'MON', 'TUE', 'WED', 'THU','FRI','SAT'],

      datasets: [
        {
          data: data.value,
          backgroundColor: ['#5561B3'],
          borderRadius: 10,
          borderSkipped: false,

          barThickness: 14,
          borderColor:'#161F5E',
          borderWidth: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0
          },


        },

      ],


    }));

    const options = computed(() => ({
     responsive: true,
     maintainAspectRatio: true,
      scales: {
        x: {
          display:true,
          color: '#fff',
          grid: {
            display: false,
            weight: 0,
            borderColor: '#fff',

          },
          ticks: {
              font: {
                size: 14,
                weight: 'Bold',

              },


            }
        },
        y: {
          display: false,
          grid: {
            display: false,
            
          }
        }
      },
      plugins: {
          legend: {
            display: false
          },

          datalabels: {
            anchor: 'end',
            align: 'top',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 12,
            },

            
            formatter: function(value) {
                return value + '°';
              }
          },

        },
    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });

    return {barChartProps, barChartRef };
  },


});
</script>

<style>

</style>
