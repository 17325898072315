<template>
<div class="wrapper" >
    <title>Badges</title>

  <div class="badges-component">
        <h1 class="title-section">Badges</h1>
        <p class="m-40" style="font-size: 1.5rem">Small and adaptive tag for adding context to just about any content.</p>

        <h2>Overview</h2>
        <p class="m-30">Badges scale to match the size of the immediate parent element by using relative font sizing and em units.
        
            <h1 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h1>
            <h2 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h2>
            <h3 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h3>
            <h4 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h4>
            <h5 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h5>
            <h6 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h6>
        

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <h1 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h1>
  <h2 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h2>
  <h3 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h3>
  <h4 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h4>
  <h5 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h5>
  <h6 class="text-secondary">Example heading <b-badge variant="secondary">New</b-badge></h6>
</div></script></code></pre>

    <b-row>
      <b-col>
        <h2>Contextual variations</h2>
        <p class="m-30">Add any of the following variants via the variant prop to change the appearance of a <code class="text-nowrap" translate="no">&lt;b-badge&gt;</code>: default, primary, success, warning, info, and danger. If no variant is specified default will be used.</p>
        
        <div class="trendy-example">
            <b-badge variant="primary">Primary</b-badge>
            <b-badge variant="secondary">Secondary</b-badge>
            <b-badge variant="success">Success</b-badge>
            <b-badge variant="danger">Danger</b-badge>
            <b-badge variant="warning">Warning</b-badge>
            <b-badge variant="info">Info</b-badge>
            <b-badge variant="light">Light</b-badge>
            <b-badge variant="dark">Dark</b-badge>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-badge variant="primary">Primary</b-badge>
  <b-badge variant="secondary">Secondary</b-badge>
  <b-badge variant="success">Success</b-badge>
  <b-badge variant="danger">Danger</b-badge>
  <b-badge variant="warning">Warning</b-badge>
  <b-badge variant="info">Info</b-badge>
  <b-badge variant="light">Light</b-badge>
  <b-badge variant="dark">Dark</b-badge>
</div></script></code></pre>

        <h3>Conveying meaning to assistive tehnologies</h3>
        <p class="m-40">
          Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive 
          technologies – such as screen readers. Ensure that information denoted by the color is either obvious from the content itself 
          (e.g. the visible text), or is included through alternative means, such as additional text hidden with the 
          <code class="text-nowrap" translate="no">.sr-only</code> class.
        </p>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Pill badges</h2>
        <p class="m-30">Use the pill prop to make badges more rounded (with a larger border-radius and additional horizontal padding). Useful if you miss the badges from Bootstrap v3.</p>
        
        <div class="trendy-example">
            <b-badge pill variant="primary">Primary</b-badge>
            <b-badge pill variant="secondary">Secondary</b-badge>
            <b-badge pill variant="success">Success</b-badge>
            <b-badge pill variant="danger">Danger</b-badge>
            <b-badge pill variant="warning">Warning</b-badge>
            <b-badge pill variant="info">Info</b-badge>
            <b-badge pill variant="light">Light</b-badge>
            <b-badge pill variant="dark">Dark</b-badge>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-badge pill variant="primary">Primary</b-badge>
  <b-badge pill variant="secondary">Secondary</b-badge>
  <b-badge pill variant="success">Success</b-badge>
  <b-badge pill variant="danger">Danger</b-badge>
  <b-badge pill variant="warning">Warning</b-badge>
  <b-badge pill variant="info">Info</b-badge>
  <b-badge pill variant="light">Light</b-badge>
  <b-badge pill variant="dark">Dark</b-badge>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Actionable badges</h2>
        <p class="m-30">
          Quickly provide actionable badges with hover and focus states by specifying either the <code class="text-nowrap" translate="no">href</code> 
          prop (links) or <code class="text-nowrap" translate="no">to</code> prop (router-links):
        </p> 

        <div class="trendy-example">
            <b-badge href="#" variant="primary">Primary</b-badge>
            <b-badge href="#" variant="secondary">Secondary</b-badge>
            <b-badge href="#" variant="success">Success</b-badge>
            <b-badge href="#" variant="danger">Danger</b-badge>
            <b-badge href="#" variant="warning">Warning</b-badge>
            <b-badge href="#" variant="info">Info</b-badge>
            <b-badge href="#" variant="light">Light</b-badge>
            <b-badge href="#" variant="dark">Dark</b-badge>
        </div>       


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-badge href="#" variant="primary">Primary</b-badge>
  <b-badge href="#" variant="secondary">Secondary</b-badge>
  <b-badge href="#" variant="success">Success</b-badge>
  <b-badge href="#" variant="danger">Danger</b-badge>
  <b-badge href="#" variant="warning">Warning</b-badge>
  <b-badge href="#" variant="info">Info</b-badge>
  <b-badge href="#" variant="light">Light</b-badge>
  <b-badge href="#" variant="dark">Dark</b-badge>
</div></script></code></pre>

      </b-col>
    </b-row>



    <b-row>
      <b-col>
        <h2>Outline</h2>
        <p class="m-30">Outline badges are provided via the variant prop.</p>

        <div class="trendy-example">
            <b-badge pill variant="outline-primary">Primary</b-badge>
            <b-badge pill variant="outline-secondary">Secondary</b-badge>
            <b-badge pill variant="outline-success">Success</b-badge>
            <b-badge pill variant="outline-danger">Danger</b-badge>
            <b-badge pill variant="outline-warning">Warning</b-badge>
            <b-badge pill variant="outline-info">Info</b-badge>
            <b-badge pill variant="outline-light">Light</b-badge>
            <b-badge pill variant="outline-dark">Dark</b-badge>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-badge pill variant="outline-primary">Primary</b-badge>
  <b-badge pill variant="outline-secondary">Secondary</b-badge>
  <b-badge pill variant="outline-success">Success</b-badge>
  <b-badge pill variant="outline-danger">Danger</b-badge>
  <b-badge pill variant="outline-warning">Warning</b-badge>
  <b-badge pill variant="outline-info">Info</b-badge>
  <b-badge pill variant="outline-light">Light</b-badge>
  <b-badge pill variant="outline-dark">Dark</b-badge>
</div></script></code></pre>

      </b-col>
    </b-row>

      <h2>Notifications</h2>
      <p class="m-30">Badges can be used as part of links or buttons to provide a counter (or similar flag).</p>

    <b-row>
      <b-col>

        <div class="trendy-example">
            <notification :count="3" variant="primary">
              MESSAGES
            </notification>
            <notification :count="3" variant="secondary">
              MESSAGES
            </notification>
            <notification :count="3" variant="success">
              MESSAGES
            </notification>
            <notification  :count="3" variant="danger">
              MESSAGES
            </notification>  
            <notification   :count="3" variant="warning">
              MESSAGES
            </notification>
            <notification :count="3" variant="info">
              MESSAGES
            </notification>
            <notification :count="3" variant="light">
              MESSAGES
            </notification>
            <notification :count="3" variant="dark">
              MESSAGES
            </notification>
        </div>
  
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <notification  :count="3" variant="primary">
    MESSAGES
  </notification>
  <notification :count="3" variant="secondary">
    MESSAGES
  </notification>
  <notification :count="3" variant="success">
    MESSAGES
  </notification>
  <notification :count="3" variant="danger">
    MESSAGES
  </notification>
  <notification  :count="3" variant="warning">
    MESSAGES
  </notification>
  <notification :count="3" variant="info">
    MESSAGES
  </notification>
  <notification :count="3" variant="light">
    MESSAGES
  </notification>
  <notification :count="3" variant="dark">
    MESSAGES
  </notification>
</div></script></code></pre>


      </b-col>
    </b-row>

    <h2 class="m-30">Notifications outline</h2>

    <b-row>
      <b-col>

        <div class="trendy-example">
            <notification  :count="3" variant="outline-primary">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-secondary">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-success">
              MESSAGES
            </notification>
            <notification  :count="3" variant="outline-danger">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-warning">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-info">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-light">
              MESSAGES
            </notification>
            <notification :count="3" variant="outline-dark">
              MESSAGES
            </notification>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <notification  :count="3" variant="outline-primary">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-secondary">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-success">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-danger">
    MESSAGES
  </notification>
  <notification  :count="3" variant="outline-warning">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-info">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-light">
    MESSAGES
  </notification>
  <notification :count="3" variant="outline-dark">
    MESSAGES
  </notification>
</div></script></code></pre>

        <p>
          Note that depending on how they are used, badges may be confusing for users of screen readers and similar assistive technologies. 
          While the styling of badges provides a visual cue as to their purpose, these users will simply be presented with the content of the badge. 
          Depending on the specific situation, these badges may seem like random additional words or numbers at the end of a sentence, link, or button.
        </p>

        <p>
          Unless the context is clear (as with the "Notifications" example, where it is understood that the "4" is the number of notifications), 
          consider including additional context with a visually hidden piece of additional text.
        </p>


      </b-col>
    </b-row>
  </div>
</div>
</template>

<script>

import Notification from "@/components/Notification";
export default {
  name: "BadgesView",
  data() {
    return {
      variant: "variant"
    }
  },
  components: {
    Notification
    },
  mounted() {
    document.title = "Badges";

    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

.badge{
  min-width: 70px;
  min-height: 20px;
  padding: .35em 1em;

  }

</style>
