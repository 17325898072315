<template>
    <div class="wrapper">
            <title>List view</title>

      <h1 class="title-section">List group</h1>
      <p class="m-40" style="font-size: 1.5rem">
        List groups are a flexible and powerful component for displaying a series of content. 
        List group items can be modified to support just about any content within. 
        They can also be used as navigation via various props.</p>
      <b-row>
        <b-col>
          <h2 class="m-30">Basic example</h2>
          <div class="list-box mb-5 mt-4" >
            <b-list-group flush>
              <b-list-group-item>Cras justo odio</b-list-group-item>
              <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item>Morbi leo risus</b-list-group-item>
              <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item>Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group flush>
    <b-list-group-item>Cras justo odio</b-list-group-item>
    <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item>Morbi leo risus</b-list-group-item>
    <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
    <b-list-group-item>Vestibulum at eros</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <h2>Active items</h2>
          <p class="m-30">Set the active prop on a <code class="text-nowrap" translate="no">&lt;b-list-group-item&gt;</code> to indicate the current active selection.</p>
          <div class="list-box-active mb-5">
            <b-list-group>
              <b-list-group-item active>Cras justo odio</b-list-group-item>
              <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item>Morbi leo risus</b-list-group-item>
              <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item>Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item active>Cras justo odio</b-list-group-item>
    <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item>Morbi leo risus</b-list-group-item>
    <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
    <b-list-group-item>Vestibulum at eros</b-list-group-item>
  </b-list-group>
</div></script></code></pre>


          <h2>Disabled items</h2>
          <p class="m-30">Set the disabled prop on a <code class="text-nowrap" translate="no">&lt;b-list-group-item&gt;</code> to make it appear disabled (also works with actionable items. see below).</p>
          <div class="list-box-active mb-5">
            <b-list-group>
              <b-list-group-item disabled>Cras justo odio</b-list-group-item>
              <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item>Morbi leo risus</b-list-group-item>
              <b-list-group-item disabled>Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item disabled>Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item disabled>Cras justo odio</b-list-group-item>
    <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item>Morbi leo risus</b-list-group-item>
    <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
    <b-list-group-item>Vestibulum at eros</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <h2>Actionable list group items</h2>
          <p class="m-30">Turn a<code class="text-nowrap" translate="no">&lt;b-list-group-item&gt;</code> into an actionable <em>link</em> (<code class="text-nowrap" translate="no">&lt;a href="..."&gt;</code>) by specifying either an href prop or router-link to prop.</p>

          <div class="list-box-active mb-5">
            <b-list-group>
              <b-list-group-item active href="#some-link">Cras justo odio</b-list-group-item>
              <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item href="#">Morbi leo risus</b-list-group-item>
              <b-list-group-item href="#">Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item active href="#some-link">Cras justo odio</b-list-group-item>
    <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item href="#">Morbi leo risus</b-list-group-item>
    <b-list-group-item href="#">Porta ac consectetur ac</b-list-group-item>
    <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <p class="m-30">Or if you prefer <code class="text-nowrap" translate="no">&lt;button&gt;</code> elements over links, set the button prop to true.</p>
          <div class="list-box-active mb-5">
            <b-list-group>
              <b-list-group-item button>Cras justo odio</b-list-group-item>
              <b-list-group-item button>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item button>Morbi leo risus</b-list-group-item>
              <b-list-group-item button disabled>Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item button>Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item button>Cras justo odio</b-list-group-item>
    <b-list-group-item button>Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item button>Morbi leo risus</b-list-group-item>
    <b-list-group-item button disabled>Porta ac consectetur ac</b-list-group-item>
    <b-list-group-item button>Vestibulum at eros</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <p><strong>Notes:</strong></p>
          <ul class="m-40"> 
            <li>
              When the prop <code class="text-nowrap" translate="no">button</code> is 
              <code class="text-nowrap" translate="no">true</code>, all <a href="/docs/components/link" class="font-weight-bold">link related props</a> 
              (other than <code class="text-nowrap" translate="no">active</code>) and the <code class="text-nowrap" translate="no">tag</code> 
              prop will have no effect.
            </li> 
            <li>
              When <code class="text-nowrap" translate="no">href</code> or <code class="text-nowrap" translate="no">to</code> are set, the 
              <code class="text-nowrap" translate="no">tag</code> prop has no effect.
            </li> 
          </ul>

          <h2 class="m-30">Link and buttons</h2>
          <div class="list-box-variant m-40">
            <b-list-group>
              <b-list-group-item button variant="primary">Cras justo odio</b-list-group-item>
              <b-list-group-item button>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item button disabled>Morbi leo risus</b-list-group-item>
              <b-list-group-item button>Porta ac consectetur ac</b-list-group-item>
              <b-list-group-item button>Vestibulum at eros</b-list-group-item>
            </b-list-group>
          </div>

          <h2>Contextual variants</h2>
          <p class="m-30">Use contextual variants to style list items with a stateful background and color, via the variant prop.</p>
          <div class="list-box-variant">
            <b-list-group>
              <b-list-group-item class="mb-3" >Default list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="primary">Primary list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="secondary">Secondary list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="success">Success list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="danger">Danger list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="warning">Warning list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="info">Info list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="light">Light list group item</b-list-group-item>
              <b-list-group-item class="mb-3" variant="dark">Dark list group item</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item>Default list group item</b-list-group-item>
    <b-list-group-item variant="primary">Primary list group item</b-list-group-item>
    <b-list-group-item variant="secondary">Secondary list group item</b-list-group-item>
    <b-list-group-item variant="success">Success list group item</b-list-group-item>
    <b-list-group-item variant="danger">Danger list group item</b-list-group-item>
    <b-list-group-item variant="warning">Warning list group item</b-list-group-item>
    <b-list-group-item variant="info">Info list group item</b-list-group-item>
    <b-list-group-item variant="light">Light list group item</b-list-group-item>
    <b-list-group-item variant="dark">Dark list group item</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <p class="m-30">Contextual variants also work with action items. Note the addition of the hover styling here not present in the previous example. 
            Also supported is the active state; set it to indicate an active selection on a contextual list group item.</p>
          <div class="list-box-variant">
            <b-list-group>
              <b-list-group-item class="mb-3" href="#">Default list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="primary">Primary list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="secondary">Secondary list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="success">Success list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="danger">Danger list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="warning">Warning list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="info">Info list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="light">Light list group item</b-list-group-item>
              <b-list-group-item class="mb-3" href="#" variant="dark">Dark list group item</b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item href="#">Default list group item</b-list-group-item>
    <b-list-group-item href="#" variant="primary">Primary list group item</b-list-group-item>
    <b-list-group-item href="#" variant="secondary">Secondary list group item</b-list-group-item>
    <b-list-group-item href="#" variant="success">Success list group item</b-list-group-item>
    <b-list-group-item href="#" variant="danger">Danger list group item</b-list-group-item>
    <b-list-group-item href="#" variant="warning">Warning list group item</b-list-group-item>
    <b-list-group-item href="#" variant="info">Info list group item</b-list-group-item>
    <b-list-group-item href="#" variant="light">Light list group item</b-list-group-item>
    <b-list-group-item href="#" variant="dark">Dark list group item</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <h3>Conveying meaning to assistive tehnologies</h3>
          <p class="m-40">
            Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. 
            Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, 
            such as additional text hidden using the <code class="text-nowrap" translate="no">.sr-only</code> class.
          </p>

          <h2>With badges</h2>
          <p class="m-30">Add badges to any list group item to show unread counts, activity, and more with the help of some flex utility classes.</p>
          <div class="list-box">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Cras justo odio
                <b-badge variant="primary" pill>14</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Dapibus ac facilisis in
                <b-badge variant="primary" pill>2</b-badge>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Morbi leo risus
                <b-badge variant="primary" pill>1</b-badge>
              </b-list-group-item>
            </b-list-group>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
    <b-list-group-item class="d-flex justify-content-between align-items-center">
      Cras justo odio
      <b-badge variant="primary" pill>14</b-badge>
    </b-list-group-item>

    <b-list-group-item class="d-flex justify-content-between align-items-center">
      Dapibus ac facilisis in
      <b-badge variant="primary" pill>2</b-badge>
    </b-list-group-item>

    <b-list-group-item class="d-flex justify-content-between align-items-center">
      Morbi leo risus
      <b-badge variant="primary" pill>1</b-badge>
    </b-list-group-item>
  </b-list-group>
</div></script></code></pre>


          <h2>Horizontal list groups</h2>
          <p>Set the prop horizontal to true to change the layout of list group items from vertical to horizontal across all breakpoints. 
            Alternatively, set horizontal to a responsive breakpoint (sm, md, lg or xl) to make a list group horizontal starting at that breakpoint's min-width. 
            Currently horizontal list groups cannot be combined with flush list groups.</p>

            <p  class="m-30"><strong>ProTip:</strong> Want equal-width list group items when horizontal? Add the class flex-fill to each list group item.</p>
            
            <b-list-group horizontal>
              <b-list-group-item>Cras justo odio</b-list-group-item>
              <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
              <b-list-group-item>Morbi leo risus</b-list-group-item>
            </b-list-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group horizontal>
    <b-list-group-item>Cras justo odio</b-list-group-item>
    <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
    <b-list-group-item>Morbi leo risus</b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          <h2>Custom content</h2>
          <p class="m-30">Add nearly any HTML or component within, even for linked list groups like the one below, with the help of flexbox utility classes.</p>
          <div class="list-group-variant">
            <b-list-group>
              <b-list-group-item href="#" active class="flex-column align-items-start">
             <div class="d-flex w-100 justify-content-between">
             <h5 class="mb-3">List group item heading</h5>
              <small>3 days ago</small>
             </div>

            <p class="mb-2">
            Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
            </p>

          <small>Donec id elit non mi porta.</small>
              </b-list-group-item>

                <b-list-group-item href="#" disabled class="flex-column align-items-start">
             <div class="d-flex w-100 justify-content-between">
             <h5 class="mb-3">List group item heading</h5>
              <small>3 days ago</small>
             </div>

            <p class="mb-2">
            Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
            </p>

          <small>Donec id elit non mi porta.</small>
              </b-list-group-item>

                <b-list-group-item href="#" disabled class="flex-column align-items-start">
             <div class="d-flex w-100 justify-content-between">
             <h5 class="mb-3">List group item heading</h5>
              <small>3 days ago</small>
             </div>

            <p class="mb-2">
            Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
            </p>

          <small>Donec id elit non mi porta.</small>
              </b-list-group-item>
            </b-list-group>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-list-group>
      <b-list-group-item href="#" active class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-3">List group item heading</h5>
          <small>3 days ago</small>
        </div>

    <p class="mb-2">
    Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
    </p>

    <small>Donec id elit non mi porta.</small>
    </b-list-group-item>

      <b-list-group-item href="#" disabled class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-3">List group item heading</h5>
          <small>3 days ago</small>
        </div>

    <p class="mb-2">
    Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
    </p>

    <small>Donec id elit non mi porta.</small>
      </b-list-group-item>

      <b-list-group-item href="#" disabled class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-3">List group item heading</h5>
          <small>3 days ago</small>
        </div>

    <p class="mb-2">
    Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.
    </p>

    <small>Donec id elit non mi porta.</small>
    </b-list-group-item>
  </b-list-group>
</div></script></code></pre>

          

        </b-col>

      </b-row>
    </div>
</template>

<script>
export default {
  name: "List",
  mounted() {
    document.title = "List view";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
