<template>
<div class="login-wrapper">
          <title>Login</title>

    <div class="login">
        <p class="title">trendy.</p>
          <div class="form-wrapper">
            <b-form class="login-form">
                <b-input type="text" placeholder="Username"></b-input>
                <b-input type="text" placeholder="Password"  id="tooltip-button-show-event" v-model="password" :state="passwordState"></b-input>

                <div class="container-switch">
                   <div class="pair primary-checked">
                        <div class="toggle-small">
                        <label class="toggle-switch round-primary">
                            <input type="checkbox">
                            <div class="slider"></div>
                        </label>
                        <p>Remember me</p>
                        </div>
                    </div>
                </div>
                <app-button variant="success" @click="login">Login</app-button>
            </b-form>

    <b-tooltip :disabled.sync="disabled" ref="tooltip" target="tooltip-button-show-event" placement="bottom" id="login-tooltip" triggers="click">
      Please enter Your password
    </b-tooltip>


        </div>


    </div>

</div>
    
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
    name: "LoginView",
    data() {
        return {
            password: '',
            disabled: false,
        }
    },
    components: {
        AppButton
    },

    methods: {
        login() {
            if (this.password.length < 2 ) {
                this.$refs.tooltip.$emit('open')

            } else {
                this.$refs.tooltip.$emit('close')

            }
        
        }
    },

    computed: {
        passwordState() {
            return this.password.length > 2 ? true : false
        }

    },

    mounted() {
        document.title = "Login";

    }
    
}
</script>