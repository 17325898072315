<template>
  <div class="wrapper">
    <h1>DASHBOARD</h1>

  </div>   
 </template>

<script>
export default {
  name: 'Home',

}
</script>
