<template>
  <div>
    <default-aside></default-aside>
  </div>
</template>

<script>

import DefaultAside from './DefaultAside'

export default {
  name: 'DefaultContainer',
  data() {
    return {

    }
  },
  components: {
    DefaultAside,
  },
  computed: {
    name() {
      return this.$route.name
    },

    list() {
      return this.$route.matched.filter((route) => route.meta.label)
    },
    navigation_list() {
      let list = []
      for (let item of this.items) {
        if (typeof item.meta === 'undefined') {
          list.push(item)
        }
        if (item.meta === store.getters['user/getUserRole']) {
          list.push(item)
        }
      }
      return list
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/actionLogout').then(() => {
      //  this.$router.replace({name: routerName.RN_LOGIN})
      })
    },

    // onItemClick(event, item) {}
}

  

}
</script>
