<template>
  <div class="wrapper">
        <title>Extras</title>


    <div class="extras"> 
    
      <h1 class="title-section">Extras</h1>

            <b-card class="card-1" no-body>
              <div class="icon">
                <img src="./../assets/images/icons/extras-component/person-icon-primary.png" alt="">
              </div>
              <div class="text">
                <p class="p-12-dark">New Leads</p>
                <span class="span-count">205</span>
              </div>
            </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-1" no-body>
    <div class="icon">
      <img src="./../assets/images/icons/extras-component/person-icon-primary.png" alt="">
    </div>
    <div class="text">
      <p class="p-12-dark">New Leads</p>
      <span class="span-count">205</span>
    </div>
  </b-card>
</div></script></code></pre>



            <b-card class="card-2" no-body>
              <div class="icon">
                <img src="./../assets/images/icons/extras-component/person-icon-primary.png" alt="">
              </div>
              <div class="text">
                <p class="p-12-dark">New Leads</p>
                <span class="span-count">205</span>
              </div>
            </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-2" no-body>
    <div class="icon">
      <img src="./../assets/images/icons/extras-component/person-icon-primary.png" alt="">
    </div>
    <div class="text">
      <p class="p-12-dark">New Leads</p>
      <span class="span-count">205</span>
    </div>
  </b-card>
</div></script></code></pre>

            <b-card class="card-contact" no-body>
              <div class="icon">
                <img src="./../assets/images/icons/extras-component/Rectangle 653.png" alt="">
              </div>
              <div class="content">
                <p class="name">Jessica Hike</p>
                <p class="p-12">UI/UX Designer</p>
                <p class="text p-12-dark">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                <app-button variant="primary" custom-class="rounded-pill">Contact Jessica</app-button>
              </div>
            </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-contact" no-body>
    <div class="icon">
      <img src="./../assets/images/icons/extras-component/Rectangle 653.png" alt="">
    </div>
    <div class="content">
      <p class="name">Jessica Hike</p>
      <p class="p-12">UI/UX Designer</p>
      <p class="text p-12-dark">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
      <app-button variant="primary" custom-class="rounded-pill">Contact Jessica</app-button>
    </div>
  </b-card>
</div></script></code></pre>

            <b-card class="new-admin-design" no-body>
            <div class="top-section">
              <div class="image">
                <img src="./../assets/images/icons/extras-component/new-admin-design.png" alt="">
              </div>
              <div class="content">
                <p class="title">New admin Design</p>
                <p class="text">It will be as simple as Occidental</p>
                <img src="./../assets/images/icons/extras-component/img-small-1.png" alt="">
                <img src="./../assets/images/icons/extras-component/img-small-2.png" alt="">
                <div class="icon-h">H</div>
              </div>
            </div>
            <div class="bottom-section">
              <app-button variant="primary" size="sm">Completed</app-button>
              <p>15 Oct, 2020</p>
            </div>
          </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="new-admin-design" no-body>
    <div class="top-section">
      <div class="image">
        <img src="./../assets/images/icons/extras-component/new-admin-design.png" alt="">
      </div>
      <div class="content">
        <p class="title">New admin Design</p>
        <p class="text">It will be as simple as Occidental</p>
        <img src="./../assets/images/icons/extras-component/img-small-1.png" alt="">
        <img src="./../assets/images/icons/extras-component/img-small-2.png" alt="">
        <div class="icon-h">H</div>
      </div>
    </div>
    <div class="bottom-section">
      <app-button variant="primary" size="sm">Completed</app-button>
      <p>15 Oct, 2020</p>
    </div>
  </b-card>
</div></script></code></pre>

          <div class="charts">
            <b-card class="chart-pie" no-body>
              <div class="content">
                <div>
                  <p class="p-14">Target Sales</p>
                  <P>300 Sales</P>
                </div>
                <div class="doughnut-chart-extra">
                  <doughnut-chart-extra></doughnut-chart-extra>
                </div>
              </div>
            </b-card>
            <b-card class="chart-line" no-body>
              <p class="p-14">Target Sales <span>320 Followers</span></p>
              <div class="chart-line-extra">
                <line-chart-extra></line-chart-extra>
              </div>
            </b-card>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <div class="charts">
    <b-card class="chart-pie" no-body>
      <div class="content">
        <div>
          <p class="p-14">Target Sales</p>
          <P>300 Sales</P>
        </div>
        <div>
          <doughnut-chart-extra></doughnut-chart-extra>
        </div>
      </div>
    </b-card>

    <b-card class="chart-line" no-body>
      <p class="p-14">Target Sales <span>320 Followers</span></p>
      <div>
        <line-chart-extra></line-chart-extra>
      </div>
    </b-card>
  </div>
</div></script></code></pre>

          <b-card class="card-3" no-body>
              <div class="icon">
                <img src="./../assets/images/icons/extras-component/icon-sun.svg" alt="">
              </div>
              <div class="content">
                <p class="p-primary">Time to leave</p>
                <span class="span-time">08:00</span>
                <p class="p-dark-bold">Estimate arriving in 34 min</p>
              </div>
          </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-3" no-body>
      <div class="icon">
        <img src="./../assets/images/icons/extras-component/icon-sun.svg" alt="">
      </div>
      <div class="content">
        <p class="p-primary">Time to leave</p>
        <span class="span-time">08:00</span>
        <p class="p-dark-bold">Estimate arriving in 34 min</p>
      </div>
  </b-card>
</div></script></code></pre>

          <b-card class="card-4" no-body>
              <div class="icon">
                <div>
                  <img src="./../assets/images/icons/extras-component/person-icon-primary.png">
                  <p class="p-12">New Leads</p>
                </div>
                <div>
                  <img src="./../assets/images/icons/extras-component/group-dots.svg"/>
                </div>

              </div>
              <hr class="left">
               <hr class="right">
              <div class="content">
                <div>
                  <span class="num-big">205</span><p class="p-12-primary">UP</p>
                </div>
                <div class="second">
                  <p class="p-12">Goal</p><span class="num-small">500</span>
                </div>
              </div>
          </b-card>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-4" no-body>
      <div class="icon">
        <div>
          <img src="./../assets/images/icons/extras-component/person-icon-primary.png">
          <p class="p-12-dark">New Leads</p>
        </div>
        <div>
          <img src="./../assets/images/icons/extras-component/group-dots.svg"/>
        </div>

      </div>
      <hr class="left">
        <hr class="right">
      <div class="content">
        <div>
          <span class="num-big">205</span><p class="p-12-primary">UP</p>
        </div>
        <div class="second">
          <p class="p-12-dark">Goal</p><span class="num-small">500</span>
        </div>
      </div>
  </b-card>
</div></script></code></pre>

          <b-card class="card-5" no-body>
              <div class="flight-info">
                <div>
                  <p class="city">LA</p>
                  <p class="time">09:45</p>
                  <p class="date">April 27, US</p>
                </div>
                <div class="flight-icon">
                  <img src="./../assets/images/icons/extras-component/icon-flight.svg" />
                </div>
                <div>
                  <p class="city">VEN</p>
                  <p class="time">12:05</p>
                  <p class="date">April 27, IT</p>
                </div>
              </div>
              <div class="passenger">
                <p class="label">Passenger</p>
                <p class="name">Jessica Hike</p>
                <div class="circle circle-1"></div>
                <div class="circle circle-2"></div>
              </div>
              <div class="barcode">
                    <!-- <barcode value="1234567890" :options="{ lineColor: '#0275d8'}"></barcode> -->
                    <barcode :value="value"  :options="{displayValue: true}"></barcode>

              </div>
          </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-5" no-body>
      <div class="flight-info">
        <div>
          <p class="city">LA</p>
          <p class="time">09:45</p>
          <p class="date">April 27, US</p>
        </div>
        <div class="flight-icon">
          <img src="./../assets/images/icons/extras-component/icon-flight.svg" />
        </div>
        <div>
          <p class="city">VEN</p>
          <p class="time">12:05</p>
          <p class="date">April 27, IT</p>
        </div>
      </div>
      <div class="passenger">
        <p class="label">Passenger</p>
        <p class="name">Jessica Hike</p>
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
      <div class="barcode">
            <barcode :value="value"  :options="{displayValue: true}"></barcode>
      </div>
  </b-card>
</div></script></code></pre>

          <b-card class="card-6" no-body>
              <div class="info">
                <div class="image"><img src="./../assets/images/icons/extras-component/Rectangle 680.png" alt=""></div>
                <div class="name">
                  <p class="p-bold-dark">Jessica Hike</p>
                  <p class="p-12-primary">UI/UX Designer</p>
                </div>
              </div>
              <div class="date">
                <p class="p-12-primary">11:45 AM</p>
                <div class="notifications">
                  <span>3</span>
                </div>
              </div>
          </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-6" no-body>
      <div class="info">
        <div class="image"><img src="./../assets/images/icons/extras-component/Rectangle 680.png" alt=""></div>
        <div class="name">
          <p class="p-bold-dark">Jessica Hike</p>
          <p class="p-12-primary">UI/UX Designer</p>
        </div>
      </div>
      <div class="date">
        <p class="p-12-primary">11:45 AM</p>
        <div class="notifications">
          <span>3</span>
        </div>
      </div>
  </b-card>
</div></script></code></pre>

          <b-card class="card-7" no-body>
              <div class="info">
                <p class="">UI/UX Designer</p>
                <span class="price">$58,000</span>
                <p class="status"><span>5.2%</span> Up from past week</p>
              </div>
              <div class="graph">
                <img src="./../assets/images/icons/extras-component/icon-graph.svg">
              </div>
          </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card class="card-7" no-body>
      <div class="info">
        <p class="">UI/UX Designer</p>
        <span class="price">$58,000</span>
        <p class="status"><span>5.2%</span> Up from past week</p>
      </div>
      <div class="graph">
        <img src="./../assets/images/icons/extras-component/icon-graph.svg">
      </div>
  </b-card>
</div></script></code></pre>

         <b-card bg-variant="primary" class="card-8" no-body>
              <div class="info">
                <p class="">Bounce rate</p>
                <span class="status">%34</span>
              </div>
              <div class="icon">
                <p>Last 30 days</p>
                <div class="graph">
                  <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
                </div>
              </div>
         </b-card>

        <b-card bg-variant="success" class="card-8" no-body>
              <div class="info">
                <p class="">Bounce rate</p>
                <span class="status">%34</span>
              </div>
              <div class="icon">
                <p>Last 30 days</p>
                <div class="graph">
                  <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
                </div>
              </div>
         </b-card>

        <b-card bg-variant="danger" class="card-8" no-body>
              <div class="info">
                <p class="">Bounce rate</p>
                <span class="status">%34</span>
              </div>
              <div class="icon">
                <p>Last 30 days</p>
                <div class="graph">
                  <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
                </div>
              </div>
         </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="extras">
  <b-card bg-variant="primary" class="card-8" no-body>
      <div class="info">
        <p class="">Bounce rate</p>
        <span class="status">%34</span>
      </div>
      <div class="icon">
        <p>Last 30 days</p>
        <div class="graph">
          <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
        </div>
      </div>
  </b-card>

<b-card bg-variant="success" class="card-8" no-body>
      <div class="info">
        <p class="">Bounce rate</p>
        <span class="status">%34</span>
      </div>
      <div class="icon">
        <p>Last 30 days</p>
        <div class="graph">
          <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
        </div>
      </div>
  </b-card>

<b-card bg-variant="danger" class="card-8" no-body>
      <div class="info">
        <p class="">Bounce rate</p>
        <span class="status">%34</span>
      </div>
      <div class="icon">
        <p>Last 30 days</p>
        <div class="graph">
          <img src="./../assets/images/icons/extras-component/arrow-up-right.svg" >
        </div>
      </div>
  </b-card>
</div></script></code></pre>




    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import VueBarcode from 'vue-barcode'
import LineChartExtra from "@/components/charts/LineChartExtra";
import DoughnutChartExtra from "@/components/charts/DoughnutChartExtra";

export default {
  name: "ExtrasView",
  data () {
    return {
        value: '1.23456789111112',
        options: {},
    }
},
  components: {
    AppButton,
    VueBarcode,
    LineChartExtra,
    DoughnutChartExtra
  },
  mounted() {
    document.title = "Extras";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
