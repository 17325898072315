<template>
<div class="wrapper">
          <title>Pagination</title>

  <!-- Big -->
  <h1 class="title-section">Pagination</h1>
  <p style="font-size: 1.5rem" class="m-40">
      Quick first, previous, next, last, and page buttons for pagination control of another component (such as <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> or lists).  </p>
  
  <h2>Overview</h2>
  <p><code class="text-nowrap" translate="no">&lt;b-pagination&gt;</code> is a custom input component that provides a current page number input control. <br>
    The value should be bound via v-model in your app. Page numbers are indexed from 1. <br>
     The number of pages is computed from the provided prop values for total-rows and per-page.</p>

  <p class="m-30">For pagination that changes to a new URL, use the <code class="text-nowrap" translate="no">&lt;b-pagination-nav&gt;</code> component instead.</p>
  
  <h3 class="m-30">Big</h3>

  <div class="overflow-auto m-40">
   <b-row class="mb-5">
      <b-pagination
        first-number
        last-number
        size="lg"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next">
      </b-pagination>
  </b-row>

  <b-row class="mb-5">
    <b-pagination
      first-number
      last-number
      size="lg"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :limit="limit"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>

  <b-row class="mb-5">
    <b-col>
    <b-pagination
      first-number
      last-number
      size="lg"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
     <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
    <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>

    </b-col>

  </b-row>
  <b-row class="mb-3">
    <b-pagination
      first-number
      last-number
      size="lg"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
    <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>
  </b-row>
</div>





    <!-- Normal -->
  <div class="overflow-auto m-40">
  <h3 class="m-30">Normal</h3>
  <b-row class="mb-5">
    <b-pagination
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>
  <b-row class="mb-5">
    <b-pagination
      pills
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next"
    ></b-pagination>
  </b-row>
  <b-row class="mb-5">
    <b-pagination
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
      <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>
  </b-row>
  <b-row class="mb-3">
    <b-pagination
      first-number
      last-number
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
      <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    
    </b-pagination>
  </b-row>
  </div>


    <!-- Small -->
  <div class="overflow-auto m-40">
  <h3 class="m-30">Small</h3>
  <b-row class="mb-5">
    <b-pagination
      size="sm"
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>
  <b-row class="mb-5">
    <b-pagination
      size="sm"
      first-number
      last-number
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next"
    ></b-pagination>
  </b-row>
  <b-row class="mb-5">
    <b-pagination
      first-number
      last-number
      size="sm"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
      <template v-slot:prev-text>
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>
  </b-row>
  <b-row class="mb-3">
    <b-pagination
      first-number
      last-number
      size="sm"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
      <template v-slot:prev-text>
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>
  </b-row>
  </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
 <b-pagination
    first-number
    last-number
    size="lg"
    v-model="currentPage"
    :total-rows="rows"
    :per-page="perPage"
    prev-text="Previous"
    next-text="Next">
 </b-pagination>

  <b-pagination
      first-number
      last-number
      size="lg"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
  </b-pagination>

  <b-pagination
      first-number
      last-number
      size="lg"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage">
    <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
  </b-pagination>

  <b-pagination
      first-number
      last-number
      size="lg"
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
    <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
     <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
  </b-pagination>
</div></script></code></pre>

<p class="m-40">The slot page is always scoped, while the slots first-text, prev-text, next-text and last-text are optionally scoped. The ellipsis-text slot is not scoped.</p>


<h2>Customizing appearance</h2>
<p class="m-40"><code class="text-nowrap" translate="no">&lt;b-pagination&gt;</code> supports several props/slots that allow you to customize the appearance. All <code class="text-nowrap" translate="no">*-text</code> props are text-only and strip out HTML but you can use their equally named slot counterparts for that.</p>

<h2>Limiting the number of displayed buttons</h2>
<p>To restrict the number of page buttons (including the ellipsis, but excluding the first, prev, next, and last buttons) shown, use the <code class="text-nowrap" translate="no">limit</code> prop to specify the desired number of page buttons (including the ellipsis, if shown). The default <code class="text-nowrap" translate="no">limit</code> is <code class="text-nowrap" translate="no">5</code>. The minimum supported value is <code class="text-nowrap" translate="no">3</code>. When <code class="text-nowrap" translate="no">limit</code> is set to <code class="text-nowrap" translate="no">3</code>, no ellipsis indicators will be shown for practical purposes.</p>
<p>The <code class="text-nowrap" translate="no">first</code> and <code class="text-nowrap" translate="no">last</code> buttons can be optionally hidden by setting the <code class="text-nowrap" translate="no">hide-goto-end-buttons</code> prop.</p>
<p class="m-40">The showing of the <code class="text-nowrap" translate="no">ellipsis</code> can be optionally disabled by setting the <code class="text-nowrap" translate="no">hide-ellipsis</code> prop.</p>

<h3>Small screen support</h3>
<p>On smaller screens (i.e. mobile), some of the <code class="text-nowrap" translate="no">&lt;b-pagination&gt;</code> buttons will be hidden to minimize the potential of the pagination interface wrapping onto multiple lines:</p>
<ul> <li>The ellipsis indicators will be hidden on screens <code class="text-nowrap" translate="no">xs</code> and smaller.</li> <li>Page number buttons will be limited to a maximum of 3 visible on <code class="text-nowrap" translate="no">xs</code> screens and smaller.</li> </ul>
<p class="m-40">This ensures that no more than 3 page number buttons are visible, along with the goto <em>first</em>, <em>prev</em>, <em>next</em>, and <em>last</em> buttons.</p>


<h2>Goto first/last button type</h2>
<p class="m-40">If you prefer to have buttons with the first and last page number to go to the corresponding page, use the first-number and last-number props.</p>

<h2>Button size</h2>
<p class="m-30">Optionally change from the default button size by setting the size prop to either 'sm' for smaller buttons or 'lg' for larger buttons.</p>

<div class="overflow-auto m-40">
  <b-row class="mb-3">
    <b-pagination
      size="sm"
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>

  <b-row class="mb-3">
    <b-pagination
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>

  <b-row class="mb-3">
    <b-pagination
      size="lg"
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>
</div>


  <h2 >Pills</h2>
  <p class="m-30">Easily switch to pill style buttons by setting the pills prop</p>
  <div class="overflow-auto m-40">


  <b-row class="mb-3">
    <b-pagination
      size="sm"
      pills
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>

  <b-row class="mb-3">
    <b-pagination
      pills
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>

  <b-row class="mb-3">
    <b-pagination
      size="lg"
      pills
      first-number
      last-number
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      prev-text="Previous"
      next-text="Next">
    </b-pagination>
  </b-row>
    
  </div>

  <h2>Alignment</h2>
  <p class="m-30">By default the pagination component is left aligned. Change the alignment to center, right (right is an alias for end), or fill by setting the prop align to the appropriate value.</p>
  <div class="overflow-auto m-40">
    <div>
      <h6>Left alignment (default)</h6>

      <b-row class="mb-5">
    <b-pagination
      first-number
      last-number
      size="sm"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
    >
     <template v-slot:prev-text>
       {{ text }}
          <inline-svg :src="ArrowLeft"></inline-svg>
    </template>
    <template v-slot:next-text>
       {{ text }}
          <inline-svg :src="ArrowRight"></inline-svg>
    </template>
    </b-pagination>

      </b-row>

    </div>


    <div class="mb-5">
      <h6 class="text-center">Center alignment</h6>
      <b-pagination
        first-number
        last-number
        size="sm"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
      >
      <template v-slot:prev-text>
        {{ text }}
            <inline-svg :src="ArrowLeft"></inline-svg>
      </template>
      <template v-slot:next-text>
        {{ text }}
            <inline-svg :src="ArrowRight"></inline-svg>
      </template>
      </b-pagination>    
    </div>

    <div class="mb-5">
      <h6 class="text-right">Right (end) alignment</h6>
      <b-pagination size="sm" v-model="currentPage" :total-rows="rows" align="right"></b-pagination>
    </div>

    <div class="mb-5">
      <h6 class="text-center">Fill alignment</h6>
      <b-pagination size="sm" v-model="currentPage" :total-rows="rows" align="fill"></b-pagination>
    </div>
  </div>

  <h2>Preventing a page from being selected</h2>
  <p>You can listen for the page-click event, which provides an option to prevent the page from being selected. The event is emitted with two arguments:</p>
  <ul> 
    <li>
      <code class="text-nowrap" translate="no">bvEvent</code>: The <code class="text-nowrap" translate="no">BvEvent</code> object. Call <code class="text-nowrap" translate="no">bvEvent.preventDefault()</code> to cancel page selection
    </li>
    <li>
      <code class="text-nowrap" translate="no">page</code>: Page number to select (starting with <code class="text-nowrap" translate="no">1</code>)
    </li>
  </ul>

  <p>For accessibility reasons, when using the page-click event to prevent a page from being selected, you should provide some means of notification to the user as to why the page is not able to be selected. It is recommended to use the disabled attribute on the <code class="text-nowrap" translate="no">&lt;b-pagination&gt;</code> component instead of using the page-click event (as disabled is more intuitive for screen reader users).</p>

</div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import ArrowLeft from '@/assets/images/icons/arrow-left-pagination.svg'
import ArrowRight from '@/assets/images/icons/arrow-right-pagination.svg'


export default {
  name: "PaginationView",

  props: {
    text: {
      type: String,
    }
  },

  data() {
    return {
      InlineSvg,
      ArrowLeft,
      ArrowRight,
      rows: 100,
      perPage: 10,
      limit:4,
      currentPage: 1,
    }
  },
  components: {
    InlineSvg
  },
  mounted() {
    document.title = "Pagination";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
