import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import BadgesView from "@/views/BadgesView";
import AlertsView from "@/views/AlertsView"
import ButtonsView from "@/views/ButtonsView";
import DropdownView from "@/views/DropdownView";
import BreadcrumbView from "@/views/BreadcrumbView"
import JumbotronView from "@/views/JumbotronView";
import ModalView from "@/views/ModalsView";
import CardsView from "@/views/CardsView";
import FormsView from "@/views/FormsView"
import InputsView from "@/views/InputsView";
import ListView from "@/views/ListView"
import NavsView from "@/views/NavsView";
import NavBars from "@/views/NavBars";
import PaginationView from "@/views/PaginationView";
import SpinnersView from "@/views/SpinnersView";
import ToastView from "@/views/ToastView";
import PopoversView from "@/views/PopoversView";
import TooltipView from "@/views/TooltipView"
import ProgressBarView from "@/views/ProgressBarView";
import CollapseView from "@/views/CollapseView";
import CarouselView from "@/views/CarouselView";
import SwitchersView from "@/views/SwitchersView";
import CalendarView from "@/views/CalendarView";
import ChartsView from "@/views/ChartsView";
import TableView from "@/views/TableView";
import ExtrasView from "@/views/ExtrasView";
import ChatView from "@/views/ChatView";
import PricingView from '@/views/PricingView';
import ButtonsGroupView from '@/views/ButtonsGroupView';
import LandingView from '@/views/LandingView';
import LandingPageSecond from '@/views/LandingPageSecond'
import DesktopChat from "@/views/DesktopChatView";
import CatalogView from '@/views/CatalogView';
import LoginView from '@/views/LoginView';
const routes = [

  {
    path: '/',
    name: 'Alerts',
    component: AlertsView,
    meta: {
      title: 'Alerts',
    }
  },
  {
    path: '/badges',
    name: 'Badges',
    component: BadgesView
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: AlertsView
  },
  {
    path: '/buttons',
    name: 'Buttons',
    component: ButtonsView
  },
  {
    path: '/dropdowns',
    name: 'Dropdowns',
    component: DropdownView
  },
  {
    path: '/breadcrumbs',
    name: 'Breadcrumbs',
    component: BreadcrumbView
  },
  {
    path: '/cards',
    name: 'Cards',
    component: CardsView
  },
  {
    path: '/jumbotron',
    name: 'Jumbotron',
    component: JumbotronView
  },
  {
    path: '/modals',
    name: 'Modals',
    component: ModalView
  },
  {
    path: '/forms',
    name: 'Forms',
    component: FormsView
  },
  {
    path: '/inputs',
    name: 'Inputs',
    component: InputsView
  },
  {
    path: '/list',
    name: 'List',
    component: ListView
  },
  {
    path: '/navs',
    name: 'Navs',
    component: NavsView
  },
  {
    path: '/nav-bars',
    name: 'NavBars',
    component: NavBars
  },
  {
    path: '/pagination',
    name: 'Pagination',
    component: PaginationView
  },
  {
    path: '/spinners',
    name: 'Spinners',
    component: SpinnersView
  },
  {
    path: '/toast',
    name: 'Toast',
    component: ToastView
  },
  {
    path: '/popovers',
    name: 'Popovers',
    component: PopoversView
  },
  {
    path: '/tooltips',
    name: 'Tooltips',
    component: TooltipView
  },
  {
    path: '/progress-bar',
    name: 'Progress bar',
    component: ProgressBarView
  },
  {
    path: '/collapse',
    name: 'Collapse',
    component: CollapseView
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: CarouselView
  },
  {
    path: '/switchers',
    name: 'Switchers',
    component: SwitchersView
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarView
  },
  {
    path: '/charts',
    name: 'Charts',
    component: ChartsView
  },
  {
    path: '/tables',
    name: 'Tables',
    component: TableView
  },
  {
    path: '/extras',
    name: 'Extras',
    component: ExtrasView
  },
  {
    path: '/chat',
    name: 'Chat',
    component: ChatView
  },
  {
    path: '/desktopchat',
    name: 'DesktopChat',
    component: DesktopChat
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingView
  },
  {
    path: '/buttonsgroup',
    name: 'ButtonsGroup',
    component: ButtonsGroupView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/landing',
    name: 'LandingView',
    component: LandingView
  },
  {
    path: '/landing2',
    name: 'LandingView2',
    component: LandingPageSecond
  },
  {
    path: '/catalog',
    name: 'CatalogView',
    component: CatalogView
  }

]



Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes
})





export default router
