import { render, staticRenderFns } from "./CollapseView.vue?vue&type=template&id=4042c823&scoped=true&"
import script from "./CollapseView.vue?vue&type=script&lang=js&"
export * from "./CollapseView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4042c823",
  null
  
)

export default component.exports