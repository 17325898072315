<template>
  <div class="wrapper">
        <title>Cards</title>

    <h1 class="title-section">Cards</h1>
    <p class="m-40" style="font-size: 1.5rem">A card is a flexible and extensible content container. It includes options for headers and footers, a wide variety of content, contextual background colors, and powerful display options.</p>
    
    <h2>Overview</h2>
    <p>Cards are built with as little markup and styles as possible, but still manage to deliver a ton of control and customization. Built with flexbox, they offer easy alignment and mix well with other components.</p>
    <p><code class="text-nowrap" translate="no">&lt;b-card&gt;</code> has no fixed width to start, so they'll naturally fill the full width of its parent element. This is easily customized via styles or standard Bootstrap v4 sizing classes.</p>
    <p class="m-30">Change the default div root tag to any other HTML element by specifying via the tag prop</p>



    <div>
      <b-card
        title="Card Title"
        :img-src="require('../../src/assets/images/background/image.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 17.5rem;"
        class="mb-2"
      >
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>

        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card
    title="Card Title"
    :img-src="require('../../src/assets/images/background/image.jpg')"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 17.5rem;"
    class="mb-2"
  >
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>

    <b-button href="#" variant="primary">Go somewhere</b-button>
  </b-card>
</div></script></code></pre>  

    <b-row>
      <b-col>
        <h2>Content types</h2>
        <p class="m-40">
          Cards support a wide variety of content, including images, text, list groups, links and more. 
          The following are examples of what's supported inside a <code class="text-nowrap" translate="no">&lt;b-card&gt;</code>
        </p>

        <h3>Card body</h3>
        <p>
          The building block of a <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> is the 
          <code class="text-nowrap" translate="no">&lt;b-card-body&gt;</code> section which provides a padded section within a card.
        </p>
        <p class="m-30">
          By default the <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> content is automatically placed in a 
          <code class="text-nowrap" translate="no">&lt;b-card-body&gt;</code> section:
        </p>

        <div>
          <b-card class="text-center">
            <div class="bg-secondary text-light">
              This is some content within the default <samp>&lt;b-card-body&gt;</samp> block of the
              <samp>&lt;b-card&gt;</samp> component. Notice the padding between the card's border and this
              gray <samp>&lt;div&gt;</samp>.
            </div>
          </b-card>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card class="text-center">
    <div class="bg-secondary text-light">
      This is some content within the default <samp>&lt;b-card-body&gt;</samp> block of the
      <samp>&lt;b-card&gt;</samp> component. Notice the padding between the card's border and this
      gray <samp>&lt;div&gt;</samp>.
    </div>
  </b-card>
</div></script></code></pre>

        <p class="m-30">
          Disable the automatic <code class="text-nowrap" translate="no">&lt;b-card-body&gt;</code> section (and associated padding) by setting the prop 
          <code class="text-nowrap" translate="no">no-body</code> on the <code class="text-nowrap" translate="no">&lt;b-card&gt;</code>.
        </p>

        <div>
          <b-card no-body class="text-center">
            <div class="bg-secondary text-light">
              This is some content without the default <samp>&lt;b-card-body&gt;</samp> section. Notice the
              lack of padding between the card's border and this gray <samp>&lt;div&gt;</samp>.
            </div>
          </b-card>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card no-body class="text-center">
    <div class="bg-secondary text-light">
      This is some content without the default <samp>&lt;b-card-body&gt;</samp> section. Notice the
      lack of padding between the card's border and this gray <samp>&lt;div&gt;</samp>.
    </div>
  </b-card>
</div></script></code></pre>

      <p>
        Note that with <code class="text-nowrap" translate="no">no-body</code> enabled, the content of the 
        <code class="text-nowrap" translate="no">title</code> and <code class="text-nowrap" translate="no">sub-title</code> props will not be rendered.
      </p>

      <p class="m-40">
        Use the <code class="text-nowrap" translate="no">&lt;b-card-body&gt;</code> sub-component to place your own card body anywhere in a 
        <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> component that has <code class="text-nowrap" translate="no">no-body</code> set.
      </p>

      </b-col>
    </b-row>
    
    <b-row>
      <b-col>

        <h2>Titles, text and links</h2>
        <p>
          <em>Card titles</em> are adding via the <code class="text-nowrap" translate="no">title</code> prop, and 
          <em>sub titles</em> are added via the <code class="text-nowrap" translate="no">sub-title</code> prop. 
          The title is rendered using the sub-component <code class="text-nowrap" translate="no">&lt;b-card-title&gt;</code> 
          while the Sub Title is rendered using the sub-component <code class="text-nowrap" translate="no">&lt;b-card-sub-title&gt;</code>.
        </p>
        <p>
          With sub-component <code class="text-nowrap" translate="no">&lt;b-card-text&gt;</code>, paragraph text can be added to the card. The last 
          <code class="text-nowrap" translate="no">&lt;b-card-text&gt;</code> in the card body will have its bottom margin automatically removed (via CSS). 
          Text within <code class="text-nowrap" translate="no">&lt;b-card-text&gt;</code> can also be styled with the standard HTML tags.
        </p>
        <p class="m-30">
          Links can be added and placed next to each other by adding the <code class="text-nowrap" translate="no">.card-link</code> class to a 
          <code class="text-nowrap" translate="no">&lt;a&gt;</code> tag (or <code class="text-nowrap" translate="no">&lt;b-link&gt;</code> component).
        </p>

        <b-row>
          <b-col>
          <b-card title="Jessica Hike" sub-title="">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>
          <a href="#" class="card-link">Card link</a>
          <b-link href="#" class="card-link">Another link</b-link>
        </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card title="Jessica Hike" sub-title="">
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>
    <a href="#" class="card-link">Card link</a>
    <b-link href="#" class="card-link">Another link</b-link>
  </b-card>
</div></script></code></pre>
          </b-col>
        </b-row>

        
          <b-row>
          <b-col>
          <b-card title="Jessica Hike">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>
            <app-button type="rounded" variant="primary">Contact Jessica</app-button>
          </b-card>
          </b-col>
          </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card class="mb-5 mt-5" title="Jessica Hike">
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>
    <app-button type="rounded" variant="primary">Contact Jessica</app-button>
  </b-card>
</div></script></code></pre>    


      </b-col>

    </b-row>


    <b-row>
      <b-col>
        <h2>Images</h2>
        <p>
          The <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> prop <code class="text-nowrap" translate="no">img-src</code> 
          places an image on the top of the card, and use the <code class="text-nowrap" translate="no">img-alt</code> prop to specify a string 
          to be placed in the image's <code class="text-nowrap" translate="no">alt</code> attribute. The image specified by the 
          <code class="text-nowrap" translate="no">img-src</code> prop will be responsive and will adjust its width when the width of the card is changed.
        </p>
        <p class="m-40">
          Alternatively you can manually place images inside <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> using the sub-component 
          <code class="text-nowrap" translate="no">&lt;b-card-img&gt;</code>. See the kitchen sink example below for usage.
        </p>
        
        <h3>Top and bottom</h3>

        <b-card
          title="Card Title"
          :img-src="require('../../src/assets/images/background/image.jpg')"
          img-alt="Image"
          img-top
          style="width: 17.5rem;"
          class="mb-5">
          <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </b-card-text>
              <app-button type="rounded"  variant="primary">Guide</app-button>
              <app-button custom-class="ml-3 btn-gray" variant="light"  type="rounded">Directions</app-button>
        </b-card>


        <b-card
          title="Card Title"
          :img-src="require('../../src/assets/images/background/image.jpg')"
          img-alt="Image"
          img-top
          style="width: 17.5rem;"
          class="mb-5">
          <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </b-card-text>
        </b-card>

        <b-card
          title="Card Title"
          :img-src="require('../../src/assets/images/background/image.jpg')"
          img-alt="Image"
          img-bottom
          style="width: 17.5rem;"
          class="mb-5">
          <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </b-card-text>
        </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card
    title="Card Title"
    :img-src="require('../../src/assets/images/background/image.jpg')"
    img-alt="Image"
    img-top
    style="width: 17.5rem;"
    class="mb-5">
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>
        <app-button type="rounded"  variant="primary">Guide</app-button>
        <app-button custom-class="ml-3 btn-gray" variant="light"  type="rounded">Directions</app-button>
  </b-card>


  <b-card
    title="Card Title"
    :img-src="require('../../src/assets/images/background/image.jpg')"
    img-alt="Image"
    img-top
    style="width: 17.5rem;"
    class="mb-5 ml-5">
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>
  </b-card>

  <b-card
    title="Card Title"
    :img-src="require('../../src/assets/images/background/image.jpg')"
    img-alt="Image"
    img-bottom
    style="width: 17.5rem;"
    class="mb-5 ml-5">
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>
  </b-card>
</div></script></code></pre>

      <div class="img-left-right">
          <h4>Left and Right (or Start and End)</h4>

          <b-row>
              <b-card 
               :img-src="require('../../src/assets/images/background/image.jpg')"
               img-alt="Image" 
               img-left 
               class="mb-3">
                <b-card-text>
                  Some quick example text to build on the card and make up the bulk of the card's content.
                </b-card-text>
              </b-card>

          </b-row>

          <b-row>
              <b-card 
              :img-src="require('../../src/assets/images/background/image.jpg')"
              img-alt="Image" 
              img-right>
                <b-card-text>
                  Some quick example text to build on the card and make up the bulk of the card's content.
                </b-card-text>
              </b-card>
          </b-row>

        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card 
  :img-src="require('../../src/assets/images/background/image.jpg')"
  img-alt="Image" 
  img-left 
  class="mb-3">
    <b-card-text>
      Some quick example text to build on the card and make up the bulk of the card's content.
    </b-card-text>
  </b-card>

  <b-card 
  :img-src="require('../../src/assets/images/background/image.jpg')"
  img-alt="Image" 
  img-right>
    <b-card-text>
      Some quick example text to build on the card and make up the bulk of the card's content.
    </b-card-text>
  </b-card>
</div></script></code></pre>

      <p class="m-40"><strong>Note:</strong> 
      For left and right images, you may need to apply additional styles to classes 
      <code class="text-nowrap" translate="no">.card-img-left</code> and <code class="text-nowrap" translate="no">.card-img-right</code>, 
      as images will "stretch" in height if you have content that is taller than your image. 
      Note headers and footers are not supported when images are left or right aligned. You may find the 
      <a href="#horizontal-card-layout" class="font-weight-bold">Horizontal Card Layout</a> example to be more flexible when creating a responsive horizontal card.
      </p>



      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <h2>Image overlay</h2>
        <p class="m-30">Place the image in the background of the card by setting the boolean prop <code class="text-nowrap" translate="no">overlay</code>:</p>

        <b-card
          overlay
          :img-src="require('../../src/assets/images/background/image.jpg')"
          img-alt="Image"
          text-variant="white"
          title="Card Title"
          style="width: 17.5rem;"
          class="mb-5">
          <b-card-text>
            Some quick example text to build on the card and make up the bulk of the card's content.
          </b-card-text>
        </b-card>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card
    overlay
    :img-src="require('../../src/assets/images/background/card-overlay.jpg')"
    img-alt="Image"
    text-variant="white"
    title="Card Title"
    style="width: 17.5rem;"
    class="mb-5">
    <b-card-text>
      Some quick example text to build on the card and make up the bulk of the card's content.
    </b-card-text>
  </b-card>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <h2>Lazy loaded images</h2>
        <p class="m-40">
          Use the <code class="text-nowrap" translate="no">&lt;b-card-img-lazy&gt;</code> sub-component to lazy load images as they scroll into view. 
          <code class="text-nowrap" translate="no">&lt;b-card-img-lazy&gt;</code> supports the same props as 
          <code class="text-nowrap" translate="no">&lt;b-card-img&gt;</code> as well as many of the props of the 
          <a href="/docs/components/image#lazy-loaded-images" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-img-lazy&gt;</code></a> component.
        </p>  
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <h2>Header and footer</h2>
        <p class="m-30">
          Add an optional header and/or footer within a card via the 
          <code class="text-nowrap" translate="no">header</code>/<code class="text-nowrap" translate="no">footer</code> props or named slots. 
          You can control the wrapper element tags used by setting the <code class="text-nowrap" translate="no">header-tag</code> and 
          <code class="text-nowrap" translate="no">footer-tag</code> props (both default is <code class="text-nowrap" translate="no">div</code>)
        </p>


        
        <b-card class="mb-5" header="Featured">
            <h4>Special title treatment</h4>
            <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
            <app-button variant="primary" type="rounded">Go somewhere</app-button>
        </b-card>

        <b-card class="mb-5" header="Quote">
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
            </h4>
            <p> - Someone famous in Source Title</p>
        </b-card>

        <b-card class="mb-5 text-center" header="Featured">
          <h4>Special title treatment</h4>
          <p>With supporting text below as a natural lead-in to additional content</p>
          <app-button variant="primary" type="rounded">Go somewhere</app-button>
          <template v-slot:footer>
            <p class="marked-light text-center"> 2 days ago</p>
          </template>
        </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card  style="max-width: 31.25;" class="mb-5" header="Featured">
      <h4>Special title treatment</h4>
      <b-card-text>With supporting text below as a natural lead-in to additional content.</b-card-text>
      <app-button variant="primary" type="rounded">Go somewhere</app-button>
  </b-card>

  <b-card  style="max-width: 31.25;" class="mb-5" header="Quote">
      <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
      </h4>
      <p> - Someone famous in Source Title</p>
  </b-card>

  <b-card  style="max-width: 31.25;" class="mb-5 text-center" header="Featured">
    <h4>Special title treatment</h4>
    <p>With supporting text below as a natural lead-in to additional content</p>
    <app-button variant="primary" type="rounded">Go somewhere</app-button>
    <template v-slot:footer>
      <p class="marked-light text-center"> 2 days ago</p>
    </template>
  </b-card>
</div></script></code></pre>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Kitchen sink example</h2>
        <p class="m-30">
          Mix and match multiple content types to create the card you need, or throw everything in there. 
          Shown below are image styles, blocks, text styles, and a list group—all wrapped in a fixed-width card.
        </p>

          <b-card
          no-body
          title="Card Title"
          :img-src="require('../../src/assets/images/background/image.jpg')"
          img-alt="Image"
          img-top
          style="width: 17.5rem"
          class="mb-5">
          <b-card-body>
          <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
          </b-card-body>

          <div class="list-box custom">
          <b-list-group flush>
              <b-list-group-item><h4>Cras justo odio</h4></b-list-group-item>
              <b-list-group-item><h4>Dapibus ac facilisis in</h4></b-list-group-item>
              <b-list-group-item><h4>Vestibulum at eros</h4></b-list-group-item>
          </b-list-group>
          </div>
          <b-card-body>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
         </b-card-body>
         </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card
  no-body
  title="Card Title"
  :img-src="require('../../src/assets/images/background/image.jpg')"
  img-alt="Image"
  img-top
  style="width: 17.5rem"
  class="mb-5">
  <b-card-body>
  <b-card-text>Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
  </b-card-body>

  <div class="list-box custom">
  <b-list-group flush>
      <b-list-group-item><h4>Cras justo odio</h4></b-list-group-item>
      <b-list-group-item><h4>Dapibus ac facilisis in</h4></b-list-group-item>
      <b-list-group-item><h4>Vestibulum at eros</h4></b-list-group-item>
  </b-list-group>
  </div>
  <b-card-body>
      <a href="#" class="card-link">Card link</a>
      <a href="#" class="card-link">Another link</a>
  </b-card-body>
  </b-card>
</div></script></code></pre>


      </b-col>
    </b-row>


        <b-row>
      <b-col>

        <h2>Horizontal layout</h2>
        <p>
           Using a combination of grid components, utility classes and individual card sub-components, cards can be made horizontal in a mobile-friendly and responsive way.
        </p>
        <p class="m-30">
          In the example below, we remove the row grid gutters with the <code class="text-nowrap" translate="no">no-gutters</code> prop on 
          <code class="text-nowrap" translate="no">&lt;b-row&gt;</code> and use <code class="text-nowrap" translate="no">md</code> props on 
          <code class="text-nowrap" translate="no">&lt;b-col&gt;</code> to make the card horizontal at the <code class="text-nowrap" translate="no">md</code> breakpoint. 
          Class <code class="text-nowrap" translate="no">rounded-0</code> removes the rounding of the <code class="text-nowrap" translate="no">&lt;b-card-img&gt;</code> 
          corners while class <code class="text-nowrap" translate="no">overflow-hidden</code> on <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> 
          will appropriately clip the image's corners based on the border-radius of the card. Further adjustments may be needed depending on your card content.
        </p> 

          <div class="horizontal-img-div">
            <b-card no-body class="horizontal" style="width: 30rem; height:">
              <b-row no-gutters>
                <b-col md="6" sm="6" cols="6">
                  <b-card-img :src="require('../../src/assets/images/background/image.jpg')" alt="Image" class="horizontal-img"></b-card-img>
                </b-col>
                <b-col md="6" sm="6" cols="6">
                  <b-card-body title="Card title">
                    <b-card-text>
                      Some quick example text to build on the card title and make up the bulk of the card's content.
                    </b-card-text>
                    <b-card-footer>
                    <a href="#" class="card-link ">Last updated 3 mins ago</a>
                    </b-card-footer>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card> 
          </div>





<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card no-body class="horizontal" style="width: 30rem; height:">
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../../src/assets/images/background/image.jpg')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body title="Card title">
          <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </b-card-text>
          <b-card-footer>
          <a href="#" class="card-link ">Last updated 3 mins ago</a>
          </b-card-footer>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card> 
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <h2>Text variants</h2>
        <p class="m-30">
          By default, cards use dark text and assume a light background. You can reverse that by toggling the color of text within, 
          as well as that of the card's sub-components, via the prop <code class="text-nowrap" translate="no">text-variant</code>. Then, specify a dark background variant.
        </p>

        <b-card bg-variant="dark" text-variant="white" title="Card Title">
          <b-card-text>
            With supporting text below as a natural lead-in to additional content.
          </b-card-text>
          <b-button href="#" variant="primary">Go somewhere</b-button>
        </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card bg-variant="dark" text-variant="white" title="Card Title">
    <b-card-text>
      With supporting text below as a natural lead-in to additional content.
    </b-card-text>
    <b-button href="#" variant="primary">Go somewhere</b-button>
  </b-card>
</div></script></code></pre>



        <h2>Background color variants</h2>
        <p class="m-30">
          Cards include their own variant style for quickly changing the background-color and of a card via the 
          <code class="text-nowrap" translate="no">bg-variant</code> and <code class="text-nowrap" translate="no">border-variant</code> props. 
          Darker solid variants may require setting the prop <code class="text-nowrap" translate="no">text-variant</code> to adjust the text color.
        </p>

        <h3>Solid</h3>


              <b-card bg-variant="primary" header-text-variant="white" text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card bg-variant="secondary" header-text-variant="white" text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
             </b-card>
             <b-card bg-variant="success"  header-text-variant="white" text-variant="white" header="Header" class="mb-5">
                <h4 >Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card bg-variant="danger" header-text-variant="white" text-variant="white" header="Header"  class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>


              <b-card bg-variant="warning" header-text-variant="white" text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card bg-variant="info" header-text-variant="white" text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card bg-variant="light"  text-variant="dark" header="Header" class="mb-5">
                <h4 >Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card bg-variant="dark" header-text-variant="white" text-variant="white" header="Header"  class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-col>
    <b-card bg-variant="primary" header-text-variant="white" text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>

    <b-card bg-variant="warning" header-text-variant="white" text-variant="white" header="Header"  class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
  </b-col>

  <b-col>
    <b-card bg-variant="secondary" header-text-variant="white" text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
      </b-card>

    <b-card bg-variant="info" header-text-variant="white" text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
  </b-col>

  <b-col>
    <b-card bg-variant="success"  header-text-variant="white" text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4 >Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card bg-variant="light" text-variant="dark" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
  </b-col>

  
  <b-col>
    <b-card bg-variant="danger" header-text-variant="white" text-variant="white" header="Header"  class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>

    <b-card bg-variant="dark" header-text-variant="white" text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
  </b-col>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h3>Bordered</h3>


              <b-card border-variant="primary" header-text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card border-variant="secondary" header-text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
             </b-card>
             <b-card border-variant="success"  header-text-variant="white" header="Header" class="mb-5">
                <h4 >Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card border-variant="danger" header-text-variant="white" header="Header"  class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>

      

              <b-card border-variant="warning" header-text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card border-variant="info" header-text-variant="white" header="Header" class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card border-variant="light"  text-variant="dark" header="Header" class="mb-5">
                <h4 >Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>
              <b-card border-variant="dark" header-text-variant="white" header="Header"  class="mb-5">
                <h4>Special title treatment</h4>
                <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
              </b-card>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <b-card border-variant="primary" header-text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="secondary" header-text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="success"  header-text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4 >Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="danger" header-text-variant="white" header="Header"  class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="warning" header-text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="info" header-text-variant="white" header="Header" class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="light"  text-variant="dark" header="Header" class="mb-5" style="width=31.25rem">
      <h4 >Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>
    <b-card border-variant="dark" header-text-variant="white" header="Header"  class="mb-5" style="width=31.25rem">
      <h4>Special title treatment</h4>
      <b-card-text class="text-left">Some quick example text to build on the card title and make up the bulk of the card's content.</b-card-text>
    </b-card>

</div></script></code></pre>

      <h4>Variant to class mapping</h4>
      <p class="m-40">
        BootstrapVue <code class="text-nowrap" translate="no">&lt;b-card&gt;</code> variants are directly mapped to Bootstrap v4 card classes by pre-pending 
        <code class="text-nowrap" translate="no">bg-</code> (for solid) or <code class="text-nowrap" translate="no">border-</code> (for bordered) to the above variant names.
      </p>

      <!-- <h3>Header and footer variants</h3>
      <p class="m-30">
        You can also apply the solid and border variants individually to card headers and footers via the 
        <code class="text-nowrap" translate="no">header-bg-variant</code>, <code class="text-nowrap" translate="no">header-border-variant</code>, 
        <code class="text-nowrap" translate="no">header-text-variant</code>, <code class="text-nowrap" translate="no">footer-bg-variant</code>, 
        <code class="text-nowrap" translate="no">footer-border-variant</code>, and <code class="text-nowrap" translate="no">footer-text-variant</code> props.
      </p>

      <div>
        <b-card
          header="Card Header"
          header-text-variant="white"
          header-tag="header"
          header-bg-variant="dark"
          footer="Card Footer"
          footer-tag="footer"
          footer-bg-variant="success"
          footer-border-variant="dark"
          title="Title"
          style="max-width: 20rem;"
          class="mb-5"
        >
          <b-card-text>Header and footers variants.</b-card-text>
        </b-card>
      </div> -->

      <h3>Conveying meaning to assistive tehnologies</h3>
      <p class="m-40">
        Using color to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – 
        such as screen readers. Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), 
        or is included through alternative means, such as additional text hidden with the <code class="text-nowrap" translate="no">.sr-only</code> class.
      </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Card groups</h2>
        <p class="m-40">
          In addition to styling the content within cards, BootstrapVue includes a <code class="text-nowrap" translate="no">&lt;b-card-group&gt;</code> 
          component for laying out series of cards. For the time being, these layout options are not yet responsive.
        </p>

        <h3>Default card groups</h3>
        <p>Use card groups to render cards as a single, attached element with equal width and height columns. Card groups use display: flex; to achieve their uniform sizing.</p>
        <p class="m-30">When using card groups with footers, their content will automatically line up.</p>




              <b-card-group  class="mb-5">
                    <b-card class="card-1" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
                      <b-card-text>
                      Some quick example text to build on the card title and make up the bulk of the card's content
                      </b-card-text>
                    </b-card>

                    <b-card class="card-2" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
                      <b-card-text>
                      Some quick example text to build on the card title and make up the bulk of the card's content
                      </b-card-text>
                    </b-card>
        
                    <b-card class="card-3" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
                      <b-card-text>
                      Some quick example text to build on the card title and make up the bulk of the card's content
                    </b-card-text>
                    </b-card>
              </b-card-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card-group  class="mb-5">
    <b-card class="card-1" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>

    <b-card class="card-2" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>

    <b-card class="card-3" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image">
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
    </b-card-text>
    </b-card>
  </b-card-group>
</div></script></code></pre>

        <b-card-group  class="mb-5">
          <b-card class="card-1" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
            <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content
            </b-card-text>
            <template #footer>
              <small class="text-muted">Last updated 3 mins ago</small>
            </template>
          </b-card>

          <b-card class="card-2" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
            <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content
            </b-card-text>
            <template #footer>
              <small class="text-muted">Last updated 3 mins ago</small>
            </template>
          </b-card>

          <b-card class="card-3" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
            <b-card-text>
            Some quick example text to build on the card title and make up the bulk of the card's content
          </b-card-text>
            <template #footer>
              <small class="text-muted">Last updated 3 mins ago</small>
            </template>
          </b-card>
        </b-card-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card-group  class="mb-5" style="width:52.5rem;">
    <b-card class="card-1" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card class="card-2" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card class="card-3" title="Card Title" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
    </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>
  </b-card-group>
</div></script></code></pre>

<h3>Card group decks</h3>
<p class="m-30">
  Need a set of equal width and height cards that aren't attached to one another? Use card decks by setting the 
  <code class="text-nowrap" translate="no">deck</code> prop. And just like with regular card groups, card footers in decks will automatically line up.
</p>

    <b-card-group deck>
    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>

    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>
  </b-card-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card-group deck style="width: 58.125rem">
    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>

    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
    </b-card>
  </b-card-group>
</div></script></code></pre>


    <b-card-group deck >
    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>
  </b-card-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card-group deck style="width: 58.125rem">
    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>

    <b-card title="Card Title" style="width:17.5rem" :img-src="require('../../src/assets/images/background/image.jpg')" img-alt="Image" img-top>
      <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content
      </b-card-text>
      <template #footer>
        <small class="text-muted">Last updated 3 mins ago</small>
      </template>
    </b-card>
  </b-card-group>
</div></script></code></pre>

        <h3>Card column groups</h3>
        <p>
          Cards can be organized into Masonry-like columns with by wrapping them in a 
          <code class="text-nowrap" translate="no">&lt;b-card-group&gt;</code> with the prop 
          <code class="text-nowrap" translate="no">columns</code> set. 
          Cards are built with CSS column properties instead of flexbox for easier alignment. 
          Cards are ordered from top to bottom and left to right.
        </p>

       <p class="m-40">
         Heads up! Your mileage with card columns may vary. To prevent cards breaking across columns, we must set them to display: 
         inline-block as column-break-inside: avoid isn't a bulletproof solution yet.
      </p>

<h2>Nav integration</h2>
<p class="m-30">Integrate <a href="/docs/components/nav" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-nav&gt;</code></a> into card headers easily.</p>

        <b-card  style="max-width: 31.25;" class="mb-5 text-center card-navigation custom-nav">
          <template #header>
            <b-nav>
              <b-nav-item active>Active</b-nav-item>
              <b-nav-item>Link</b-nav-item>
              <b-nav-item disabled>Disabled</b-nav-item>
            </b-nav>
          </template>
          <h4>Special title treatment</h4>
          <p>With supporting text below as a natural lead-in to additional content</p>
           <app-button variant="primary" type="rounded">Go somewhere</app-button>
        </b-card>

        <b-card  style="max-width: 31.25;" class="mb-5 text-center card-navigation">
          <template #header>
            <b-nav>
              <b-nav-item active class="rounded">Active</b-nav-item>
              <b-nav-item>Link</b-nav-item>
              <b-nav-item disabled>Disabled</b-nav-item>
            </b-nav>
          </template>
          <h4>Special title treatment</h4>
          <p>With supporting text below as a natural lead-in to additional content</p>
          <app-button variant="primary" type="rounded">Go somewhere</app-button>

        </b-card>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card  style="max-width: 31.25;" class="mb-5 text-center card-navigation">
    <template #header>
      <b-nav >
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
    </template>
    <h4>Special title treatment</h4>
    <p>With supporting text below as a natural lead-in to additional content</p>
      <app-button variant="primary" type="rounded">Go somewhere</app-button>
  </b-card>

  <b-card  style="max-width: 31.25;" class="mb-5 text-center card-navigation">
    <template #header>
      <b-nav pills>
        <b-nav-item active class="rounded">Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
    </template>
    <h4>Special title treatment</h4>
    <p>With supporting text below as a natural lead-in to additional content</p>
    <app-button variant="primary" type="rounded">Go somewhere</app-button>
  </b-card>
</div></script></code></pre>



      </b-col>
    </b-row>



  </div>
</template>

<script>
import VisaIcon from "@/assets/images/icons/visa.svg"
import PayPalIcon from "@/assets/images/icons/paypal.svg"
import MasterCardIcon from "@/assets/images/icons/mastercard.svg"
import InlineSvg from "vue-inline-svg";
import AppButton from "@/components/AppButton";
import Background from "@/assets/images/background/card-background.jpg";
export default {
  name: "CardsView",
  data(){
    return {
      VisaIcon,
      PayPalIcon,
      MasterCardIcon,
      Background
    }
  },
  components: {
    AppButton,
    InlineSvg
  },
  mounted() {
    document.title = "Cards";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
