<template>
  <div>
    <LineChart v-bind="barChartProps" :width="width" :height="height" />
  </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
import { LineChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';

Chart.register(...registerables);

export default defineComponent ({
  name: "App",
  components: {
    LineChart
  },

    data(){
    return {
      height: 540,
      width: 1120
    }
  },

  setup() {

    const data = ref([18, 26, 16, 17, 13, 18.7, 17, 25, 16, 22, 23, 23]);

    const chartData = computed(() => ({
      labels: ['', '02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', ''],

      datasets: [
        {
          data: data.value,
          backgroundColor: '#5561B3',
          borderColor: '#5561B3',
          lineTension: 0.3,
          borderWidth: 3,
          //fill: false,
          fontColor: '#5561B3',

        }

      ],

    }));

    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        point: {
          radius: 0
       }
      },
      scales: {
        x: {
          display: true,
          beginAtZero: false,
          grid: {
            display: false
          },
          ticks: {
               font: {
                    weight: 'Bold',
                }
          }
         },
        y: {
          display: true,
          beginAtZero: true,
          grid: {
            display: true,
            borderColor: '#fff'

          },
          min: 9,
          max: 27,
          ticks: {
                stepSize:3,
                //beginAtZero: true,
                display: true,
                fontColor: '#5561B3',
                font: {
                    weight: 'Bold',
                    backgroundColor: '#5561B3',
                }

            }
        },


      },
        plugins: {
          legend: {
            display: false
          },
          subtitle: {
            display: true,
            text: '   STATISTICS',
            align: 'start',
            color: '#161F5E',
            font: {
                family: "'Poppins', sans-serif",
                size: 18,
                weight: 500,
            },
            padding: {
                left: 30,
                top:31,
                bottom: 34
            },


        }

          // subtitle: {
          //   display: true,
          //   text: 'STATISTICS',
          //   align: start,
          //   color: 'black',
          //   font: {
          //     family: "'Poppins', sans-serif",
          //     size: 10,
          //     weight: 500,
          //   },
          //   padding: {
          //     bottom: 34
          //   }
          // }
        },

    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });

    return {
      barChartProps,
      barChartRef
    };
  }


})
</script>

<style scoped>

</style>
