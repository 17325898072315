<template>
  <transition name="fade" appear>
  <b-modal v-model="show" no-close-on-esc no-close-on-backdrop hide-header-close>
    <template slot="modal-header">
      <h3 class="modal-title">{{title}}</h3>
      <button type="button" aria-label="Close" class="close" @click="cancelEvent">×</button>
    </template>
    <slot><span >{{ box_message }}</span></slot>
    <div slot="modal-footer">
      <slot name="modal-footer">
        <b-button  @click="confirmEvent" >Confirm</b-button>
        <b-button class="mr-2 ml-2 pl-2" @click="cancelEvent">Cancel</b-button>
      </slot>
    </div>
  </b-modal>
  </transition>
</template>

<script>
export default {
  name: "AppConfirmationDialog",
  props: {
    show: {
      required: true,
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Delete confirmation"
    },
   box_message:{
     type:String,
     default:"Are you sure you want to delete the selected item?"
   },
    id: {
      default: null
    },

  },
  methods: {
    confirmEvent() {
      this.$emit('confirm', this.id)
    },
    cancelEvent() {
      this.$emit('cancel', this.id)
    }
  },
}
</script>

<style scoped>

</style>
