<template>
  <b-form-group class="mb-0 app-select ">

    <b-dropdown  :toggle-class="toggleClass"
                :size="size" :id="component_id"
                :name="component_id"
                :variant="variant" :disabled="disabled"
                class="w-100"
                :boundary="boundary">
      <template v-slot:button-content>
        <div class="text-holder">
          <slot name="text" :item="value">
            <span v-html="text"></span>
          </slot>
        </div>
        <!-- <inline-svg v-if="state !== false && !disabled" :src="AngleDown"></inline-svg> -->

      </template>

      <div class="dropdown_field--options">

        <template v-for="(optionItem, optionIndex) in options_data">
          <template v-if="Array.isArray(optionItem)">
            <b-dropdown-group header-classes="pb-1 pt-1" header-variant="light" :header="optionIndex" :key="optionItem[ValueField]">

              <b-dropdown-item
                :ref="`dropdownItem-${optionGroupItem[ValueField]}`"
                v-for="(optionGroupItem) in optionItem"
                :key="optionGroupItem[ValueField]"
                :active="isActive(optionGroupItem)"
                @click="change(optionGroupItem)">
                <div v-html="getOptionLabel(optionGroupItem)"></div>
              </b-dropdown-item>
            </b-dropdown-group>
          </template>
          <template v-else>
          <b-dropdown-group :key="optionItem[ValueField]">
            <b-dropdown-item :key="optionItem[ValueField]"
                             :ref="`dropdownItem-${optionItem[ValueField]}`"
                             :active="isActive(optionItem)"
                             @click="change(optionItem)">
              <slot name="text" :item="optionItem">
                <span v-html="getOptionLabel(optionItem)"></span>
              </slot>
            </b-dropdown-item>
          </b-dropdown-group>
          </template>
        </template>
      </div>

    </b-dropdown>
  </b-form-group>

</template>

<script>
import _cloneDeep from "lodash/cloneDeep"
import InlineSvg from "vue-inline-svg";
import AngleDown from "@/assets/images/icons/angle-down.svg"
export default {
  name: "AppSelectList",
  props: {
    rounded:{
      type:Boolean,
      default:false
    },
    value: {
      default: null
    },
    options: {
      type: Array | Object,
      default: () => {
        return ['Option 1', 'Option 2', 'Option 3', 'Option 4']
      }
    },
    ValueField: {
      type: String,
      default: 'value'
    },
    TextField: {
      type: String,
      default: 'text'
    },
    variant: {
      type: String,
      default: 'default'

    },
    size: {
      type: String,
      default:'md'
    },
    disabled: {
      type: Boolean
    },
    boundary: {
      type: String
    },
    state: {
      default: null
    },
    SearchEmptyItem: {
      type: Boolean
    },
    SearchInput: {
      type: Boolean
    },

    returnType: {
      type: String,
      default: 'value'
    },

    //elements: Object,


  },
  data() {
    return {
      keyword: "",
      AngleDown,
    }
  },
  components:{
    InlineSvg
  },
  computed: {
      toggleClass(){
      let toggle_class = ''

      if(this.state === false){
        toggle_class += 'border-danger'
      }
      if(this.rounded){
        toggle_class = ' toggle-rounded' + '-' +this.size
      } else {
        if(this.size === 'lg') {
          toggle_class += ' toggle-lg'
        }
        if(this.size === 'sm') {
          toggle_class += ' toggle-sm'
        }
      }
      return toggle_class
    },
    emptyItem() {
      return {
        [this.ValueField]: null,
        [this.TextField]: null
      }
    },
    component_input_id() {
      return 'list_search_auto_input_' + this._uid
    },
    component_id() {
      return 'list_select_' + this._uid
    },

    text() {
      let node = undefined;

      if (Array.isArray(this.options_data)) {
        node = this.options_data.find(item => item[this.ValueField] == this.value)
      } else {

        Object.keys(this.options_data).forEach(objectKey => {
          if (!node && this.options_data.hasOwnProperty(objectKey)) {

            node = this.options_data[objectKey].find(item => item[this.ValueField] == this.value)
          }
        })
      }

      if (typeof node === 'undefined') {
        if (this.value && typeof this.value === 'object' && this.value[this.TextField]) {
          return this.value[this.TextField]
        } else {
          return ''
        }

      }
      return node[this.TextField]
    },
    options_data() {

      return this.getFilteredList(this.options)
    }
  },

  methods: {
    getOptionLabel(item) {
      if (item.hasOwnProperty(this.TextField)) {
        return item[this.TextField]
      }
      return item
    },
    change(item) {
      if (typeof item[this.ValueField] !== 'undefined') {
        if (this.returnType === 'object') {
          return this.$emit('input', _cloneDeep(item))
        } else {
          return this.$emit('input', item[this.ValueField])
        }
      }
      return this.$emit('input', item)
    },
    reset() {
      this.$emit('input', this.emptyItem[this.ValueField])
    },
    isActive(currentItem) {

      if (currentItem[this.ValueField] == this.value) {
        return true
      }
      return false
    },


    getFilteredList(list) {
      if (this.keyword) {
        if (Array.isArray(list)) {
          return list.filter(el => el[this.TextField].toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)
        } else {

          return Object.keys(list).reduce((map, category) => {
            let items = []
            if (Array.isArray(list[category])) {
              items = list[category].filter(el => el[this.TextField].toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1)
            }

            if (items.length) {
              return {
                ...map,
                [category]: items
              }
            } else {
              return map
            }
          }, {})
        }
      } else {
        return list
      }
    }
    
  },
}
</script>

<style scoped>

</style>
