<template>
  <div class="horizontal-wrapper">
    <p>PAGES VISITED</p>
    <BarChart v-bind="barChartProps" :width="width" :height="height" :plugins=[ChartDataLabels] >
    </BarChart>
  </div>
</template>

<script >
import { Chart, registerables } from 'chart.js';
import  ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    BarChart,
    ChartDataLabels
  },

  data() {
      return {
       height: 440,
       width: 800,
       ChartDataLabels

      }

  },
  setup() {
    const data3 = ([64, 48, 36, 78, 56, 92, 42]);


    const data = ref ([64, 48, 36, 78, 56, 92, 42]);
    const data2 = ref ([100, 100, 100, 100, 100, 100, 100]);
    const labels =  (['/index.html', '/about.html', '/product.html', '/categories.html', '/contact.html','/users.html','/blog.html']);

    const chartData = computed(() => ({
      labels: ['/index.html', '/about.html', '/product.html', '/categories.html', '/contact.html','/users.html','/blog.html'],

      datasets: [
        {
          label: "",
          backgroundColor: "#5561B3",
          data : data.value,
          unitSuffix: "%",
          borderRadius:15,
          barThickness: 14,
          borderSkipped: false,
        },
        {
          label: "",
          backgroundColor: '#161F5E',
          data : data2.value,
          borderRadius:5,
          barThickness: 14,
          borderSkipped: false,


        }]
      
    }));

    const options = computed(() =>({
      indexAxis: 'y',
      responsive: true, 
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          beginAtZero: true,
            grid: {
              display: false,
              borderColor: '#F4F5FC',
              offset: true,
              beginAtZero: false,

            },
        },
        y: {
          stacked: true,
          display: true,
          beginAtZero: false,

            grid: {
              display: false,
              beginAtZero: false,
              borderColor: '#fff',
              offset: true
            },
            ticks: {
              padding: 50,

            }
        },


      },


        plugins: {
          legend: {
            display: false
          },

          datalabels: {
            anchor: 'center',
            align: 'center',
            color: '#fff',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 10,
            },

            padding: {
              top: 8,
              //left: 30

            },
            
            formatter: function(value) {
                if(value === 100) {
                  return null;
                }
                return value + '%';
                
              }
          },

          tooltip: {
            enabled: true,
            callbacks: {
            label: function(tooltipItem, chartData) {            
              for (let i = 0; i < labels.length; i++) {
                if(tooltipItem.label === labels[i]){
                  return data3[i] + '%'
                }
               }
              }
             }
            }
           },



    }));


    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });



    return {barChartProps, barChartRef };
  },

});
</script>

<style>

</style>
