<template>
  <b-button
    :style="customStyle"
    :class="customClass"
    :size="size"
    :variant="variant"
    :block="block"
    :href="href"
    @click="$emit('click')"
  >
    <b-spinner class="mr-2" medium :size="size" v-if="loader" variant="light" />
    <inline-svg class="svg-icon" v-else-if="icon" :src="icon"></inline-svg>
    <slot></slot>
  </b-button>
</template>

<script>
import InlineSvg from "vue-inline-svg";
export default {
  name: "AppButton",
  props: {
    variant: {
      type: String
      //default: 'transparent'
    },
    size: {
      type: String
    },
    href: {
      type: String
    },
    loader: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    position: {
      type: String,
      default: "center"
    },
    customClass: {
      type: String
    },
    block: {
      type: Boolean,
      default: false
    }
  },

  components: {
    InlineSvg
  },
  computed: {
    customStyle() {
      return this.width && this.height
        ? "width:" + this.width + "; height:" + this.height
        : "";
    }
  }
};
</script>

<style scoped></style>
