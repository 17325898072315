<template>
  <div>
    <LineChart v-bind="barChartProps" :width="width" :height="height" />
  </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
import { LineChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';

Chart.register(...registerables);

export default defineComponent ({
  name: "App",
  components: {
    LineChart
  },

    data(){
    return {
      height: 30,
      width: 200
    }
  },

  setup() {

    const data = ref([ 0, 50, -50, 2, -40, 95, -10, 0]);

    const chartData = computed(() => ({
      labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', '07 JAN', '08 JAN'],

      datasets: [
        {
          data: data.value,
          backgroundColor: '#fff',
          borderColor: '#5561B3',
          lineTension: 0.3,
          borderWidth: 2,
          fill: false,
          legend: {
              display: false
          },
          responsive: true,
          maintainAspectRatio: false

       }

      ],


    }));

    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0
       }
      },
      scales: {
        x: {
          display: false,
          beginAtZero: false,
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          grid: {
            display: false
          }
        }
      },
      plugins: {
          legend: {
            display: false
          },
        },

    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });

    return {
      barChartProps,
      barChartRef
    };
  }




})
</script>

<style scoped>

</style>
