<template>
<div class="wrapper">
      <title>Cards</title>


  <h1 class="title-section">Carousel</h1>
  <p class="m-30" style="font-size: 1.5rem">
    The carousel is a slideshow for cycling through a series of content, built with CSS 3D transforms. 
    It works with a series of images, text, or custom markup. It also includes support for previous/next controls and indicators.
  </p>

  <b-row>
    <b-col>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="interval"
        controls
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="carousel-custom"
      >
        <b-carousel-slide
        :img-src="require('../../src/assets/images/background/image.jpg')"
        ></b-carousel-slide>

        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <b-carousel-slide        
        :img-src="require('../../src/assets/images/background/image.jpg')"

        ></b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>

      </b-carousel>
    </b-col>
  </b-row>
<pre><code class="language-html" data-label="Copy"><script type="text/plain" class="language-html">
<template>
  <b-carousel
  id="carousel-1"
  v-model="slide"
  :interval="interval"
  controls
  img-width="1024"
  img-height="480"
  style="text-shadow: 1px 1px 2px #333;"
  @sliding-start="onSlideStart"
  @sliding-end="onSlideEnd"
  class="carousel-custom"
  >
  <b-carousel-slide
    :img-src="require('../../src/assets/images/background/image.jpg')">
  </b-carousel-slide>

  <b-carousel-slide 
    :img-src="require('../../src/assets/images/background/image.jpg')">
  </b-carousel-slide>

  <b-carousel-slide 
    :img-src="require('../../src/assets/images/background/image.jpg')">
  </b-carousel-slide>
  <b-carousel-slide>
    <template #img>
      <img
        class="d-block img-fluid w-100"
        width="1024"
        height="480"
        :src="require('../../src/assets/images/background/image.jpg')"
        alt="image slot"
      >
    </template>
  </b-carousel-slide>

  </b-carousel>
 </template>
</script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
 export default {
  name: "CarouselView",
  data() {
    return {
      slide: 0,
      slide_1: 0,
      slide_2: 0,
      slide_3: 0,
      sliding: null,
      interval:30000
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
&lt;/script&gt;
</code></pre>



<p class="m-40">Please be aware that nested carousels are not supported.</p>

<h2>Sizing</h2>
<p>
  Carousels don't automatically normalize slide dimensions. As such, you may need to use additional utilities or custom styles to appropriately size content. 
  When using images in each slide, ensure they all have the same dimensions (or aspect ratio).
</p>

<p>
  When using <code class="text-nowrap" translate="no">img-src</code> or <code class="text-nowrap" translate="no">img-blank</code> on 
  <code class="text-nowrap" translate="no">&lt;b-carousel-slide&gt;</code>, you can set the image width and height via the 
  <code class="text-nowrap" translate="no">img-width</code> and <code class="text-nowrap" translate="no">img-height</code> props on 
  <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code> and have these values automatically applied to each 
  <code class="text-nowrap" translate="no">carousel-slide</code> image.
</p>

<p>Note that images will still be responsive and automatically grow or shrink to fit within the width of its parent container.</p>

<p class="m-40">
  Internally, <code class="text-nowrap" translate="no">&lt;b-carousel-slide&gt;</code> uses the 
  <a href="/docs/components/image" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-img&gt;</code></a> component to render the images. 
  The <code class="text-nowrap" translate="no">img-*</code> props map to the corresponding props available to <code class="text-nowrap" translate="no">&lt;b-img&gt;</code>.
</p>

<h3>Interval</h3>
<p>
  Carousel defaults to an interval of <code class="text-nowrap" translate="no">5000</code>ms (5 seconds). 
  You can change the interval between slides by setting the <code class="text-nowrap" translate="no">interval</code> 
  prop to the desired number of milliseconds. The smallest supported sliding interval is 1000ms (1 second).
</p>

<p class="m-40">
  In browsers where the <a href="https://www.w3.org/TR/page-visibility/" target="_blank" rel="noopener">Page Visibility API</a> is supported, 
  the carousel will avoid sliding when the webpage is not visible to the user (such as when the browser tab is inactive, the browser window is minimized, etc.). 
  Sliding will resume when the browser tab is active.
</p>

<h3>Pausing the carousel</h3>
<p>
  To pause the carousel from auto sliding, set the <code class="text-nowrap" translate="no">interval</code> prop to 
  <code class="text-nowrap" translate="no">0</code>. To restart a paused carousel, set the <code class="text-nowrap" translate="no">interval</code> 
  back to the desired number of ms.
</p>

<p>
  When the carousel is paused, the user can still switch slides via the controls (if enabled) or touch swipe (on touch enabled devices, if not disabled).
</p>

<p class="m-40">
  When the users mouse hovers the carousel it will automatically pause, and will automatically restart when the mouse leaves the carousel. 
  To disable this feature, set the <code class="text-nowrap" translate="no">no-hover-pause</code> prop on 
  <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code>.
</p>

<h2>Controls and indicators</h2>
<p>Set the prop <code class="text-nowrap" translate="no">controls</code> to enable the previous and next control buttons.</p>
<p>Set the prop <code class="text-nowrap" translate="no">indicators</code> to show the slide indicator buttons.</p>
<p class="m-30">Both indicators and controls can be set at the same time or independently.</p>

  <b-row>
    <b-col>
      <b-carousel
        id="carousel-1"
        v-model="slide_2"
        :interval="interval"
        controls
        indicators
        class="carousel-custom"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
        :img-src="require('../../src/assets/images/background/image.jpg')"
        ></b-carousel-slide>

        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
  </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-carousel
    id="carousel-1"
    v-model="slide_2"
    :interval="interval"
    controls
    indicators
    class="carousel-custom"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    >
    <b-carousel-slide
    :img-src="require('../../src/assets/images/background/image.jpg')"
    ></b-carousel-slide>

    <b-carousel-slide 
    :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>

    <b-carousel-slide 
    :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../../src/assets/images/background/image.jpg')"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>
  </b-carousel>
</div></script></code></pre>

<h2>Rounded indicators</h2>
    <b-row class="m-40">
    <b-col>
    <b-carousel
        id="carousel-1"
        v-model="slide_3"
        :interval="interval"
        controls
        indicators
        class="carousel-custom indicators"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide  >
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>

            <h1>First slide</h1>
            <p>Nulla vitae elit libero.</p>


        </b-carousel-slide>

        <!-- Slides with custom text -->
        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
          <h1>Hello world!</h1>
        </b-carousel-slide>

        <!-- Slides with image only -->
        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <!-- Slides with img slot -->
        <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>

        <!-- Slide with blank fluid image to maintain slide aspect ratio -->
        <b-carousel-slide caption="Blank Image" class="blank-img" img-blank img-alt="Blank image">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
    </b-row>

<div class="mt-5">
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
 <b-carousel
        id="carousel-1"
        v-model="slide_3"
        :interval="interval"
        controls
        indicators
        class="carousel-custom indicators"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide  >
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')">
              alt="image slot"
            >
          </template>

            <h1>First slide label 3</h1>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>


        </b-carousel-slide>

        <!-- Slides with custom text -->
        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
          <h1>Hello world!</h1>
        </b-carousel-slide>

        <!-- Slides with image only -->
        <b-carousel-slide 
        :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <!-- Slides with img slot -->
        <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>

        <!-- Slide with blank fluid image to maintain slide aspect ratio -->
        <b-carousel-slide caption="Blank Image" class="blank-img" img-blank img-alt="Blank image">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
            a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
          </p>
        </b-carousel-slide>
      </b-carousel>
</div></script></code></pre>
</div>




<h2>Carousel slide content</h2>
<p class="m-30"><code class="text-nowrap" translate="no">b-carousel-slide</code> provides several props and slots for placing content in the slide.</p>

<h2>Props</h2>
<ul> 
  <li>
    <code class="text-nowrap" translate="no">caption</code> Text to use as the main title on the slide (placed inside the inner element which has the class 
    <code class="text-nowrap" translate="no">carousel-caption</code>)
  </li> 
  <li>
    <code class="text-nowrap" translate="no">text</code> Textual placed under the title (placed inside the inner element which has the class 
    <code class="text-nowrap" translate="no">carousel-caption</code>)
  </li> 
  <li>
    <code class="text-nowrap" translate="no">img-src</code> URL of image to be placed into the background of the slide
  </li> 
  <li>
    <code class="text-nowrap" translate="no">caption-html</code> Alternate prop to the 
    <code class="text-nowrap" translate="no">caption</code> prop, which supports HTML strings
  </li> 
  <li>
    <code class="text-nowrap" translate="no">html</code> Alternate prop to the 
    <code class="text-nowrap" translate="no">text</code> prop, which supports HTML strings
  </li> 
</ul>

<p class="alert alert-danger m-40"> 
  <strong>Warning:</strong> Be cautious of using the caption-html and html props to display user supplied content, as it may make your application vulnerable to 
  <a class="alert-link" href="https://en.wikipedia.org/wiki/Cross-site_scripting"> <abbr title="Cross Site Scripting Attacks">XSS attacks</abbr></a>,
  if you do not first <a class="alert-link" href="https://en.wikipedia.org/wiki/HTML_sanitization">sanitize</a> the user supplied string. 
</p>

<h3>Named slots</h3>
<ul class="m-40"> 
  <li>
    <code class="text-nowrap" translate="no">default</code> content that will be placed inside of the inner element which has the class 
    <code class="text-nowrap" translate="no">carousel-caption</code>. Appears after any content from the 
    <code class="text-nowrap" translate="no">caption</code> and <code class="text-nowrap" translate="no">text</code> props.
  </li> 
  <li>
    <code class="text-nowrap" translate="no">img</code> content to place into the background of the slide.
    Despite the slot's name, you can place almost any content in this slot in lieu of using the 
    <code class="text-nowrap" translate="no">default</code> slot or <code class="text-nowrap" translate="no">caption</code> and 
    <code class="text-nowrap" translate="no">text</code> props.
  </li> 
</ul>

<h3>Carousel animation</h3>
<p class="m-40">Carousel, by default, uses a sliding animation. You can change the slide animation to a cross-fade animation, or disable animation completely.</p>

<h3>Crossfade animation</h3>
<p class="m-30">
  Set the <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code> <code class="text-nowrap" translate="no">fade</code> prop to 
  <code class="text-nowrap" translate="no">true</code> to animate slides with a fade transition instead of the default slide animation.
</p>

  <b-row>
    <b-col>
      <b-carousel
        id="carousel-1"
        v-model="slide_2"
        :interval="interval"
        controls
        indicators
        fade
        class="carousel-custom"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          :img-src="require('../../src/assets/images/background/image.jpg')">
        ></b-carousel-slide>

        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
  </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-carousel
    id="carousel-1"
    v-model="slide_2"
    :interval="interval"
    controls
    indicators
    fade
    class="carousel-custom"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    >
    <b-carousel-slide
      :img-src="require('../../src/assets/images/background/image.jpg')">
    ></b-carousel-slide>

    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>

    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../../src/assets/images/background/image.jpg')"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>
  </b-carousel>
</div></script></code></pre>

<h2>Disable animation</h2>
<p class="m-30">
  Set the <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code> <code class="text-nowrap" translate="no">no-animation</code> prop to 
  <code class="text-nowrap" translate="no">true</code> to disable slide animation.
</p>

  <b-row>
    <b-col>
      <b-carousel
        id="carousel-1"
        v-model="slide_2"
        :interval="interval"
        no-animation
        controls
        indicators
        class="carousel-custom"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>

        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
  </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-carousel
    id="carousel-1"
    v-model="slide_2"
    :interval="interval"
    no-animation
    controls
    indicators
    fade
    class="carousel-custom"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    >
    <b-carousel-slide
      :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>

    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>

    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../../src/assets/images/background/image.jpg')"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>
  </b-carousel>
</div></script></code></pre>

<h2>Slide wrapping</h2>
<p>Normally when the carousel reaches one end or the other in the list of slides, it will wrap to the opposite end of the list of slides and continue cycling.</p>
<p class="m-40">To disable carousel slide wrapping, set the <code class="text-nowrap" translate="no">no-wrap</code> prop to true.</p>

<h2 class="m-30">Slide captions</h2>
  <b-row>
    <b-col>
      <b-carousel
        id="carousel-2"
        v-model="slide_1"
        :interval="interval"
        controls
        indicators
        class="carousel-custom"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
          <h1>First slide label</h1>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </b-carousel-slide>

        <b-carousel-slide 
          :img-src="require('../../src/assets/images/background/image.jpg')">
          <h1>Second slide label</h1>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </b-carousel-slide>


        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="480"
              :src="require('../../src/assets/images/background/image.jpg')"
              alt="image slot"
            >
          </template>
          <h1>Third slide label</h1>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </b-carousel-slide>
      </b-carousel>
    </b-col>
  </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-carousel
    id="carousel-2"
    v-model="slide_1"
    :interval="interval"
    controls
    indicators
    class="carousel-custom"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    >
    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
      <h1>First slide label</h1>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </b-carousel-slide>

    <b-carousel-slide 
      :img-src="require('../../src/assets/images/background/image.jpg')">
      <h1>Second slide label</h1>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </b-carousel-slide>

    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../../src/assets/images/background/image.jpg')"
          alt="image slot"
        >
      </template>
      <h1>Third slide label</h1>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </b-carousel-slide>
  </b-carousel>
</div></script></code></pre>

<h2>Hide slide text content on small screens</h2>
<p class="m-30">
  On smaller screens you may want to hide the captions and headings. You can do so via the <code class="text-nowrap" translate="no">content-visible-up</code> 
  prop of <code class="text-nowrap" translate="no">&lt;b-carousel-slide&gt;</code>. The prop accepts a breakpoint name 
  (i.e. <code class="text-nowrap" translate="no">sm</code>, <code class="text-nowrap" translate="no">md</code>, 
  <code class="text-nowrap" translate="no">lg</code>, <code class="text-nowrap" translate="no">xl</code>, or custom breakpoint names if you have defined them), 
  and will hide the headings and captions on screens <em>smaller</em> than the breakpoint.
</p>




    <div class="m-40"><p class="m-40"></p></div>
    <div class="m-40"><p class="m-40"></p></div>


      <h2 class="mt-40">Touch swipe support</h2>
      <p class="m-40">
        On touch enabled devices, users can switch slides by swiping left or right on the carousel. 
        To disable touch control, set the <code class="text-nowrap" translate="no">no-touch</code> prop to <code class="text-nowrap" translate="no">true</code>.
      </p>

      <h2><span class="bd-content-title"><code class="text-nowrap" translate="no">v-model</code> support<a class="anchorjs-link" href="#v-model-support" aria-labelledby="v-model-support"></a></span></h2>
      <p class="m-40">Programmatically control which slide is showing via <code class="text-nowrap" translate="no">v-model</code> (which binds to the <code class="text-nowrap" translate="no">value</code> prop). Note, that slides are indexed starting at <code class="text-nowrap" translate="no">0</code>.</p>
    
      <h2>Programmatic slide control</h2>
      <p class="m-30">The <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code> instance provides several public methods for controlling sliding:</p>

      <div class="m-40">
        <b-table hover :items="items"></b-table>
      </div>

      <h2>Accessibility</h2>
      <p>Carousels are generally not fully compliant with accessibility standards, although we try to make them as accessible as possible.</p>
      <p>By providing a document unique value via the <code class="text-nowrap" translate="no">id</code> prop, <code class="text-nowrap" translate="no">&lt;b-carousel&gt;</code> will enable accessibility features. It is highly recommended to always add an ID to all components.</p>
      <p>All carousel controls and indicators have aria labels. These can be customized by setting the various <code class="text-nowrap" translate="no">label-*</code> props.</p>
      <p><strong>Note:</strong> The animation effect of this component is dependent on the <code class="text-nowrap" translate="no">prefers-reduced-motion</code> media query. See the <a href="/docs/reference/accessibility" class="font-weight-bold">reduced motion section of our accessibility documentation</a> for additional details.</p>




</div>
</template>

<script>
export default {
  name: "CarouselView",
  data() {
    return {
      slide: 0,
      slide_1: 0,
      slide_2: 0,
      slide_3: 0,
      sliding: null,
      interval:3000,

      items: [
        { method: 'setSlide(index)', description: 'Go to slide specified by index' },
        { method: 'next()', description: 'Go to next slide' },
        { method: 'prev()', description: 'Go to previous slide' },
        { method: 'pause', description: 'Pause the slide cycling' },
        { method: 'start()', description: 'Start slide cycling (prop interval must have a value)' },
      ]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  },
  mounted() {
    document.title = "Carousel";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

</style>
