<template>
<div class="wrapper">
      <title>Collapse</title>

  <h1 class="title-section">Collapse</h1>
  <p class="m-30" style="font-size: 1.5rem">
    Easily toggle visibility of almost any content on your pages in a vertically collapsing container. Includes support for making accordions. 
    Visibility can be easily toggled with our <a href="/docs/directives/toggle" class="font-weight-bold">
    <code class="text-nowrap" translate="no">v-b-toggle</code> directive</a>, or via <code class="text-nowrap" translate="no">v-model</code>.
  </p>

      <div class="trendy-example">
          <app-button variant="primary"
            :aria-expanded="visible ? 'true' : 'false'"
            href="collapse-4"
            @click="visible = !visible">Link with href
          </app-button>
          
          <app-button
            variant="primary"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible = !visible">
            Button with data-target
          </app-button>
      </div>



    <b-row class="m-40 ml-1">
      <b-collapse id="collapse-4" v-model="visible">
        <b-col cols="7">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
          squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.
        </b-col>
      </b-collapse>
    </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="trendy-example">
    <app-button variant="primary"
      :aria-expanded="visible ? 'true' : 'false'"
      href="collapse-4"
      @click="visible = !visible">Link with href
    </app-button>
    
    <app-button
      variant="primary"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapse-4"
      @click="visible = !visible">
      Button with data-target
    </app-button>
</div>

<b-row class="m-40">
  <b-collapse id="collapse-4" v-model="visible">
    <b-col cols="7">
      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
      squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.
    </b-col>
  </b-collapse>
</b-row>

</script></code></pre>  
    
  <h2>How it works</h2>
  <p class="m-40">
    The accordion uses <a href="/docs/5.0/components/collapse/">collapse</a> internally to make it collapsible. To render an accordion that’s expanded, add the 
    <code>.open</code> class on the <code>.accordion</code>.
  </p>


  <div class="section">
    <h2>Accordion example</h2>
    <p >
      Turn a group of <code class="text-nowrap" translate="no">b-collapse</code> components into an accordion by supplying an accordion group identifier via the accordion prop. Note that only one collapse in an accordion group can be open at a time.
    </p>
    <p class="m-30">Click the accordions below to expand/collapse the accordion content.</p>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <app-button class="btn--dropdown-down1" block v-b-toggle.accordion-1 variant="light" >
            <span class="pull-left">Accordion 1</span>
          </app-button>
        </b-card-header>

        <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
          <b-card-body>
           <h4>Header 1</h4>
            <b-card-text>{{ text }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <app-button class="btn--dropdown-down1" block v-b-toggle.accordion-2  variant="light" >
            <span class="pull-left">Accordion 2</span>
          </app-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ text }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <app-button class="btn--dropdown-down1" block v-b-toggle.accordion-3  variant="light" >
            <span class="pull-left">Accordion 3</span>
          </app-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ text }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button class="btn--dropdown-down1" block v-b-toggle.accordion-1  >
        <span class="pull-left">Accordion 1</span>
      </b-button>
    </b-card-header>

    <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <h4>Header 1</h4>
        <b-card-text>{{ text }}</b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>

  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button class="btn--dropdown-down1" block v-b-toggle.accordion-2>
        <span class="pull-left">Accordion 2</span>
      </b-button>
    </b-card-header>
    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <b-card-text>{{ text }}</b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>

  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button class="btn--dropdown-down1" block v-b-toggle.accordion-3 >
        <span class="pull-left">Accordion 3</span>
      </b-button>
    </b-card-header>
    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
      <b-card-body>
        <b-card-text>{{ text }}</b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</div></script></code></pre>  

<p><strong>Notes:</strong></p>
<ul> 
  <li>
    When using accordion mode, make sure you place the trigger elements and <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code> 
    components inside an element with <code class="text-nowrap" translate="no">role="tablist"</code> and set 
    <code class="text-nowrap" translate="no">role="tab"</code> on each trigger element's container 
    (each trigger element should be wrapped) in order to help screen reader users navigate the accordion group.
  </li> 
  <li>
    If using the <code class="text-nowrap" translate="no">v-model</code> feature of 
    <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code> in accordion mode, do not bind the 
    <code class="text-nowrap" translate="no">v-model</code> or <code class="text-nowrap" translate="no">visible</code> 
    prop of all the collapses in the accordion group to the same variable!
  </li> 
  <li>
    Ensure, at most, only one <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code> in the accordion group has the 
    <code class="text-nowrap" translate="no">visible</code> prop and/or <code class="text-nowrap" translate="no">v-model</code> set to 
    <code class="text-nowrap" translate="no">true</code>. Only one collapse in an accordion group can be open at a time.
  </li> 
</ul>
  </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import CaretDown from '@/assets/images/icons/caret-down.svg'
import AppButton from "@/components/AppButton";
export default {
  name: "CollapseView",
  components:{
    AppButton,
    InlineSvg
  },
  data() {
    return {
      CaretDown,
      visible: true,
      text: `Anim pariatur cliche reprehenderit, enim eiusmod
      high life accusamus terry richardson ad squid.
      3 wolf moon officia aute, non cupidatat
      skateboard dolor brunch.`
    }
  },
  mounted() {
    document.title = "Collapse";
    Prism.highlightAll();
  }

}
</script>

<style scoped>

</style>
