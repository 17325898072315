<template>
  <div class="wrapper">
              <title>Switches</title>

  <h1 class="title-section">Switches</h1>
  <p class="m-40" style="font-size: 1.5rem">
      Vue switch is a simple component used for activating one of two predefined options. Commonly
      used as an on/off button.
    </p>

    <h2>Basic example</h2>
  <p class="m-30">A switch has the markup of a custom checkbox but uses the .form-switch class to render a toggle switch. Switches also support the disabled attribute.</p>
  <div>
    
    <div class="pair primary-checked">
      <div class="toggle-small">
        <label class="toggle-switch round-primary">
          <input type="checkbox" checked>
          <div class="slider"></div>
        </label>
        <p>Switch</p>
      </div>
      <label class="toggle-switch round-primary big">
        <input type="checkbox" checked>
        <div class="slider"></div>
      </label>
    </div>
    <div class="pair primary-unchecked">
      <div class="toggle-small">
        <label class="toggle-switch round-primary">
          <input type="checkbox">
          <div class="slider"></div>
        </label>
        <p>Switch</p>
      </div>
      <label class="toggle-switch round-primary big">
        <input type="checkbox">
        <div class="slider"></div>
      </label>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="pair primary-checked">
    <div class="toggle-small">
      <label class="toggle-switch round-primary">
        <input type="checkbox" checked>
        <div class="slider"></div>
      </label>
      <p>Switch</p>
    </div>
    <label class="toggle-switch round-primary big">
      <input type="checkbox" checked>
      <div class="slider"></div>
    </label>
  </div>

  <div class="pair primary-unchecked">
    <div class="toggle-small">
      <label class="toggle-switch round-primary">
        <input type="checkbox">
        <div class="slider"></div>
      </label>
      <p>Switch</p>
    </div>
    <label class="toggle-switch round-primary big">
      <input type="checkbox">
      <div class="slider"></div>
    </label>
  </div>
</div></script></code></pre>  

    <h2 class="text-section">Switches inside div</h2>

    <div class="small-with-bg">
      <div class="toggle-small light">
        <label class="toggle-switch round-primary">
          <input type="checkbox" checked>
          <div class="slider"></div>
        </label>
        <p>Switch</p>
      </div>
      <div class="toggle-small light">
        <label class="toggle-switch round-primary">
          <input type="checkbox">
          <div class="slider"></div>
        </label>
        <p>Switch</p>
      </div>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="small-with-bg">
    <div class="toggle-small light">
      <label class="toggle-switch round-primary">
        <input type="checkbox" checked>
        <div class="slider"></div>
      </label>
      <p>Switch</p>
    </div>
    <div class="toggle-small light">
      <label class="toggle-switch round-primary">
        <input type="checkbox">
        <div class="slider"></div>
      </label>
      <p>Switch</p>
    </div>
  </div>
</div></script></code></pre>  

<h2 class="m-30">Switches with ON/OFF state</h2>

    <div class="switcher-big ">
      <label class="toggle-switch round-primary big">
        <input type="checkbox">
        <div class="slider"></div>
        <div class="switch">
          <div class="">OFF</div>
          <div class="">ON</div>
        </div>
      </label>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="switcher-big ">
    <label class="toggle-switch round-primary big">
      <input type="checkbox">
      <div class="slider"></div>
      <div class="switch">
        <div class="">OFF</div>
        <div class="">ON</div>
      </div>
    </label>
  </div>
</div></script></code></pre>

<h2 class="m-30">Switches with arrows</h2>

    <div class="arrow-group">
      <div class="switcher-small">
        <label class="toggle-switch round-primary big">
          <input type="checkbox">
          <div class="slider">
            <div class="switch">
              <div class="image"><img src="./../assets/switchers-component/arrow-left-dark.svg" alt=""/>
              </div>
              <div class="image"><img src="./../assets/switchers-component/arrow-right-dark.svg" alt=""/></div>
            </div>
          </div>
        </label>
      </div>

      <div class="switcher-small">
        <label class="toggle-switch round-primary big">
          <input type="checkbox" checked>
          <div class="slider">
            <div class="switch">
              <div class="image"><img src="./../assets/switchers-component/arrow-left-dark.svg" alt=""/></div>
              <div class="image"><img src="./../assets/switchers-component/arrow-right-dark.svg" alt=""/></div>
            </div>
          </div>
        </label>
      </div>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="arrow-group">
    <div class="switcher-small">
      <label class="toggle-switch round-primary big">
        <input type="checkbox">
        <div class="slider">
          <div class="switch">
            <div class="image"><img src="./../assets/switchers-component/arrow-left-dark.svg" alt=""/>
            </div>
            <div class="image"><img src="./../assets/switchers-component/arrow-right-dark.svg" alt=""/></div>
          </div>
        </div>
      </label>
    </div>
    
    <div class="switcher-small">
      <label class="toggle-switch round-primary big">
        <input type="checkbox" checked>
        <div class="slider">
          <div class="switch">
            <div class="image"><img src="./../assets/switchers-component/arrow-left-dark.svg" alt=""/></div>
            <div class="image"><img src="./../assets/switchers-component/arrow-right-dark.svg" alt=""/></div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div></script></code></pre>

<h2 class="m-30">On off group</h2>

    <div class="on-off-group">
      <div class="switcher-small on-off">
        <label class="toggle-switch round-primary big">
          <input type="checkbox">
          <div class="slider">
            <div class="switch">
              <div class="">On</div>
              <div class="">Off</div>
            </div>
          </div>
        </label>
      </div>
      <div class="switcher-small on-off">
        <label class="toggle-switch round-primary big">
          <input type="checkbox" checked>
          <div class="slider">
            <div class="switch">
              <div class="">On</div>
              <div class="">Off</div>
            </div>
          </div>
        </label>
      </div>
    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="on-off-group">
    <div class="switcher-small on-off">
      <label class="toggle-switch round-primary big">
        <input type="checkbox">
        <div class="slider">
          <div class="switch">
            <div class="">On</div>
            <div class="">Off</div>
          </div>
        </div>
      </label>
    </div>
    
    <div class="switcher-small on-off">
      <label class="toggle-switch round-primary big">
        <input type="checkbox" checked>
        <div class="slider">
          <div class="switch">
            <div class="">On</div>
            <div class="">Off</div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div></script></code></pre>
  </div>
  </div>


</template>

<script>
export default {
  name: "SwitchersView",
  mounted() {
    document.title = "Switchers";
    Prism.highlightAll();
  }
}
</script>

<style lang="scss" scoped>


</style>
