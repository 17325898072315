const select = () => {
    function styledOnMultiselectClick(e) {
        var element = e.target.parentNode;
        var previousSibling = element.parentNode.previousSibling;
        while (previousSibling && previousSibling.nodeType != 1) {
            previousSibling = previousSibling.previousSibling;
        }
    
    
        for (var i = 0; i < previousSibling.children.length; i++) {
            if (previousSibling.children[i].getAttribute('selected') !== null) {
                if (e.target.getAttribute('value') == previousSibling.children[i].value) {
                    previousSibling.children[i].removeAttribute('selected');                           
                }
            } else {
                if (e.target.getAttribute('value') == previousSibling.children[i].value) {
                    previousSibling.children[i].setAttribute('selected', true);
                }
            }
        }
    
    
        if (e.target.getAttribute('selected') !== null) {
            e.target.removeAttribute('selected');
            e.target.classList.remove('selected')
        } else {
            e.target.setAttribute('selected', true);
            e.target.classList.add('selected')
    
        }
    
    }
    function styledOnClick(e) {
        var element = e.target.parentNode.parentNode;
        var previousSibling = element.previousSibling;
        while (previousSibling && previousSibling.nodeType != 1) {
            previousSibling = previousSibling.previousSibling;
        }
    
        previousSibling.value = e.target.getAttribute('value');
        var options = element.children[0].children;
        for (var i = 0; i < options.length; i++) {
            options[i].removeAttribute('selected');
            options[i].classList.remove('selected');
        }
    
        e.target.setAttribute('selected', true);
        e.target.classList.add('selected');
    
    
    
        e.target.parentNode.parentNode.parentNode.children[0].innerText = e.target.innerText;
        e.target.parentNode.parentNode.style.display = e.target.parentNode.parentNode.style.display == 'none' ? 'block' : 'none';
    
    }
    
    function toggleSelect(e) {
        if (e.target.parentNode.children[1].style.display == 'none') {
            e.target.parentNode.children[1].style.display = 'block';
        } else {
            e.target.parentNode.children[1].style.display = 'none';
        }
    }
    
    let elements = document.getElementsByTagName('select');
    for (var i = 0; i < elements.length; i++) {
        if (elements[i].getAttribute("styled-select") && elements[i].hasAttribute('multiple')) {
    
            var styledElementWrapper = document.createElement('div');
            styledElementWrapper.setAttribute('class', 'select-container');
            var styledElement = document.createElement('div');
            styledElement.setAttribute('class', 'wrapper2');
    
            var options = elements[i].options;
            for (var j = 0; j < options.length; j++) {
                var styledOption = document.createElement('button');
                styledOption.innerText = options[j].innerText;
                styledOption.setAttribute('type', 'button');
                styledOption.setAttribute('value', options[j].value);
                styledOption.classList.add('option')
                styledOption.onclick = styledOnMultiselectClick;
                styledElement.appendChild(styledOption)
            }
            styledElementWrapper.appendChild(styledElement);
            elements[i].parentNode.insertBefore(styledElementWrapper, elements[i].nextSibling);
        } else if (elements[i].getAttribute("styled-select") && !elements[i].hasAttribute('multiple')) {
        
            var styledElementSection = document.createElement('div');
            styledElementSection.setAttribute('class', 'section-select');
    
            var selectButton = document.createElement('button');
            selectButton.setAttribute('class', `select-options ${elements[i].getAttribute('class') ? elements[i].getAttribute('class') : ''}`);
            selectButton.setAttribute('type', 'button');
            selectButton.innerText = elements[i].getAttribute('placeholder')
            selectButton.onclick = toggleSelect;
    
            var styledElementWrapper = document.createElement('div');
            styledElementWrapper.setAttribute('class', 'select-wrapper');
            styledElementWrapper.style.display = 'none';
    
            var styledElement = document.createElement('div');
            styledElement.setAttribute('class', 'select');
    
            var options = elements[i].options;
            for (var j = 0; j < options.length; j++) {
                var styledOption = document.createElement('button');
                styledOption.innerText = options[j].innerText;
                styledOption.setAttribute('type', 'button');
                styledOption.setAttribute('value', options[j].value);
                styledOption.classList.add('option')
                styledOption.onclick = styledOnClick;
                styledElement.appendChild(styledOption)
            }
    
            styledElementWrapper.appendChild(styledElement);
            styledElementSection.appendChild(selectButton);
            styledElementSection.appendChild(styledElementWrapper);
    
            elements[i].parentNode.insertBefore(styledElementSection, elements[i].nextSibling);
        }
    
    }
    
    
        document.addEventListener('click', function (event) {
        let elements = document.getElementsByTagName('select');
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].getAttribute("styled-select") && !elements[i].hasAttribute('multiple')) {
                var nextSibling = elements[i].nextSibling;
                while (nextSibling && nextSibling.nodeType != 1) {
                    nextSibling = nextSibling.nextSibling
                }
    
                if (!nextSibling.contains(event.target)) {
                    nextSibling.children[1].style.display = 'none';
                }
    
            }
        }
    })
    
    
    
    
}


export default select