<template>
  <b-dropdown pill :id="component_id" :name="component_id" :disabled="disabled" :variant="variant"
              :size="sizeClass" :dropup="dropup" :dropleft="dropleft" :dropright="dropright" :split="split" :block="block">
    <template v-slot:button-content>
      {{ text }}
      <!--inline-svg :src="CaretDown"></inline-svg-->
    </template>
    <slot name="items"></slot>
    <b-dropdown-item :key="index" v-for="(item,index) in items">
      {{ item }}
    </b-dropdown-item>


  </b-dropdown>
</template>

<script>
import CaretDown from '@/assets/images/icons/caret-down.svg'
import InlineSvg from "vue-inline-svg";

export default {
  name: "DropDown",
  props: {
    text: {
      type: String,
      default: 'Primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default() {
        return []
      },
    divider: {
      type: Boolean,
      default: false,
    }
    },
    variant: String,
    size: String,
    position: String,
    rounded: {
      type: Boolean,
      default: false
    },
    dropup: {
      type: Boolean,
      default: false
    },
    dropleft: {
      type: Boolean,
      default: false
    },
    dropright: {
      type: Boolean,
      default: false
    },
    split: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    customClass:{
      type:String
    }
  },
  data() {
    return {
      CaretDown
    }
  },
  components: {
    InlineSvg
  },
  computed: {
    component_id() {
      return 'drop_down_' + this._uid
      console.log(this._uid);
    },
    sizeClass() {
      if (this.size === 'xl') {
        return this.rounded ? 'xl rounded-pill' : 'xl'
      }
      if (this.size === 'lg') {
        return this.rounded ? 'lg rounded-pill' : 'lg'
      }
      if (this.size === 'md') {
        return this.rounded ? 'md rounded-pill' : 'md'
      }
      if (this.size === 'sm') {
        return this.rounded ? 'sm rounded-pill' : 'sm'
      }
      if (this.size === 'xs') {
        return this.rounded ? 'xs rounded-pill' : 'xs'
      }
      return this.rounded ? ' rounded-pill' : 'md'
    },

    }

 }

</script>

<style scoped>

</style>
