<template>
  <div class="doughnut-chart">
  <p>HOT PRODUCTS</p>

  <div class="round">
    <DoughnutChart v-bind="barChartProps" id="canvas-doughnut-chart" />
  </div>
              <ul class="list-group">
                  <li class="list-group-item"><span class="iphone"><i class="fa fa-circle"></i>Iphone</span></li>
                  <li class="list-group-item"><span class="general-mobile"><i class="fa fa-circle"></i>General Mobile</span></li>
                  <li class="list-group-item"><span class="xiaomi"><i class="fa fa-circle"></i>Xiaomi</span></li>
                  <li class="list-group-item"><span class="huawei"><i class="fa fa-circle"></i>Huawei</span></li>
                  <li class="list-group-item"><span class="samsung"><i class="fa fa-circle"></i>Samsung</span></li>

              </ul>
</div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
import { DoughnutChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';;

Chart.register(...registerables);


export default defineComponent({
  name: 'App',
  components: {
    DoughnutChart,
  },
  setup() {
    const data = ref([22, 25, 13, 20, 15]);

    const chartData = computed(() => ({

      datasets: [
        {
          labels: ['Iphone', 'General Mobile', 'Xiaomi', 'Huawei', 'Samsung'],
          data: data.value,
          backgroundColor: [
            '#5561B3',
            '#161F5E',
            '#4FAC68',
            '#DFCA41',
            '#DF4149'],
        },

      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {

            grid: {
              display: false
            },
            ticks: {
              font: {
                weight: 'Bold',

              }
            }
          },
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false
            },

          },
          xAxes: [{
            barPercentage: 0.4
          }]
        },
        plugins: {
          legend: {
            display: true,

          },
          // tooltips: {
          //   callbacks: {
          //     labels: function(tooltipItem) {
          //       return tooltipItem.yLabel;
          //     }
          //   }
          // }
        },

      },
    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
    });



    return {barChartProps, barChartRef };
  },
});
</script>

<style>

</style>
