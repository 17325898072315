<template>

<div class="slider-container">
    <input @input="onInput" class="slider" type="range" min="0" max="100" :value="value" ref="slider">
    <div class="selector" ref="selector">
        <div class="select-btn"></div>
        <div class="select-value" ref="selectValue">{{value}}%</div>
    </div>
    <div class="range-bar" ref="progressBar"></div>
</div>

    
</template>

<script>
export default {
    name: "ProgressRange",
    data() {
        return {
            value:50
        }
    },

    methods: {
      onInput() {
        this.value = this.$refs.slider.value;
        this.$refs.selector.style.left = this.value + "%";
        this.$refs.progressBar.style.width = this.value + "%"

    },
    },

    mounted() {
        this.onInput();
    }
    
}
</script>