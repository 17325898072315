<template>
<div class="wrapper">
            <title>Toast</title>

  <h1 class="title-section">Toasts</h1>
  <p style="font-size: 1.5rem">Push notifications to your visitors with a <code class="text-nowrap" translate="no">&lt;b-tost&gt;</code> 
  and<code class="text-nowrap" translate="no">&lt;b-toaster&gt;</code>, lightweight components which are easily customizable for generating alert messages.</p>
  
  <p>Toasts are lightweight notifications designed to mimic the push notifications that have been popularized by mobile and desktop operating systems.</p>
  <p class="m-40">Toasts are intended to be small interruptions to your visitors or users, and therefore should contain minimal, to-the-point, non-interactive content.</p>
  
  <h2>Overview</h2>
  <p>To encourage extensible and predictable toasts, we recommend providing a header (title) and body. Toast headers use the style <code class="text-nowrap" translate="no">'display: flex'</code>, allowing easy alignment of content thanks to Bootstrap's <a href="/docs/reference/utility-classes" class="font-weight-bold">margin and flexbox utility classes</a>.</p>
  <p class="m-30">Toasts are slightly translucent, too, so they blend over whatever they might appear over. For browsers that support the <code class="text-nowrap" translate="no">backdrop-filter</code> CSS property, they also attempt to blur the elements under the toast.</p>
  
  <div>

    <b-button class="mb-2" variant="primary" @click="$bvToast.show('my-toast')">
      Show toast
    </b-button>

    <b-toast visible no-auto-hide id="my-toast" static variant="light" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#5561B3" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button class="mb-2" variant="primary" @click="$bvToast.show('my-toast')">
    Show toast
  </b-button>

  <b-toast id="my-toast" static variant="light" solid>
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <b-img blank blank-color="#5561B3" class="mr-2" width="12" height="12"></b-img>
        <strong class="mr-auto">Bootstrap</strong>
        <small class="text-muted mr-2">11 mins ago</small>
      </div>
    </template>
      Hello, world! This is a toast message.
  </b-toast>
</div></script></code></pre>

<p class="m-40"><strong>Note:</strong>> we are using the static prop in the above example to render the toast in-place in the document, 
rather than transporting it to a <code class="text-nowrap" translate="no">&lt;b-toaster&gt;</code> target container. 
And we have added classes bg-secondary and progress-bar-striped to the outer 
<code class="text-nowrap" translate="no">&lt;div&gt;</code> for illustrative purposes of toast transparency only.</p>
  </div>

  <h3>Toast features and notes</h3>
  <ul>
     <li>
       Toasts can be generated on demand via the injection <code class="text-nowrap" translate="no">this.$bvToast</code> object,
      or manually created using the <code class="text-nowrap" translate="no">&lt;b-toast&gt;</code> component.
    </li> 
    <li>
      Titles are optional, but should be included, titles are rendered inside a <code class="text-nowrap" translate="no">&lt;strong&gt;</code> 
      element, unless using the <code class="text-nowrap" translate="no">toast-title</code> slot.
    </li>
    <li>
      The close button at the top right of the toast can be removed via the <code class="text-nowrap" translate="no">no-close-button</code> prop.
    </li> 
    <li>
      A title bar is shown, unless you give no title and set the <code class="text-nowrap" translate="no">no-close-button</code> prop.
    </li>
    <li>
      Auto-hiding occurs after 5000 ms, which can be changed via the <code class="text-nowrap" translate="no">auto-hide-delay</code> prop, 
      or disabled with the <code class="text-nowrap" translate="no">no-auto-hide</code> prop.
    </li> 
    <li>
      When auto-hide is enabled, while you hover over the toast, the auto-hide countdown will pause. You can disable this feature by setting the
      <code class="text-nowrap" translate="no">no-hover-pause</code> prop to <code class="text-nowrap" translate="no">true</code>.
    </li> 
    <li>
      If you disable the auto-hide feature, avoid hiding the close button, or if you hide the close button be sure to allow the toast to auto-dismiss. 
      Please refer to the <a href="#accessibility-tips" class="font-weight-bold">Accessibility tips</a> section below for <strong>important</strong> usage information.
    </li>
    <li>
      Toast transparency can be disabled by setting the <code class="text-nowrap" translate="no">solid</code> prop to <code class="text-nowrap" translate="no">true</code>.
    </li> 
    <li>
      Toasts will show inside a named <code class="text-nowrap" translate="no">&lt;b-toaster&gt;</code> target component. 
      BootstrapVue comes with several pre-defined toaster targets. 
      Toasts will check for the named toaster in the document before they are shown, and will dynamically create the named toaster target if one is not found.
    </li>
    <li>
      Toaster targets are defined completely with CSS for controlling the positioning of the contained <code class="text-nowrap" translate="no">&lt;b-toast&gt;</code> components.
    </li> 
    <li>
      Toasts can can targeted to any named toaster.
    </li> 
    <li>
      Toasts are wrapped in a <code class="text-nowrap" translate="no">&lt;div&gt;</code> with class <code class="text-nowrap" translate="no">b-toast</code> 
      to allow for Vue list-transition support when displayed in a toaster component.
    </li> 
  </ul>

  <p class="m-40">BootstrapVue uses PortalVue to transport toasts into the toasters.</p>

  <h2>Options</h2>
  <p class="m-40">
    Toasts have various options that can control their style and behaviour. Options are available both as props on the 
    <code class="text-nowrap" translate="no">&lt;b-toast&gt;</code> component and as properties of the options object passed to 
    <code class="text-nowrap" translate="no">this.$bvToast.toast()</code>. When passing options to <code class="text-nowrap" translate="no">this.$bvToast.toast()</code>, 
    use the <samp class="notranslate" translate="no">camelCase</samp> version of the component prop name, i.e. use 
    <code class="text-nowrap" translate="no">noAutoHide</code> instead of <code class="text-nowrap" translate="no">no-auto-hide</code>.
  </p>

  <h3>Title</h3>
  <p class="m-40">
    Add a title to your toast via the <code class="text-nowrap" translate="no">title</code> option. 
    Just like the toast <code class="text-nowrap" translate="no">message</code>, the title can be a simple string, or an array of VNodes. 
    See the <a href="#advanced-usage" class="font-weight-bold">Advanced usage</a> section for an example of passing an array of 
    <code class="text-nowrap" translate="no">VNodes</code> as the message and title.
  </p>

  <h3>Transparency</h3>
  <p>
    Toasts have a semi-transparent background by default. To disable the default transparency, 
    just set the solid prop to true to remove the alpha channel from the background color.
  </p>
  <p class="m-40">
    Transparency can also be altered via the BootstrapVue custom SCSS variable <code class="text-nowrap" translate="no">$b-toast-background-opacity</code>
    when using the SCSS files rather than CSS files. Refer to the <a href="/docs/reference/theming" class="font-weight-bold">Theming</a> reference section.
  </p>

  <h3>Variants</h3>
  <p class="m-30">
    BootstrapVue toasts provide custom CSS to define color variants. Variants follow the standard Bootstrap v4 variant names. 
    If you have custom SCSS defined Bootstrap color theme variants, the toast custom SCSS will automatically create toast variants for you 
    (refer to the <a href="/docs/reference/theming" class="font-weight-bold">Theming</a> reference section).
  </p>


    <b-row class="m-40">
    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="primary" @click="$bvToast.show('primary-toast')" >
      Show toast
    </b-button>

    <b-toast id="primary-toast" static variant="primary" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#5561B3" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>


    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="secondary" @click="$bvToast.show('secondary-toast', 'b-toaster-top-right')">
      Show toast
    </b-button>
    <b-toast id="secondary-toast" static variant="secondary" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#161F5E" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="success" @click="$bvToast.show('success-toast')">
      Show toast
    </b-button>
    <b-toast id="success-toast" static variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#4FAC68" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="warning" @click="$bvToast.show('warning-toast')">
      Show toast
    </b-button>
    <b-toast id="warning-toast" static variant="warning" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#DFCA41" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="danger" @click="$bvToast.show('danger-toast')">
      Show toast
    </b-button>
    <b-toast id="danger-toast" static variant="danger" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#DF4149" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="info" @click="$bvToast.show('info-toast')">
      Show toast
    </b-button>
    <b-toast id="info-toast" static variant="info" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#7FACDF" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="light" @click="$bvToast.show('light-toast')">
      Show toast
    </b-button>
    <b-toast id="light-toast" static variant="light" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#6D7587" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>

    <b-col md="6" class="mb-3">
    <b-button class="mb-2" variant="dark" @click="$bvToast.show('dark-toast')">
      Show toast
    </b-button>
    <b-toast id="dark-toast" static variant="dark" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img blank blank-color="#131629" class="mr-2" width="12" height="12"></b-img>
          <strong class="mr-auto">Bootstrap</strong>
          <small class="text-muted mr-2">11 mins ago</small>
        </div>
      </template>
        Hello, world! This is a toast message.
    </b-toast>
    </b-col>
    </b-row>

  <h3>Toaster target</h3>
  <p>BootstrapVue comes with the following "built-in" toaster names (and associated styles defined in SCSS):</p>
  <ul class="m-30"> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-top-right</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-top-left</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-top-center</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-top-full</code>   
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-bottom-right</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-bottom-left</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-bottom-center</code>
    </li> 
    <li>
      <code class="text-nowrap" translate="no">b-toaster-bottom-full</code>
    </li> 
  </ul>

  <div class="trendy-example">
    <b-button variant="primary" @click="toast('b-toaster-top-right', false, 'primary')" class="mb-2">b-toaster-top-right</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-left', false, 'primary')" class="mb-2">b-toaster-top-left</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-center', false, 'primary')" class="mb-2">b-toaster-top-center</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-full', false, 'primary')" class="mb-2">b-toaster-top-full</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-right', true, 'primary')" class="mb-2">b-toaster-bottom-right</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-left', true, 'primary')" class="mb-2">b-toaster-bottom-left</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-center', true, 'primary')" class="mb-2">b-toaster-bottom-center</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-full', true, 'primary')" class="mb-2">b-toaster-bottom-full</b-button>
  </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"> <div>
    <b-button variant="primary" @click="toast('b-toaster-top-right', false, 'primary')" class="mb-2">b-toaster-top-right</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-left', false, 'primary')" class="mb-2">b-toaster-top-left</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-center', false, 'primary')" class="mb-2">b-toaster-top-center</b-button>
    <b-button variant="primary" @click="toast('b-toaster-top-full', false, 'primary')" class="mb-2">b-toaster-top-full</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-right', true, 'primary')" class="mb-2">b-toaster-bottom-right</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-left', true, 'primary')" class="mb-2">b-toaster-bottom-left</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-center', true, 'primary')" class="mb-2">b-toaster-bottom-center</b-button>
    <b-button variant="primary" @click="toast('b-toaster-bottom-full', true, 'primary')" class="mb-2">b-toaster-bottom-full</b-button>
 </div></script></code></pre>



  <p><strong>Notes:</strong></p>
  <ul class="m-40"> 
    <li>
      Toaster target names that have not been defined in CSS will render at the bottom of the document, stacked and not positioned 
      (appended to <code class="text-nowrap" translate="no">&lt;body&gt;</code> inside a <code class="text-nowrap" translate="no">&lt;b-toaster&gt;</code>
      with class name and ID set to the toaster target name). The only default styling the toaster will have is a 
      <code class="text-nowrap" translate="no">z-index</code> of <code class="text-nowrap" translate="no">1100</code>.
    </li> 
    <li>
      Avoid using <code class="text-nowrap" translate="no">b-toaster-top-*</code> toasters together, or 
      <code class="text-nowrap" translate="no">b-toaster-bottom-*</code> toasters together, 
      at the same time in your app as notifications could be obscured/overlap on small screens (i.e. <code class="text-nowrap" translate="no">xs</code>).
    </li> 
  </ul>

  <h3>Prepend and append</h3>
  <p class="m-40">
    Toasts default to prepending themselves to the top of the toasts shown in the specified toaster in the order they were created. 
    To append new toasts to the bottom, set the <code class="text-nowrap" translate="no">append-toast</code> prop to <code class="text-nowrap" translate="no">true</code>.
  </p>

  <h3>Auto hide</h3>
  <p>
    Change to auto hide delay time via the <code class="text-nowrap" translate="no">auto-hide-delay</code> prop (value is in milliseconds), which defaults to 
    <code class="text-nowrap" translate="no">5000</code> (minimum value <code class="text-nowrap" translate="no">1000</code>). 
    Or, disable the auto-hide feature completely by setting the <code class="text-nowrap" translate="no">no-auto-hide</code> prop to 
    <code class="text-nowrap" translate="no">true</code>.
  </p>
  <p class="m-40">
    When auto-hide is enabled, hovering over the toast will pause the auto-hide timer. When you un-hover the toast, the auto-hide timer will be resumed. 
    You can disable this feature by setting the <code class="text-nowrap" translate="no">no-hover-pause</code> prop to <code class="text-nowrap" translate="no">true</code>.
  </p>

  <h3>Close button</h3>
  <p>
    Toasts have a close button to hide them on use click by default. Setting the <code class="text-nowrap" translate="no">no-close-button</code> prop to 
    <code class="text-nowrap" translate="no">true</code> will prevent this and creates a toast without the default close button.
  </p>
  <p>
    It is still possible to create a custom close button for the toast by providing a unique ID and use the 
    <code class="text-nowrap" translate="no">this.$bvToast.hide(id)</code> method to hide the specific toast.
  </p>





</div>
</template>

<script>
export default {
  name: "ToastView",
   methods: {

      toast(toaster, append, variant) {
        const h = this.$createElement        
        const ToastTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] },
          [
            h('b-img', { class:'mr-2', props: {blank:true, blankColor:'#5561B3', width: 12, height:12} }),
            h('strong', { class: 'mr-auto' }, 'Bootstrap'),
            h('small', { class: 'text-muted mr-2' }, '11 minutes ago')
          ]
        )
        this.$bvToast.toast(`Hello, world! This is a toast message.`, {
          title: [ToastTitle],
          toaster: toaster,
          variant: variant,
          solid: true,
          appendToast: append
        })
      }
   },
   mounted() {
    document.title = "Toast";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

</style>
