<template>

<div class="wrapper">
    <title>Buttons group</title>
    <h1 class="title-section">Buttons group</h1>

    <p style="font-size: 1.5rem">Group a series of buttons together on a single line with <code class="text-nowrap" translate="no">&lt;b-button-group&gt;</code>.</p>
    <p class="m-40" style="font-size: 1.5rem">Button groups are an easy way to group a series of buttons together.</p>


            <h2>Overview</h2>
            <p class="m-30">Button groups are an easy way to group a series of buttons together.</p>

                <b-button-group>
                    <app-button variant="success">Success</app-button>
                    <b-button variant="info">Info</b-button>
                    <b-button variant="warning">Warning</b-button>
                </b-button-group>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <b-button-group>
        <b-button variant="success">Success</b-button>
        <b-button variant="info">Info</b-button>
        <b-button variant="warning">Warning</b-button>
    </b-button-group>
</div></script></code></pre>


    <h2>Sizing</h2>
    <p class="m-30">Set the size prop to xl, lg, sm or xs to render extra large, large, small or extra small, respectively, buttons. There is no need to specify the size on the individual buttons.</p>

    <div class="btn-group-wrapper">

    <div class="mb-5">
        <b-button-group size="lg">
            <app-button variant="primary">Left</app-button>
            <app-button variant="primary">Middle</app-button>
            <app-button variant="primary">Right</app-button>
         </b-button-group>
    </div>

    <div class="mb-5">
        <b-button-group>
            <app-button variant="primary">Left</app-button>
            <app-button variant="primary">Middle</app-button>
            <app-button variant="primary">Right</app-button>
         </b-button-group>
    </div>

    <div class="mb-5">
        <b-button-group size="sm">
            <app-button variant="primary">Left</app-button>
            <app-button variant="primary">Middle</app-button>
            <app-button variant="primary">Right</app-button>
         </b-button-group>
    </div>
    </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="btn-group-wrapper">
 <div class="mb-5">
    <b-button-group size="lg">
        <app-button variant="primary">Left</app-button>
        <app-button variant="primary">Middle</app-button>
        <app-button variant="primary">Right</app-button>
    </b-button-group>
 </div>

 <div class="mb-5">
    <b-button-group>
        <app-button variant="primary">Left</app-button>
        <app-button variant="primary">Middle</app-button>
        <app-button variant="primary">Right</app-button>
    </b-button-group>
 </div>

 <div class="mb-5">
    <b-button-group size="sm">
        <app-button variant="primary">Left</app-button>
        <app-button variant="primary">Middle</app-button>
        <app-button variant="primary">Right</app-button>
    </b-button-group>
 </div>
</div></script></code></pre>

    <h2>Basic light example</h2>
    <div class="btn-group-wrapper">

    <div class="mb-5">
        <b-button-group size="lg">
            <app-button variant="light">Left</app-button>
            <app-button variant="light">Middle</app-button>
            <app-button variant="light">Right</app-button>
         </b-button-group>
    </div>

    <div class="mb-5">
        <b-button-group>
            <app-button variant="light">Left</app-button>
            <app-button variant="light">Middle</app-button>
            <app-button variant="light">Right</app-button>
         </b-button-group>
    </div>

    <div class="mb-5">
        <b-button-group size="sm">
            <app-button variant="light">Left</app-button>
            <app-button variant="light">Middle</app-button>
            <app-button variant="light">Right</app-button>
         </b-button-group>
    </div>
    </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
 <div class="mb-5">
    <b-button-group size="lg">
        <app-button variant="light">Left</app-button>
        <app-button variant="light">Middle</app-button>
        <app-button variant="light">Right</app-button>
    </b-button-group>
 </div>

 <div class="mb-5">
    <b-button-group>
        <app-button variant="light">Left</app-button>
        <app-button variant="light">Middle</app-button>
        <app-button variant="light">Right</app-button>
    </b-button-group>
 </div>

 <div class="mb-5">
    <b-button-group size="sm">
        <app-button variant="light">Left</app-button>
        <app-button variant="light">Middle</app-button>
        <app-button variant="light">Right</app-button>
    </b-button-group>
 </div>
</div></script></code></pre>




        <h2 >Vertical variation</h2> 
        <p class="m-30">Make a set of buttons appear vertically stacked rather than horizontally by setting the vertical prop. <br>Split button dropdowns are not supported here.</p>         
        
        <b-button-group vertical class="mb-5">
           <app-button variant="light" >Button</app-button>
           <app-button variant="light" >Button</app-button>
           <app-button variant="light" >Button</app-button>
           <app-button variant="light" >Button</app-button>
           <app-button variant="light" >Button</app-button>
        </b-button-group>

        <b-button-group vertical class="ml-5 mb-5">
           <app-button variant="light">Button</app-button>
           <app-button variant="light" >Button</app-button>
           <app-button variant="light">Button</app-button>
           <drop-down variant="light" :items="items" text="Dropdown"></drop-down>
           <drop-down variant="light" :items="items" text="Dropdown"></drop-down>       
           <app-button variant="light">Button</app-button>
           <app-button variant="light">Button</app-button>
           <b-button variant="light">Button</b-button>

        </b-button-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="mb-5">
    <b-button-group vertical>
        <app-button variant="light" >Button</app-button>
        <app-button variant="light" >Button</app-button>
        <app-button variant="light" >Button</app-button>
        <app-button variant="light" >Button</app-button>
        <app-button variant="light" >Button</app-button>
    </b-button-group>

    <b-button-group vertical>
           <app-button variant="light">Button</app-button>
           <app-button variant="light" >Button</app-button>
           <app-button variant="light">Button</app-button>
           <drop-down variant="light" :items="items" text="Dropdown"></drop-down>
           <drop-down variant="light" :items="items" text="Dropdown"></drop-down>       
           <app-button variant="light">Button</app-button>
           <app-button variant="light">Button</app-button>
           <b-button variant="light">Button</b-button>
    </b-button-group>
</div></script></code></pre>



          <h2>Dropdown menu support</h2>
          <p class="m-30">Add <code class="text-nowrap" translate="no">&lt;drop-down&gt;</code> menus directly inside your <code class="text-nowrap" translate="no">&lt;b-button-group&gt;</code>. Note that split dropdown menus are not supported when prop vertical is set.</p>

        <div>
        <b-button-group>
            <app-button variant="light">1</app-button>
            <app-button variant="light">2</app-button>
            <drop-down variant="light" :items="items" text="Dropdown"></drop-down>
         </b-button-group>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <b-button-group>
        <app-button variant="light">1</app-button>
        <app-button variant="light">2</app-button>
        <drop-down variant="light" :items="items" text="Dropdown"></drop-down>
    </b-button-group>
</div></script></code></pre>



        <h2>Button toolbar</h2>
        <p class="m-30">Combine sets of button groups into button toolbars for more complex components. Use utility classes as needed to space out groups, buttons, and more.</p>
        <div>

        <b-button-group class="mb-5">
            <app-button variant="light">1</app-button>
            <app-button variant="light">2</app-button>
            <app-button variant="light">3</app-button>
            <app-button variant="light">4</app-button>
         </b-button-group>
        <b-button-group class="ml-5 mb-5">
            <app-button variant="light">5</app-button>
            <app-button variant="light">6</app-button>
            <app-button variant="light">7</app-button>
         </b-button-group>
         <b-button-group class="ml-5 mb-5">
            <app-button variant="light">8</app-button>
         </b-button-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <b-button-group>
        <app-button variant="light">1</app-button>
        <app-button variant="light">2</app-button>
        <app-button variant="light">3</app-button>
        <app-button variant="light">4</app-button>
    </b-button-group>
    <b-button-group class="ml-5">
        <app-button variant="light">5</app-button>
        <app-button variant="light">6</app-button>
        <app-button variant="light">7</app-button>
    </b-button-group>
    <b-button-group class="ml-5">
        <app-button variant="light">8</app-button>
    </b-button-group>
</div></script></code></pre>

        </div>


        <h2>Extras</h2>
        <div class="mb-5">

        <b-button-group class="extras-btn" size="xl" >
            <app-button variant="primary">
                <div>
                    <inline-svg :src="ForwardIcon"></inline-svg>
                </div>
            </app-button>
            <app-button variant="primary">
                 <div>
                    <inline-svg :src="PlayIcon"></inline-svg>
                </div>
            </app-button>
            <app-button variant="primary">
                <div>
                    <inline-svg :src="StopIcon"></inline-svg>
                </div>
            </app-button>
            <app-button variant="primary">
                <div>
                    <inline-svg :src="FastForwardIcon"></inline-svg>
                </div>
            </app-button>
        </b-button-group>

        </div>

        <div class="mb-4 mr-5" size="lg">
        <b-button-group class="btn-group-wrapper">
            <app-button variant="primary">
                <div>
                    <inline-svg :src="AngleLeft"></inline-svg>
                </div>
            </app-button>
            <app-button size="lg" variant="primary">
                <div>
                    <inline-svg :src="AngleRight"></inline-svg>
                </div>
            </app-button>
        </b-button-group>

        <b-button-group vertical class="vertical-custom" size="xl">
           <b-button variant="light"  class="btn-icon-plus">
                 <div>
                    <inline-svg :src="PlusIcon"></inline-svg>
                </div>
           </b-button>
           <b-button variant="light" class="btn-icon-minus">
                 <div>
                    <inline-svg :src="MinusIcon"></inline-svg>
                </div>
           </b-button>
       

        </b-button-group>


        </div>

        
        

   
</div>

    
</template>

<script>

import ButtonsView from "@/views/ButtonsView"
import AppButton from '../components/AppButton.vue'
import PlayIcon from '@/assets/images/icons/play-icon.svg';
import ForwardIcon from '@/assets/images/icons/forward-icon.svg';
import StopIcon from '@/assets/images/icons/stop-icon.svg';
import FastForwardIcon from '@/assets/images/icons/fast-forward-icon.svg';
import AngleLeft from '@/assets/images/icons/angle-left.svg'
import AngleRight from '@/assets/images/icons/angle-right.svg'
import PlusIcon from '@/assets/images/icons/plus-icon.svg'
import MinusIcon from '@/assets/images/icons/minus-icon.svg'
import DropDown from "@/components/DropDown";

import InlineSvg from "vue-inline-svg";

export default {
    name: "ButtonsGroupView",
    data() {
    return {
        PlayIcon,
        ForwardIcon,
        StopIcon,
        FastForwardIcon,
        AngleLeft,
        AngleRight,
        PlusIcon,
        MinusIcon,
        variant: "variant",
        items:[
       'First action',
       'Second action',
       'Third action'
      ],
         }
     },

    components: {
        ButtonsView,  
        AppButton,
        InlineSvg,
        DropDown,
        DropDown 
    },
    mounted() {
    document.title = "Buttons group";
    Prism.highlightAll(); // highlight your code on mount
  }
    
}
</script>

<style scoped>

</style>