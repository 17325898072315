<template>
<div class="wrapper">
  <title>Alerts</title>
      <div class="alert-groups">
        <h1 class="title-section">Alerts</h1>
        <p class="m-40" style="font-size: 1.5rem">Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.</p>

<b-row>
  <b-col>
            <h2>Overview</h2>
        <p class="m-30">Alerts are available for any length of text, as well as an optional dismiss button (and optional auto-dismissing).</p>


          <div>
            <b-alert show variant="primary">Default Alert</b-alert>

            <b-alert variant="success" show>Success Alert</b-alert>

            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
              Dismissible Alert!
            </b-alert>

            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="warning"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              <p>This alert will dismiss after {{ dismissCountDown }} seconds...</p>
              <b-progress
                variant="warning"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
              ></b-progress>
            </b-alert>

            <b-button @click="showAlert" variant="info" class="m-1">
              Show alert with count-down timer
            </b-button>

            <b-button @click="showDismissibleAlert=true" variant="info" class="m-1">
              Show dismissible alert ({{ showDismissibleAlert ? 'visible' : 'hidden' }})
            </b-button>

          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert show variant="primary">Default Alert</b-alert>

  <b-alert variant="success" show>Success Alert</b-alert>

  <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
    Dismissible Alert!
  </b-alert>

  <b-alert
    :show="dismissCountDown"
    dismissible
    variant="warning"
    @dismissed="dismissCountDown=0"
    @dismiss-count-down="countDownChanged"
  >
    <p>This alert will dismiss after {{ dismissCountDown }} seconds...</p>
    <b-progress
      variant="warning"
      :max="dismissSecs"
      :value="dismissCountDown"
      height="4px"
    ></b-progress>
  </b-alert>

  <b-button @click="showAlert" variant="info" class="m-1">
    Show alert with count-down timer
  </b-button>

  <b-button @click="showDismissibleAlert=true" variant="info" class="m-1">
    Show dismissible alert ({{ showDismissibleAlert ? 'visible' : 'hidden' }})
  </b-button>
</div></script></code></pre>
  </b-col>

</b-row>



        <h2>Visible state</h2>
        <p>
          Use the <code class="text-nowrap" translate="no">show</code> prop to control the visibility state of the alert. By default alerts are 
          <strong>not</strong> shown. Set the prop <code class="text-nowrap" translate="no">show</code> to explicitly display them.
        </p>

        <p class="m-40">
          The <code class="text-nowrap" translate="no">show</code> prop accepts boolean <code class="text-nowrap" translate="no">true</code> or 
          <code class="text-nowrap" translate="no">false</code> to show and hide the alert respectively.
          It can also be set to a positive integer (representing seconds) to create a self dismissing alert. See the 
          <a href="#auto-dismissing-alerts" class="font-weight-bold">Auto Dismissing Alerts</a> section below for details.
        </p>

        <h3>v-model support</h3>
        <p class="m-40">
          You can use the v-model directive to create two-way data bindings on the show prop as in v-model="showDismissibleAlert" above.
          Useful when you use dismissible because when user closes the alert, your variable will be updated. Do not use the show prop when using v-model.
        </p>


        <h2>Contextual variants</h2>
        <p class="m-30">For proper styling of <code class="text-nowrap" translate="no">&lt;b-alert&gt;</code>, use one of the four required contextual variants by setting the variant prop to one of the following: info, success, warning or danger. The default is info.</p>

        <b-alert  show variant="primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert  show variant="dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert  show variant="primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert  show variant="dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
</div></script></code></pre>

    
        <h2>Outline variants</h2>
        <p class="m-30">In need of an alert, but not the hefty background colors they bring? Add outline class to remove all background images and colors on any alert.</p>
        <b-alert show class="alert-outline-primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert show class="alert-outline-dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert show class="alert-outline-primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert show class="alert-outline-dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
</div></script></code></pre>

        <h3>Conveying meaning to assistive tehnologies</h3>
        <p class="m-40">
          Using color variants to add meaning only provides a visual indication, which will not be conveyed to users of assistive technologies – such as screen readers. 
          Ensure that information denoted by the color is either obvious from the content itself (e.g. the visible text), or is included through alternative means, 
          such as additional text hidden with the <code class="text-nowrap" translate="no">.sr-only</code> class.
        </p>

    <b-row>
    <b-col>
    <h2>Additional content onside alerts</h2>
    <p class="m-30"><code class="text-nowrap" translate="no">&lt;b-alert&gt;</code> tag can also contain additional HTML elements like headings and paragraphs, which will be styled with the appropriate color matching the variant.</p>
    <b-alert show variant="success"><h4 class="alert-heading">Well done!</h4>
        <p class="mb-2">
                    Aww yeah, you successfully read this important alert message. This example text is going to
                    run a bit longer so that you can see how spacing within an alert works with this kind of
                    content. </p> 
    <hr color="white">
    <p class="mb-2"> Whenever you need to, be sure to use margin utilities to keep things nice and tidy. </p>
  </b-alert>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert show variant="success">
    <h4 class="alert-heading">Well done!</h4>
      <p class="mb-2">
                  Aww yeah, you successfully read this important alert message. This example text is going to
                  run a bit longer so that you can see how spacing within an alert works with this kind of
                  content. </p> 
  <hr color="white">
  <p class="mb-2"> Whenever you need to, be sure to use margin utilities to keep things nice and tidy. </p>
</b-alert>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
          <h3>Icons</h3>
          <p class="m-30">Similarly, you can use <a href="/docs/5.1/utilities/flex/">flexbox utilities</a> and <a href="https://icons.getbootstrap.com/">Bootstrap Icons</a> to create alerts with icons. Depending on your icons and content, you may want to add more utilities or custom styles.</p>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
  </symbol>
  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
  </symbol>
  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </symbol>
</svg>
            <b-alert show variant="primary">   <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
            <span class="title-bold ml-2">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
           
            <b-alert  show variant="success">    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
            <span class="title-bold ml-2">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
            
            <b-alert  show variant="warning">  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
            <span class="title-bold ml-2">Warning Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
            
            <b-alert  show variant="danger">  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
            <span class="title-bold ml-2">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>           
            
  <b-alert show variant="primary">   
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
      <span class="title-bold ml-2">Primary Alert</span> - Lorem ipsum dolor sit amet.
  </b-alert>
  
  <b-alert  show variant="success">    
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
      <span class="title-bold ml-2">Success Alert</span> - Lorem ipsum dolor sit amet.
  </b-alert>
  
  <b-alert  show variant="warning">  
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
      <span class="title-bold ml-2">Warning Alert</span> - Lorem ipsum dolor sit amet.
  </b-alert>
  
  <b-alert  show variant="danger">  
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
      <span class="title-bold ml-2">Danger Alert</span> - Lorem ipsum dolor sit amet.
  </b-alert>

</div></script></code></pre>         
          

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h3>Color of links within alerts</h3>
        <p class="m-30">
          Use the <code class="text-nowrap" translate="no">.alert-link</code> utility CSS class to quickly provide matching colored links within any alert.
          Use on <code class="text-nowrap" translate="no">&lt;a&gt;</code> or <code class="text-nowrap" translate="no">&lt;b-link&gt;</code>.
        </p>

        <div>
          <b-alert show variant="primary"><a href="#" class="alert-link">Primary Alert</a></b-alert>
          <b-alert show variant="secondary"><a href="#" class="alert-link">Secondary Alert</a></b-alert>
          <b-alert show variant="success"><a href="#" class="alert-link">Success Alert</a></b-alert>
          <b-alert show variant="danger"><a href="#" class="alert-link">Danger Alert</a></b-alert>
          <b-alert show variant="warning"><a href="#" class="alert-link">Warning Alert</a></b-alert>
          <b-alert show variant="info"><a href="#" class="alert-link">Info Alert</a></b-alert>
          <b-alert show variant="light"><a href="#" class="alert-link">Light Alert</a></b-alert>
          <b-alert show variant="dark"><a href="#" class="alert-link">Dark Alert</a></b-alert>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert show variant="primary"><a href="#" class="alert-link">Primary Alert</a></b-alert>
  <b-alert show variant="secondary"><a href="#" class="alert-link">Secondary Alert</a></b-alert>
  <b-alert show variant="success"><a href="#" class="alert-link">Success Alert</a></b-alert>
  <b-alert show variant="danger"><a href="#" class="alert-link">Danger Alert</a></b-alert>
  <b-alert show variant="warning"><a href="#" class="alert-link">Warning Alert</a></b-alert>
  <b-alert show variant="info"><a href="#" class="alert-link">Info Alert</a></b-alert>
  <b-alert show variant="light"><a href="#" class="alert-link">Light Alert</a></b-alert>
  <b-alert show variant="dark"><a href="#" class="alert-link">Dark Alert</a></b-alert>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
      <b-col class="alert-groups">
        <h2>Dismissible alerts</h2>

        <p class="m-30">Using the Vue alert component, it’s possible to dismiss any alert inline. Here’s how: <br>
        * Add a dismiss property, which adds close button and extra padding to the right of the alert which positions the close button. <br>
        * Click on the close button to dismiss the alert.</p>
        <b-alert dismissible show variant="primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet</b-alert>
        <b-alert dismissible show variant="info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show variant="dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert dismissible show variant="primary"><span class="title-bold">Primary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="secondary"><span class="title-bold">Secondary Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="success"><span class="title-bold">Success Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="danger"><span class="title-bold">Danger Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="warning"><span class="title-bold">Warning Alert</span> - Lorem ipsum dolor sit amet</b-alert>
  <b-alert dismissible show variant="info"><span class="title-bold">Info Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="light"><span class="title-bold">Light Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show variant="dark"><span class="title-bold">Dark Alert</span> - Lorem ipsum dolor sit amet.</b-alert>
</div></script></code></pre>

        <h2>Dismissible alerts - Outline</h2>
        <p class="m-30">In need of an alert, but not the hefty background colors they bring? Add outline class to remove all background images and colors on any alert.</p>
        <b-alert dismissible show class="alert-outline-primary"><span class="title-bold">Primary</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-secondary"><span class="title-bold">Secondary</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-success"><span class="title-bold">Success</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-danger"><span class="title-bold">Danger</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-warning"><span class="title-bold">Warning</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-info"><span class="title-bold">Info</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-light"><span class="title-bold">Light</span> - Lorem ipsum dolor sit amet.</b-alert>
        <b-alert dismissible show class="alert-outline-dark"><span class="title-bold">Dark</span> - Lorem ipsum dolor sit amet.</b-alert>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert dismissible show class="alert-outline-primary"><span class="title-bold">Primary</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-secondary"><span class="title-bold">Secondary</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-success"><span class="title-bold">Success</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-danger"><span class="title-bold">Danger</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-warning"><span class="title-bold">Warning</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-info"><span class="title-bold">Info</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-light"><span class="title-bold">Light</span> - Lorem ipsum dolor sit amet.</b-alert>
  <b-alert dismissible show class="alert-outline-dark"><span class="title-bold">Dark</span> - Lorem ipsum dolor sit amet.</b-alert>
</div></script></code></pre>

        <h3>Auto dismissing alerts</h3>
        <p class="m-30">To create a<code class="text-nowrap" translate="no">&lt;b-alert&gt;</code> that dismisses automatically after a period of time, set the show prop (or the v-model)
           to the number of seconds you would like the <code class="text-nowrap" translate="no">&lt;b-alert&gt;</code> to remain visible for. 
           Only integer number of seconds are supported.</p>

             <div class="m-40">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="warning"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
              >
                This alert will dismiss after {{ dismissCountDown }} seconds...
              </b-alert>
              <b-button @click="showAlert" variant="info" class="m-1">
                Show alert with count-down timer
              </b-button>
            </div>
 <pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert
    :show="dismissCountDown"
    dismissible
    variant="warning"
    @dismissed="dismissCountDown=0"
    @dismiss-count-down="countDownChanged"
  >
    This alert will dismiss after {{ dismissCountDown }} seconds...
  </b-alert>
</div></script></code></pre>   

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        dismissSecs: 10,
        dismissCountDown: 0
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
  }
&lt;/script&gt;
</code></pre>
        </b-col>
      </b-row>



      <b-row>
        <b-col>
          <h2>Fading alerts</h2>
          <p class="m-30">Use the <code class="text-nowrap" translate="no">fade</code> prop to enable animation. By default alerts are not animated.</p> 
          <b-alert show dismissible fade>Dismissible Alert!</b-alert>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-alert show dismissible fade>Dismissible Alert!</b-alert>
</div></script></code></pre>
        
        </b-col>
      </b-row>



      </div>
  </div>

</template>

<script>
import {BAlert} from "bootstrap-vue";

export default {
  name: "AlertsView",
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  comments:{
    BAlert
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    document.title = "Alerts";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

</style>
