<template>
  <div class="wrapper">
              <title>Progress</title>

    <h1 class="title-section">Progress</h1>
    <p class="m-40" style="font-size: 1.5rem">Use our custom progress component for displaying simple or complex progress bars, featuring support for horizontally stacked bars, animated backgrounds, and text labels.</p>

      <h2 class="m-30">Examples</h2>
      <b-progress class="mb-4" value="59" :max="max"  variant="primary" ></b-progress>
      <b-progress class="mb-4" value="45" :max="max" variant="secondary" ></b-progress>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-progress class="mb-4" value="59" :max="max"  variant="primary" ></b-progress>
  <b-progress class="mb-4" value="45" :max="max" variant="secondary" ></b-progress>
</div></script></code></pre>

    <h2>Values</h2>
    <p>
      Set the maximum value with the <code class="text-nowrap" translate="no">max</code> prop (default is <code class="text-nowrap" translate="no">100</code>), 
      and the current value via the <code class="text-nowrap" translate="no">value</code> prop (default <code class="text-nowrap" translate="no">0</code>).
    </p>

    <p class="m-40">
      When creating multiple bars in a single process, place the value prop on the individual <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code> 
      sub components (see the <strong>Multiple Bars</strong> section below for more details).
    </p>

      <h2>Labels</h2>
      <p class="m-30">
        Add labels to your progress bars by either enabling <code class="text-nowrap" translate="no">show-progress</code> (percentage of max) or 
        <code class="text-nowrap" translate="no">show-value</code>for the current absolute value. 
        You may also set the precision (number of digits after the decimal) via the <code class="text-nowrap" translate="no">precision</code> 
        prop (default is <code class="text-nowrap" translate="no">0</code>digits after the decimal).
      </p>

      <div class="progress-box mb-3">
        <h5 class="mt-3 progress-label">No label</h5>
        <b-progress class="mb-4" :value="value" :max="max"  variant="primary" ></b-progress>
      </div>

      <div class="progress-box mb-3">
        <h5 class="mt-3 progress-label">Value label</h5>
        <b-progress show-value  class="mb-4" :value="value" :max="max"  variant="primary" ></b-progress>
      </div>

      <div class="progress-box mb-3">
        <h5 class="mt-3 progress-label">Progress label</h5>
        <b-progress class="mb-4" :value="value" :max="max" show-progress variant="primary" ></b-progress>
      </div>

      <div class="progress-box mb-3">
        <h5 class="mt-3 progress-label">Value label with precision</h5>
        <b-progress class="mb-4" :value="value" :max="max" show-value  precision="2" variant="primary" ></b-progress>
      </div>

      <div class="progress-box mb-3">
        <h5 class="mt-3 progress-label">Progress label with precision</h5>
        <b-progress class="mb-4" :value="value" :max="max" show-progress  precision="2" variant="primary" ></b-progress>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="progress-box mb-3">
    <h5 class="mt-3 progress-label">No label</h5>
    <b-progress class="mb-4" :value="value" :max="max"  variant="primary" ></b-progress>
  </div>

  <div class="progress-box mb-3">
    <h5 class="mt-3 progress-label">Value label</h5>
    <b-progress show-value  class="mb-4" :value="value" :max="max"  variant="primary" ></b-progress>
  </div>

  <div class="progress-box mb-3">
    <h5 class="mt-3 progress-label">Progress label</h5>
    <b-progress class="mb-4" :value="value" :max="max" show-progress variant="primary" ></b-progress>
  </div>

  <div class="progress-box mb-3">
    <h5 class="mt-3 progress-label">Value label with precision</h5>
    <b-progress class="mb-4" :value="value" :max="max" show-value  precision="2" variant="primary" ></b-progress>
  </div>

  <div class="progress-box mb-3">
    <h5 class="mt-3 progress-label">Progress label with precision</h5>
    <b-progress class="mb-4" :value="value" :max="max" show-progress  precision="2" variant="primary" ></b-progress>
  </div>
</div></script></code></pre>     

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
export default {
    data() {
      return {
        value: 33.333333333,
        max: 100
      }
    }
  }
&lt;/script&gt;
</code></pre>

<progress-range class="mt-5 m-40"></progress-range>

  <h2>Custom progress label</h2>
  <p class="m-30">
    Need more control over the label? Provide your own label by using the default slot within a 
    <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code> sub-component, or by using the 
    <code class="text-nowrap" translate="no">label</code> or <code class="text-nowrap" translate="no">label-html</code> property on 
    <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code>:
  </p>

  <div>
    <h5>Custom label via default slot</h5>
    <b-progress :max="max" height="3rem" variant="primary">
      <b-progress-bar :value="value">
        <span>Progress: <strong>{{ value.toFixed(2) }} / {{ max }}</strong></span>
      </b-progress-bar>
    </b-progress>

    <h5 class="mt-3">Custom label via property</h5>
    <b-progress :max="max" variant="primary">
      <b-progress-bar :value="value" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
    </b-progress>

    <h5 class="mt-3">Custom label via property (HTML support)</h5>
    <b-progress :max="max" variant="primary">
      <b-progress-bar :value="value" :label-html="`<del>${value}</del>`"></b-progress-bar>
    </b-progress>
  </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-progress :max="max" height="3rem" variant="primary">
    <b-progress-bar :value="value">
      <span>Progress: <strong>{{ value.toFixed(2) }} / {{ max }}</strong></span>
    </b-progress-bar>
  </b-progress>

  <h5 class="mt-3">Custom label via property</h5>
  <b-progress :max="max" variant="primary">
    <b-progress-bar :value="value" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
  </b-progress>

  <h5 class="mt-3">Custom label via property (HTML support)</h5>
  <b-progress :max="max" variant="primary">
    <b-progress-bar :value="value" :label-html="`<del>${value}</del>`"></b-progress-bar>
  </b-progress>
</div></script></code></pre>  

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
export default {
    data() {
      return {
        value: 33.333333333,
        max: 100
      }
    }
  }
&lt;/script&gt;
</code></pre>

    <p>Precedence order for label methods (top-most has precedence):</p>
    <ul class="m-40"> 
      <li>
        default slot of <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">label</code> prop of <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">show-progress</code> prop of <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">show-value</code> prop of <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">show-progress</code> prop of <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">show-value</code> prop of <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code>
      </li> 
      <li>no label</li> 
    </ul>

      <h2>Width and height</h2>
      <p class="m-30">
        <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code> will always expand to the maximum with of its parent container. 
        To change the width, place <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code> 
        in a standard Bootstrap column or apply one of the standard Bootstrap width classes.
      </p>

        <div>
          <h5>Default width</h5>
          <b-progress :value="value" class="mb-3" variant="primary"></b-progress>

          <h5>Custom widths</h5>
          <b-progress :value="value" class="w-75 mb-2" variant="primary"></b-progress>
          <b-progress :value="value" class="w-50 mb-2" variant="primary"></b-progress>
          <b-progress :value="value" class="w-25" variant="primary"></b-progress>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <h5>Default width</h5>
  <b-progress :value="value" class="mb-3" variant="primary"></b-progress>

  <h5>Custom widths</h5>
  <b-progress :value="value" class="w-75 mb-2" variant="primary"></b-progress>
  <b-progress :value="value" class="w-50 mb-2" variant="primary"></b-progress>
  <b-progress :value="value" class="w-25" variant="primary"></b-progress>
</div></script></code></pre>

        <p class="m-30">
          The height of the progress bar can be controlled with the <code class="text-nowrap" translate="no">height</code> prop. 
          The height value should be a standard CSS dimension (<code class="text-nowrap" translate="no">px</code>, 
          <code class="text-nowrap" translate="no">rem</code>, <code class="text-nowrap" translate="no">em</code>, etc.). 
          The default height is <code class="text-nowrap" translate="no">1rem</code>.
        </p>

          <div>
            <h5>Default height</h5>
            <b-progress :value="value" show-progress class="mb-3"  variant="primary"></b-progress>

            <h5>Custom heights</h5>
            <b-progress height="2rem" :value="value" show-progress class="mb-2"  variant="primary"></b-progress>
            <b-progress height="20px" :value="value" show-progress class="mb-2"  variant="primary"></b-progress>
            <b-progress height="2px" :value="value"  variant="primary"></b-progress>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <h5>Default height</h5>
  <b-progress :value="value" show-progress class="mb-3"  variant="primary"></b-progress>

  <h5>Custom heights</h5>
  <b-progress height="2rem" :value="value" show-progress class="mb-2"  variant="primary"></b-progress>
  <b-progress height="20px" :value="value" show-progress class="mb-2"  variant="primary"></b-progress>
  <b-progress height="2px" :value="value"  variant="primary"></b-progress>
</div></script></code></pre>


      <h2>Backgrounds</h2>
      <p class="m-30">Use background variants to change the appearance of individual progress bars. The default variant is <code class="text-nowrap" translate="no">primary</code>.</p>
      <h3>Solid color variants</h3>

      <div v-for="bar in bars" class="row mb-1" :key="bar.variant">
        <div class="col-sm-12 pt-1">
          <b-progress :value="bar.value" :variant="bar.variant" :key="bar.variant"></b-progress>
        </div>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div v-for="bar in bars" class="row mb-1" :key="bar.variant">
    <div class="col-sm-12 pt-1">
      <b-progress :value="bar.value" :variant="bar.variant" :key="bar.variant"></b-progress>
    </div>
  </div>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
export default {
    data() {
      return {
        bars: [
          { variant: 'primary', value: 75 },
          { variant: 'secondary', value: 75 },
          { variant: 'success', value: 75 },
          { variant: 'info', value: 75 },
          { variant: 'warning', value: 75 },
          { variant: 'danger', value: 75 },
          { variant: 'dark', value: 75 }
        ],
        timer: null
      }
    },
    mounted() {
      this.timer = setInterval(() => {
        this.bars.forEach(bar => (bar.value = 25 + Math.random() * 75))
      }, 2000)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    }
  }
&lt;/script&gt;
</code></pre>


      <h3>Striped background</h3>

      <p class="m-30">Set <code class="text-nowrap" translate="no">striped</code> to apply a stripe via CSS gradient over the progress bar's background variant.</p>
        <div>
          <b-progress :value="25" variant="success" :striped="striped"></b-progress>
          <b-progress :value="50" variant="info" :striped="striped" class="mt-2"></b-progress>
          <b-progress :value="75" variant="warning" :striped="striped" class="mt-2"></b-progress>
          <b-progress :value="100" variant="danger" :striped="striped" class="mt-2"></b-progress>

          <b-button variant="primary" @click="striped = !striped" class="mt-3">
            {{ striped ? 'Remove' : 'Add' }} Striped
          </b-button>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-progress :value="25" variant="success" :striped="striped"></b-progress>
  <b-progress :value="50" variant="info" :striped="striped" class="mt-2"></b-progress>
  <b-progress :value="75" variant="warning" :striped="striped" class="mt-2"></b-progress>
  <b-progress :value="100" variant="danger" :striped="striped" class="mt-2"></b-progress>

  <b-button variant="primary" @click="striped = !striped" class="mt-3">
    {{ striped ? 'Remove' : 'Add' }} Striped
  </b-button>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        striped: true
      }
    }
  }
&lt;/script&gt;
</code></pre>

      <h3>Animated background</h3>
      <p class="m-30">The striped gradient can also be animated by setting the <code class="text-nowrap" translate="no">animated</code>prop.</p>
        <div>
          <b-progress :value="25" variant="success" striped :animated="animate"></b-progress>
          <b-progress :value="50" variant="info" striped :animated="animate" class="mt-2"></b-progress>
          <b-progress :value="75" variant="warning" striped :animated="animate" class="mt-2"></b-progress>
          <b-progress :value="100" variant="danger" :animated="animate" class="mt-3"></b-progress>

          <b-button variant="secondary" @click="animate = !animate" class="mt-3">
            {{ animate ? 'Stop' : 'Start' }} Animation
          </b-button>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-progress :value="25" variant="success" striped :animated="animate"></b-progress>
  <b-progress :value="50" variant="info" striped :animated="animate" class="mt-2"></b-progress>
  <b-progress :value="75" variant="warning" striped :animated="animate" class="mt-2"></b-progress>
  <b-progress :value="100" variant="danger" :animated="animate" class="mt-3"></b-progress>

  <b-button variant="secondary" @click="animate = !animate" class="mt-3">
    {{ animate ? 'Stop' : 'Start' }} Animation
  </b-button>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        animate: true
      }
    }
  }
&lt;/script&gt;
</code></pre>

        <p><strong>Notes:</strong></p>
        <ul class="m-40"> 
          <li>
            if <code class="text-nowrap" translate="no">animated</code> is true, <code class="text-nowrap" translate="no">striped</code> 
            will automatically be enabled.</li> <li>Animated progress bars don't work in Opera 12 — as they don't support CSS3 animations.
          </li> 
          <li>
            The animation effect of this component is dependent on the <code class="text-nowrap" translate="no">prefers-reduced-motion</code> media query. 
            See the <a href="/docs/reference/accessibility" class="font-weight-bold">reduced motion section of our accessibility documentation</a> for additional details.
          </li> 
        </ul>

      <h2>Multiple bars</h2>
      <p class="m-30">
        Include multiple <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code> sub-components in a 
        <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code> component to build a horizontally stacked set of progress bars.
      </p>
      <b-progress class="mt-2 progress-bar-group" :max="max">
        <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
        <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
        <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
        <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
      </b-progress>
      <b-progress show-value class="mt-2 progress-bar-group" :max="max">
        <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
        <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
        <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
        <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
      </b-progress>
      <b-progress show-value striped class="mt-2 progress-bar-group" :max="max">
        <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
        <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
        <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
        <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
      </b-progress>
      <b-progress show-progress class="mt-2 progress-bar-group" :max="max">
        <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
        <b-progress-bar :value="value * (4 / 10)" variant="info" animated></b-progress-bar>
        <b-progress-bar :value="value * (2.5 / 10)" variant="warning" animated></b-progress-bar>
        <b-progress-bar :value="value * (1.5 / 10)" variant="danger" striped></b-progress-bar>
      </b-progress>

      <b-button class="mt-3" @click="randomValue" variant="primary">Click me</b-button>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-progress class="mt-2 progress-bar-group" :max="max">
    <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
    <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
    <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
    <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
  </b-progress>
  <b-progress show-value class="mt-2 progress-bar-group" :max="max">
    <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
    <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
    <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
    <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
  </b-progress>
  <b-progress show-value striped class="mt-2 progress-bar-group" :max="max">
    <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
    <b-progress-bar :value="value * (4 / 10)" variant="info"></b-progress-bar>
    <b-progress-bar :value="value * (2.5 / 10)" variant="warning"></b-progress-bar>
    <b-progress-bar :value="value * (1.5 / 10)" variant="danger"></b-progress-bar>
  </b-progress>
  <b-progress show-progress class="mt-2 progress-bar-group" :max="max">
    <b-progress-bar :value="value * (6 / 10)" variant="success"></b-progress-bar>
    <b-progress-bar :value="value * (4 / 10)" variant="info" animated></b-progress-bar>
    <b-progress-bar :value="value * (2.5 / 10)" variant="warning" animated></b-progress-bar>
    <b-progress-bar :value="value * (1.5 / 10)" variant="danger" striped></b-progress-bar>
  </b-progress>

  <b-button class="mt-3" @click="randomValue" variant="primary">Click me</b-button>
</div>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        value: 33.3333333,
        max: 100
      }
    },
    methods: {
      randomValue() {
        this.value = Math.random() * this.max
      }
    }
  }
&lt;/script&gt;
</code></pre>

<p><code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code> will inherit most of the props from the <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code> parent component, but you can override any of the props by setting them on the <code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code></p>

<ul> <li><code class="text-nowrap" translate="no">height</code>, if specified, should always set on the <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code> component.</li> <li><code class="text-nowrap" translate="no">&lt;b-progress-bar&gt;</code> will not inherit <code class="text-nowrap" translate="no">value</code> from <code class="text-nowrap" translate="no">&lt;b-progress&gt;</code>.</li> </ul>
  </div>
</template>

<script>
import ProgressRange from "./ProgressRange"
export default {
  name: "ProgressBarView",
  data() {
    return {
      value: 33.3333333,
      width: 0,
      value2: 40,
      customValue: 50,
      max: 100,
      striped: true,
      animate: true,
      bars: [
        { variant: 'primary', value: 75 },
        { variant: 'secondary', value: 75 },
        { variant: 'success', value: 75 },
        { variant: 'info', value: 75 },
        { variant: 'warning', value: 75 },
        { variant: 'danger', value: 75 },
        { variant: 'dark', value: 75 }
      ],
      timer: null
    }
  },

  components: {
    ProgressRange
  },

  methods: {
    randomValue() {
      this.value = Math.random() * this.max
    },

  },
  mounted() {
    document.title = "Progress bar";
    Prism.highlightAll();

    this.timer = setInterval(() => {
      this.bars.forEach(bar => (bar.value = 25 + Math.random() * 75))
      }, 2000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
      this.timer = null
    }


}
</script>

<style scoped>

</style>
