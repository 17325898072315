<template>
    <div class="wrapper">
            <title>Desktop chat</title>

        <h1 class="title-section"> Chat</h1>

        <div class="desktop-chat">
            <div class="desktop-wrapper">
                <div class="search-container">

                    <div class="chat-header d-flex justify-content-between bg-white">
                        <div class="search-chat d-flex">
                            <inline-svg :src="IconSearch"></inline-svg>
                            <input type="text" placeholder="Search for..." class="form-control">
                        </div>
                        <app-button class="btn-icon">
                            <inline-svg :src="Bell"></inline-svg>
                            <span>9</span>
                        </app-button>

                    </div>
                </div>

                                <div class="main-wrapper">
            <!-- Conversation MESSAGES -->
                    <div class="chat-menu overflow-auto" :class="[{ active: active}, {notactive: !active}]" >
                        <div class="top">
                            <h3>Chats</h3>
                            
                            <div class="search-chat d-flex">
                                <inline-svg :src="IconSearch"></inline-svg>
                                <input type="text" placeholder="Search for..." class="form-control">
                            </div>

                            <div v-for="conversation in conversations" :key="conversation.id">
                            <b-card class="conversation" :class="[conversation.id == someNum ? 'active' : '']" @click="changeNumber(conversation.id)">
                                <h4>{{conversation.header}}</h4>
                                <p>{{conversation.text}}</p>
                                <p class="chat-heads"><inline-svg :src="ChatHeads"></inline-svg><span >Aug 26</span></p>
                            </b-card>

                            </div>
                        </div>

                        <div class="bottom">
                        <p>Only healthcare professionals can initiate chat.
                            You will be notified when a chat opens.
                        </p>
                        <div class="trendy-example">
                            <app-button variant="info">Active</app-button>
                            <app-button variant="light">History</app-button>
                        </div>  
                        </div>

                    </div>

                    <div class="active-chat">
                        <div class="chat-header">
                            <app-button @click="toggleConversations()"  id="conversations-toggler" class="toggle-sidebar">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                            </app-button>
                            <h4><inline-svg :src="TestConversation"></inline-svg>Conversation 12</h4>
                            <div class="active-friends">
                                <div class="friend">
                                    <div class="chat-head active">
                                        <img src="../../src/assets/images/chat-desktop/chat-head-1.png" alt="">
                                    </div>
                                    <p>Elizabet</p>
                                </div>
                                <div class="friend">
                                    <div class="chat-head active">
                                        <img src="../../src/assets/images/chat-desktop/chat-head-1.png" alt="">
                                    </div>
                                    <p>Djole</p>
                                </div>
                                <div class="friend">
                                    <div class="chat-head active">
                                        <img src="../../src/assets/images/chat-desktop/chat-head-1.png" alt="">
                                    </div>
                                    <p>Momir</p>
                                </div>
                                <div class="friend">
                                    <div class="chat-head active">
                                        <img src="../../src/assets/images/chat-desktop/chat-head-1.png" alt="">
                                    </div>
                                    <p>Djole</p>
                                </div>             
                        </div>
  
                        </div>

                    <!-- Opened FIRST conversation by default - MESSAGES -->


                    <!-- POCETAK PETLJE -->
                    <div v-for="conversation in conversations" :key="conversation.id"  >
                    <div class="messages" :id="conversation.id" :class="[conversation.id == someNum ? 'active' : '']">
                      <div class="chat-desktop">
                            <div class="chat-body">
                                <div class="chat-messages">

                                    <div v-for="messages in conversation.messages" :key="messages.id"  >
                                        <div class="message-block">
                                            <img v-if="messages.user_id == 2" class="chat-head" src="../../src/assets/images/chat-desktop/chat-head-1.png" alt="">
                                            <div  v-bind:class="(messages.user_id == 1 ? 'send' : 'received')" >
                                                {{ messages.content}}
                                            </div>
                                        </div>


                                        <p v-if="messages.user_id ==2" class="chat-date-recived">{{timestamp}}</p>
                                        <p v-if="messages.user_id ==1" class="chat-date-sent">{{timestamp}}</p>


                                    </div>

                                </div>
                                <div>

                                </div>
                            </div>
                        </div> 
                    </div>
                    </div>

                    <!-- KRAJ PETLJE -->
                    <div class="input-container">
                        <div class="input">
                            <app-button class="btn btn-attach"><inline-svg :src="Attach"></inline-svg></app-button>
                            <app-button class="btn btn-attach img"><inline-svg :src="AttachImg"></inline-svg></app-button>
                            <b-input type="text" placeholder="Message..." v-model="newMessageContent" v-on:keyup.enter="addMessageNew"/>
                            <app-button class="btn btn-send"  @click="addMessageNew()"><inline-svg :src="Send"></inline-svg></app-button>
                                
                        </div>
                    </div>


                    </div>
                </div>



            </div>

        </div>
        
    </div>    
</template>

<script>
import AppButton from "@/components/AppButton";
import InlineSvg from "vue-inline-svg";
import SearchIcon from '@/assets/images/chat-desktop/search-icon.svg'
import ChatHeads from '@/assets/images/chat-desktop/chat-heads.svg'
import TestConversation from '@/assets/images/chat-desktop/test-conversation-icon.svg'
import Attach from '@/assets/images/chat-desktop/attach.svg'
import AttachImg from '@/assets/images/chat-desktop/attach-image.svg'
import Send from '@/assets/images/chat-desktop/send.svg'
import Bell from '@/assets/images/chat-desktop/bell.svg'
import IconSearch from '@/assets/images/chat-desktop/icon-search.svg'

export default {
    name: 'DesktopChat',

    data() {
        const date = new Date();

        return {
            SearchIcon,
            ChatHeads,
            TestConversation,
            Attach,
            AttachImg,
            Bell,
            IconSearch,
            Send,
            someNum: 1,
            show: true,
            active: false,
            date:new Date(),
            timestamp: "",

            conversations: [
                // Conversation 1
                {
                    id: 1, header: "Conversation with testers", text: "Conversation text", 
                    // messages 1
                    messages: [
                        {user_id: 2, content:'Hi Sara'},
                        {user_id: 1, content:'Hi Elizabeth'}, 
                        {user_id: 2, content:'I want to tell you that our project is finished!You can come and read it if you have any suggestions.'}, 
                        {user_id: 1, content:"Thank you Elizabet.I was wondering is it finished.I will read it to make sure everything is fine."}, 
                        {user_id: 2, content:'You’re welcome.I like the last picture that you add on second page of the project. She did amazing job.'}, 
                        {user_id: 1, content:'Yes, I like it too.'}, 
                        {user_id: 1, content:'Oh, would you like some coffee?'}, 
                        {user_id: 2, content:'OK I am waiting for you.'}, 
                        {user_id: 2, content:'Yes, coffee would be great 😊'}, 
                        {user_id: 1, content:'See you soon!'}, 
                        {user_id: 2, content:'See you'}
                    ]
                },

                // Conversation 2
                {
                    id: 2, header: "Group name", text: "Conversation text", 
                    // messages 2
                    messages: [
                        {user_id: 2, content:'Hi, Elizabeth'},
                        {user_id: 1, content:'Hi Linda'}, 
                    ]
                },

                // Conversation 3
                {
                    id: 3, header: "Naslov", text: "Conversation text", 
                    // messages 3
                    messages: [
                        {user_id: 2, content:'Hello, Can I ask you something'},
                        {user_id: 1, content:'Yes, sure'}, 
                    ]
                }

            ],

            newMessageContent: '',
            messages: [
                {user_id: 2, content:'Hi Linda'},
                {user_id: 1, content:'Hi Elizabeth'}, 
                {user_id: 2, content:'I want to tell you that our project is finished!You can come and read it if you have any suggestions.'}, 
                {user_id: 1, content:"Thank you Elizabet.I was wondering is it finished.I will read it to make sure everything is fine."}, 
                {user_id: 2, content:'You’re welcome.I like the last picture that you add on second page of the project. She did amazing job.'}, 
                {user_id: 1, content:'Yes, I like it too.'}, 
                {user_id: 1, content:'Oh, would you like some coffee?'}, 
                {user_id: 2, content:'OK I am waiting for you.'}, 
                {user_id: 2, content:'Yes, coffee would be great 😊'}, 
                {user_id: 1, content:'See you soon!'}, 
                {user_id: 2, content:'See you'}, 
                ],
            messages2: [
                {user_id: 2, content:'Hi, Elizabeth'},
                {user_id: 1, content:'Hi Linda'}, 
                ],
            messages3: [
                {user_id: 2, content:'Hello, Can I ask you something'},
                {user_id: 1, content:'Yes, sure'}, 
                ],
        }
    },

    components: {
        InlineSvg,
        AppButton
    },

    mounted() {
        document.title = "Desktop chat";
        this.getNow()
        // this.conversations[2].messages.forEach(element => console.log(element.content))

    },

    created() {
    },

    methods: {
        changeNumber(value) {
            this.someNum = value
            let id = this.someNum - 1   
            this.active =! this.active
        },
        
        addMessage() {
          if(this.someNum == 1) {
            this.messages.push({user_id: 1, content: this.newMessageContent});
          }
          
          if(this.someNum == 2) {
            this.messages2.push({user_id: 1, content: this.newMessageContent});
          } 
          else if (this.someNum == 3) {
            this.messages3.push({user_id: 1, content: this.newMessageContent});

          }
          // 'this' refers to 'app'.
          this.newMessageContent = '';
        },  
        
        addMessageNew() {
          if(this.someNum == 1) {
            this.conversations[0].messages.push({user_id: 1, content: this.newMessageContent});
          }
          
          if(this.someNum == 2) {
            this.conversations[1].messages.push({user_id: 1, content: this.newMessageContent});
          } 
          else if (this.someNum == 3) {
            this.conversations[2].messages.push({user_id: 1, content: this.newMessageContent});

          }
          // 'this' refers to 'app'.
          this.newMessageContent = '';
        },  

        toggleConversations() {
            this.active =! this.active
            //console.log(this.active)

        },

        getNow() {
            const today = new Date();
            const date = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
            const time = today.getHours() + ":" + today.getMinutes();
            const dateTime = date +' | '+ time;

            this.timestamp = dateTime;


        }


    }
    
}
</script>