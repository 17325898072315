<template>
<div class="v-scroll">
    
    <sidebar-menu :menu="menu" id="sidebar" :class="{ active: active}">
    </sidebar-menu>
</div>
</template>

<script>
export default {
name: "DefaultAside",
data() {
    return {
      active: true,
      visible: true,
      name: "",

      menu: [
        {
          header: true,
          title: 'COMPONENTS',
        },
        {
          href: '/alerts',
          title: 'Alerts',
        },
        {
          href: '/badges',
          title: 'Badges',
        },
        {
          href: '/breadcrumbs',
          title:'Breadcrumbs',
        },
        {
          href: '/buttons',
          title:'Buttons',
        },
         {
          href: '/buttonsgroup',
          title:'ButtonsGroup',
        },
        {
          href: '/calendar',
          title: 'Calendar'
        },
        {
          href:'/cards',
          title:'Cards'
        },
        {
          href: '/carousel',
          title:'Carousel'
        },
        {
          href: '/collapse',
          title: 'Collapse'
        },
        {
          href: '/dropdowns',
          title: 'Dropdowns'
        },
        {
          href: '/forms',
          title: 'Forms'
        },
        {
          href: '/inputs',
          title:'Inputs'
        },       
        {
          href:'/jumbotron',
          title:'Jumbotron'
        },
        {
          href: '/list',
          title:'List Group'
        },
        {
          href:'/modals',
          title:'Modal'
        },
        {
          href: '/navs',
          title:'Navs & Tabs'
        },
        {
          href:'/nav-bars',
          title:'Navbar'
        },
        {
          href: '/pagination',
          title:'Pagination'
        },
        {
          href:'progress-bar',
          title:'Progress bar'
        },
        {
          href:'/spinners',
          title:'Spinners'
        },        
        {
          href: '/tables',
          title:'Tables'
        },
        {
          href: '/toast',
          title: 'Toast'
        },
        {
          href:'/tooltips',
          title:'Tooltips'
        },

        {
          href:'/chat',
          title: 'Chat'
        },
        {
          href:'/desktopchat',
          title: 'DesktopChat'
        },
        {
          href: '/charts',
          title: 'Charts'
        },
        {
          href: '/switchers',
          title:'Switchers'
        },
        {
          href: '/extras',
          title: 'Extras'
        },
        {
          href: '/pricing',
          title: 'Pricing'
        },
        {
          href: '/login',
          title: 'LoginView'
        },
        {
          href: '/landing',
          title: 'LandingView'
        },
        {
          href: '/landing2',
          title: 'LandingView2'
        },
        {
          href: '/catalog',
          title: 'CatalogView'
        },

      ]

    };
  },
  methods: {
    logout(){

    },
  },
}
</script>

<style scoped>

</style>
