<template>
  <div class="wrapper">
        <title>Calendar</title>


    <h1 class="title-section">Calendar</h1>
    <p style="font-size: 1.5rem">An elegan calendar and datepicker for Vuejs</p>
    <p class="m-30" style="font-size: 1.5rem">Show dots, highlights, bars and custom popovers</p>
  
      <b-row>
      <b-col md="6" class="mb-5">
        <v-date-picker v-model="date" :attributes="attrs"  is-expanded title-position="left"></v-date-picker>
      </b-col>
      <b-col md="6">

        <!-- date box       -->
        <div class="date-box-first mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row justify-content-start ">
              <b-button class="m-auto justify-content-center" variant="primary">
                <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
              </b-button>
              <div class="day-box ">

                <div class="day">
                  10:00 - 11:00
                </div>
                <div class="title">
                  {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="date-box-first mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row justify-content-start ">
              <b-button class="m-auto justify-content-center" variant="primary">
                <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
              </b-button>
              <div class="day-box">

                <div class="day">
                  10:00 - 11:00
                </div>
                <div class="title">
                  {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

                </div>
              </div>
            </div>
            <div class="d-flex justify-content-start mr-3">
              <b-button variant="info">
                <inline-svg  :src="AngleRightWhite"></inline-svg>
              </b-button>

            </div>
          </div>
        </div>
        <div class="date-box-second mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start">
              <div class="date">
                {{date.getDate() }}
              </div>
              <div class="day-box">
                <div class="title">Today</div>
                <div class="day">
                  {{full_name_days[date.getDay()] }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-start mr-3">
              <inline-svg :src="AngleRight"></inline-svg>
            </div>
          </div>
        </div>
        <div class="date-box">
          <div class="date">
            {{date.getDate() }}
            <div class="day">{{ days[date.getDay()] }}</div>
          </div>
        </div>
      </b-col>
    </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <v-date-picker v-model="date" :attributes="attrs"  is-expanded title-position="left"></v-date-picker>
</div></script></code></pre>

       <h3>Date box</h3>

        <!-- date box       -->
        <div class="date-box-first mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row justify-content-start ">
              <b-button class="m-auto justify-content-center" variant="primary">
                <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
              </b-button>
              <div class="day-box ">

                <div class="day">
                  10:00 - 11:00
                </div>
                <div class="title">
                  {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

                </div>
              </div>
            </div>

          </div>
        </div>
  
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="date-box-first mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-row justify-content-start ">
        <b-button class="m-auto justify-content-center" variant="primary">
          <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
        </b-button>
        <div class="day-box ">

          <div class="day">
            10:00 - 11:00
          </div>
          <div class="title">
            {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

          </div>
        </div>
      </div>
    </div>
 </div></script></code></pre>


        <div class="date-box-first mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row justify-content-start ">
              <b-button class="m-auto justify-content-center" variant="primary">
                <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
              </b-button>
              <div class="day-box">

                <div class="day">
                  10:00 - 11:00
                </div>
                <div class="title">
                  {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

                </div>
              </div>
            </div>
            <div class="d-flex justify-content-start mr-3">
              <b-button variant="info">
                <inline-svg  :src="AngleRightWhite"></inline-svg>
              </b-button>

            </div>
          </div>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="date-box-first mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex flex-row justify-content-start ">
      <b-button class="m-auto justify-content-center" variant="primary">
        <inline-svg class="p-1" :src="CalendarIcon"></inline-svg>
      </b-button>
      <div class="day-box">

        <div class="day">
          10:00 - 11:00
        </div>
        <div class="title">
          {{full_name_days[date.getDay()]}},  {{monthNames[date.getMonth()]}} 2021

        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start mr-3">
      <b-button variant="info">
        <inline-svg  :src="AngleRightWhite"></inline-svg>
      </b-button>

    </div>
  </div>
</div></script></code></pre>

        <div class="date-box-second mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start">
              <div class="date">
                {{date.getDate() }}
              </div>
              <div class="day-box">
                <div class="title">Today</div>
                <div class="day">
                  {{full_name_days[date.getDay()] }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-start mr-3">
              <inline-svg :src="AngleRight"></inline-svg>
            </div>
          </div>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="date-box-second mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-start">
      <div class="date">
        {{date.getDate() }}
      </div>
      <div class="day-box">
        <div class="title">Today</div>
        <div class="day">
          {{full_name_days[date.getDay()] }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start mr-3">
      <inline-svg :src="AngleRight"></inline-svg>
    </div>
  </div>
</div></script></code></pre>


        <div class="date-box">
          <div class="date">
            {{date.getDate() }}
            <div class="day">{{ days[date.getDay()] }}</div>
          </div>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="date-box">
    <div class="date">
      {{date.getDate() }}
      <div class="day">{{ days[date.getDay()] }}</div>
    </div>
</div></script></code></pre>


  </div>
</template>

<script>
import Vue from 'vue';
import VCalendar from 'v-calendar';
import InlineSvg from 'vue-inline-svg';
import AngleRight from '@/assets/images/icons/angle-right.svg'
import AngleRightWhite from '@/assets/images/icons/angle-right-white.svg'
import CalendarIcon from '@/assets/images/icons/icon-calendar.svg'
Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  columns: 2,
});

export default {
  name: "CalendarView",
  data() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    return {
      AngleRight,
      CalendarIcon,
      AngleRightWhite,
      attrs: [
        {
          highlight: 'red',
          dates: new Date(year,month,18),
        },
        {
          highlight: 'yellow',
          dates: new Date(year,month,19),
        },
        {
          highlight: {
            color: 'purple',
            fillMode: 'light',
          },
          dates: new Date(year, month, 13),
        },
      ],
      date:new Date(),
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      full_name_days:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      monthNames: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ]
    };
  },
  components:{
    InlineSvg
  },

  mounted() {
    document.title = "Calendar";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
