<template>
<div>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">


  <div id="app" class="container">
  <b-row>
    <b-col lg="12">
      <nav-bar class="main-navbar" v-if="$route.name!='LandingView' && $route.name!='LandingViewSecond'  && $route.name!='LandingView2' && $route.name!='CatalogView' && $route.name!='LoginView'"></nav-bar>
    </b-col>
  </b-row>
    <b-row  v-if="$route.name!='LandingView' && $route.name!='LandingViewSecond' && $route.name!='LandingView2' && $route.name!='CatalogView' && $route.name!='LoginView'" >
          <b-col lg="3" id="col-sidebar">
              <default-container id="sidebar" :class="[{ active: active}, {notactive: !active}]"/>
          </b-col>

          <b-col lg="9" id="column">
              <div>
                <router-view/>
              </div>
          </b-col>


    </b-row>

    <b-row>
                <b-col lg="12" id="column" v-if="$route.name =='LandingView'|| $route.name =='LandingViewSecond' || $route.name =='LandingView2' || $route.name =='CatalogView' || $route.name=='LoginView' " >
              <div>
                <router-view/>
              </div>
          </b-col>
    </b-row>


  </div>

</div>
</template>

<style lang="scss">
@import 'assets/scss/style.scss';
/* Import Bootstrap Vue Styles */
@import 'assets/scss/_variables.scss';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
</style>
<script>
import DefaultContainer from "@/containers/DefaultContainer";
import NavBar from "@/containers/NavBar";


export default {
  name: 'App',
  data() {
    return {
      active: false,
    }
  },
  components: {
    DefaultContainer,
    NavBar
    
    },

    mounted () {
      this.$on('toggleNav', () => {
      this.active =! this.active
      })

    },

}
</script>
