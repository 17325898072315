<template>
  <div class="wrapper">
              <title>Spinners</title>


    <h1 class="title-section">Spinners</h1>
    <p style="font-size: 1.5rem">The <code class="text-nowrap" translate="no">&lt;b-spinner&gt;</code> component can be used to show the loading state in your projects. They're rendered only with basic HTML and CSS as a lightweight Vue functional component. <br>
    Their appearance, alignment, and sizing can be easily customized with a few built-in props and/or Bootstrap v4 utility classes.</p>

    <p class="m-30">Spinners can be placed just about anywhere, including inside buttons, alerts, and even <code class="text-nowrap" translate="no">&lt;b-table&gt;</code>'s busy slot.</p>
    <div class="text-center">

      <div class="trendy-example">
          <b-spinner label="Spinning"></b-spinner>
          <b-spinner type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="success" label="Spinning"></b-spinner>
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>

    </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
  <b-spinner label="Spinning"></b-spinner>
  <b-spinner type="grow" label="Spinning"></b-spinner>
  <b-spinner variant="primary" label="Spinning"></b-spinner>
  <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
  <b-spinner variant="success" label="Spinning"></b-spinner>
  <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
</div></script></code></pre>

    <h2>Spinner types</h2>
    <p class="m-40">Bootstrap includes two types of spinners. The default spinner type is called border (spinning circle border), and the optional type grow (a throbber style indicator).</p>
   
        <h2 >Border spinner</h2>
        <p class="m-30">Use the default border type spinners for a lightweight loading indicator.</p>
        <b-spinner label="Loading..." variant="primary" class="m-40"></b-spinner>


        <h2>Color variants</h2>
        <p>Spinners use currentColor for their color, meaning it inherits the current font color. You can customize the color using the standard text color variants using the variant prop, or place classes or styles on the component to change its color.</p>
        <p class="m-30">The variant prop translates the variant name to the Bootstrap v4 class .text-{variant}, so if you have custom defined text color variants, feel free to use them via the variant prop.</p>
        <div class="text-center trendy-example">
          
          <b-spinner
            v-for="variant in variants"
            :variant="variant"
            :key="variant"
          ></b-spinner>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="text-center mb-3 d-flex justify-content-between">
      <b-spinner
        v-for="variant in variants"
        :variant="variant"
        :key="variant"
      ></b-spinner>
  </div>
</div></script></code></pre>


        <h2>Grow spinner</h2>
        <p class="m-30">If you don't fancy a border spinner, switch to the grow spinner by setting the prop type to 'grow'. While it doesn't technically spin, it does repeatedly grow!</p>
        <b-spinner type="grow" label="Loading..." variant="primary" class="m-40"></b-spinner>


        <h2>Color variants</h2>
        <p class="m-30">Growing spinners are also available in all color variants.</p>
        <div class="text-center">

          <div class="trendy-example">
            <b-spinner
              type="grow"
              v-for="variant in variants"
              :variant="variant"
              :key="variant"
            ></b-spinner>
          </div>

        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="text-center mb-3 d-flex justify-content-between">
      <b-spinner
        type="grow"
        v-for="variant in variants"
        :variant="variant"
        :key="variant"
      ></b-spinner>
  </div>
</div></script></code></pre>


          <h2>Size</h2>
          <p class="m-30">Set the prop small to true to make a smaller spinner that can quickly be used within other components.</p>
            <div class="small-spinners trendy-example">
              <b-spinner small label="Small Spinner" variant="primary"></b-spinner>
              <b-spinner small label="Small Spinner" type="grow" variant="primary"></b-spinner>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="small-spinners">
    <b-spinner small label="Small Spinner" variant="primary"></b-spinner>
    <b-spinner small label="Small Spinner" type="grow" variant="primary"></b-spinner>
</div></script></code></pre>  

          <p class="m-30">Or, use custom CSS or inline styles to change the dimensions as needed.</p>

          <div class="trendy-example">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" variant="primary"></b-spinner>
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow" variant="primary"></b-spinner>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="large-spinners">
  <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" variant="primary"></b-spinner>
  <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow" variant="primary"></b-spinner>
</div></script></code></pre>  


 
          <h2>Alignment</h2>
          <p class="m-40">Spinners in Bootstrap are built with rems, currentColor, and display: inline-flex. This means they can easily be resized, recolored, and quickly aligned.</p>


          <h2>Margin</h2>
          <p class="m-30">Use margin utilities like .m-5 for easy spacing.</p>

          <div class="trendy-example">
            <b-spinner class="m-5" label="Busy" variant="primary"></b-spinner>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="large-spinners">
  <b-spinner class="m-5" label="Busy" variant="primary"></b-spinner>
</div></script></code></pre> 

          <h2>Placement</h2>
          <p class="m-40">Use flexbox utilities, float utilities, or text alignment utility classes to place spinners exactly where you need them in any situation.</p>

          <h2>Flex</h2>
          <p class="m-30">Using flex utility classes:</p>

          <div trendy-example>
            <div class="d-flex justify-content-center">
                <b-spinner label="Loading..." variant="primary"></b-spinner>
            </div>

            <div class="d-flex align-items-center">
                <strong>Loading...</strong>
                <b-spinner class="ml-auto" variant="primary"></b-spinner>
            </div>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..." variant="primary"></b-spinner>
    </div>

    <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <b-spinner class="ml-auto" variant="primary"></b-spinner>
    </div>
</div></script></code></pre> 

          <h2>Floats</h2>
          <p class="m-30">Using float utility classes:</p>

          <div class="clearfix trendy-example">
            <b-spinner class="float-right" label="Floated Right" variant="primary"></b-spinner>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="clearfix">
    <b-spinner class="float-right" label="Floated Right" variant="primary"></b-spinner>
</div></script></code></pre> 


          <h2>Text align</h2>
          <p class="m-30">Using text alignment utility classes:</p>

          <div class="text-center trendy-example">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
  <b-spinner variant="primary" label="Text Centered"></b-spinner>
</div></script></code></pre>

          <h2>Spinners in buttons</h2>
          <p>Use spinners within buttons to indicate an action is currently processing or taking place. You may also swap the label text out of the spinner element and utilize button text as needed.</p>
          <p class="m-30">Both, border and growing spinners are enabled within buttons.</p>
        

        <div class="trendy-example">
            <b-button variant="primary">
              <b-spinner small></b-spinner>
              <span class="sr-only">Primary</span>
            </b-button>

            <b-button variant="primary">
              <b-spinner small ></b-spinner>
              Primary
            </b-button>

            <b-button variant="primary">
              <b-spinner type="grow" small class="grow-spinner"></b-spinner>
              <span class="sr-only">Primary</span>
            </b-button>

            <b-button variant="primary">
              <b-spinner type="grow" small class="grow-spinner"></b-spinner>
              Primary
            </b-button>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button class="mr-3" variant="primary">
    <b-spinner small></b-spinner>
    <span class="sr-only">Primary</span>
  </b-button>

  <b-button variant="primary">
    <b-spinner small ></b-spinner>
    Primary
  </b-button>

  <b-button class="ml-5" variant="primary">
    <b-spinner type="grow" small class="grow-spinner"></b-spinner>
    <span class="sr-only">Primary</span>
  </b-button>

  <b-button class="ml-3" variant="primary">
    <b-spinner type="grow" small class="grow-spinner"></b-spinner>
    Primary
  </b-button>
</div></script></code></pre>        


    <h2>Spinner accessibility</h2>
    <p>
      Place a hidden label text inside the spinner for screen reader users, via the <code class="text-nowrap" translate="no">label</code> 
      prop or <code class="text-nowrap" translate="no">label</code> slot. The content will be placed <em>inside</em> 
      the spinner wrapped in a <code class="text-nowrap" translate="no">&lt;span&gt;</code> element that has the class 
      <code class="text-nowrap" translate="no">sr-only</code>, which will make the label available to screen reader users.
    </p>
    <p>
      For accessibility purposes, each spinner will automatically have a <code class="text-nowrap" translate="no">role="status"</code> 
      attribute when a label is provided. You can easily customize the role if required via prop <code class="text-nowrap" translate="no">role</code>. 
      The specified <code class="text-nowrap" translate="no">role</code> will not be applied when no label is provided.
    </p>
    <p>
      As well, when no label is provided, the spinner will automatically have the attribute 
      <code class="text-nowrap" translate="no">aria-hidden="true"</code> to hide the spinner from screen reader users.
    </p>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
  name: "SpinnersView",
  components: {
    AppButton
  },
  data() {
    return {
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'light', 'dark'],
      small: "small",
      large: "large"
    }
  },
  mounted() {
    document.title = "Spinners";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
