<template>
  <div class="wrapper">
        <title>Forms</title>

    <h1 class="title-section">Form group</h1>
    <p class="m-40" style="font-size: 1.5rem">
      The <code class="text-nowrap" translate="no">&lt;b-form-group&gt;</code> component is the easiest way to add some structure to forms. 
      Its purpose is to pair form controls with a legend or label, and to provide help text and invalid/valid feedback text, as well as visual (color) contextual state feedback.
    </p>

    <h2>Textual and Value inputs</h2>
    <p class="m-40">
      Create various type inputs such as: <code class="text-nowrap" translate="no">text</code>, <code class="text-nowrap" translate="no">password</code>, 
      <code class="text-nowrap" translate="no">number</code>, <code class="text-nowrap" translate="no">url</code>, <code class="text-nowrap" translate="no">email</code>, 
      <code class="text-nowrap" translate="no">search</code>, <code class="text-nowrap" translate="no">range</code>, <code class="text-nowrap" translate="no">date</code> 
      and more.
    </p>

    <b-row>
      <b-col>
        <h3>Input type group</h3>
        <p class="m-40">
          <code class="text-nowrap" translate="no">&lt;b-form-input&gt;</code> defaults to a <code class="text-nowrap" translate="no">text</code> input, 
          but you can set the <code class="text-nowrap" translate="no">type</code> prop to one of the supported native browser HTML5 types: 
          <code class="text-nowrap" translate="no">text</code>, <code class="text-nowrap" translate="no">password</code>, 
          <code class="text-nowrap" translate="no">email</code>, <code class="text-nowrap" translate="no">number</code>, 
          <code class="text-nowrap" translate="no">url</code>, <code class="text-nowrap" translate="no">tel</code>,
          <code class="text-nowrap" translate="no">search</code>, <code class="text-nowrap" translate="no">date</code>, 
          <code class="text-nowrap" translate="no">datetime</code>, <code class="text-nowrap" translate="no">datetime-local</code>, 
          <code class="text-nowrap" translate="no">month</code>, <code class="text-nowrap" translate="no">week</code>, 
          <code class="text-nowrap" translate="no">time</code>, <code class="text-nowrap" translate="no">range</code>, or 
          <code class="text-nowrap" translate="no">color</code>.
        </p>


        <h3>Basic input</h3>

        <div class="m-40">
        <b-form-group>
          <label>Email address</label>
          <b-input v-model="text" size="md"></b-input>
          <div class="mt-2">Value: {{ text }}</div>
        </b-form-group>

        </div>



        <h3>Rounded input</h3>

        <div class="m-40">
        <b-form-group>
          <label>Email address</label>
          <b-input size="md" class="form-control-rounded"></b-input>
        </b-form-group>

        </div>


        <h3>Readonly input</h3>
        <p>
          If you want to have <code class="text-nowrap" translate="no">&lt;b-form-input readonly&gt;</code> 
          elements in your form styled as plain text, set the <code class="text-nowrap" translate="no">plaintext</code> prop (no need to set 
          <code class="text-nowrap" translate="no">readonly</code>) to remove the default form field styling and preserve the correct margin and padding.
        </p>
        <p class="m-30">
          The <code class="text-nowrap" translate="no">plaintext</code> option is not supported by input types 
          <code class="text-nowrap" translate="no">color</code> or <code class="text-nowrap" translate="no">range</code>.
        </p>

       <b-col md="5">
        <b-input class="mb-4" readonly placeholder="Readonly input here..."></b-input>
        <b-input readonly placeholder="Readonly input here..."></b-input>
      </b-col>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input class="mb-4" readonly placeholder="Readonly input here..."></b-input>
  <b-input readonly placeholder="Readonly input here..."></b-input>
</div></script></code></pre>

        <h3>Range input type</h3>
          <p>Inputs with type <code class="text-nowrap" translate="no">range</code> render using Bootstrap v4's <code class="text-nowrap" translate="no">.custom-range</code> class. The track (the background) and thumb (the value) are both styled to appear the same across browsers.</p>
          <p class="m-30">Range inputs have implicit values for <code class="text-nowrap" translate="no">min</code> and <code class="text-nowrap" translate="no">max</code> of <code class="text-nowrap" translate="no">0</code> and <code class="text-nowrap" translate="no">100</code> respectively. You may specify new values for those using the <code class="text-nowrap" translate="no">min</code> and <code class="text-nowrap" translate="no">max</code> props.</p>
          
          <div class="m-40">
            <div class="range-input">
              <progress-range class="mt-5 m-40" v-model="rangeValue"></progress-range>          
          </div>
          <!-- <b-form-input  min="0" max="100" v-model="rangeValue" type="range"/> -->
          <div class="mt-2">Value: {{ rangeValue }}</div>





          </div>


      
    <b-row>
      <b-col>
        <h2>Select options</h2>
        <p class="m-30">Generate your select options by passing an array or object to the <code class="text-nowrap" translate="no">options</code> props:</p>

        <b-form-group class="mb-5">
            <label>Example select</label>
        <select hidden styled-select="true" placeholder="Choose">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>

            <!-- <app-select-list :options="options" v-model="select"></app-select-list> -->
        </b-form-group>

        <b-form-group class="mb-5">
            <label>Example select</label>
        <select hidden styled-select="true" placeholder="Choose" class="rounded-pill">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>
            <!-- <app-select-list v-model="select" :options="options" rounded></app-select-list> -->
        </b-form-group>

        <p class="m-40">You can even define option groups with the <code class="text-nowrap" translate="no">options</code> prop:</p>

        <h3>Options property</h3>
        <p><code class="text-nowrap" translate="no">options</code> can be an array of strings or objects, or a key-value object. Available fields:</p>
        <ul> 
          <li>
            <strong><code class="text-nowrap" translate="no">value</code></strong> The selected value which will be set on 
            <code class="text-nowrap" translate="no">v-model</code>
          </li> 
          <li>
            <strong><code class="text-nowrap" translate="no">disabled</code></strong> Disables item for selection
          </li> 
          <li>
            <strong><code class="text-nowrap" translate="no">text</code></strong> Display text, or <strong>
              <code class="text-nowrap" translate="no">html</code></strong> Display basic inline html
          </li> 
        </ul>

        <p>
          <code class="text-nowrap" translate="no">value</code> can be a string, number, or simple object. Avoid using complex types in values.
        </p>
        <p class="m-40">
          If both <code class="text-nowrap" translate="no">html</code> and <code class="text-nowrap" translate="no">text</code> are provided, 
          <code class="text-nowrap" translate="no">html</code> will take precedence. Only basic/native HTML is supported in the 
          <code class="text-nowrap" translate="no">html</code> field (components will not work). 
          Note that not all browsers will render inline html (i.e. <code class="text-nowrap" translate="no">&lt;i&gt;</code>, 
          <code class="text-nowrap" translate="no">&lt;strong&gt;</code>, etc.) inside <code class="text-nowrap" translate="no">&lt;option&gt;</code> elements of a 
          <code class="text-nowrap" translate="no">&lt;select&gt;</code>.
        </p>

        <h3>Value in single mode</h3>
        <p class="m-30">In non <code class="text-nowrap" translate="no">multiple</code> mode, <code class="text-nowrap" translate="no">&lt;b-form-select&gt;</code> returns the a single <code class="text-nowrap" translate="no">value</code> of the currently selected option.</p>
        
        <div class="m-40">
        <b-form-group class="mb-5">
            <label>Example select</label>
        <select hidden styled-select="true" placeholder="Choose">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>

            <!-- <app-select-list :options="options" v-model="select"></app-select-list> -->
        </b-form-group>

        <!-- <div>
          Selected : {{ select }}
        </div> -->

        </div>
      </b-col>
    </b-row>

        
      
      <h3>Control sizing</h3>
      <p>
        Set heights using the <code class="text-nowrap" translate="no">size</code> prop to 
        <code class="text-nowrap" translate="no">sm</code> or <code class="text-nowrap" translate="no">lg</code> for small or large respectively.
      </p>

      <p class="m-30">To control width, place the input inside standard Bootstrap grid column.</p>

      <h5>Select sizes</h5>

      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" size="lg">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group> 

      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" size="md" >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" size="sm">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" size="lg">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>


  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" size="md" >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>

  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" size="sm">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>
</div></script></code></pre>  

        <h3>Rounded sizes</h3>



      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" size="lg" class="rounded-pill">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group>


      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="mb-5">
        <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="sm">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </b-form-select>
      </b-form-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" size="lg" class="rounded-pill">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>


  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>

  <b-form-group class="mb-5">
    <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="sm">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
    </b-form-select>
  </b-form-group>
</div></script></code></pre>

      <p>
        <strong>Note:</strong> Input type <code class="text-nowrap" translate="no">range</code> currently does not support control sizing 
        unless it is placed inside a <code class="text-nowrap" translate="no">&lt;b-input-group&gt;</code> which has its
        <code class="text-nowrap" translate="no">size</code> prop set.
      </p>

      <p class="m-40">
        <strong>Note:</strong> The native HTML <code class="text-nowrap" translate="no">&lt;input&gt;</code> attribute 
        <code class="text-nowrap" translate="no">size</code> (which sets a horizontal width on the 
        <code class="text-nowrap" translate="no">&lt;input&gt;</code> in characters) is not supported. 
        Use styling, utility classes, or the layout rows (<code class="text-nowrap" translate="no">&lt;b-row&gt;</code>) and columns 
        (<code class="text-nowrap" translate="no">&lt;b-col&gt;</code>) to set the desired width.
      </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Multiple select</h2>

        <b-form-group class="mt-4">
          <label>Example select</label>
          <div class="mb-4 select-box">

    <form>
        <select multiple="true" hidden styled-select="true">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
        </select>

    </form>
          </div>
        </b-form-group>

        <h2>Rounded multiple select</h2>

      <div class="m-40">
        <b-form-group>
          <label>Example select</label>
          <div class="mb-4 select-box-rounded">
          <form>
              <select multiple="true" hidden styled-select="true">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
              </select>
          </form>
          </div>
        </b-form-group>
      </div>

     </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Contextual states</h2>
       <p>
         Bootstrap includes validation styles for <code class="text-nowrap" translate="no">valid</code> and 
         <code class="text-nowrap" translate="no">invalid</code> states on most form controls.
      </p>  
      <p>Generally speaking, you'll want to use a particular state for specific types of feedback:</p>
      <ul class="m-40"> 
        <li>
          <code class="text-nowrap" translate="no">false</code> (denotes invalid state) is great for when there's a blocking or required field. 
          A user must fill in this field properly to submit the form.
        </li> 
        <li>
          <code class="text-nowrap" translate="no">true</code> (denotes valid state) is ideal for situations when you have per-field validation throughout
           a form and want to encourage a user through the rest of the fields.
        </li> 
        <li>
          <code class="text-nowrap" translate="no">null</code> Displays no validation state (neither valid nor invalid)
        </li> 
      </ul>    
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Form textarea</h2>
        <p class="m-30">Create multi-line text inputs with support for auto height sizing, minimum and maximum number of rows, and contextual states.</p>

          <label>Example textarea</label>
          <b-form-group class="mb-4">
            <b-textarea v-model="text" rows="6" placeholder="Your message here..."></b-textarea>
          </b-form-group>


          <div class="m-40">
          <label>Example textarea</label>
          <b-form-group class="mb-4">
            <b-textarea v-model="text1" class="textarea-rounded" rows="6" placeholder="Your message here..."></b-textarea>
          </b-form-group>

          </div>

 
        <h3>Displayed rows</h3>
        <p class="m-40">
          To set the height of <code class="text-nowrap" translate="no">&lt;b-form-textarea&gt;</code>, set the 
          <code class="text-nowrap" translate="no">rows</code> prop to the desired number of rows. If no value is provided to 
          <code class="text-nowrap" translate="no">rows</code>, then it will default to <code class="text-nowrap" translate="no">2</code> 
          (the browser default and minimum acceptable value). Setting it to null or a value below 2 will result in the default of 
          <code class="text-nowrap" translate="no">2</code> being used.
        </p>

        <h3>Disable resize handle</h3>
        <p class="m-40">
          Some web browsers will allow the user to re-size the height of the textarea. To disable this feature, set the 
          <code class="text-nowrap" translate="no">no-resize</code> prop to <code class="text-nowrap" translate="no">true</code>.
        </p>

        <h3>Auto height</h3>
        <p>
          <code class="text-nowrap" translate="no">&lt;b-form-textarea&gt;</code> can also automatically adjust its height (text rows) to fit the content, 
          even as the user enters or deletes text. The height of the textarea will either grow or shrink to fit the content (grow to a maximum of 
          <code class="text-nowrap" translate="no">max-rows</code> or shrink to a minimum of <code class="text-nowrap" translate="no">rows</code>).
        </p>
        <p>
          To set the initial minimum height (in rows), set the <code class="text-nowrap" translate="no">rows</code> prop to the desired number of lines 
          (or leave it at the default of <code class="text-nowrap" translate="no">2</code>), And then set maximum rows that the text area will grow to 
          (before showing a scrollbar) by setting the <code class="text-nowrap" translate="no">max-rows</code> prop to the maximum number of lines of text.
        </p>
        <p>
          To make the height <code class="text-nowrap" translate="no">sticky</code> (i.e. never shrink), set the 
          <code class="text-nowrap" translate="no">no-auto-shrink</code> prop to <code class="text-nowrap" translate="no">true</code>. 
          The <code class="text-nowrap" translate="no">no-auto-shrink</code> props has no effect if <code class="text-nowrap" translate="no">max-rows</code> 
          is not set or is equal to or less than <code class="text-nowrap" translate="no">rows</code>.
        </p>
        <p class="m-40">Note that the resize handle of the textarea (if supported by the browser) will automatically be disabled in auto-height mode.</p>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Form File input</h2>
        <p class="m-30">
          Customized, cross-browser consistent, file input control that supports single file, multiple files, and directory upload (for browsers that support directory mode).
        </p>

        <div class="m-40">
          <b-form-group class="mt-4">
          <label>Example file input</label>
          <b-form-file browse-text="Choose file"></b-form-file>
          </b-form-group>
        </div>



        <h4 class="mt-5">Rounded file input</h4>
        <b-form-group class="mt-4">
          <label>Example file input</label>
          <b-form-file class="rounded-file" browse-text="Choose file"></b-form-file>
        </b-form-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-group class="mt-4">
    <label>Example file input</label>
    <b-form-file browse-text="Choose file"></b-form-file>
  </b-form-group>

  <b-form-group class="mt-4">
    <label>Example file input</label>
    <b-form-file class="rounded-file" browse-text="Choose file"></b-form-file>
  </b-form-group>
</div></script></code></pre>

      <h3>Single file input (default)</h3>
      <p class="m-40">
        On single file mode, when no file is selected or user cancels the "Browse" dialog, <code class="text-nowrap" translate="no">v-model</code> is 
        <code class="text-nowrap" translate="no">null</code> indicating no file selected. When a file is selected the return value will be a JavaScript 
        <a href="https://developer.mozilla.org/en/docs/Web/API/File" target="_blank" rel="noopener"><code class="text-nowrap" translate="no">File</code></a> object instance.
      </p>

      <h3>Multiple file input</h3>
      <p class="m-40">
        Multiple file uploading is supported by adding <code class="text-nowrap" translate="no">multiple</code> prop to component. In this case 
        <code class="text-nowrap" translate="no">v-model</code> is <em>always</em> an <code class="text-nowrap" translate="no">Array</code>.
        When no files are selected, an empty array will be returned. When a file or files are selected the return value will be an array of JavaScript 
        <a href="https://developer.mozilla.org/en/docs/Web/API/File" target="_blank" rel="noopener">
        <code class="text-nowrap" translate="no">File</code></a> object instances.
      </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Form Checkbox</h2>
      <p class="m-40">For cross browser consistency, <code class="text-nowrap" translate="no">&lt;b-form-checkbox-group&gt;</code> and 
        <code class="text-nowrap" translate="no">&lt;b-form-checkbox&gt;</code> use Bootstrap's custom checkbox input to replace the browser default checkbox input. 
        It is built on top of semantic and accessible markup, so it is a solid replacement for the default checkbox input.
      </p>

      <h3 class="m-30">Single checkbox</h3>
      <div class="m-40">
        <b-form-checkbox size="lg" :checked="true" class="checkbox-primary basic"></b-form-checkbox>

      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-checkbox size="lg" :checked="true" class="checkbox-primary basic"></b-form-checkbox>
</div></script></code></pre>     

      <h3 class="m-30">Multiple checkboxes</h3>
      <b-row class="trendy-example m-40">
          <b-form-checkbox size="lg" :checked="true" class="checkbox-primary basic"></b-form-checkbox>
          <b-form-checkbox size="lg" class="checkbox-primary basic"></b-form-checkbox>
          <b-form-checkbox size="lg" class="checkbox-primary basic"></b-form-checkbox>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-checkbox size="lg" :checked="true" class="checkbox-primary basic"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-primary basic"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-primary basic"></b-form-checkbox>
</div></script></code></pre>   

      <b-row class="trendy-example m-40">
          <b-form-checkbox size="lg" :checked="true" class="checkbox-light basic"></b-form-checkbox>
          <b-form-checkbox size="lg" class="checkbox-light basic"></b-form-checkbox>
          <b-form-checkbox size="lg" class="checkbox-light basic"></b-form-checkbox>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-checkbox size="lg" :checked="true" class="checkbox-light basic"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-light basic"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-light basic"></b-form-checkbox>
</div></script></code></pre>  


      <h3 class="m-30">Multiple checkboxes with border</h3>
      <b-row class="trendy-example m-40">
        <b-form-checkbox size="lg" :checked="true" class="checkbox-primary"></b-form-checkbox>
        <b-form-checkbox size="lg" class="checkbox-primary"></b-form-checkbox>
        <b-form-checkbox size="lg" class="checkbox-primary"></b-form-checkbox>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-checkbox size="lg" :checked="true" class="checkbox-primary"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-primary"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-primary"></b-form-checkbox>
</div></script></code></pre>  

      <b-row class="trendy-example m-40">
        <b-form-checkbox size="lg" :checked="true" class="checkbox-light"></b-form-checkbox>
        <b-form-checkbox size="lg" class="checkbox-light"></b-form-checkbox>
        <b-form-checkbox size="lg" class="checkbox-light"></b-form-checkbox>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-checkbox size="lg" :checked="true" class="checkbox-light"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-light"></b-form-checkbox>
  <b-form-checkbox size="lg" class="checkbox-light"></b-form-checkbox>
</div></script></code></pre>

      <p class="m-40">
        Feel free to mix and match <code class="text-nowrap" translate="no">options</code> prop and 
        <code class="text-nowrap" translate="no">&lt;b-form-checkbox&gt;</code> in <code class="text-nowrap" translate="no">&lt;b-form-checkbox-group&gt;</code>. \
        Manually placed <code class="text-nowrap" translate="no">&lt;b-form-checkbox&gt;</code> inputs will appear <em>below</em> any checkbox inputs generated by the 
        <code class="text-nowrap" translate="no">options</code> prop. To have them appear <em>above</em> the inputs generated by 
        <code class="text-nowrap" translate="no">options</code>, place them in the named slot <code class="text-nowrap" translate="no">first</code>.
      
      </p>

      <h3>Checkbox group options array</h3>
      <p><code class="text-nowrap" translate="no">options</code> can be an array of strings or objects. Available fields:</p>
      <ul> 
        <li>
          <strong><code class="text-nowrap" translate="no">value</code></strong> The selected value which will be set on 
          <code class="text-nowrap" translate="no">v-model</code></li> <li><strong><code class="text-nowrap" translate="no">disabled</code></strong> 
          Disables item for selection
        </li> 
        <li>
          <strong><code class="text-nowrap" translate="no">text</code></strong> Display text, or <strong><code class="text-nowrap" translate="no">html</code></strong> 
          Display basic inline html
        </li> 
      </ul>
      <p><code class="text-nowrap" translate="no">value</code> can be a string, number, or simple object. Avoid using complex types in values.</p>
      <p class="m-40">
        If both <code class="text-nowrap" translate="no">html</code> and <code class="text-nowrap" translate="no">text</code> are provided, 
        <code class="text-nowrap" translate="no">html</code> will take precedence. Only basic/native HTML is supported in the 
        <code class="text-nowrap" translate="no">html</code> field (components will not work). Note that not all browsers will render inline html (i.e. 
        <code class="text-nowrap" translate="no">&lt;i&gt;</code>, <code class="text-nowrap" translate="no">&lt;strong&gt;</code>, etc.) inside 
        <code class="text-nowrap" translate="no">&lt;option&gt;</code> elements of a <code class="text-nowrap" translate="no">&lt;select&gt;</code>.
      </p>



      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Form Radio</h2>
        <p class="m-30">For cross browser consistency, <code class="text-nowrap" translate="no">&lt;b-form-radio-group&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-form-radio&gt;</code> uses Bootstrap's custom radio input to replace the browser default radio input. It is built on top of semantic and accessible markup, so it is a solid replacement for the default radio input.</p>
        
        <h3 class="m-30">Radio buttons</h3>
        
        <b-row class="trendy-example m-40">
            <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
            <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
            <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
        </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="primary-radio basic rounded"></b-form-radio>
</div></script></code></pre>

        <b-row class="trendy-example m-40">
            <b-form-radio name="some-radios" value="B" class="light-radio basic rounded"></b-form-radio>
            <b-form-radio name="some-radios" value="B" class="light-radio basic rounded"></b-form-radio>
            <b-form-radio name="some-radios" value="B" class="light-radio basic rounded"></b-form-radio>

        </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-radio name="some-radios" value="A" class="light-radio basic rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="light-radio basic rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="light-radio basic rounded"></b-form-radio>
</div></script></code></pre>


        <h3 class="m-30">Radio buttons with border</h3>
        
        <b-row class="trendy-example m-40">
          <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
          <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
          <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
        </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
  <b-form-radio name="some-radios" value="A" class="primary-radio rounded"></b-form-radio>
</div></script></code></pre>
        
        <b-row class="trendy-example m-40">
          <b-form-radio name="some-radios" value="B" class="light-radio rounded"></b-form-radio>
          <b-form-radio name="some-radios" value="B" class="light-radio rounded"></b-form-radio>
          <b-form-radio name="some-radios" value="B" class="light-radio rounded"></b-form-radio>
        </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-radio name="some-radios" value="B" class="light-radio"></b-form-radio>
  <b-form-radio name="some-radios" value="B" class="light-radio"></b-form-radio>
  <b-form-radio name="some-radios" value="B" class="light-radio"></b-form-radio>
</div></script></code></pre>


      
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2>Search forms</h2>
        <b-form-group>
          <search-input class="mb-4 custom-search"></search-input>
          <search-input rounded class="custom-search"></search-input>
        </b-form-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-form-group>
    <search-input class="mb-4"></search-input>
    <search-input rounded></search-input>
  </b-form-group>
</div></script></code></pre>

      </b-col>
    </b-row>

    <b-row>
         <b-col>
           <h2>Server side</h2>
            <b-form-group  class="mb-4 is-valid" :state="true" valid-feedback="Looks good">
              <label for="input-valid">First name</label>
              <b-input       :invalid-feedback="invalidFeedback" id="input-valid" :state="true"></b-input>
            </b-form-group>
            <b-form-group class="mb-4"    :invalid-feedback="invalidFeedback" :state="false">
              <label for="input-invalid">Email address</label>
              <b-input id="input-invalid" valid-feedback="Please provide a valid Email." class="is-invalid" :state="true"></b-input>
            </b-form-group>
            <b-form-group  class="mb-4">
              <b-form-checkbox size="lg" :invalid-feedback="invalidFeedback" :state="false">
                Agree to terms and conditions
                <b-form-invalid-feedback :state="false"  >You must agree before submitting.</b-form-invalid-feedback>
              </b-form-checkbox>

            </b-form-group>
            <b-button size="lg" variant="primary">Submit form</b-button>

            <b-form-group class="mb-4 mt-4">

              <b-form-file
                invalid-feedback="Please file"
                :state="false"
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>

            <b-form-group  class="mb-4">
              <b-form-checkbox size="lg" :invalid-feedback="invalidFeedback" :state="false">
                Agree to terms and conditions
                <b-form-invalid-feedback :state="false"  >You must agree before submitting.</b-form-invalid-feedback>
              </b-form-checkbox>

            </b-form-group>
            <b-form-group  class="mb-4">
              <b-form-radio size="lg" :invalid-feedback="invalidFeedback" :state="false">
                Agree to terms and conditions
                <b-form-invalid-feedback :state="false"  >You must agree before submitting.</b-form-invalid-feedback>
              </b-form-radio>

            </b-form-group>
          </b-col>

    </b-row>

    <b-row>
      <b-col>



      </b-col>
    </b-row>

  </div>

</template>

<script>
import AppButton from "@/components/AppButton";
import SearchInput from "@/components/SearchInput";
import AppSelectList from "@/components/app/AppSelectList";
import ProgressRange from "./ProgressRange";
import select from '../select.js'
export default {
  name: "FormsView",
  data() {
    return {
      select: null,
      select_second: null,
      rangeValue: 65,
      value: 50,
      text: '',
      text1: '',
      options: [
        {value: 1, text: 'Option 1'},
        {value: 2, text: 'Option 2'},
        {value: 3, text: 'Option 3'},
        {value: 4, text: 'Option 4'},

      ],
      options_second: [
        {value: 1, text: 'Option 1'},
        {value: 2, text: 'Option 2'},
        {value: 3, text: 'Option 3'},
        {value: 4, text: 'Option 4'},
        {value: 5, text: 'Option 5'},

      ],
      invalidFeedback: 'Please provide a valid Email.',
      name: '',
      radio_value:'A',
    }
  },
  components: {
    AppSelectList, 
    SearchInput,
    AppButton,
    ProgressRange,
  },
  
  mounted() {
    document.title = "Forms";
    Prism.highlightAll();
    select(); 
  },

 
}



  

</script>

<style scoped>

</style>
