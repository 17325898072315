<template>
  <b-button :variant="variant" class="notification-btn">
    <slot></slot>
    <b-badge class="ml-2 mr-auto" :variant="variant" >{{count}}</b-badge>
  </b-button>
</template>

<script>
import {} from "@/assets/scss/_variables.scss"

export default {

  name: "Notification",
  props:{
    variant:{
      type:String,
      default:'primary'
    },
    count:{
      type:Number,
      default:0,
    },
  },

}
</script>

<style scoped>

</style>
