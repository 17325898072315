<template>
  <div>
    <div class="chart-header">
      <p>PROJECT TASKS</p>
      <drop-down variant="light" text="OPTIONS">      
            <template v-slot:items>
              <b-dropdown-item-button>OPTION 1</b-dropdown-item-button>
              <b-dropdown-item-button>OPTION 2</b-dropdown-item-button>
              <b-dropdown-item-button>OPTION 3</b-dropdown-item-button>
          </template>
      </drop-down>
    </div>

    <BarChart v-bind="barChartProps" :width="width" :height="height" :plugins=[ChartDataLabels]></BarChart>
  </div>
</template>

<script >
import { Chart, registerables } from 'chart.js';
import  ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';
import DropDown from "@/components/DropDown";

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    BarChart,
    ChartDataLabels,
    DropDown
  },

  data() {
      return {
       height: 440,
       width: 1120,
       ChartDataLabels

      }

  },
  setup() {
    
    const data = ref([48, 65, 41, 82, 45, 48, 65, 41, 82, 45]);

    const chartData = computed(() => ({
      labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN','07 JAN','08 JAN', '09 JAN', '10 JAN', '11 JAN'],

      datasets: [
        {
          data: data.value,
          backgroundColor: ['#5561B3'],
          borderRadius:5,
          borderSkipped: false,
          borderColor:'#161F5E',
          borderWidth: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 0
          },


          
        },

      ],
    }));

    const options = computed(() =>({
        responsive: true,
        maintainAspectRatio: true,

        scales: {
          x: {
            display: true,
            color: '#fff',
            beginAtZero: true,
            grid: {
              display: false,
              borderColor: '#F4F5FC',
              offset: true

            },
            ticks: {
              font: {
                weight: 'Bold',

              }
            }
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderColor: '#fff'

            },
             min: 0,
             max: 120,

            ticks: {
                stepSize:20,
                fontColor: '#5561B3',
                font: {
                    weight: 'Bold',
                    backgroundColor: '#5561B3',
                }
            }

          }
        },

        plugins: {
          legend: {
            display: false
          },

          datalabels: {
            align: 'bottom',
            color: '#FFF',
            font: {
                family: "'Poppins', sans-serif",
                weight: 500,
                size: 12,
            },
            formatter: function(value) {
                return value;
              }
          },


        },



    }));


    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });



    return {barChartProps, barChartRef };
  },

});
</script>

<style>

</style>
