<template>
  <div class="pie-chart">

    <p>SESSIONS BY CHANEL</p>
    <div class="round">
    <PieChart v-bind="barChartProps" id="canvas-pie-chart" />
    </div>
<b-row>
    <b-col>
      <ul class="list-group">
        <li class="list-group-item"><span>Organic search</span><br><span class="organic-search"><i class="fa fa-circle"></i>1.242</span></li>
        <li class="list-group-item"><span>Referral</span><br><span class="referral"><i class="fa fa-circle"></i>422</span></li>
      </ul>
    </b-col>
    <b-col>
      <ul class="list-group">
        <li class="list-group-item"><span>Email</span><br><span class="email"><i class="fa fa-circle"></i>742</span></li>
        <li class="list-group-item"><span>Paid search</span><br><span class="paid-search"><i class="fa fa-circle"></i>1.738</span></li>
      </ul>
                

    </b-col>
</b-row>
    


  </div>
</template>


<script>
import {PieChart, useBarChart} from "vue-chart-3";
import { ref, computed, defineComponent } from '@vue/composition-api';


//const { reactiveProp } = mixins
export default defineComponent({
  name: "App",
  components: {
    PieChart,
  },
  setup() {
    const data = ref([25,20,35,20]);

      const chartData = computed(() => ({

      datasets: [
        {
          labels: ['Organic Search', 'Email', 'Referral', 'Paid Search'],

          data: data.value,
          backgroundColor: [
            '#5561B3',
            '#161F5E',
            '#DFCA41',
            '#DF4149'
          ],
        },
        
        
      ],
      options: {
        responsive: true,
        maintainAspectRatio: true,

        legend: {
          display: true
        },
        scales: {
          x: {

            grid: {
              display: false
            },
            ticks: {
              font: {
                weight: 'Bold',

              }
            }
          },
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false
            },

          },
          xAxes: [{
            barPercentage: 0.4
          }]
        },
        plugins: {
          legend: {
            display: true,
          },
        },

      },
    }));

     const { barChartProps, barChartRef } = useBarChart({
      chartData,
    });



    return {barChartProps, barChartRef };
  },



  
 
});
</script>

<style scoped>

</style>
