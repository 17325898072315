<template>
  <div class="wrapper">
        <title>Input group</title>

            <h1 class="title-section">Inputs group</h1>
            <p class="m-40" style="font-size: 1.5rem">Easily extend form controls by adding text, buttons, or button groups on either side of textual inputs.</p>
            <h2>Basic Example</h2>
            <p class="m-40">Place one add-on or button on either side of an input. You may also place one on both sides of an input. Remember to place <code>&lt;label&gt;</code>s outside the input group.</p>
            

            <h2>Usage</h2>
            <p class="m-40">You can attach addons using either props, named slots and/or sub-components.</p>
            <h3>Using preprend and append props</h3>
            <p class="m-30">Values will be internally wrapped by a <code class="text-nowrap" translate="no">&lt;b-input-group-text&gt;</code> to display correctly.</p>
 
            <div class="m-40">
              <b-input-group  prepend="@" class="mb-5 prepend-custom" >
                <b-form-input placeholder="Username"></b-form-input>
              </b-input-group>

              <b-input-group class="append-custom" append="@example.com">
                <b-form-input  placeholder="Recipient's username"></b-form-input>
              </b-input-group>
            </div>


            <h3 class="m-30">Your vanity URL</h3>
            <b-input-group prepend="https://example.com/users/" class="prepend-custom mb-5">
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group prepend="$" append="0.00" class="prepend-custom append-custom mb-5" >
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group prepend="With textarea" class="prepend-custom mb-5">
              <b-form-textarea rows="2"></b-form-textarea>

            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group  prepend="@" class="mb-5 prepend-custom" >
    <b-form-input placeholder="Username"></b-form-input>
  </b-input-group>

  <b-input-group class="append-custom" append="@example.com">
    <b-form-input  placeholder="Recipient's username"></b-form-input>
  </b-input-group>

  <h5 class="title-section">Your vanity URL</h5>
  <b-input-group prepend="https://example.com/users/" class="prepend-custom mb-5">
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group prepend="$" append="0.00" class="prepend-custom append-custom mb-5" >
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group prepend="With textarea" class="prepend-custom mb-5">
    <b-form-textarea rows="2"></b-form-textarea>

  </b-input-group>
</div></script></code></pre>


<h3>Using named slots</h3>
<p>
  if you want better control over addons, you can use <code class="text-nowrap" translate="no">prepend</code> and 
  <code class="text-nowrap" translate="no">append</code> slots instead.
</p>
<p class="m-40">
  The slot content will automatically be wrapped by <a href="#using-sub-components" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-input-group-prepend&gt;</code>
   or <code class="text-nowrap" translate="no">&lt;b-input-group-append&gt;</code></a> to display correctly.
</p>


<h3>Using sub-components</h3>
<p>
  Use the <code class="text-nowrap" translate="no">&lt;b-input-group-prepend&gt;</code> or 
  <code class="text-nowrap" translate="no">&lt;b-input-group-append&gt;</code> to add arbitrary addons wherever you like, 
  and use these components to group buttons in your input group. Single buttons must always be wrapped in these components for proper styling.
</p>

<p class="m-40">
  Set the <code class="text-nowrap" translate="no">is-text</code> prop on <code class="text-nowrap" translate="no">&lt;b-input-group-prepend&gt;</code> or 
  <code class="text-nowrap" translate="no">&lt;b-input-group-append&gt;</code> if the content is textual in nature to apply proper styling. 
  Alternatively, place the <code class="text-nowrap" translate="no">&lt;b-input-group-text&gt;</code> subcomponent inside of the 
  <code class="text-nowrap" translate="no">&lt;b-input-group-prepend&gt;</code> or <code class="text-nowrap" translate="no">&lt;b-input-group-append&gt;</code>. 
  This also applies when you want to use on of <a href="/docs/icons" class="font-weight-bold">BootstrapVue's icons</a>.
</p>



            <h3>Rounded examples</h3>
            <p class="m-30">More rounded examples are provided</p>

            <div class="m-40">
              <b-input-group prepend="@" class="mb-5 prepend-custom-rounded">
                <b-form-input class="form-control-rounded" placeholder="Username"></b-form-input>
              </b-input-group>
              <b-input-group append="@example.com" class="append-custom-rounded">
                <b-form-input class="form-control-rounded" placeholder="Recipient's username"></b-form-input>
              </b-input-group>

            </div>



            <h3 class="m-30">Your vanity URL</h3>
            <b-input-group prepend="https://example.com/users/" class="prepend-custom-rounded mb-5">
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

            <b-input-group prepend="With textarea" class="prepend-custom-rounded mb-5">
              <b-form-textarea rows="2"></b-form-textarea>

            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group prepend="@" class="mb-5 prepend-custom-rounded">
    <b-form-input class="form-control-rounded" placeholder="Username"></b-form-input>
  </b-input-group>

  <b-input-group append="@example.com" class="append-custom-rounded">
    <b-form-input class="form-control-rounded" placeholder="Recipient's username"></b-form-input>
  </b-input-group>

  <h5 class="title-section">Your vanity URL</h5>
  <b-input-group prepend="https://example.com/users/" class="prepend-custom-rounded mb-5">
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group prepend="With textarea" class="prepend-custom-rounded mb-5">
    <b-form-textarea rows="3"></b-form-textarea>
  </b-input-group>
</div></script></code></pre>


      <div class="section">
        <b-row>
          <b-col>
            <h2>Button addons</h2>
            <p class="m-30">You can attach addons using either props, named slots and/or sub-components.</p>

           <b-input-group class="mb-5  prepend-custom">
              <b-input-group-prepend>
                <app-button size="md" variant="primary">Button</app-button>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            
            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append>
                <app-button variant="primary">Button</app-button>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5  prepend-custom">
    <b-input-group-prepend>
      <app-button size="md" variant="primary">Button</app-button>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  
  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

<h3 class="m-30">Rounded button addons example</h3>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend>
                <app-button variant="primary">Button</app-button>
              </b-input-group-prepend>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-input-group-append>
                <app-button variant="primary">Button</app-button>
              </b-input-group-append>
            </b-input-group>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5  prepend-custom-rounded">
    <b-input-group-prepend>
      <app-button variant="primary">Button</app-button>
    </b-input-group-prepend>
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input class="form-control-rounded"></b-form-input>
    <b-input-group-append>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>




            <h2 class="m-30">Buttons with dropdowns</h2>
            <b-input-group class="mb-5 prepend-custom">
              <b-input-group-prepend>
                <drop-down variant="primary" text="Dropdown" :items="items"></drop-down>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append>
                <drop-down variant="primary" text="Dropdown" :items="items"></drop-down>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom">
    <b-input-group-prepend>
      <b-dropdown  text="Dropdown" variant="primary">
        <b-dropdown-item>First Action</b-dropdown-item>
        <b-dropdown-item>Second Action</b-dropdown-item>
        </b-dropdown>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append>
      <drop-down variant="primary" text="Dropdown" :items="items"></drop-down>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

<h3 class="m-30">Rounded buttons with dropdowns</h3>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend>
                  <drop-down rounded variant="primary" text="Dropdown" :items="items"></drop-down>
              </b-input-group-prepend>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-input-group-append>
                 <drop-down rounded variant="primary" text="Dropdown" :items="items"></drop-down>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend>
      <drop-down variant="primary" text="Dropdown" :items="items"></drop-down>
    </b-input-group-prepend>
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input class="form-control-rounded"></b-form-input>
    <b-input-group-append>
      <drop-down variant="primary" text="Dropdown" :items="items"></drop-down>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

            
          </b-col>
        </b-row>
        <b-row>
          <b-col>


            <h3>Multiple inputs</h3>
            <p class="m-30">While multiple <code>&lt;input&gt;</code>s are supported visually, validation styles are only available for input groups with a single <code>&lt;input&gt;</code>.</p>
            
            <b-input-group prepend="First and last name" class="mb-5 prepend-custom ">
              <b-form-input></b-form-input>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group prepend="First and last name" class="mb-5 prepend-custom-rounded ">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group prepend="First and last name" class="mb-5 prepend-custom ">
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group prepend="First and last name" class="mb-5 prepend-custom-rounded ">
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>
</div></script></code></pre>

            <h3>Multiple addons</h3>
            <p class="m-30">Multiple add-ons are supported and can be mixed with checkbox and radio input versions.</p>

            <b-input-group class="mb-5 prepend-custom ">
              <b-input-group-prepend class="prepend-duo-btn">
                <div  class="input-group-text">$</div>
                <div  class="input-group-text">0.00</div>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom ">
              <b-form-input></b-form-input>
              <b-input-group-append class="append-duo-btn">
                <div  class="input-group-text">$</div>
                <div  class="input-group-text">0.00</div>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom ">
    <b-input-group-prepend class="prepend-duo-btn">
      <div  class="input-group-text">$</div>
      <div  class="input-group-text">0.00</div>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom ">
    <b-form-input></b-form-input>
    <b-input-group-append class="append-duo-btn">
      <div  class="input-group-text">$</div>
      <div  class="input-group-text">0.00</div>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>  

<h3 class="m-30">Rounded multiple addons</h3>

            <b-input-group class="mb-5 prepend-custom-rounded ">
              <b-input-group-prepend class="prepend-duo-btn">
                <div  class="input-group-text">$</div>
                <div  class="input-group-text">0.00</div>
              </b-input-group-prepend>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>


            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-input-group-append class="append-duo-btn">
                <div  class="input-group-text">$</div>
                <div  class="input-group-text">0.00</div>
              </b-input-group-append>
            </b-input-group>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom-rounded ">
    <b-input-group-prepend class="prepend-duo-btn">
      <div  class="input-group-text">$</div>
      <div  class="input-group-text">0.00</div>
    </b-input-group-prepend>
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input class="form-control-rounded"></b-form-input>
    <b-input-group-append class="append-duo-btn">
      <div  class="input-group-text">$</div>
      <div  class="input-group-text">0.00</div>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>            
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h2>Sizing</h2>
            <p class="m-30">Set height using the <code class="text-nowrap" translate="no">size</code> prop to <code class="text-nowrap" translate="no">sm</code> or <code class="text-nowrap" translate="no">lg</code> for small or large respectively. There is no need to set size on the individual inputs or buttons. Note however, you <em>will be required</em> to also set the size on dropdowns.</p>
            <b-input-group size="sm" class="mb-5 prepend-custom ">
              <b-input-group-prepend >
                <app-button variant="primary">Small</app-button>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom ">
              <b-input-group-prepend >
                <app-button variant="primary">Default</app-button>
              </b-input-group-prepend>
              <b-form-input ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" class="mb-5 prepend-custom ">
              <b-input-group-prepend >
                <app-button variant="primary">Large</app-button>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group size="sm" class="mb-5 prepend-custom ">
    <b-input-group-prepend >
      <app-button variant="primary">Small</app-button>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom ">
    <b-input-group-prepend >
      <app-button variant="primary">Default</app-button>
    </b-input-group-prepend>
    <b-form-input ></b-form-input>
  </b-input-group>

  <b-input-group size="lg" class="mb-5 prepend-custom ">
    <b-input-group-prepend >
      <app-button variant="primary">Large</app-button>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>
</div></script></code></pre>


            <h3 class="m-30">Sizing rounded</h3>

            <b-input-group size="sm" class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend >
                <app-button variant="primary">Small</app-button>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend >
                <app-button variant="primary">Default</app-button>
              </b-input-group-prepend>
              <b-form-input ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend >
                <app-button variant="primary">Large</app-button>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group size="sm" class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend >
      <app-button variant="primary">Small</app-button>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend >
      <app-button variant="primary">Default</app-button>
    </b-input-group-prepend>
    <b-form-input ></b-form-input>
  </b-input-group>

  <b-input-group size="lg" class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend >
      <app-button variant="primary">Large</app-button>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>
</div></script></code></pre>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="m-30">Segment buttons</h3>

            <b-input-group class="mb-5 prepend-custom">
              <b-input-group-prepend class="custom-prepend-dropbox">
                <drop-down split variant="primary" text="Action" :items="items"></drop-down>
              </b-input-group-prepend>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append class="custom-append-dropbox">
                <drop-down split variant="primary" text="Action" :items="items"></drop-down>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom">
    <b-input-group-prepend class="custom-prepend-dropbox">
      <drop-down split variant="primary" text="Action" :items="items"></drop-down>
    </b-input-group-prepend>
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append class="custom-append-dropbox">
      <drop-down split variant="primary" text="Action" :items="items"></drop-down>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>  

<h3 class="m-30">Rounded segment buttons</h3>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend class="custom-prepend-dropbox">
                <drop-down split variant="primary" text="Action" :items="items"></drop-down>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input></b-form-input>
              <b-input-group-append class="custom-append-dropbox">
                <drop-down split variant="primary" text="Action" :items="items"></drop-down>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend class="custom-prepend-dropbox">
      <drop-down split variant="primary" text="Action" :items="items"></drop-down>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input></b-form-input>
    <b-input-group-append class="custom-append-dropbox">
      <drop-down split variant="primary" text="Action" :items="items"></drop-down>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>


            <h2>Checkbox and radio addons</h2>
            <p>Place any checkbox or radio within an input group's addon instead of text.</p>
            <p class="m-40"><strong>Note:</strong> Bootstrap v4.x recommends using native radio and checkbox inputs over custom radios and checkboxes, but it is possible to use as <code class="text-nowrap" translate="no">&lt;b-form-radio&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-form-checkbox&gt;</code> with a few utility classes applied.</p>
            
            <h3>Custom checkbox and radio buttons</h3>
            <p class="m-40">Using <code class="text-nowrap" translate="no">&lt;b-form-checkbox&gt;</code> and <code class="text-nowrap" translate="no">&lt;b-form-radio&gt;</code> components as addons, using Bootstrap <a href="/docs/reference/utility-classes" class="font-weight-bold">utility classes</a> for additional styling to get them to "fit" in the addon:</p>
            
            <h4 class="m-30">Checkbox</h4>
            <b-input-group class="mb-5 prepend-custom">
              <b-input-group-prepend is-text>
                  <b-form-checkbox size="lg" class="mr-n2 "></b-form-checkbox>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend is-text>
                  <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input></b-form-input>
              <b-input-group-append is-text>
                  <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
              </b-input-group-append>
            </b-input-group>

            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append is-text>
                  <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom">
    <b-input-group-prepend is-text>
        <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend is-text>
        <b-form-checkbox size="lg" class="mr-n2 class="checkbox-primary""></b-form-checkbox>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input></b-form-input>
    <b-input-group-append is-text>
        <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
    </b-input-group-append>
  </b-input-group>

  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append is-text>
        <b-form-checkbox size="lg" class="mr-n2"></b-form-checkbox>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>


            <!-- RADIO BUTTONS -->

            <h4 class="m-30">Radio buttons</h4>

            <b-input-group class="mb-5 prepend-custom">
              <b-input-group-prepend is-text>
                  <b-form-radio size="lg" class="mr-n2"></b-form-radio>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend is-text>
                  <b-form-radio size="lg" class="mr-n2"></b-form-radio>
              </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-input-group-append is-text>
                  <b-form-radio size="lg" class="mr-n2"></b-form-radio>
              </b-input-group-append>
            </b-input-group>

            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append is-text>
                  <b-form-radio size="lg" class="mr-n2"></b-form-radio>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom">
    <b-input-group-prepend is-text>
        <b-form-radio size="lg" class="mr-n2"></b-form-radio>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend is-text>
        <b-form-radio size="lg" class="mr-n2"></b-form-radio>
    </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input class="form-control-rounded"></b-form-input>
    <b-input-group-append is-text>
        <b-form-radio size="lg" class="mr-n2"></b-form-radio>
    </b-input-group-append>
  </b-input-group>

  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append is-text>
        <b-form-radio size="lg" class="mr-n2"></b-form-radio>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

            <h2>Custom forms</h2>
            <p class="m-40">Input groups include support for custom selects and custom file inputs. Browser default versions of these are not supported.</p>

            <h3>Custom select</h3>
            <p class="m-30">Input groups include support for custom selects and custom file inputs. Browser default versions of these are not supported.</p>
            <div>
            <b-input-group class="mb-5 prepend-custom">
              <b-input-group-prepend>
                <app-button variant="primary" class="prepend-btn">Button</app-button>
              </b-input-group-prepend>
               <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
            </b-input-group>
            </div>

            <b-input-group class="mb-5 append-custom">
               <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
              <b-input-group-append>
                <app-button variant="primary" class="append-btn">Button</app-button>
              </b-input-group-append>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom" prepend="Options">
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
            </b-input-group>

            <b-input-group class="mb-5 append-custom " append="Options">
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom ">
              <b-input-group-prepend class="prepend-duo-btn">
               <app-button variant="primary">Button</app-button>
               <app-button variant="primary">Button</app-button>
            </b-input-group-prepend>
              <b-form-input></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom">
              <b-form-input></b-form-input>
              <b-input-group-append class="append-duo-btn">
                <app-button variant="primary">Button</app-button>
                <app-button variant="primary">Button</app-button>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom">
    <b-input-group-prepend>
      <app-button variant="primary">Button</app-button>
    </b-input-group-prepend>
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
    </b-input-group>

  <b-input-group class="mb-5 append-custom ">
    <b-form-group>
      <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
      </b-form-select>
    </b-form-group>
    <b-input-group-append>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom" prepend="Options">
    <app-select-list></app-select-list>
  </b-input-group>

  <b-input-group class="mb-5 append-custom " append="Options">
    <app-select-list></app-select-list>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom ">
    <b-input-group-prepend class="prepend-duo-btn">
      <app-button variant="primary">Button</app-button>
      <app-button variant="primary">Button</app-button>
  </b-input-group-prepend>
    <b-form-input></b-form-input>
  </b-input-group>
  
  <b-input-group class="mb-5 append-custom">
    <b-form-input></b-form-input>
    <b-input-group-append class="append-duo-btn">
      <app-button variant="primary">Button</app-button>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

            <h2>Button addons rounded</h2>
            <p class="m-30">Custom file input groups are provided in more rounded style</p>
            <b-input-group class="mb-5 prepend-custom-rounded">
              <b-input-group-prepend>
                <app-button variant="primary">Button</app-button>
              </b-input-group-prepend>
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
              </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>              <b-input-group-append>
                <app-button variant="primary">Button</app-button>
              </b-input-group-append>
            </b-input-group>
            
            <b-input-group class="mb-5 prepend-custom-rounded" prepend="Options">
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded" append="Options">
                <b-form-group>
                  <b-form-select hidden styled-select="true" placeholder="Choose" class="rounded-pill" size="md">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                  </b-form-select>
               </b-form-group>
            </b-input-group>

            <b-input-group class="mb-5 prepend-custom-rounded ">
              <b-input-group-prepend class="prepend-duo-btn">
                <app-button variant="primary">Button</app-button>
                <app-button variant="primary">Button</app-button>
              </b-input-group-prepend>
              <b-form-input class="form-control-rounded"></b-form-input>
            </b-input-group>

            <b-input-group class="mb-5 append-custom-rounded">
              <b-form-input class="form-control-rounded"></b-form-input>
              <b-input-group-append class="append-duo-btn">
                <app-button variant="primary">Button</app-button>
                <app-button variant="primary">Button</app-button>
              </b-input-group-append>
            </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom-rounded">
    <b-input-group-prepend>
      <app-button variant="primary">Button</app-button>
    </b-input-group-prepend>
    <app-select-list rounded></app-select-list>
  </b-input-group>
  
  <b-input-group class="mb-5 append-custom-rounded">
    <app-select-list rounded></app-select-list>
    <b-input-group-append>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>
  
  <b-input-group class="mb-5 prepend-custom-rounded" prepend="Options">
    <app-select-list rounded></app-select-list>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded" append="Options">
    <app-select-list rounded></app-select-list>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom-rounded ">
    <b-input-group-prepend class="prepend-duo-btn">
      <app-button variant="primary">Button</app-button>
      <app-button variant="primary">Button</app-button>
    </b-input-group-prepend>
    <b-form-input class="form-control-rounded"></b-form-input>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-input class="form-control-rounded"></b-form-input>
    <b-input-group-append class="append-duo-btn">
      <app-button variant="primary">Button</app-button>
      <app-button variant="primary">Button</app-button>
    </b-input-group-append>
  </b-input-group>
</div></script></code></pre>

            <h3 class="m-30">Custom file input</h3>

              <b-input-group class="mb-5 prepend-custom ">
                <label variant="primary" for="inputGroupFile1"  id="custom-label">Upload</label>
                <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile1"></b-form-file>
              </b-input-group>

              <b-input-group class="mb-5 append-custom ">
                <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile2"></b-form-file>
                <label variant="primary" for="inputGroupFile2"  id="custom-label">Upload</label>
              </b-input-group>

              <b-input-group class="mb-5 prepend-custom ">
                <app-button variant="primary" id="btnPrepend">Button</app-button>
                <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
              </b-input-group>

              <b-input-group class="mb-5 append-custom ">
                <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
                <app-button variant="primary" id="btnPrepend">Button</app-button>
              </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom ">
    <label variant="primary" for="inputGroupFile1"  id="custom-label">Upload</label>
    <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile1"></b-form-file>
  </b-input-group>

  <b-input-group class="mb-5 append-custom ">
    <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile2"></b-form-file>
    <label variant="primary" for="inputGroupFile2"  id="custom-label">Upload</label>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom ">
    <app-button variant="primary" id="btnPrepend">Button</app-button>
    <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
  </b-input-group>

  <b-input-group class="mb-5 append-custom ">
    <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
    <app-button variant="primary" id="btnPrepend">Button</app-button>
  </b-input-group>
</div></script></code></pre>             

              
            <h3 class="m-30">Custom file input rounded</h3>
              <b-input-group class="mb-5 prepend-custom-rounded">
                <label variant="primary" for="inputGroupFile1" id="custom-label">Upload</label>
                <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile1"></b-form-file>
              </b-input-group>

              <b-input-group class="mb-5 append-custom-rounded">
                <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile2"></b-form-file>
                <label variant="primary" for="inputGroupFile2" id="custom-label">Upload</label>
              </b-input-group>

              <b-input-group class="mb-5 prepend-custom-rounded">
                <app-button variant="primary" id="btnPrepend">Button</app-button>
                <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
              </b-input-group>

              <b-input-group class="mb-5 append-custom-rounded">
                <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
                <app-button variant="primary" id="btnPrepend">Button</app-button>
              </b-input-group>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-input-group class="mb-5 prepend-custom-rounded">
    <label variant="primary" for="inputGroupFile1" id="custom-label">Upload</label>
    <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile1"></b-form-file>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-file placeholder="Choose file" class="form-control" id="inputGroupFile2"></b-form-file>
    <label variant="primary" for="inputGroupFile2" id="custom-label">Upload</label>
  </b-input-group>

  <b-input-group class="mb-5 prepend-custom-rounded">
    <app-button variant="primary" id="btnPrepend">Button</app-button>
    <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
  </b-input-group>

  <b-input-group class="mb-5 append-custom-rounded">
    <b-form-file placeholder="Choose file" class="form-control"></b-form-file>
    <app-button variant="primary" id="btnPrepend">Button</app-button>
  </b-input-group>
</div></script></code></pre>



          </b-col>
        </b-row>
        
      </div>

    

  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppSelectList from "@/components/app/AppSelectList";
import DropDown from "@/components/DropDown";
import select from '../select.js'

export default {
  name: "InputsView",
    data(){
    return {
      select: null,
       options: [
        {value: 1, text: 'Option 1'},
        {value: 2, text: 'Option 2'},
        {value: 3, text: 'Option 3'},
        {value: 4, text: 'Option 4'},

      ],

      items:[
       'First action',
       'Second action',
       'Third action'
      ],
    }
  },
  components: {
    AppSelectList, 
    AppButton,
    DropDown
  
    
    },

  mounted() {
    document.title = "Inputs";
    Prism.highlightAll();
    select();
  }
}
</script>

<style scoped>

</style>
