<template>
  <div class="navigation" id="nav-bar">
    <b-navbar class="text-center" type="dark" variant="primary">
      <div class="container">
        <b-navbar-brand href="#">trendy.</b-navbar-brand>

        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
        <b-navbar-nav class="main-navbar">
          <b-nav-item-dropdown href="#" text="Docs">
            <b-dropdown-item href="https://flatuikit-reactjs.trendy.solutions"
              >React</b-dropdown-item
            >
            <b-dropdown-item href="https://flatuikit-angular.trendy.solutions"
              >Angular</b-dropdown-item
            >
            <b-dropdown-item href="https://flatuikit-vuejs.trendy.solutions"
              >Vue</b-dropdown-item
            >
            <b-dropdown-item
              href="https://flatuikit-html.trendy.solutions/components/alerts.html"
              >Javascript</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <app-button
            class="btn-shopcart"
            variant="light"
            custom-class="rounded-pill"
            href="https://flatuikit.trendy.solutions/#buy-now"
          >
            <inline-svg :src="ShoppingCart"></inline-svg><span>BUY NOW</span>
          </app-button>
        </b-navbar-nav>

        <app-button @click="toggleNav()" variant="primary" id="sidenav-toggler">
          <i class="fa fa-bars fa-lg"></i>
        </app-button>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import DefaultContainer from "@/containers/DefaultContainer";
import AppButton from "@/components/AppButton";
import InlineSvg from "vue-inline-svg";
import ShoppingCart from "@/assets/images/icons/shopping-cart.svg";

export default {
  data() {
    return {
      ShoppingCart
    };
  },
  components: {
    DefaultContainer,
    AppButton,
    InlineSvg
  },

  methods: {
    toggleNav() {
      this.$parent.$emit("toggleNav");
    }
  }
};
</script>
