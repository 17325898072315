<template>
<div class="landing-page" id="landing-page">
        <title>Landing page 2</title>

    <b-navbar class="mb-5 navbar-wrapper rounded-pill" toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#"><span>trendy.</span></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="ml-auto justify-content-end">
          <b-navbar-nav>
            <b-nav-item href="#" active>home</b-nav-item>
            <b-nav-item href="http://localhost:8080/">components</b-nav-item>
            <b-nav-item href="#">who we are</b-nav-item>
            <b-nav-item href="#">our work</b-nav-item>
            <b-nav-item href="#">pricing</b-nav-item>
            <b-nav-item href="#">blog</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <img class="plant" src="./../assets/images/landing-page/plant.png" alt="" >
      <img class="coffee" src="./../assets/images/landing-page/coffee.png" alt="">

      <section class="section-1">
        <img class="glasses" src="./../assets/images/landing-page/glasses.png" alt="">
        <img class="laptop" src="./../assets/images/landing-page/laptop.png" alt="">
        <p class="title text-secondary">Creative Design</p>
        <p class="text-center fw-semi-bold text-grey">Treely is a Design Studio was founded in London, United Kingdom.</p>
        <app-button variant="primary" custom-class="rounded-pill">Learn More</app-button>
      </section>

      <section class="section-2">
        <b-row>
          <b-col md="6">
            <b-carousel
             id="carousel-1"
             v-model="slide"
             indicators
             class="carousel-custom-landing indicators-rounded"

              >
              <b-carousel-slide>
                <template #img>
                    <img src="./../assets/images/landing-page/carousel-img-1.png" alt="Random first slide">
                  <!-- <div class="carousel-caption">
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  </div> -->
                </template>
              </b-carousel-slide>

              <b-carousel-slide>
                <template #img>
                    <img src="./../assets/images/landing-page/carousel-img-1.png" alt="Random first slide">
                </template>
              </b-carousel-slide>

              <b-carousel-slide>
                <template #img>
                    <img src="./../assets/images/landing-page/carousel-img-1.png" alt="Random first slide">
                </template>
              </b-carousel-slide>

            </b-carousel>
            <div class="carousel-background bg-primary"></div>
          </b-col>
          <b-col md="6">
            <p class="fw-semi-bold text-primary">Information about Agency</p>
            <p class="title text-secondary">Why We're Ordinary</p>
            <p class="fw-semi-bold text-grey mb-4">
              Donec semper euismod nisi quis feugiat. Nullam finibus
              metus eget orci volutpat porta. Morbi quis arcu vulputate,
              dignissim mi ac, varius magna.
            </p>
            <p class="fw-semi-bold text-grey">
              Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed
              suscipit. Vestibulum auctor nunc vitae diam eleifend, in
              maximus metus sollicitudin.
            </p>
            <app-button variant="primary" custom-class="rounded-pill">Learn More</app-button>

          </b-col>
        </b-row>
      </section>

      <section class="section-3">
        <p class="title text-secondary text-center">What We Offer</p>
        <p class="text-center text-semibold text-grey mb-5">
          Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.
          Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
        </p> 
        <b-row>
          <b-col md="4">
            <b-card
              title="Website Design"
              sub-title="Graphics Animation">
             <div class="card-icon"><img src="./../assets/images/landing-page/pic-1.svg" alt=""></div>

                <b-card-text>
                  Donec semper euismod nisi quis
                  feugiat. Nullam finibus metus eget orci
                  volutpat porta. Morbi quis arcu
                  vulputate, dignissim mi ac, varius.
                </b-card-text>

            </b-card>

          </b-col>

          <b-col md="4">
            <b-card
              title="Product Branding"
              sub-title="Packages & Products">
             <div class="card-icon"><img src="./../assets/images/landing-page/pen.svg" alt=""></div>

                <b-card-text>
                  Donec semper euismod nisi quis
                  feugiat. Nullam finibus metus eget orci
                  volutpat porta. Morbi quis arcu
                  vulputate, dignissim mi ac, varius.
                </b-card-text>

            </b-card>

          </b-col>

          <b-col md="4">
            <b-card
              title="SEO Marketing"
              sub-title="Online Services">
             <div class="card-icon"><img src="./../assets/images/landing-page/page-1.svg" alt=""></div>

                <b-card-text>
                  Donec semper euismod nisi quis
                  feugiat. Nullam finibus metus eget orci
                  volutpat porta. Morbi quis arcu
                  vulputate, dignissim mi ac, varius.
                </b-card-text>

            </b-card>

          </b-col>
        </b-row>
      </section>

      <section class="section-4">
        <p class="title text-secondary text-center">We Have Creative People</p>
        <p class="text-center text-semibold text-grey">
          Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.
          Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
        </p>
        <b-row>
          <b-col md="5">
            <b-card style="max-width: 21.875rem;">
              <img src="./../assets/images/landing-page/image-1.png" alt="">
            </b-card>
          </b-col>
          <b-col md="7">
            <p class="subtitle">Skills & Experience</p>
            <b-progress class="mb-4" height="20px" value="25" :max="100"  variant="primary" ></b-progress>
            <b-progress class="mb-4" height="20px" value="40" :max="100"  variant="primary" ></b-progress>
            <b-progress class="mb-4" height="20px" value="55" :max="100"  variant="primary" ></b-progress>

            <p>Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.</p>

          </b-col>
        </b-row>
      </section>

      <section class="section-5">
        <p class="title text-secondary text-center">Most Flexible Pricing Plans</p>
        <p class="text-center text-semibold mb-5">
          Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed suscipit.
          Vestibulum auctor nunc vitae diam eleifend, in maximus metus sollicitudin.
        </p>

        <div class="container">
          <b-row class="pricing">
          <b-col lg="4">
            <b-card no-body>
                <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">BASIC PLAN</h5>
                    <span class="h2">$</span><span class="price">19</span>
                    <p>/MONTH</p>
                  </div>
                </div>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>10 members</span></li>
                    <li class="list-group-item">Admins <span>No access</span></li>
                </ul>
                  <app-button variant="primary">Start with Basic</app-button>
              </b-card>
          </b-col>
          <b-col lg="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">STANDARD PLAN</h5>
                    <span class="h2">$</span><span class="price">29</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>50 members</span></li>
                    <li class="list-group-item">Admins <span>5 admins</span></li>
                </ul>
                <app-button variant="primary">Start with Standard</app-button>
              </b-card>
          </b-col>
          <b-col lg="4">
            <b-card no-body>
              <div class="pricing-header">
                  <div class="text-center">
                    <h5 class="card-title">ENTERRISE PLAN</h5>

                    <span class="h2">$</span><span class="price">99</span>
                    <p>/MONTH</p>
                  </div>
                </div>
    
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Unlimited activity</li>
                    <li class="list-group-item">Direct messaging</li>
                    <li class="list-group-item">Members <span>Unlimited</span></li>
                    <li class="list-group-item">Admins <span>Unlimited</span></li>
                </ul>
                <app-button variant="primary">Start with Enterprise</app-button>
              </b-card>
          </b-col>

        </b-row>

</div>


    </section>

    <section class="section-6 mb-5">
      <b-row>
        <b-col md="6">
          <b-card>
            <p class="subtitle text-secondary mb-3">Connect with <span class="fw-bold ">trendy.</span></p>
            <b-card-text>
              Curabitur mollis bibendum luctus.
              Duis suscipit vitae dui sed suscipit.
              Vestibulum auctor nunc vitae diam eleifend.
            </b-card-text>
            <p>31 Van Choung Street</p>
            <p>Ha Noi, Viet Nam</p>
            <p>+84 (91)2 847 911</p>
            <p>Pagina@info.com</p>

          </b-card>

        </b-col>
        
        <b-col md="6">
          <div class="form-wrapper">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-input type="text" placeholder="Your name"></b-input>
                </b-col>
                <b-col md="6">
                  <b-input type="text" placeholder="Your email"></b-input>
                </b-col>
              </b-row>

              <b-input type="text" placeholder="Subject" ></b-input>
 
              <b-textarea rows="3" placeholder="Your message"></b-textarea>

              <app-button variant="primary">Send Message</app-button>


            </b-form>

          </div>

        </b-col>
        <img class="pens" src="./../assets/images/landing-page/pen.png" alt="" >


      </b-row>



    </section>


</div>


    
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
    name: "LandingView",
    data() {
      return {
        slide: 0,
        slide_1: 0,
        slide_2: 0,
        slide_3: 0,
        sliding: null,
      }
    },
    components: {
      AppButton
    },
    mounted() {
          document.title = "Landing page";

    }


}
</script>