<template>
  <div class="wrapper">
        <title>Buttons</title>


        <h1 class="title-section">Buttons</h1>
        <p class="m-40" style="font-size: 1.5rem">Use Trendy's custom <code class="text-nowrap" translate="no">&lt;app-button&gt;</code> component for actions in forms, dialogs, and more. Includes support for a handful of contextual variations, sizes, states, and more.</p>

        <h2>Overview</h2>
        <p class="m-30">Trendy's <code class="text-nowrap" translate="no">&lt;app-button&gt;</code> component generates either a 
        <code class="text-nowrap" translate="no">&lt;button&gt;</code> element, 
        <code class="text-nowrap" translate="no">&lt;a&gt;</code> element, or 
        <code class="text-nowrap" translate="no">&lt;router&gt;</code> component with the styling of a button.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button>Button</app-button>
            <app-button variant="primary">Button</app-button>
            <app-button variant="secondary">Button</app-button>
            <app-button variant="success">Button</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button>Button</app-button>
  <app-button variant="primary">Button</app-button>
  <app-button variant="secondary">Button</app-button>
  <app-button variant="success">Button</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

      <h2>Element type</h2>
      <p class="m-30">
        The <code class="text-nowrap" translate="no">&lt;b-button&gt;</code> component generally renders a <code class="text-nowrap" translate="no">&lt;button&gt;</code> 
        element. However, you can also render an <code class="text-nowrap" translate="no">&lt;a&gt;</code> element by providing an 
        <code class="text-nowrap" translate="no">href</code> prop value. You may also generate <code class="text-nowrap" translate="no">vue-router</code> 
        <code class="text-nowrap" translate="no">&lt;router-link&gt;</code> when providing a value for the <code class="text-nowrap" translate="no">to</code> prop 
        (<code class="text-nowrap" translate="no">vue-router</code> is required).
      </p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button>I am a Button</app-button>
            <app-button href="#">I am a Link</app-button>
          </div>



<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button>I am a Button</app-button>
  <app-button href="#">I am a Link</app-button>
</div></script></code></pre>

        </b-col>
      </b-row>

      <h2>Type</h2>
      <p>
        You can specify the button's type by setting the prop <code class="text-nowrap" translate="no">type</code> to 
        <code class="text-nowrap" translate="no">'button'</code>, <code class="text-nowrap" translate="no">'submit'</code> or 
        <code class="text-nowrap" translate="no">'reset'</code>. The default type is <code class="text-nowrap" translate="no">'button'</code>.
      </p>
      <p class="m-40">
        Note the <code class="text-nowrap" translate="no">type</code> prop has no effect when either <code class="text-nowrap" translate="no">href</code> or 
        <code class="text-nowrap" translate="no">to</code> props are set.
      </p>


      <h2>Contextual variants</h2>
      <p class="m-40">Use the variant prop to generate the various Bootstrap contextual button variants. <br>
           By default <code class="text-nowrap" translate="no">&lt;app-button&gt;</code> will render with the secondary variant. <br>
           The variant prop adds the Bootstrap v4.3 class .btn-<code class="text-nowrap" translate="no">&lt;variant&gt;</code> on the rendered button.
        </p>
        <h3>Solid color variants</h3>
      <p class="m-30">primary, secondary, success, danger, warning, info, light and dark.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button variant="primary">Primary</app-button>
            <app-button variant="secondary">Secondary</app-button>
            <app-button variant="success">Success</app-button>
            <app-button variant="danger">Danger</app-button>
            <app-button variant="warning">Warning</app-button>
            <app-button variant="info">Info</app-button>
            <app-button variant="light">Light</app-button>
            <app-button variant="dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary">Primary</app-button>
  <app-button variant="secondary">Secondary</app-button>
  <app-button variant="success">Success</app-button>
  <app-button variant="danger">Danger</app-button>
  <app-button variant="warning">Warning</app-button>
  <app-button variant="info">Info</app-button>
  <app-button variant="light">Light</app-button>
  <app-button variant="dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

        <h3>Outline color variants</h3>
        <p>In need of a button, but not the hefty background colors they bring? Use the outline-* variants to remove all background images and colors on any <code class="text-nowrap" translate="no">&lt;app-button&gt;</code>: </p>
        <p  class="m-30"> outline-primary, outline-secondary, outline-success, outline-danger, outline-warning, outline-info, outline-light and outline-dark.</p>

        
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button variant="outline-primary">Primary</app-button>
            <app-button variant="outline-secondary">Secondary</app-button>
            <app-button variant="outline-success">Success</app-button>
            <app-button variant="outline-danger">Danger</app-button>
            <app-button variant="outline-warning">Warning</app-button>
            <app-button variant="outline-info">Info</app-button>
            <app-button variant="outline-light">Light</app-button>
            <app-button variant="outline-dark">Dark</app-button>
          </div>

        
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="outline-primary">Primary</app-button>
  <app-button variant="outline-secondary">Secondary</app-button>
  <app-button variant="outline-success">Success</app-button>
  <app-button variant="outline-danger">Danger</app-button>
  <app-button variant="outline-warning">Warning</app-button>
  <app-button variant="outline-info">Info</app-button>
  <app-button variant="outline-light">Light</app-button>
  <app-button variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>


        <h3>Rounded</h3>
        <p class="m-30">Prefer buttons with a more rounded-pill style? Just use the custom-class rounded-pill to achieve that.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" variant="primary">Primary</app-button>
            <app-button custom-class="rounded-pill" variant="secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" variant="success">Success</app-button>
            <app-button custom-class="rounded-pill" variant="danger">Danger</app-button>
            <app-button custom-class="rounded-pill" variant="warning">Warning</app-button>
            <app-button custom-class="rounded-pill" variant="info">Info</app-button>
            <app-button custom-class="rounded-pill" variant="light">Light</app-button>
            <app-button custom-class="rounded-pill" variant="dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button custom-class="rounded-pill" variant="primary">Primary</app-button>
  <app-button custom-class="rounded-pill" variant="secondary">Secondary</app-button>
  <app-button custom-class="rounded-pill" variant="success">Success</app-button>
  <app-button custom-class="rounded-pill" variant="danger">Danger</app-button>
  <app-button custom-class="rounded-pill" variant="warning">Warning</app-button>
  <app-button custom-class="rounded-pill" variant="info">Info</app-button>
  <app-button custom-class="rounded-pill" variant="light">Light</app-button>
  <app-button custom-class="rounded-pill" variant="dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

        <h3>Outline rounded</h3>
        <p class="m-30">You can use outline-* variants and rounded-pill properties together to make the button outline and rounded at the same time.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" variant="outline-primary">Primary</app-button>
            <app-button custom-class="rounded-pill" variant="outline-secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" variant="outline-success">Success</app-button>
            <app-button custom-class="rounded-pill" variant="outline-danger">Danger</app-button>
            <app-button custom-class="rounded-pill" variant="outline-warning">Warning</app-button>
            <app-button custom-class="rounded-pill" variant="outline-info">Info</app-button>
            <app-button custom-class="rounded-pill" variant="outline-light">Light</app-button>
            <app-button custom-class="rounded-pill" variant="outline-dark">Dark</app-button>
          </div>
  
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button custom-class="rounded-pill" variant="outline-primary">Primary</app-button>
  <app-button custom-class="rounded-pill" variant="outline-secondary">Secondary</app-button>
  <app-button custom-class="rounded-pill" variant="outline-success">Success</app-button>
  <app-button custom-class="rounded-pill" variant="outline-danger">Danger</app-button>
  <app-button custom-class="rounded-pill" variant="outline-warning">Warning</app-button>
  <app-button custom-class="rounded-pill" variant="outline-info">Info</app-button>
  <app-button custom-class="rounded-pill" variant="outline-light">Light</app-button>
  <app-button custom-class="rounded-pill" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

        <h2>Progress button</h2>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button :loader="true" custom-class="rounded-pill" variant="primary">Primary</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="secondary">Secondary</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="success">Success</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="danger">Danger</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="warning">Warning</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="info">Info</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="light">Light</app-button>
            <app-button :loader="true" custom-class="rounded-pill" variant="dark">Dark</app-button>

          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button :loader="true" custom-class="rounded-pill" variant="primary">Primary</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="secondary">Secondary</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="success">Success</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="danger">Danger</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="warning">Warning</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="info">Info</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="light">Light</app-button>
  <app-button :loader="true" custom-class="rounded-pill" variant="dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

        <h2>Sizing</h2>
        <p class="m-30">Fancy larger or smaller buttons? Add size="xl", size="lg", size="sm" or size="xs" for additional sizes.</p>

        <b-row>
          <b-col>

    

          <div class="trendy-example">
            <app-button size="lg" variant="primary">Large</app-button>
            <app-button variant="primary">Default</app-button>
            <app-button size="sm" variant="primary">Small</app-button>
          </div>
              </b-col>
        </b-row>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-row>
    <b-col lg="2" class="pb-2"><app-button size="lg" variant="primary">Large</app-button></b-col>
    <b-col lg="2" class="pb-2"><app-button variant="primary">Default</app-button></b-col>
    <b-col lg="2" class="pb-2"><app-button size="sm" variant="primary">Small</app-button></b-col>
  </b-row>
</div></script></code></pre>



      <h3 class="m-30">Extra large</h3>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="xl" variant="primary">Primary</app-button>
            <app-button size="xl" variant="secondary">Secondary</app-button>
            <app-button size="xl" variant="success">Success</app-button>
            <app-button size="xl" variant="danger">Danger</app-button>
            <app-button size="xl" variant="warning">Warning</app-button>
            <app-button size="xl" variant="info">Info</app-button>
            <app-button size="xl" variant="light">Light</app-button>
            <app-button size="xl" variant="dark">Dark</app-button>

          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xl" variant="primary">Primary</app-button>
  <app-button size="xl" variant="secondary">Secondary</app-button>
  <app-button size="xl" variant="success">Success</app-button>
  <app-button size="xl" variant="danger">Danger</app-button>
  <app-button size="xl" variant="warning">Warning</app-button>
  <app-button size="xl" variant="info">Info</app-button>
  <app-button size="xl" variant="light">Light</app-button>
  <app-button size="xl" variant="dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>

      <h3 class="m-30">Outline Extra large</h3>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="xl" variant="outline-primary">Primary</app-button>
            <app-button size="xl" variant="outline-secondary">Secondary</app-button>
            <app-button size="xl" variant="outline-success">Success</app-button>
            <app-button size="xl" variant="outline-danger">Danger</app-button>
            <app-button size="xl" variant="outline-warning">Warning</app-button>
            <app-button size="xl" variant="outline-info">Info</app-button>
            <app-button size="xl" variant="outline-light">Light</app-button>
            <app-button size="xl" variant="outline-dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xl" variant="outline-primary">Primary</app-button>
  <app-button size="xl" variant="outline-secondary">Secondary</app-button>
  <app-button size="xl" variant="outline-success">Success</app-button>
  <app-button size="xl" variant="outline-danger">Danger</app-button>
  <app-button size="xl" variant="outline-warning">Warning</app-button>
  <app-button size="xl" variant="outline-info">Info</app-button>
  <app-button size="xl" variant="outline-light">Light</app-button>
  <app-button size="xl" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
        </b-col>
      </b-row>


      <h3>Rounded extra large</h3>
      <p class="m-30">Extra large buttons are available in rounded style. Just use size="xl" and rounded-pill custom-class to achieve that.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="xl" variant="primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="success">Success</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="info">Info</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="light">Light</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xl" variant="primary">Primary</app-button>
  <app-button size="xl" variant="secondary">Secondary</app-button>
  <app-button size="xl" variant="success">Success</app-button>
  <app-button size="xl" variant="danger">Danger</app-button>
  <app-button size="xl" variant="warning">Warning</app-button>
  <app-button size="xl" variant="info">Info</app-button>
  <app-button size="xl" variant="light">Light</app-button>
  <app-button size="xl" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3 class="m-30">Outline rounded extra large</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="xl" variant="outline-primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-success">Success</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-info">Info</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-light">Light</app-button>
            <app-button custom-class="rounded-pill" size="xl" variant="outline-dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xl" variant="outline-primary">Primary</app-button>
  <app-button size="xl" variant="outline-secondary">Secondary</app-button>
  <app-button size="xl" variant="outline-success">Success</app-button>
  <app-button size="xl" variant="outline-danger">Danger</app-button>
  <app-button size="xl" variant="outline-warning">Warning</app-button>
  <app-button size="xl" variant="outline-info">Info</app-button>
  <app-button size="xl" variant="outline-light">Light</app-button>
  <app-button size="xl" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>


      <h3>Large</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="lg" variant="primary">Primary</app-button>
            <app-button size="lg" variant="secondary">Secondary</app-button>
            <app-button size="lg" variant="success">Success</app-button>
            <app-button size="lg" variant="danger">Danger</app-button>
            <app-button size="lg" variant="warning">Warning</app-button>
            <app-button size="lg" variant="info">Info</app-button>
            <app-button size="lg" variant="light">Light</app-button>
            <app-button size="lg" variant="dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="lg" variant="primary">Primary</app-button>
  <app-button size="lg" variant="secondary">Secondary</app-button>
  <app-button size="lg" variant="success">Success</app-button>
  <app-button size="lg" variant="danger">Danger</app-button>
  <app-button size="lg" variant="warning">Warning</app-button>
  <app-button size="lg" variant="info">Info</app-button>
  <app-button size="lg" variant="light">Light</app-button>
  <app-button size="lg" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3>Outline Large</h3>

      <b-row>
        <b-col>

        <div class="trendy-example">
          <app-button size="lg" variant="outline-primary">Primary</app-button>
          <app-button size="lg" variant="outline-secondary">Secondary</app-button>
          <app-button size="lg" variant="outline-success">Success</app-button>
          <app-button size="lg" variant="outline-danger">Danger</app-button>
          <app-button size="lg" variant="outline-warning">Warning</app-button>
          <app-button size="lg" variant="outline-info">Info</app-button>
          <app-button size="lg" variant="outline-light">Light</app-button>
          <app-button size="lg" variant="outline-dark">Dark</app-button>
        </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="lg" variant="outline-primary">Primary</app-button>
  <app-button size="lg" variant="outline-secondary">Secondary</app-button>
  <app-button size="lg" variant="outline-success">Success</app-button>
  <app-button size="lg" variant="outline-danger">Danger</app-button>
  <app-button size="lg" variant="outline-warning">Warning</app-button>
  <app-button size="lg" variant="outline-info">Info</app-button>
  <app-button size="lg" variant="outline-light">Light</app-button>
  <app-button size="lg" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3>Rounded large</h3>
      <p class="m-30">Large buttons are available in rounded style. Just use size="lg" and rounded-pill custom-class to achieve that.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="lg" variant="primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="success">Success</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="info">Info</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="light">Light</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button custom-class="rounded-pill"  size="lg" variant="primary">Primary</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="secondary">Secondary</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="success">Success</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="danger">Danger</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="warning">Warning</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="info">Info</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="light">Light</app-button>
  <app-button custom-class="rounded-pill"  size="lg" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3 class="m-30">Outline rounded large</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="lg" variant="outline-primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-success">Success</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-info">Info</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-light">Light</app-button>
            <app-button custom-class="rounded-pill" size="lg" variant="outline-dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="lg" variant="outline-primary">Primary</app-button>
  <app-button size="lg" variant="outline-secondary">Secondary</app-button>
  <app-button size="lg" variant="outline-success">Success</app-button>
  <app-button size="lg" variant="outline-danger">Danger</app-button>
  <app-button size="lg" variant="outline-warning">Warning</app-button>
  <app-button size="lg" variant="outline-info">Info</app-button>
  <app-button size="lg" variant="outline-light">Light</app-button>
  <app-button size="lg" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>


        <h3 class="m-30">Small</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="sm" variant="primary">Primary</app-button>
            <app-button size="sm" variant="secondary">Secondary</app-button>
            <app-button size="sm" variant="success">Success</app-button>
            <app-button size="sm" variant="danger">Danger</app-button>
            <app-button size="sm" variant="warning">Warning</app-button>
            <app-button size="sm" variant="info">Info</app-button>
            <app-button size="sm" variant="light">Light</app-button>
            <app-button size="sm" variant="dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="sm" variant="primary">Primary</app-button>
  <app-button size="sm" variant="secondary">Secondary</app-button>
  <app-button size="sm" variant="success">Success</app-button>
  <app-button size="sm" variant="danger">Danger</app-button>
  <app-button size="sm" variant="warning">Warning</app-button>
  <app-button size="sm" variant="info">Info</app-button>
  <app-button size="sm" variant="light">Light</app-button>
  <app-button size="sm" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3>Outline small</h3>
      <p class="m-30">Small buttons are available in outline rounded style. Just use size="sm", outline-* variant and rounded-pill custom-class to achieve that.</p>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="sm" variant="outline-primary" >Primary</app-button>
            <app-button size="sm" variant="outline-secondary">Secondary</app-button>
            <app-button size="sm" variant="outline-success">Success</app-button>
            <app-button size="sm" variant="outline-danger">Danger</app-button>
            <app-button size="sm" variant="outline-warning">Warning</app-button>
            <app-button size="sm" variant="outline-info">Info</app-button>
            <app-button size="sm" variant="outline-light">Light</app-button>
            <app-button size="sm" variant="outline-dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="sm" variant="outline-primary">Primary</app-button>
  <app-button size="sm" variant="outline-secondary">Secondary</app-button>
  <app-button size="sm" variant="outline-success">Success</app-button>
  <app-button size="sm" variant="outline-danger">Danger</app-button>
  <app-button size="sm" variant="outline-warning">Warning</app-button>
  <app-button size="sm" variant="outline-info">Info</app-button>
  <app-button size="sm" variant="outline-light">Light</app-button>
  <app-button size="sm" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3>Rounded small</h3>
      <p class="m-30">Small buttons are available in outline rounded style. Just use size="sm", outline-* variant and rounded-pill custom-class to achieve that.</p>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="sm" variant="primary" >Primary</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="success">Success</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="info">Info</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="light">Light</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button custom-class="rounded-pill" size="sm" variant="primary" >Primary</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="secondary" class="ml-1">Secondary</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="success" class="ml-1">Success</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="danger" class="ml-1">Danger</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="warning" class="ml-1">Warning</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="info" class="ml-1">Info</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="light" class="ml-1">Light</app-button>
  <app-button custom-class="rounded-pill" size="sm" variant="dark" class="ml-1">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3 class="m-30">Outline rounded small</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="sm" variant="outline-primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-success">Success</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-info">Info</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-light">Light</app-button>
            <app-button custom-class="rounded-pill" size="sm" variant="outline-dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="sm" variant="outline-primary">Primary</app-button>
  <app-button size="sm" variant="outline-secondary">Secondary</app-button>
  <app-button size="sm" variant="outline-success">Success</app-button>
  <app-button size="sm" variant="outline-danger">Danger</app-button>
  <app-button size="sm" variant="outline-warning">Warning</app-button>
  <app-button size="sm" variant="outline-info">Info</app-button>
  <app-button size="sm" variant="outline-light">Light</app-button>
  <app-button size="sm" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>





      <h3 class="m-30">Extra small</h3>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="xs" variant="primary">Primary</app-button>
            <app-button size="xs" variant="secondary">Secondary</app-button>
            <app-button size="xs" variant="success">Success</app-button>
            <app-button size="xs" variant="danger">Danger</app-button>
            <app-button size="xs" variant="warning">Warning</app-button>
            <app-button size="xs" variant="info">Info</app-button>
            <app-button size="xs" variant="light">Light</app-button>
            <app-button size="xs" variant="dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xs" variant="primary">Primary</app-button>
  <app-button size="xs" variant="secondary">Secondary</app-button>
  <app-button size="xs" variant="success">Success</app-button>
  <app-button size="xs" variant="danger">Danger</app-button>
  <app-button size="xs" variant="warning">Warning</app-button>
  <app-button size="xs" variant="info">Info</app-button>
  <app-button size="xs" variant="light">Light</app-button>
  <app-button size="xs" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

       <h3>Outline extra small</h3>
      <p class="m-30">Extra mall buttons are available in outline rounded style. Just use size="xs", outline-* variant and rounded-pill custom-class to achieve that.</p>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button size="xs" variant="outline-primary">Primary</app-button>
            <app-button size="xs" variant="outline-secondary">Secondary</app-button>
            <app-button size="xs" variant="outline-success">Success</app-button>
            <app-button size="xs" variant="outline-danger">Danger</app-button>
            <app-button size="xs" variant="outline-warning">Warning</app-button>
            <app-button size="xs" variant="outline-info">Info</app-button>
            <app-button size="xs" variant="outline-light">Light</app-button>
            <app-button size="xs" variant="outline-dark">Dark</app-button>
          </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xs" variant="outline-primary">Primary</app-button>
  <app-button size="xs" variant="outline-secondary">Secondary</app-button>
  <app-button size="xs" variant="outline-success">Success</app-button>
  <app-button size="xs" variant="outline-danger">Danger</app-button>
  <app-button size="xs" variant="outline-warning">Warning</app-button>
  <app-button size="xs" variant="outline-info">Info</app-button>
  <app-button size="xs" variant="outline-light">Light</app-button>
  <app-button size="xs" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3 class="m-30">Rounded Extra small</h3>
      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="xs" variant="primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="success">Success</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="info">Info</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="light">Light</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button custom-class="rounded-pill" size="xs" variant="primary">Primary</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="secondary">Secondary</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="success">Success</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="danger">Danger</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="warning">Warning</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="info">Info</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="light">Light</app-button>
  <app-button custom-class="rounded-pill" size="xs" variant="dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>

      <h3 class="m-30">Outline rounded extra small</h3>

      <b-row>
        <b-col>

          <div class="trendy-example">
            <app-button custom-class="rounded-pill" size="xs" variant="outline-primary">Primary</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-secondary">Secondary</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-success">Success</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-danger">Danger</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-warning">Warning</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-info">Info</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-light">Light</app-button>
            <app-button custom-class="rounded-pill" size="xs" variant="outline-dark">Dark</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button size="xs" variant="outline-primary">Primary</app-button>
  <app-button size="xs" variant="outline-secondary">Secondary</app-button>
  <app-button size="xs" variant="outline-success">Success</app-button>
  <app-button size="xs" variant="outline-danger">Danger</app-button>
  <app-button size="xs" variant="outline-warning">Warning</app-button>
  <app-button size="xs" variant="outline-info">Info</app-button>
  <app-button size="xs" variant="outline-light">Light</app-button>
  <app-button size="xs" variant="outline-dark">Dark</app-button>
</div></script></code></pre>
</b-col>
</b-row>


        <h2>Block Level Buttons</h2>
        <p class="m-30">Create block level buttons — those that span the full width of a parent — by setting the block prop.</p>
        <b-row>
          <b-col>
       
        <app-button block variant="primary">Block Level Button</app-button>
        <app-button block variant="secondary">Block Level Button</app-button>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button block variant="primary">Block Level Button</app-button>
  <app-button block variant="secondary">Block Level Button</app-button>
</div></script></code></pre>
           </b-col>
          </b-row>

        
          <h2>Link variant</h2>
          <p class="m-30">
            Variant <code class="text-nowrap" translate="no">link</code> will render a button with the appearance of a link while maintaining the default padding and size of a button.
          </p>

          <div class="m-40">
            <app-button variant="link">Link</app-button>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="link">Link</app-button>
</div></script></code></pre>




      <h2>Disabled state</h2>
      <p class="m-30">
        Set the <code class="text-nowrap" translate="no">disabled</code> prop to disable button default functionality. 
        <code class="text-nowrap" translate="no">disabled</code> also works with buttons rendered as 
        <code class="text-nowrap" translate="no">&lt;a&gt;</code> elements and 
        <code class="text-nowrap" translate="no">&lt;router-link&gt;</code> (i.e. with the 
        <code class="text-nowrap" translate="no">href</code> or <code class="text-nowrap" translate="no">to</code> prop set).
      </p>

      <b-row>
        <b-col class="m-40">
          <div class="trendy-example">
            <b-button disabled size="lg" variant="primary">Primary</b-button>
            <b-button disabled size="lg">Secondary</b-button>
          </div>

        </b-col>
      </b-row>



      <h2>Pressed state and toggling</h2>
      <p>
        Buttons will appear pressed (with a darker background, darker border, and inset shadow) when the prop 
        <code class="text-nowrap" translate="no">pressed</code> is set to <code class="text-nowrap" translate="no">true</code>.
      </p>
      <p>The <code class="text-nowrap" translate="no">pressed</code> prop can be set to one of three values:</p>
      <ul class="m-40"> 
        <li>
          <code class="text-nowrap" translate="no">true</code>: Sets the <code class="text-nowrap" translate="no">.active</code> 
          class and adds the attribute <code class="text-nowrap" translate="no">aria-pressed="true"</code>.
        </li> 
        <li>
          <code class="text-nowrap" translate="no">false</code>: Clears the <code class="text-nowrap" translate="no">.active</code> 
          class and adds the attribute <code class="text-nowrap" translate="no">aria-pressed="false"</code>.
        </li> 
        <li>
          <code class="text-nowrap" translate="no">null</code>: (default) Neither the class 
          <code class="text-nowrap" translate="no">.active</code> nor the attribute 
          <code class="text-nowrap" translate="no">aria-pressed</code> will be set.
        </li> 
      </ul>





    <div class="btn-wrapper">
        <h2 class="title-section">Icon button</h2>
      <b-row class="mb-4">
        <b-col>
        <app-button position="left" :icon="Wallet" class="mr-3 icon-btn" variant="light">
          <span>Your wallet</span>
          <inline-svg :src="AngleRightPrimary" class="angle"/>
        </app-button>

        <app-button position="left" :icon="RightArrow" class="mr-3 icon-sign-in" variant="light">SIGN IN</app-button>
        
        <app-button  class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleLeft"></inline-svg>
        </app-button>

        <app-button  class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleRight"></inline-svg>
        </app-button>
        <app-button  custom-class="rounded-pill" class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleLeft"></inline-svg>
        </app-button>
        <app-button custom-class="rounded-pill"   class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleRight"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
            <inline-svg :src="User">
            </inline-svg>
          <span class="notification-right">
                  5
          </span>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
           <span class="notification-left">
                  3
          </span>
          <inline-svg :src="Bell"></inline-svg>
         
        </app-button>
          <app-button class="mr-3 btn-icon" variant="light">
          <inline-svg :src="ShoppingCart"></inline-svg>
          <span class="notification-right">
                  17
          </span>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
          <inline-svg :src="Home"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
          <inline-svg :src="Trash"></inline-svg>
        </app-button>
          
        </b-col>

      </b-row>
      <b-row class="mb-4">
        <b-col>
        <app-button position="left" custom-class="rounded-pill"  :icon="Wallet" class="mr-3 icon-btn" variant="light">
          <span>Your wallet</span>
          <inline-svg :src="AngleRightPrimary" class="angle"/>
        </app-button>
        <app-button position="left" custom-class="rounded-pill" :icon="RightArrow" class="mr-3 icon-sign-in" variant="light">SIGN IN</app-button>

        <app-button class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleLeftPrimary"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleRightPrimary"></inline-svg>
        </app-button>
        <app-button custom-class="rounded-pill" class="mr-3 btn-icon" variant="light">
          <inline-svg :src="AngleLeftPrimary"></inline-svg>
        </app-button>
        <app-button custom-class="rounded-pill" class="mr-3 btn-icon" variant="light">
            <inline-svg :src="AngleRightPrimary"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
            <inline-svg :src="MenuDot"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
            <inline-svg :src="MenuAlt"></inline-svg>
        </app-button>
        <app-button class="mr-3 btn-icon" variant="light">
            <inline-svg :src="PlayPlaylist"></inline-svg>
        </app-button>
         <app-button class="mr-3 btn-icon" variant="light">
            <inline-svg :src="Shuflle"></inline-svg>
        </app-button>
        <app-button custom-class="rounded-pill" class="mr-3 btn-icon lg" variant="light" >
            <inline-svg :src="UserPhoto"></inline-svg>
        </app-button>

        </b-col>

      </b-row>


      <app-button class="mr-3 icon-big" variant="light">
        <div> <inline-svg :src="Airplane"></inline-svg>
          </div>
         <p>AIRPLANE MODE <br> INACTIVE</p>  
       </app-button>

      <app-button class="mr-3 icon-big" variant="light">
        <div> <inline-svg :src="Fingerprint"></inline-svg>
          </div>
         <p>CONFIRM WITH <br> FINGERPRINT</p>  
       </app-button>

    </div>




  </div>
</template>

<script>
import RightArrow from '@/assets/images/icons/arrow-right.svg'
import Wallet from "@/assets/images/icons/wallet.svg"
import AngleLeft from '@/assets/images/icons/angle-left.svg'
import AngleRight from '@/assets/images/icons/angle-right.svg'
import User from '@/assets/images/icons/user.svg'
import Bell from '@/assets/images/icons/bell.svg'
import AppButton from "@/components/AppButton";
import ShoppingCart from '@/assets/images/icons/shopping-cart.svg'
import MenuAlt from '@/assets/images/icons/menu-alt.svg'
import InlineSvg from "vue-inline-svg";
import Home from '@/assets/images/icons/home.svg';
import Trash from '@/assets/images/icons/trash.svg';
import Shuflle from '@/assets/images/icons/shuffle.svg';
import MenuDot from '@/assets/images/icons/menu-dot.svg';
import PlayPlaylist from '@/assets/images/icons/playlist-play.svg';
import Airplane from '@/assets/images/icons/airplane-icon.svg';
import Fingerprint from '@/assets/images/icons/fingerprint.svg';
import AngleRightPrimary from '@/assets/images/icons/angle-right-primary.svg';
import AngleLeftPrimary from '@/assets/images/icons/angle-left-primary.svg';
import UserPhoto from '@/assets/images/icons/user-photo.svg';




export default {
  name: "ButtonsView",
  data() {
    return {
      RightArrow,
      Wallet,
      AngleLeft,
      AngleRight,
      User,
      Bell,
      ShoppingCart,
      MenuAlt,
      Home,
      Trash,
      MenuDot,
      Shuflle,
      PlayPlaylist,
      Airplane,
      Fingerprint,
      AngleRightPrimary,
      AngleLeftPrimary,
      UserPhoto
    }
  },
  components: {
    AppButton,
    InlineSvg
    },

  mounted() {
    document.title = "Buttons";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>
</style>
