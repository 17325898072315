<template>
  <div class="wrapper">
              <title>Table</title>

    <h1 class="title-section">Tables</h1>
    <p class="m-40" style="font-size: 1.5rem">
      For displaying tabular data, <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> supports 
      pagination, filtering, sorting, custom rendering, various style options, events, and asynchronous data. 
      For simple display of tabular data without all the fancy features, BootstrapVue provides two lightweight alternative components 
      <a href="#light-weight-tables" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-table-lite&gt;</code></a> and 
      <a href="#simple-tables" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-table-simple&gt;</code></a>.
    </p>

    <app-transition class="m-40 overflow-auto">
      <div class="d-flex justify-content-between">
        <div class="d-flex mb-3  justify-content-start">
          <div class="d-flex align-items-center">
            <span class="mr-2">Show</span>
            
            <app-select-list :options="select_options" v-model="perPage"></app-select-list>

            <span class="ml-2">entries</span>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="align-items-center">
              <search-input placeholder="Filter results"></search-input>
          </div>
        </div>
      </div>



      <b-table :per-page="perPage" :current-page="page" hover :items="items" :fields="fields">
        <template v-slot:cell(actions)>
          <div class="cellActions">
            <app-button variant="link">
              <inline-svg :src="IconEye"></inline-svg>
            </app-button>
            <app-button variant="link">
              <inline-svg :src="IconPencil"></inline-svg>
            </app-button>
            <app-button variant="link" @click="confirmDelete">
              <inline-svg :src="IconTrash"></inline-svg>
            </app-button>
          </div>

        </template>
      </b-table>

      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <b-pagination :total-rows="rows" :per-page="perPage" v-model="page"></b-pagination>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          Showing <span class="per-page">{{ page > 1 ? perPage * (page - 1) + 1 : 1 }}</span> to <span class="per-page">{{
            perPage * page
          }}</span> of <span class="total-rows"> {{ items.length }} </span>
        </div>
      </div>



      <app-confirmation-dialog @cancel="show_confirmation_dialog = false" @confirm="show_confirmation_dialog = false"
                               :show="show_confirmation_dialog"></app-confirmation-dialog>


    </app-transition>

    <h2>Items</h2>
    <p class="m-30">
      <code class="text-nowrap" translate="no">items</code> is the table data in array format, where each record (row) data are keyed objects. Example format:
    </p>
<pre><code class="language-javascript" data-label="Copy"><script type="prism-html-markup">const items = [
  { grad: 'Bijeljina', pdv_broj: 500132484568 },
  { grad: 'Bijeljina', pdv_broj: 500132484568 },
  { grad: 'Bijeljina', pdv_broj: 500132484568 }
]
</script></code></pre>

    <p>
      <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> automatically samples the first row to extract field names (the keys in the record data). 
      Field names are automatically "humanized" by converting <code class="text-nowrap" translate="no">kebab-case</code>, 
      <code class="text-nowrap" translate="no">snake_case</code>, and <code class="text-nowrap" translate="no">camelCase</code> 
      to individual words and capitalizes each word. Example conversions:
    </p>

    <ul> 
      <li>
        <code class="text-nowrap" translate="no">first_name</code> becomes <code class="text-nowrap" translate="no">First Name</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">last-name</code> becomes <code class="text-nowrap" translate="no">Last Name</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">age</code> becomes <code class="text-nowrap" translate="no">Age</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">YEAR</code> remains <code class="text-nowrap" translate="no">YEAR</code>
      </li> 
      <li>
        <code class="text-nowrap" translate="no">isActive</code> becomes <code class="text-nowrap" translate="no">Is Active</code>
      </li> 
    </ul>

    <p>
      <strong>Note:</strong> Field order is not guaranteed. Fields will typically appear in the order they were defined in the first row, 
      but this may not always be the case depending on the version of browser in use. See section 
      <a href="#fields-column-definitions" class="font-weight-bold">Fields (column definitions)</a> below to see how to guarantee the order 
      of fields, and to override the headings generated.
    </p>

    <p>
      <code class="text-nowrap" translate="no">items</code> can also be a reference to a <em>provider</em> function, which returns an 
      <code class="text-nowrap" translate="no">Array</code> of items data. Provider functions can also be asynchronous:
    </p>

    <ul class="m-40"> 
      <li>By returning <code class="text-nowrap" translate="no">null</code> (or <code class="text-nowrap" translate="no">undefined</code>) 
      and calling a callback, when the data is ready, with the data array as the only argument to the callback,
     </li> 
     <li>
      By returning a <code class="text-nowrap" translate="no">Promise</code> that resolves to an array.
     </li> 
   </ul>

   <h3>Table item notes and warnings</h3>
   <ul class="m-40"> 
     <li>
       Avoid manipulating record data in place, as changes to the underlying items data will cause either the row or entire table to be re-rendered. 
       See <a href="#primary-key" class="font-weight-bold">Primary Key</a>, below, for ways to minimize Vue's re-rendering of rows.
      </li> 
      <li>
        <code class="text-nowrap" translate="no">items</code> array records should be a simple object and <strong>must</strong> 
        avoid placing data that may have circular references in the values within a row. 
        <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> serializes the row data into strings for sorting and filtering, 
        and circular references will cause stack overflows to occur and your app to crash!
      </li> 
    </ul>

    <h2>Fields (column definition)</h2>
    <p>
      The <code class="text-nowrap" translate="no">fields</code> prop is used to customize the table columns headings, 
      and in which order the columns of data are displayed. The field object keys (i.e. <code class="text-nowrap" translate="no">age</code> or 
      <code class="text-nowrap" translate="no">first_name</code> as shown below) are used to extract the value from each item (record) row, 
      and to provide additional features such as enabling <a href="#sorting" class="font-weight-bold">sorting</a> on the column, etc.
    </p>

    <p class="m-40">
      Fields can be provided as a <em>simple array</em> or an <em>array of objects</em>. <strong>Internally the fields data will be normalized into the 
      <em>array of objects</em> format</strong>. Events or slots that include the column <code class="text-nowrap" translate="no">field</code> 
      data will be in the normalized field object format (array of objects for <code class="text-nowrap" translate="no">fields</code>, or an object for an individual 
      <code class="text-nowrap" translate="no">field</code>).
    </p>

    <h3>Fields as simple array</h3>
    <p class="m-40">Fields can be a simple array, for defining the order of the columns, and which columns to display:</p>

    <h3>Fields as an array of objects</h3>
    <p class="m-40">Fields can be a an array of objects, providing additional control over the fields (such as sorting, formatting, etc.). Only columns (keys) that appear in the fields array will be shown:</p>

    <h2>Primary key</h2>
    <p><code class="text-nowrap" translate="no">&lt;b-table&gt;</code> provides an additional prop <code class="text-nowrap" translate="no">primary-key</code>, which you can use to identify the <em>name</em> of the field key that <em>uniquely</em> identifies the row.</p> 
    <p>The value specified by the primary column key <strong>must be</strong> either a <code class="text-nowrap" translate="no">string</code> or <code class="text-nowrap" translate="no">number</code>, and <strong>must be unique</strong> across all rows in the table.</p>
    <p class="m-40">The primary key column does not need to appear in the displayed fields.</p>

    <h3>Table row ID generation</h3>
    <p class="m-40">When provided, the <code class="text-nowrap" translate="no">primary-key</code> will generate a unique ID for each item row <code class="text-nowrap" translate="no">&lt;tr&gt;</code> element. The ID will be in the format of <code class="text-nowrap" translate="no">{table-id}__row_{primary-key-value}</code>, where <code class="text-nowrap" translate="no">{table-id}</code> is the unique ID of the <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> and <code class="text-nowrap" translate="no">{primary-key-value}</code> is the value of the item's field value for the field specified by <code class="text-nowrap" translate="no">primary-key</code>.</p>

    <h3>Table render and transition optimization</h3>
    <p>The <code class="text-nowrap" translate="no">primary-key</code> is also used by <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> to help Vue optimize the rendering of table rows. Internally, the value of the field key specified by the <code class="text-nowrap" translate="no">primary-key</code> prop is used as the Vue <code class="text-nowrap" translate="no">:key</code> value for each rendered item row <code class="text-nowrap" translate="no">&lt;tr&gt;</code> element.</p>
    <p>If you are seeing rendering issue (i.e. tooltips hiding or unexpected subcomponent re-usage when item data changes or data is sorted/filtered/edited) or table row transitions are not working, setting the <code class="text-nowrap" translate="no">primary-key</code> prop (if you have a unique identifier per row) can alleviate these issues.</p>
    <p>Specifying the <code class="text-nowrap" translate="no">primary-key</code> column is handy if you are using 3rd party table transitions or drag and drop plugins, as they rely on having a consistent and unique per row <code class="text-nowrap" translate="no">:key</code> value.</p> 
    <p class="m-40">If <code class="text-nowrap" translate="no">primary-key</code> is not provided, <code class="text-nowrap" translate="no">&lt;b-table&gt;</code> will auto-generate keys based on the displayed row's index number (i.e. position in the <em>displayed</em> table rows). This may cause GUI issues such as sub components/elements that are rendering with previous results (i.e. being re-used by Vue's render patch optimization routines). Specifying a <code class="text-nowrap" translate="no">primary-key</code> column can alleviate this issue (or you can place a unique <code class="text-nowrap" translate="no">:key</code> on your element/components in your custom formatted field slots).</p>  
  

    <h2 class="m-30">Table style options</h2>
    <h3>Table styling</h3>
    <p><code class="text-nowrap" translate="no">&lt;b-table&gt;</code> provides several props to alter the style of the table:</p>
      <div class="m-30">
        <b-table-simple hover responsive >
          <b-thead>
            <b-tr>
              <b-th>prop</b-th>
              <b-th>Type</b-th>
              <b-th>Description</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">striped</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>Add zebra-striping to the table rows within the <code class="text-nowrap" translate="no">&lt;tbody&gt;</code></b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">borderd</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>For borders on all sides of the table and cells.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">borderless</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>removes inner borders from table.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">outlined</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>For a thin border on all sides of the table. Has no effect if <code class="text-nowrap" translate="no">bordered</code> is set.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">small</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>To make tables more compact by cutting cell padding in half.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">hover</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>To enable a hover highlighting state on table rows within a <code class="text-nowrap" translate="no">&lt;tbody&gt;</code></b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">dark</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>Invert the colors — with light text on dark backgrounds (equivalent to Bootstrap v4 class <code class="text-nowrap" translate="no">.table-dark</code>)</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">fixed</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>Generate a table with equal fixed-width columns (<code class="text-nowrap" translate="no">table-layout: fixed;</code>)</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">responsive</code></b-td>
              <b-td>Boolean or String</b-td>
              <b-td>Generate a responsive table to make it scroll horizontally. Set to <code class="text-nowrap" translate="no">true</code> for an always responsive table, or set it to one of the breakpoints <code class="text-nowrap" translate="no">'sm'</code>, <code class="text-nowrap" translate="no">'md'</code>, <code class="text-nowrap" translate="no">'lg'</code>, or <code class="text-nowrap" translate="no">'xl'</code> to make the table responsive (horizontally scroll) only on screens smaller than the breakpoint. See <a href="#responsive-tables" class="font-weight-bold">Responsive tables</a> below for details.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">sticky-header</code></b-td>
              <b-td>Boolean or String</b-td>
              <b-td>Generates a vertically scrollable table with sticky headers. Set to <code class="text-nowrap" translate="no">true</code> to enable sticky headers (default table max-height of <code class="text-nowrap" translate="no">300px</code>), or set it to a string containing a height (with CSS units) to specify a maximum height other than <code class="text-nowrap" translate="no">300px</code>. See the <a href="#sticky-headers" class="font-weight-bold">Sticky header</a> section below for details.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">stacked</code></b-td>
              <b-td>Boolean or String</b-td>
              <b-td>Generate a responsive stacked table. Set to <code class="text-nowrap" translate="no">true</code> for an always stacked table, or set it to one of the breakpoints <code class="text-nowrap" translate="no">'sm'</code>, <code class="text-nowrap" translate="no">'md'</code>, <code class="text-nowrap" translate="no">'lg'</code>, or <code class="text-nowrap" translate="no">'xl'</code> to make the table visually stacked only on screens smaller than the breakpoint. See <a href="#stacked-tables" class="font-weight-bold">Stacked tables</a> below for details.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">caption-top</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>If the table has a caption, and this prop is set to <code class="text-nowrap" translate="no">true</code>, the caption will be visually placed above the table. If <code class="text-nowrap" translate="no">false</code> (the default), the caption will be visually placed below the table.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">table-variant</code></b-td>
              <b-td>String</b-td>
              <b-td>Give the table an overall theme color variant.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">head-variant</code></b-td>
              <b-td>String</b-td>
              <b-td>Use <code class="text-nowrap" translate="no">'light'</code> or <code class="text-nowrap" translate="no">'dark'</code> to make table header appear light or dark gray, respectively</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">foot-variant</code></b-td>
              <b-td>String</b-td>
              <b-td>Use <code class="text-nowrap" translate="no">'light'</code> or <code class="text-nowrap" translate="no">'dark'</code> to make table footer appear light or dark gray, respectively. If not set, <code class="text-nowrap" translate="no">head-variant</code> will be used. Has no effect if <code class="text-nowrap" translate="no">foot-clone</code> is not set</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">foot-clone</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>Turns on the table footer, and defaults with the same contents a the table header</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">no-footer-sorting</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>When <code class="text-nowrap" translate="no">foot-clone</code> is true and the table is sortable, disables the sorting icons and click behaviour on the footer heading cells. Refer to the <a href="#sorting" class="font-weight-bold">Sorting</a> section below for more details.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">no-border-collapse</code></b-td>
              <b-td>Boolean</b-td>
              <b-td>Disables the default of collapsing of the table borders. Mainly for use with <a href="#sticky-headers" class="font-weight-bold">sticky headers</a> and/or <a href="#sticky-columns" class="font-weight-bold">sticky columns</a>. Will cause the appearance of double borders in some situations.</b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </div>

      <p class="m-40"><strong>Note:</strong> The table style options <code class="text-nowrap" translate="no">fixed</code>, <code class="text-nowrap" translate="no">stacked</code>, <code class="text-nowrap" translate="no">caption-top</code>, <code class="text-nowrap" translate="no">no-border-collapse</code>, sticky headers, sticky columns and the table sorting feature, all require BootstrapVue's custom CSS.</p>
      
      <h3>Row styling and attributes</h3>
      <p>You can also style every row using the <code class="text-nowrap" translate="no">tbody-tr-class</code> prop, and optionally supply additional attributes via the <code class="text-nowrap" translate="no">tbody-tr-attr</code> prop:</p>
  

      <div class="m-30">
        <b-table-simple hover responsive>
          <b-thead>
            <b-tr>
              <b-th>Property</b-th>
              <b-th>Type</b-th>
              <b-th>Description</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">tbody-tr-class</code></b-td>
              <b-td>String, Array or Function</b-td>
              <b-td>Classes to be applied to every row on the table. If a function is given, it will be called as <code class="text-nowrap" translate="no">tbodyTrClass( item, type )</code> and it may return an <code class="text-nowrap" translate="no">Array</code>, <code class="text-nowrap" translate="no">Object</code> or <code class="text-nowrap" translate="no">String</code>.</b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">tbody-tr-attr</code></b-td>
              <b-td>Object or Function</b-td>
              <b-td>Attributes to be applied to every row on the table. If a function is given, it will be called as <code class="text-nowrap" translate="no">tbodyTrAttr( item, type )</code> and it must return an <code class="text-nowrap" translate="no">Object</code>.</b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </div>

      <p>When passing a function reference to <code class="text-nowrap" translate="no">tbody-tr-class</code> or <code class="text-nowrap" translate="no">tbody-tr-attr</code>, the function's arguments will be as follows:</p>
      <ul class="m-40"> <li><code class="text-nowrap" translate="no">item</code> - The item record data associated with the row. For rows that are not associated with an item record, this value will be <code class="text-nowrap" translate="no">null</code> or <code class="text-nowrap" translate="no">undefined</code></li> <li><code class="text-nowrap" translate="no">type</code> - The type of row being rendered. <code class="text-nowrap" translate="no">'row'</code> for an item row, <code class="text-nowrap" translate="no">'row-details'</code> for an item details row, <code class="text-nowrap" translate="no">'row-top'</code> for the fixed row top slot, <code class="text-nowrap" translate="no">'row-bottom'</code> for the fixed row bottom slot, or <code class="text-nowrap" translate="no">'table-busy'</code> for the table busy slot.</li> </ul>
  

      <h3>Responsive tables</h3>
<p class="m-40">Responsive tables allow tables to be scrolled horizontally with ease. Make any table responsive across all viewports by setting the prop <code class="text-nowrap" translate="no">responsive</code> to <code class="text-nowrap" translate="no">true</code>. Or, pick a maximum breakpoint with which to have a responsive table up to by setting the prop <code class="text-nowrap" translate="no">responsive</code> to one of the breakpoint values: <code class="text-nowrap" translate="no">sm</code>, <code class="text-nowrap" translate="no">md</code>, <code class="text-nowrap" translate="no">lg</code>, or <code class="text-nowrap" translate="no">xl</code>.</p>
  
  <h3>Stacked tables</h3>
  <p>An alternative to responsive tables, BootstrapVue includes the stacked table option (using custom SCSS/CSS), which allow tables to be rendered in a visually stacked format. Make any table stacked across <em>all viewports</em> by setting the prop <code class="text-nowrap" translate="no">stacked</code> to <code class="text-nowrap" translate="no">true</code>. Or, alternatively, set a breakpoint at which the table will return to normal table format by setting the prop <code class="text-nowrap" translate="no">stacked</code> to one of the breakpoint values <code class="text-nowrap" translate="no">'sm'</code>, <code class="text-nowrap" translate="no">'md'</code>, <code class="text-nowrap" translate="no">'lg'</code>, or <code class="text-nowrap" translate="no">'xl'</code>.</p>
  <p>Column header labels will be rendered to the left of each field value using a CSS <code class="text-nowrap" translate="no">::before</code> pseudo element, with a width of 40%.</p>
  <p>The <code class="text-nowrap" translate="no">stacked</code> prop takes precedence over the <a href="#sticky-headers" class="font-weight-bold"><code class="text-nowrap" translate="no">sticky-header</code></a> prop and the <a href="#sticky-columns" class="font-weight-bold"><code class="text-nowrap" translate="no">stickyColumn</code></a> field definition property.</p>

  
  </div>
</template>

<script>
import IconEye from "@/assets/images/icons/icon-eye.svg"
import IconPencil from "@/assets/images/icons/icon-pencil.svg"
import IconTrash from "@/assets/images/icons/icon-trash.svg"

import InlineSvg from 'vue-inline-svg';
import SearchInput from "@/components/SearchInput";
import AppConfirmationDialog from "@/components/app/AppConfirmationDialog";
import AppTransition from "@/components/app/AppTransition";
import AppSelectList from "@/components/app/AppSelectList";
import AppButton from '@/components/AppButton'
import DropDown from '@/components/DropDown'


export default {
  name: "TableView",
  data() {
    return {
      show_confirmation_dialog: false,
      IconEye,
      IconPencil,
      IconTrash,
      select_options: [
        {value: 10, text: 10},
        {value: 20, text: 20},
        {value: 50, text: 50},
        {value: 100, text: 100},
      ],
      page: 1,
      perPage: 10,

      fields: [{key: 'id', label: "Sifra", sortable: true},
        {key: 'name', label: "Naziv", sortable: true, tdClass: "font-weight-bold"},
        {key: 'city', label: "Grad", sortable: true,},
        {key: 'jib', label: "JIB", sortable: true,},
        {key: 'vat', label: "PDV broj", sortable: true,},
        {key: 'actions', label: ""},

      ],
      items: [
        {
          id: 1,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 2,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 3,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 4,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 5,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 6,
          name: "nova media",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 7,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 8,
          name: "nova media.",
          address: 'Trebinjskih brigada 17',
          city: 'Trebinje',
          jib: "500132484561",
          vat: "500132484568"
        },
        {
          id: 9,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 10,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 11,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 12,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 13,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 14,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 15,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 16,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 17,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 18,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 19,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 20,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 21,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },
        {
          id: 22,
          name: "nova media.",
          address: 'Mese Selimovica 23b/21',
          city: 'Bijeljina',
          jib: "500132484568",
          vat: "500132484568"
        },

      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  components: {
    AppSelectList,
    AppTransition,
    AppConfirmationDialog,
    SearchInput,
    InlineSvg,
    AppButton,
    DropDown
  },
  methods: {
    confirmDelete() {
      this.show_confirmation_dialog = true
    }
  },
  mounted() {
    document.title = "Table";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
