// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
//import './polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from "bootstrap-vue";
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/plugins/toolbar/prism-toolbar';
import 'prismjs/plugins/toolbar/prism-toolbar.css';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
import VueBarcode from 'vue-barcode';
Vue.use(BootstrapVue)
Vue.use(VueSidebarMenu)
Vue.use(Prism)
Vue.component('barcode', VueBarcode)
export const app = new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App,
  },
  mounted() {
    window.Prism = window.Prism || {};
    window.Prism.manual = true;
    Prism.highlightAll(); // highlight your code on mount
  }
})

