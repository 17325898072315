<template>
<div class="wrapper">
      <title>Modals</title>

  <h1 class="title-section">Modals</h1>
  <p class="m-30" style="font-size: 1.5rem">Modals are streamlined, but flexible dialog prompts powered by JavaScript and CSS. They support a number of use cases from user notification to completely custom content and feature a handful of helpful sub-components, sizes, variants, accessibility, and more.</p>
  
  
  <app-button v-b-modal.modal-1 variant="primary">Modal</app-button>



  <b-modal v-model="visible"  size="lg" id="modal-1" >
    <template v-slot:modal-title>
      <h4>Modal title</h4>
    </template>
    <p class="my-4">Hello from modal!</p>
    <template v-slot:modal-footer>
      <app-button variant="secondary" class="mr-3" @click="visible=false">Close</app-button>
      <app-button variant="primary" class="mr-3">Save changes</app-button>
    </template>
  </b-modal>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-modal v-model="visible"  size="lg" id="modal-1" >
    <template v-slot:modal-title>
      <h4>Modal title</h4>
    </template>
    <p class="my-4">Hello from modal!</p>
    <template v-slot:modal-footer>
      <app-button custom-class="f-12" variant="second" class="mr-3" @click="visible=false">Close</app-button>
      <app-button custom-class="f-12" variant="primary" class="mr-3">Save changes</app-button>
    </template>
  </b-modal>
</div></script></code></pre>

<h2>Overview</h2>
<p><code class="text-nowrap" translate="no">&lt;b-modal&gt;</code>, by default, has an OK and Cancel buttons in the footer. These buttons can be customized by setting various props on the component. 
   You can customize the size of the buttons, disable buttons, hide the Cancel button (i.e. ok-only), choose a variant (e.g. danger for a red OK button)
   using the ok-variant and cancel-variant props, and provide custom button content using the ok-title and cancel-title props, or using the named slots
  modal-ok and modal-cancel.
</p>

<p><code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> supports close on ESC (enabled by default), close on backdrop click (enabled by default), and the X close button in the header (enabled by default). 
  These features may be disabled by setting the props no-close-on-esc, no-close-on-backdrop, and hide-header-close respectively.
</p>

<p>
  You can override the modal title via the named slot modal-title, override the header completely via the modal-header slot, 
  and override the footer completely via the modal-footer slot.
</p>

<p>
  Note: when using the modal-footer slot, the default OK and Cancel buttons will not be present. 
  Also, if you use the modal-header slot, the default header X close button will not be present, nor can you use the modal-title slot.
</p>

<p class="m-40">
  Modals will not render their content in the document until they are shown (lazily rendered).
  Modals, when visible, are rendered appended to the <code class="text-nowrap" translate="no">&lt;body&gt;</code> element. 
  The placement of the <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component will not affect layout, as it always renders as a placeholder comment node (<!---->). 
  You can revert to the behaviour of older BootstrapVue versions via the use of the static prop.
</p>

<h2>Toggle modal visibility</h2>
<p class="m-40">
  There are several methods that you can employ to toggle the visibility of<code class="text-nowrap" translate="no">&lt;b-modal&gt;</code>.
</p>

<h3><span class="bd-content-title">Using <code class="text-nowrap" translate="no">v-b-modal</code> directive<a class="anchorjs-link" href="#using-v-b-modal-directive" aria-labelledby="using-v-b-modal-directive"></a></span></h3>
<p class="m-30">Other elements can easily show modals using the <code class="text-nowrap" translate="no">v-b-modal</code> directive.</p>
<div>

  <div class="trendy-example">
      <!-- Using modifiers -->
      <app-button variant="primary" v-b-modal.my-modal>Show Modal</app-button>

      <!-- Using value -->
      <app-button variant="primary" v-b-modal="'my-modal'">Show Modal</app-button>
  </div>


  <!-- The modal -->
  <b-modal variant="primary" id="my-modal">Hello From My Modal!</b-modal>
</div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <!-- Using modifiers -->
  <app-button variant="primary" v-b-modal.my-modal>Show Modal</app-button>

  <!-- Using value -->
  <app-button variant="primary" v-b-modal="'my-modal'">Show Modal</app-button>

  <!-- The modal -->
  <b-modal variant="primary" id="my-modal">Hello From My Modal!</b-modal>
</div></script></code></pre>

<p class="m-40">This approach will automatically return focus to the trigger element once the modal closes (similar to default Bootstrap functionality). Other approaches for toggling modal visibility may require additional code to implement this accessibility feature.</p>

<h3><span class="bd-content-title">Using <code class="text-nowrap" translate="no">this.$bvModal.show()</code> and <code class="text-nowrap" translate="no">this.$bvModal.hide()</code> instance methods<a class="anchorjs-link" href="#using-thisbvmodalshow-and-thisbvmodalhide-instance-methods" aria-labelledby="using-thisbvmodalshow-and-thisbvmodalhide-instance-methods"></a></span></h3>
      <p class="m-30">
        When BootstrapVue is installed as a plugin, or the <code class="text-nowrap" translate="no">ModalPlugin</code> plugin is used, 
        BootstrapVue will inject a <code class="text-nowrap" translate="no">$bvModal</code> object into every Vue instance (components, apps). 
        <code class="text-nowrap" translate="no">this.$bvModal</code> exposes several methods, of which two are for showing and hiding modals:
      </p>

      
      <div class="m-30">
        <b-table-simple hover responsive>
          <b-thead>
            <b-tr>
              <b-th>Method</b-th>
              <b-th>Description</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">this.$bvModal.show(id)</code></b-td>
              <b-td>Show the modal with the specified <code class="text-nowrap" translate="no">id</code></b-td>
            </b-tr>
            <b-tr>
              <b-td><code class="text-nowrap" translate="no">this.$bvModal.hide(id)</code></b-td>
              <b-td>Hide the modal with the specified <code class="text-nowrap" translate="no">id</code></b-td>
            </b-tr>
          </b-tbody>

        </b-table-simple>
      </div>

  <app-button variant="primary" id="show-btn" @click="$bvModal.show('bv-modal-example')">Open Modal</app-button>

  <b-modal id="bv-modal-example" hide-footer>
    <template #modal-title>
      Using <code>$bvModal</code> Methods
    </template>
    <div class="d-block text-center">
      <h3>Hello From This Modal!</h3>
    </div>
    <app-button variant="primary" class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</app-button>
  </b-modal>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" id="show-btn" @click="$bvModal.show('bv-modal-example')">Open Modal</app-button>

  <b-modal id="bv-modal-example" hide-footer>
    <template #modal-title>
      Using <code>$bvModal</code> Methods
    </template>
    <div class="d-block text-center">
      <h3>Hello From This Modal!</h3>
    </div>
    <app-button variant="primary" class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</app-button>
  </b-modal>
</div></script></code></pre>

<p class="m-40"><strong>Note:</strong> It is recommended to use the <code class="text-nowrap" translate="no">this.$bvModal.show()</code> and <code class="text-nowrap" translate="no">this.$bvModal.hide()</code> methods (mentioned in the previous section) instead of using <code class="text-nowrap" translate="no">$ref</code> methods.</p>

<h3><span class="bd-content-title">Using <code class="text-nowrap" translate="no">show()</code>, <code class="text-nowrap" translate="no">hide()</code>, and <code class="text-nowrap" translate="no">toggle()</code> component methods<a class="anchorjs-link" href="#using-show-hide-and-toggle-component-methods" aria-labelledby="using-show-hide-and-toggle-component-methods"></a></span></h3>
    <p class="m-30">
      You can access modal using <code class="text-nowrap" translate="no">ref</code> attribute and then call the <code class="text-nowrap" translate="no">show()</code>, 
      <code class="text-nowrap" translate="no">hide()</code> or <code class="text-nowrap" translate="no">toggle()</code> methods.
    </p>
  
  <div>

    <div class="trendy-example">
        <app-button variant="primary" id="show-btn" @click="showModal">Open Modal</app-button>
        <app-button variant="primary" id="toggle-btn" @click="toggleModal">Toggle Modal</app-button>
    </div>


    <b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <app-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</app-button>
      <app-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</app-button>
    </b-modal>
  </div>

 <pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div>
    <app-button variant="primary" id="show-btn" @click="showModal">Open Modal</app-button>
    <app-button variant="primary" id="toggle-btn" @click="toggleModal">Toggle Modal</app-button>

    <b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <app-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</app-button>
      <app-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</app-button>
    </b-modal>
  </div>
</div></script></code></pre>

      <p class="m-40">
        The <code class="text-nowrap" translate="no">hide()</code> method accepts an optional string <code class="text-nowrap" translate="no">trigger</code>
        argument for defining what triggered the modal to close. See section <a href="#prevent-closing" class="font-weight-bold">Prevent Closing</a> below for details.
        </p>

      <h3><span class="bd-content-title">Using <code class="text-nowrap" translate="no">v-model</code> property<a class="anchorjs-link" href="#using-v-model-property" aria-labelledby="using-v-model-property"></a></span></h3>
      <p class="m-30">
        <code class="text-nowrap" translate="no">v-model</code> property is always automatically synced with 
        <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> visible state and you can show/hide using <code class="text-nowrap" translate="no">v-model</code>.
      </p>
        
        <div>
          <app-button variant="primary" @click="modalShow = !modalShow">Open Modal</app-button>

          <b-modal v-model="modalShow">Hello From Modal!</b-modal>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><template>
  <div>
    <b-button @click="modalShow = !modalShow">Open Modal</b-button>
    <b-modal v-model="modalShow">Hello From Modal!</b-modal>
  </div>
 </template></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        modalShow: false
      }
    }
  }
&lt;/script&gt;
</code></pre>

      <p class="m-40">When using the <code class="text-nowrap" translate="no">v-model</code> prop, <strong>do not</strong> use the <code class="text-nowrap" translate="no">visible</code> prop at the same time.</p>

      <h3><span class="bd-content-title">Emitting events on $root<a class="anchorjs-link" href="#emitting-events-on-root" aria-labelledby="emitting-events-on-root"></a></span></h3>
      <p class="m-40">
        You can emit <code class="text-nowrap" translate="no">bv::show::modal</code>, <code class="text-nowrap" translate="no">bv::hide::modal</code>, and 
        <code class="text-nowrap" translate="no">bv::toggle::modal</code> events on <code class="text-nowrap" translate="no">$root</code> 
        with the first argument set to the modal's id. An optional second argument can specify the element to return focus to once the modal is closed. 
        The second argument can be a CSS selector, an element reference, or a component reference (the root element of the component will be focused).
      </p>

      <h3>Prevent closing</h3>
      <p class="m-40">
        To prevent <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> from closing (for example when validation fails). you can call the 
        <code class="text-nowrap" translate="no">.preventDefault()</code> method of the event object passed to your <code class="text-nowrap" translate="no">ok</code> 
        (<strong>OK</strong> button), <code class="text-nowrap" translate="no">cancel</code> (<strong>Cancel</strong> button), <code class="text-nowrap" translate="no">close</code> 
        (modal header close button) and <code class="text-nowrap" translate="no">hide</code> event handlers. <br> Note that <code class="text-nowrap" translate="no">.preventDefault()</code>, 
        when used, <strong>must</strong> be called synchronously, as async is not supported.
      </p>

      <h2 class="m-30">Modal content</h2>
      <h3>Using the grid</h3>
      <p class="m-40">
        Utilize the Bootstrap grid system within a modal by nesting <code class="text-nowrap" translate="no">&lt;b-container fluid&gt;</code> within the modal-body. 
        Then, use the normal grid system <code class="text-nowrap" translate="no">&lt;b-row&gt;</code> (or <code class="text-nowrap" translate="no">&lt;b-form-row&gt;</code>) 
        and <code class="text-nowrap" translate="no">&lt;b-col&gt;</code> as you would anywhere else.
      </p>

      <h3>Tooltips and popovers</h3>
      <p class="m-40">
        Tooltips and popovers can be placed within modals as needed. When modals are closed, any tooltips and popovers within are also automatically dismissed. 
        Tooltips and popovers are automatically appended to the modal element (to ensure correct z-indexing), although you can override where they are appended by specifying a container ID (refer to tooltip and popover docs for details).
      </p>

      <h2>Lazy loading and static modals</h2>
      <p>
        By default, modals will not render their content in the document until they are shown (lazily rendered). Modals that, when visible, are rendered appended to the 
        <code class="text-nowrap" translate="no">&lt;body&gt;</code> element. The <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component will not affect layout, 
        as they render as a placeholder comment node (<code class="text-nowrap" translate="no">&lt;!----&gt;</code>) in the DOM position they are placed. 
        Due to the portalling process, it can take two or more <code class="text-nowrap" translate="no">$nextTick</code>s to render changes of the content into the target.
      </p>

      <p>
        Modals can be rendered <em>in-place</em> in the document (i.e. where the <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component is placed in the document) 
        by setting the <code class="text-nowrap" translate="no">static</code> prop to <code class="text-nowrap" translate="no">true</code>. 
        Note that the content of the modal will be rendered in the DOM even if the modal is not visible/shown when <code class="text-nowrap" translate="no">static</code> is 
        <code class="text-nowrap" translate="no">true</code>. To make <code class="text-nowrap" translate="no">static</code> modals lazy rendered, also set the 
        <code class="text-nowrap" translate="no">lazy</code> prop to <code class="text-nowrap" translate="no">true</code>. The modal will then appear in the document <em>only</em> when it is visible. 
        Note, when in <code class="text-nowrap" translate="no">static</code> mode, placement of the 
        <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component <em>may affect layout</em> of your document and the modal.
      </p>

      <p class="m-40">
        The <code class="text-nowrap" translate="no">lazy</code> prop will have no effect if the prop <code class="text-nowrap" translate="no">static</code> is not 
        <code class="text-nowrap" translate="no">true</code> (non-static modals will <em>always</em> be lazily rendered).
      </p>

      <h2 class="m-30">Styling, options and customization</h2>
      <h3>Modal sizing</h3>
      <p class="m-30">
        Modals have three optional sizes, available via the prop <code class="text-nowrap" translate="no">size</code>. 
        These sizes kick in at certain breakpoints to avoid horizontal scrollbars on narrower viewports. Valid optional sizes are 
        <code class="text-nowrap" translate="no">sm</code>, <code class="text-nowrap" translate="no">lg</code>, and <code class="text-nowrap" translate="no">xl</code>.
      </p>

      <div>

        <div class="trendy-example">
            <app-button v-b-modal.modal-xl variant="primary">xl modal</app-button>
            <app-button v-b-modal.modal-lg variant="primary">lg modal</app-button>
            <app-button v-b-modal.modal-sm variant="primary">sm modal</app-button>
        </div>


        <b-modal id="modal-xl" size="xl" title="Extra Large Modal">Hello Extra Large Modal!</b-modal>
        <b-modal id="modal-lg" size="lg" title="Large Modal">Hello Large Modal!</b-modal>
        <b-modal id="modal-sm" size="sm" title="Small Modal">Hello Small Modal!</b-modal>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button v-b-modal.modal-xl variant="primary">xl modal</app-button>
  <app-button v-b-modal.modal-lg variant="primary">lg modal</app-button>
  <app-button v-b-modal.modal-sm variant="primary">sm modal</app-button>

  <b-modal id="modal-xl" size="xl" title="Extra Large Modal">Hello Extra Large Modal!</b-modal>
  <b-modal id="modal-lg" size="lg" title="Large Modal">Hello Large Modal!</b-modal>
  <b-modal id="modal-sm" size="sm" title="Small Modal">Hello Small Modal!</b-modal>
</div></script></code></pre>

      <p class="m-40">The <code class="text-nowrap" translate="no">size</code> prop maps the size to the <code class="text-nowrap" translate="no">.modal-&lt;size&gt;</code> classes.</p>

      <h3>Scrolling long content</h3>
      <p class="m-30">When modals become too long for the user's viewport or device, they scroll independent of the page itself. Try the demo below to see what we mean.</p>

      <div>
        <app-button variant="primary" v-b-modal.modal-tall>Launch overflowing modal</app-button>

        <b-modal id="modal-tall" title="Overflowing Content">
          <p class="my-4" v-for="i in 20" :key="i">
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </p>
        </b-modal>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <app-button variant="primary" v-b-modal.modal-tall>Launch overflowing modal</app-button>

    <b-modal id="modal-tall" title="Overflowing Content">
      <p class="my-4" v-for="i in 20" :key="i">
        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
        in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
      </p>
    </b-modal>
</div></script></code></pre>

        <p class="m-30">
          You can also create a scrollable modal that allows the scrolling of the modal body by setting the prop 
          <code class="text-nowrap" translate="no">scrollable</code> to <code class="text-nowrap" translate="no">true</code>.
        </p>

        <div>
          <app-button variant="primary" v-b-modal.modal-scrollable>Launch scrolling modal</app-button>

          <b-modal id="modal-scrollable" scrollable title="Scrollable Content">
            <p class="my-4" v-for="i in 20" :key="i">
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
              in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </p>
          </b-modal>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" v-b-modal.modal-scrollable>Launch scrolling modal</app-button>

  <b-modal id="modal-scrollable" scrollable title="Scrollable Content">
    <p class="my-4" v-for="i in 20" :key="i">
      Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
      in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
    </p>
  </b-modal>
</div></script></code></pre>

        <h3>Vertically centered modal</h3>
        <p class="m-30">Vertically center your modal in the viewport by setting the <code class="text-nowrap" translate="no">centered</code> prop.</p>

        <div>
          <app-button variant="primary" v-b-modal.modal-center>Launch centered modal</app-button>

          <b-modal id="modal-center" centered title="BootstrapVue">
            <p class="my-4">Vertically centered modal!</p>
          </b-modal>
        </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" v-b-modal.modal-center>Launch centered modal</app-button>

  <b-modal id="modal-center" centered title="BootstrapVue">
    <p class="my-4">Vertically centered modal!</p>
  </b-modal>
</div></script></code></pre>

        <p class="m-40">Feel free to mix vertically <code class="text-nowrap" translate="no">centered</code> with <code class="text-nowrap" translate="no">scrollable</code>.</p>

        <h2>Variants</h2>
        <p>
          Control the header, footer, and body background and text variants by setting the <code class="text-nowrap" translate="no">header-bg-variant</code>, 
          <code class="text-nowrap" translate="no">header-text-variant</code>, <code class="text-nowrap" translate="no">body-bg-variant</code>, 
          <code class="text-nowrap" translate="no">body-text-variant</code>, <code class="text-nowrap" translate="no">footer-bg-variant</code>, and 
          <code class="text-nowrap" translate="no">footer-text-variant</code> props. Use any of the standard Bootstrap variants such as 
          <code class="text-nowrap" translate="no">danger</code>, <code class="text-nowrap" translate="no">warning</code>, <code class="text-nowrap" translate="no">info</code>, 
          <code class="text-nowrap" translate="no">success</code>, <code class="text-nowrap" translate="no">dark</code>, <code class="text-nowrap" translate="no">light</code>, etc.
        </p>
        <p class="m-30">
          The variants for the bottom border of the header and top border of the footer can be controlled by the 
          <code class="text-nowrap" translate="no">header-border-variant</code> and <code class="text-nowrap" translate="no">footer-border-variant</code> props respectively.
        </p>

          <div class="m-40">
            <app-button @click="show=true" variant="primary">Show Modal</app-button>

            <b-modal
              v-model="show"
              title="Modal Variants"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
              :body-bg-variant="bodyBgVariant"
              :body-text-variant="bodyTextVariant"
              :footer-bg-variant="footerBgVariant"
              :footer-text-variant="footerTextVariant"
            >
              <b-container fluid>
                <b-row class="mb-1 text-center">
                  <b-col cols="3"></b-col>
                  <b-col>Background</b-col>
                  <b-col>Text</b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col cols="3">Header</b-col>
                  <b-col>
                    <b-form-select
                      v-model="headerBgVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="headerTextVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col cols="3">Body</b-col>
                  <b-col>
                    <b-form-select
                      v-model="bodyBgVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="bodyTextVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="3">Footer</b-col>
                  <b-col>
                    <b-form-select
                      v-model="footerBgVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="footerTextVariant"
                      :options="variants"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>

              <template #modal-footer>
                <div class="w-100">
                  <p class="float-left">Modal Footer Content</p>
                  <app-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="show=false"
                  >
                    Close
                  </app-button>
                </div>
              </template>
            </b-modal>
          </div>

          <h3>Hiding the backdrop</h3>
          <p class="m-30">Hide the modal's backdrop via setting the <code class="text-nowrap" translate="no">hide-backdrop</code> prop.</p>

          <div>
            <app-button variant="primary" v-b-modal.modal-no-backdrop>Open modal</app-button>

            <b-modal id="modal-no-backdrop" hide-backdrop content-class="shadow" title="BootstrapVue">
              <p class="my-2">
                We've added the utility class <code>'shadow'</code>
                to the modal content for added effect.
              </p>
            </b-modal>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" v-b-modal.modal-no-backdrop>Open modal</app-button>

  <b-modal id="modal-no-backdrop" hide-backdrop content-class="shadow" title="BootstrapVue">
    <p class="my-2">
      We've added the utility class <code>'shadow'</code>
      to the modal content for added effect.
    </p>
  </b-modal>
</div></script></code></pre>

          <p class="m-40">
            Note that clicking outside of the modal will still close the modal even though the backdrop is hidden. You can disable this behaviour by setting the 
            <code class="text-nowrap" translate="no">no-close-on-backdrop</code> prop on <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code>.
          </p>

          <h3>Disable open and close animation</h3>
          <p class="m-40">
            To disable the fading transition/animation when modal opens and closes, just set the prop 
            <code class="text-nowrap" translate="no">no-fade</code> on the <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component.
          </p>

          <h3>Footer button sizing</h3>
          <p class="m-30">
            Fancy smaller or larger buttons in the default footer? Simply set the <code class="text-nowrap" translate="no">button-size</code> prop to 
            <code class="text-nowrap" translate="no">'sm'</code> for small buttons, or <code class="text-nowrap" translate="no">'lg'</code> for larger buttons.
          </p>

          <div>

            <div class="trendy-example">
                <app-button variant="primary" v-b-modal.modal-footer-sm>Small Footer Buttons</app-button>
                <app-button variant="primary" v-b-modal.modal-footer-lg>Large Footer Buttons</app-button>
            </div>

            <b-modal id="modal-footer-sm" title="BootstrapVue" button-size="sm">
              <p class="my-2">This modal has small footer buttons</p>
            </b-modal>

            <b-modal id="modal-footer-lg" title="BootstrapVue" button-size="lg">
              <p class="my-2">This modal has large footer buttons</p>
            </b-modal>
          </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" v-b-modal.modal-footer-sm>Small Footer Buttons</app-button>
  <app-button variant="primary" v-b-modal.modal-footer-lg>Large Footer Buttons</app-button>

  <b-modal id="modal-footer-sm" title="BootstrapVue" button-size="sm">
    <p class="my-2">This modal has small footer buttons</p>
  </b-modal>

  <b-modal id="modal-footer-lg" title="BootstrapVue" button-size="lg">
    <p class="my-2">This modal has large footer buttons</p>
  </b-modal>
</div></script></code></pre>

          <p class="m-40">
            The prop <code class="text-nowrap" translate="no">button-size</code> has no effect if you have provided your own footer via the 
            <a href="#custom-rendering-with-slots" class="font-weight-bold"><code class="text-nowrap" translate="no">modal-footer</code></a> slot.
          </p>

          <h3>Disabling built-in footer buttons</h3>
          <p>You can disable the built-in footer buttons programmatically.</p>
          <p>
            You can disable the <strong>Cancel</strong> and <strong>OK</strong> buttons individually by setting the <code class="text-nowrap" translate="no">cancel-disabled</code> and 
            <code class="text-nowrap" translate="no">ok-disabled</code> props, respectively, to <code class="text-nowrap" translate="no">true</code>. 
            Set the prop to <code class="text-nowrap" translate="no">false</code> to re-enable the button.
          </p>

          <p class="m-40">
            To disable both <strong>Cancel</strong> and <strong>OK</strong> buttons at the same time, simply set the <code class="text-nowrap" translate="no">busy</code> prop to 
            <code class="text-nowrap" translate="no">true</code>. Set it to <code class="text-nowrap" translate="no">false</code> to re-enable both buttons.
          </p>

          <h3>Custom rendering with slots</h3>
          <p class="m-40"><code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> provides several named slots (of which some are optionally scoped) that you can use to customize the content of various sections of the modal.</p>

          <h3 class="m-30">Example modal using custom scoped slots</h3>

          <app-button variant="primary" @click="$bvModal.show('modal-scoped')">Open Modal</app-button>

            <b-modal id="modal-scoped">
              <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <app-button size="sm" variant="outline-danger" @click="close()">
                  Close Modal
                </app-button>
                <h5>Modal Header</h5>
              </template>

              <template #default="{ hide }">
                <p>Modal Body with button</p>
                <app-button variant="primary" @click="hide()">Hide Modal</app-button>
              </template>

              <template #modal-footer="{ ok, cancel, hide }">
                <b>Custom Footer</b>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="ok()">
                  OK
                </b-button>
                <app-button size="sm" variant="danger" @click="cancel()">
                  Cancel
                </app-button>
                <!-- Button with custom close trigger value -->
                <app-button size="sm" variant="outline-secondary" @click="hide('forget')">
                  Forget it
                </app-button>
              </template>
            </b-modal>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" @click="$bvModal.show('modal-scoped')">Open Modal</app-button>

  <b-modal id="modal-scoped">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <app-button size="sm" variant="outline-danger" @click="close()">
        Close Modal
      </app-button>
      <h5>Modal Header</h5>
    </template>

    <template #default="{ hide }">
      <p>Modal Body with button</p>
      <app-button variant="primary" @click="hide()">Hide Modal</app-button>
    </template>

    <template #modal-footer="{ ok, cancel, hide }">
      <b>Custom Footer</b>
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="ok()">
        OK
      </b-button>
      <app-button size="sm" variant="danger" @click="cancel()">
        Cancel
      </app-button>
      <!-- Button with custom close trigger value -->
      <app-button size="sm" variant="outline-secondary" @click="hide('forget')">
        Forget it
      </app-button>
    </template>
  </b-modal>
</div></script></code></pre>


            <h3>Multiple modal support</h3>
            <p>Unlike native Bootstrap v4, BootstrapVue supports multiple modals opened at the same time.</p>
            <p class="m-30">
              To disable stacking for a specific modal, just set the prop <code class="text-nowrap" translate="no">no-stacking</code> on the 
              <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component. This will hide the modal before another modal is shown.
            </p>

            <div>
              <app-button variant="primary" v-b-modal.modal-multi-1>Open First Modal</app-button>

              <b-modal id="modal-multi-1" size="lg" title="First Modal" ok-only no-stacking>
                <p class="my-2">First Modal</p>
                <app-button variant="primary" v-b-modal.modal-multi-2>Open Second Modal</app-button>
              </b-modal>

              <b-modal id="modal-multi-2" title="Second Modal" ok-only>
                <p class="my-2">Second Modal</p>
                <app-button variant="primary" v-b-modal.modal-multi-3 size="sm">Open Third Modal</app-button>
              </b-modal>

              <b-modal id="modal-multi-3" size="sm" title="Third Modal" ok-only>
                <p class="my-1">Third Modal</p>
              </b-modal>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button variant="primary" v-b-modal.modal-multi-1>Open First Modal</app-button>

  <b-modal id="modal-multi-1" size="lg" title="First Modal" ok-only no-stacking>
    <p class="my-2">First Modal</p>
    <app-button variant="primary" v-b-modal.modal-multi-2>Open Second Modal</app-button>
  </b-modal>

  <b-modal id="modal-multi-2" title="Second Modal" ok-only>
    <p class="my-2">Second Modal</p>
    <app-button variant="primary" v-b-modal.modal-multi-3 size="sm">Open Third Modal</app-button>
  </b-modal>

  <b-modal id="modal-multi-3" size="sm" title="Third Modal" ok-only>
    <p class="my-1">Third Modal</p>
  </b-modal>              
</div></script></code></pre>

            <p><strong>Notes:</strong></p>
            <ul class="m-40"> 
              <li>
                Avoid nesting a <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> <em>inside</em> another 
                <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code>, as it may get "constrained" 
                to the boundaries of the parent modal dialog (specifically when static modals are used).
             </li> 
             <li>
               The opaque backdrop will appear progressively darker for each modal that is opened. 
               This is expected behaviour as each backdrop is opened over top the other modals and backdrops.
              </li> 
            </ul>

            <h3>Modal message boxes</h3>
            <p>
              BootstrapVue provides a few built in Message Box methods on the exposed <code class="text-nowrap" translate="no">this.$bvModal</code> object. 
              These methods provide a way to generate simple OK and Confirm style modal messages, from anywhere in your app without having to explicitly place a 
              <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> component in your pages.
            </p>

            <p>
              The <code class="text-nowrap" translate="no">options</code> argument is an optional configuration object for adding titles and styling the Message Box modal. 
              The object properties correspond to <code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> props, except in <samp class="notranslate" translate="no">camelCase</samp> 
              format instead of <samp class="notranslate" translate="no">kebab-case</samp>.
            </p>
            <p>
              Both methods return a <code class="text-nowrap" translate="no">Promise</code> (requires a polyfill for IE 11 and older browser support) which resolve into a value when the modal hides. 
              <code class="text-nowrap" translate="no">.msgBoxOk()</code> always resolves to the value <code class="text-nowrap" translate="no">true</code>, while 
              <code class="text-nowrap" translate="no">.msgBoxConfirm()</code> resolves to either <code class="text-nowrap" translate="no">true</code> (OK button pressed), 
              <code class="text-nowrap" translate="no">false</code> (CANCEL button pressed), or <code class="text-nowrap" translate="no">null</code> (if the modal was closed via backdrop click, 
              <kbd class="notranslate" translate="no">Esc</kbd> press, or some other means.
            </p>
            <p>
              If <code class="text-nowrap" translate="no">message</code> is not provided, both methods will return immediately with the value <code class="text-nowrap" translate="no">undefined</code>.
            </p>
            <p class="m-40">
             You can use either the <code class="text-nowrap" translate="no">.then(..).catch(...)</code> or async <code class="text-nowrap" translate="no">await</code> code styles (async 
             <code class="text-nowrap" translate="no">await</code> requires modern browsers or a transpiler).
            </p>

            <h3>OK message box</h3>
            <p class="m-30">Example OK Message boxes</p>
              <div>
                <div class="mb-2">
                <b-button @click="showMsgBoxOne">Simple msgBoxOk</b-button>
                Return value: {{ String(boxOne) }}
                </div>
                <div class="mb-1">
                <b-button @click="showMsgBoxTwo">msgBoxOk with options</b-button>
                Return value: {{ String(boxTwo) }}
                </div>
              </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <div class="mb-2">
  <b-button @click="showMsgBoxOne">Simple msgBoxOk</b-button>
  Return value: {{ String(boxOne) }}
  </div>
  <div class="mb-1">
  <b-button @click="showMsgBoxTwo">msgBoxOk with options</b-button>
  Return value: {{ String(boxTwo) }}
  </div>           
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        boxOne: '',
        boxTwo: ''
      }
    },
    methods: {
      showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxOk('Action completed')
          .then(value => {
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
          })
      },
      showMsgBoxTwo() {
        this.boxTwo = ''
        this.$bvModal.msgBoxOk('Data was submitted successfully', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            this.boxTwo = value
          })
          .catch(err => {
            // An error occurred
          })
      }
    }
  }
&lt;/script&gt;
</code></pre>

      <h3>Confirm message box </h3>
      <p class="m-30">Example Confirm Message boxes</p>

        <div>
          <div class="mb-2">
          <b-button @click="showMessageBoxOne">Simple msgBoxConfirm</b-button>
          Return value: {{ String(firstBox) }}
          </div>
          <div class="mb-1">
          <b-button @click="showMessageBoxTwo">msgBoxConfirm with options</b-button>
          Return value: {{ String(secondBox) }}
          </div>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
    <div class="mb-2">
    <b-button @click="showMessageBoxOne">Simple msgBoxConfirm</b-button>
    Return value: {{ String(firstBox) }}
    </div>
    <div class="mb-1">
    <b-button @click="showMessageBoxTwo">msgBoxConfirm with options</b-button>
    Return value: {{ String(secondBox) }}
    </div>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
   export default {
    data() {
      return {
        boxOne: '',
        boxTwo: ''
      }
    },
    methods: {
      showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
          })
      },
      showMsgBoxTwo() {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            this.boxTwo = value
          })
          .catch(err => {
            // An error occurred
          })
      }
    }
  }
&lt;/script&gt;
</code></pre>


      <h3>Message box advanced usage</h3>
      <p>
        When using the <code class="text-nowrap" translate="no">this.$bvModal.msgBoxOk(...)</code> or 
        <code class="text-nowrap" translate="no">this.$bvModal.msgBoxConfirm(...)</code> methods for generating modals, 
        you may want the modal content to be more than just a string message. As mentioned in the 
        <a href="#message-box-notes" class="font-weight-bold">message box notes</a> section above, you can pass 
        <em>arrays</em> of VNodes as the message and title for more complex content.
      </p>

        <div>
          <b-button @click="showMsgOk">Show OK message box with custom content</b-button>
        </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button @click="showMsgOk">Show OK message box with custom content</b-button>
</div></script></code></pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    methods: {
      showMsgOk() {
        const h = this.$createElement
        // Using HTML string
        const titleVNode = h('div', { domProps: { innerHTML: 'Title from &lt;i&gt;HTML&lt;i&gt; string' } })
        // More complex structure
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('p', { class: ['text-center'] }, [
            ' Flashy ',
            h('strong', 'msgBoxOk'),
            ' message ',
          ]),
          h('p', { class: ['text-center'] }, [h('b-spinner')]),
          h('b-img', {
            props: {
              src: 'https://picsum.photos/id/20/250/250',
              thumbnail: true,
              center: true,
              fluid: true, rounded: 'circle'
            }
          })
        ])
        // We must pass the generated VNodes as arrays
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'sm',
          centered: true, size: 'sm'
        })
      }
    }
  }
&lt;/script&gt;
</code></pre>

      <h2 id="listening-to-modal-changes-via-root-events" class="bv-no-focus-ring"><span class="bd-content-title">Listening to modal changes via $root events<a class="anchorjs-link" href="#listening-to-modal-changes-via-root-events" aria-labelledby="listening-to-modal-changes-via-root-events"></a></span></h2>
      <p>To listen to any modal opening, use:</p>
      
<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    mounted() {
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        console.log('Modal is about to be shown', bvEvent, modalId)
      })
    }
  }
&lt;/script&gt;
</code></pre>

      <h2 id="accessibility" class="bv-no-focus-ring"><span class="bd-content-title">Accessibility<a class="anchorjs-link" href="#accessibility" aria-labelledby="accessibility"></a></span></h2>
      <p><code class="text-nowrap" translate="no">&lt;b-modal&gt;</code> provides several accessibility features, including auto focus, return focus, keyboard (tab) <em>focus containment</em>, and automated <code class="text-nowrap" translate="no">aria-*</code> attributes.</p>

      <p><strong>Note:</strong> The animation effect of this component is dependent on the <code class="text-nowrap" translate="no">prefers-reduced-motion</code> media query. See the <a href="/docs/reference/accessibility" class="font-weight-bold">reduced motion section of our accessibility documentation</a> for additional details.</p>

</div>
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
  name: "ModalsView",
  data(){
    return {
      visible:false,
        modalShow: false,
        show: false,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'warning',
        footerTextVariant: 'dark',
        boxOne: '',
        boxTwo: '',
        firstBox: '',
        secondBox: ''


    }
  },
      methods: {
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
      },
      showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxOk('Action completed')
          .then(value => {
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
          })
      },

      showMsgBoxTwo() {
        this.boxTwo = ''
        this.$bvModal.msgBoxOk('Data was submitted successfully', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            this.boxTwo = value
          })
          .catch(err => {
            // An error occurred
          })
      },

      showMessageBoxOne() {
        this.firstBox = ''
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            this.firstBox = value
          })
          .catch(err => {
            // An error occurred
          })
      },

      showMessageBoxTwo() {
        this.secondBox = ''
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            this.secondBox = value
          })
          .catch(err => {
            // An error occurred
          })
      },

     showMsgOk() {
        const h = this.$createElement
        // Using HTML string
        const titleVNode = h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
        // More complex structure
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('p', { class: ['text-center'] }, [
            ' Flashy ',
            h('strong', 'msgBoxOk'),
            ' message ',
          ]),
          h('p', { class: ['text-center'] }, [h('b-spinner')]),
          h('b-img', {
            props: {
              src: 'https://picsum.photos/id/20/250/250',
              thumbnail: true,
              center: true,
              fluid: true, rounded: 'circle'
            }
          })
        ])
        // We must pass the generated VNodes as arrays
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'sm',
          centered: true, size: 'sm'
        })
      }
    },
  components: {
    AppButton
    },
   mounted() {
    document.title = "Modals";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>

</style>
