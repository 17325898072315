<template>
  <b-input-group class="d-flex-inline mb-4 search">
    <b-input-group-prepend class="search-box" :class="rounded ? 'round' : ''" >
      <inline-svg  :src="SearchIcon"></inline-svg>
    </b-input-group-prepend>
    <b-form-input :placeholder="placeholder" :class="rounded ? 'form-control-rounded' : ''"></b-form-input>
  </b-input-group>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import SearchIcon from "@/assets/images/icons/search-icon.svg"
export default {
  name: "SearchInput",
  props:{
    placeholder:{
      type:String,
      default:"Search for..."
    },
    rounded:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      SearchIcon
    }
  },
  components:{
    InlineSvg
  }
}
</script>

<style scoped>

</style>
