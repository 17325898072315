<template>
<div class="wrapper">
      <title>Charts</title>

  <h1 class="title-section">Charts</h1>
  <p style="font-size: 1.5rem">Vue charts are visual representations of data. They are responsive and easy to customize.</p>
  <p>vue-chartjs is a wrapper for Chart.js in Vue. It enables you to easily create reusable chart components.</p>
  <p class="m-40">Use Charts is provided without much hassle inside Vue. It's perfect for people who need simple charts up and running as fast as possible.</p>

  <h2>Bar chart</h2>
  <p class="m-30">A bar chart provides a way of showing data values represented as vertical bars. It is sometimes used to show trend data, and the comparison of multiple data sets side by side.</p>
  <b-row>
    <b-col class="mb-5">
      <div class="bar-chart">
        <bar-chart></bar-chart>
      </div>

      <div class="bar-chart-2 mt-5">
        <bar-chart-2></bar-chart-2>
      </div>
    </b-col>
  </b-row>

  <h3></h3>

  <h2>Line chart</h2>
  <p class="m-30">A line chart is a way of plotting data points on a line. Often, it is used to show trend data, or the comparison of two data sets.</p>
  <b-row>
    <b-col class="mb-5">
      <div class="line-chart mb-5">
        <line-chart></line-chart>
      </div>

      <div class="line-chart-2">
       <p>ACTIVE USERS</p>
       <h1>46</h1>
      <line-chart-2></line-chart-2>
     </div>
    </b-col>

  </b-row>


  <h2>Doughnut and Pie Charts</h2>
  <p>Pie and doughnut charts are probably the most commonly used charts. They are divided into segments, the arc of each segment shows the proportional value of each piece of data.</p>
  <p>They are excellent at showing the relational proportions between data.</p>
  <p>Pie and doughnut charts are effectively the same class in Chart.js, but have one different default value - their <code>cutout</code>. This equates to what portion of the inner should be cut out. This defaults to <code>0</code> for pie charts, and <code>'50%'</code> for doughnuts.</p>
  <p class="m-30">They are also registered under two aliases in the <code>Chart</code> core. Other than their different default value, and different alias, they are exactly the same.</p>

  <b-row>
    <b-col md="6" class="mb-5">
      <div class="doughnut-chart">
        <chart-doughnut></chart-doughnut>
      </div>
    </b-col>

    <b-col md="6">
      <div class="doughnut-chart">
        <pie-chart></pie-chart>
     </div>
    </b-col>

  </b-row>

      <div class="horizontal-chart mt-5 mb-5">
        <horizontal-bar-chart></horizontal-bar-chart>
     </div>



</div>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import ChartDoughnut from "@/components/charts/ChartDoughnut";
import PieChart from "@/components/charts/PieChart";
import LineChart from "@/components/charts/LineChart";
import BarChart2 from "@/components/charts/BarChart2";
import LineChart2 from "@/components/charts/LineChart2";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart";

export default {
  name: "ChartsView",
  data(){
    return {
      dataCollection:{
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontColor: 'rgb(255, 99, 132)'
          }
        },
        labels: ['Iphone', 'Samsung', 'Huawei','Xiaomi','General Mobile'],

        datasets: [
          {

            backgroundColor: [
              '#5561B3',
              '#161F5E',
              '#4FAC68',
              '#DFCA41',
              '#DF4149',
            ],
            data: [25, 30,10,15,25]
          }
        ]
      },
      dataCollectionBarChart:{
        labels: ['Sun', 'Mon','Tue', 'Wed', 'Thu','Fri','Sat'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#5561B3',
            data: [7, 9,12,9,15,10,12]
          }
        ],
      },
      dataCollectionPieChart:{
        labels: ['Iphone', 'Samsung', 'Huawei','General Mobile'],
        datasets: [
          {
            backgroundColor: [
              '#5561B3',
              '#161F5E',
              '#DFCA41',
              '#DF4149',
            ],
            data: [25, 20,35,20]
          }
        ]
      },
      dataCollectionLineChart:{
        labels: ['02 JAN', '03 JAN', '04 JAN','05 JAN'],
        datasets: [
          {
            fill:true,
            backgroundColor: '#5561B3',
            borderColor: [
              '#5561B3',
            ],
            data: [19, 25,17,18,13,19,16,26,17,20],
            tension: 0.5
          }
        ]
      },

      dataCollectionLineChart2:{
        labels: ['02 JAN', '03 JAN', '04 JAN','05 JAN'],
        datasets: [
          {
            fill:true,
            backgroundColor: '#5561B3',
            borderColor: [
              '#5561B3',
            ],
            data: [19, 25,17,18,13,19,16,26,17,20],
            tension: 0.5
          }
        ]
      },

      dataCollectionBar2:{
      labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN','07 JAN','08 JAN', '09 JAN', '10 JAN', '11 JAN'],
        datasets: [
          {
            label: 'Data aaaaaaaaaaaaaaaaaaaaaaa',
            backgroundColor: '#5561B3',
            data: [48, 65, 41, 82, 45, 48, 65, 41, 82, 45],
            borderColor: 'rgb(0,0,0)'
          }
        ],

        
      }
    }
  },
  components:{
    LineChart,
    ChartDoughnut,
    BarChart,
    PieChart,
    BarChart2,
    LineChart2,
    HorizontalBarChart
  },

  props: {
    chartData: {
      type: Object,
      default: null
    },
    },

  mounted() {
        document.title = "Charts";


    

  },

  methods: {

  }

}
</script>

<style  scoped>

</style>
