<template>
  <div class="wrapper">
      <title>Breadcrumbs</title>

    <h1 class="title-section">Breadcrumbs</h1>
    <p class="m-40" style="font-size: 1.5rem">Indicate the current page's location within a navigational hierarchy. Separators are automatically added in CSS through <code class="notranslate" translate="no">::before</code> and <code class="notranslate" translate="no">content</code>.</p>
    <h2>Overview</h2>
    
    <b-breadcrumb :items="items"></b-breadcrumb>    


<pre class="code-toolbar" data-copy-success=""><code class="language-markup"><script type="prism-html-markup"> <template>
  <b-breadcrumb :items="items"></b-breadcrumb>
 </template></script></code>
</pre>

<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    data() {
      return {
        items: [
          {
            text: 'Home',
            href: '#'
          },
          {
            text: 'Library',
          },
          {
            text: 'Data'
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre>
    <h2>Breadcrumb items</h2>
    <p class="m-30">
      Items are rendered using <code class="text-nowrap" translate="no">:items</code> prop. 
      It can be an array of objects to provide link and active state. Links can be 
      <code class="text-nowrap" translate="no">href</code>'s for anchor tags, or <code class="text-nowrap" translate="no">to</code>'s for router-links. 
      Active state of last element is automatically set if it is <code class="text-nowrap" translate="no">undefined</code>.
    </p>


<pre><code class="language-javascript" data-label="Copy"><script type="prism-html-markup">
  const items = [
    {
      text: 'Home',
      href: 'https://google.com'
    },
    {
      text: 'Library',
      to: { name: 'library' }
    },
    {
      text: 'Data',
      active: true
    }
  ]
</script></code></pre>

    <h2>Manually placed items</h2>
    <p class="m-30">
      You may also manually place individual <code class="text-nowrap" translate="no">&lt;b-breadcrumb-item&gt;</code>
      child components in the default slot of the <code class="text-nowrap" translate="no">&lt;b-breadcrumb&gt;</code> component, 
      as an alternative to using the <code class="text-nowrap" translate="no">items</code> prop, for greater control over the content of each item:
    </p>


      <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
      </b-breadcrumb>
      <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
        <b-breadcrumb-item active>Library</b-breadcrumb-item>
    </b-breadcrumb>
      <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
        <b-breadcrumb-item href="#">Library</b-breadcrumb-item>
        <b-breadcrumb-item href="#">Data</b-breadcrumb-item>
    </b-breadcrumb>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"> <template>
    <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
    </b-breadcrumb>

    <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
        <b-breadcrumb-item active>Library</b-breadcrumb-item>
    </b-breadcrumb>

    <b-breadcrumb>
        <b-breadcrumb-item href="#">Home</b-breadcrumb-item>
        <b-breadcrumb-item href="#">Library</b-breadcrumb-item>
        <b-breadcrumb-item href="#">Data</b-breadcrumb-item>
    </b-breadcrumb>
 </template></script></code></pre>


      <p>
        Remember to set the <code class="text-nowrap" translate="no">active</code> prop on the last item.
      </p>
      <p>
        <code class="text-nowrap" translate="no">&lt;b-breadcrumb-item&gt;</code> also supports the various 
        <code class="text-nowrap" translate="no">&lt;router-link&gt;</code> props such as 
        <code class="text-nowrap" translate="no">to</code>, etc.
      </p>


  </div>


</template>

<script>
export default {
  name: "BreadcrumbView",
  data(){
    return {
      items:[
        {
          text: 'Home',
          href: '#',

        },
        {
          text: 'Library',
        },
        {
          text: 'Data',

        }
      ]
    }
  },
  mounted() {
    document.title = "Breadcrumb";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
