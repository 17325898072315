<template>
  <div class="wrapper">
              <title>Tooltip</title>

    <b-container fluid>

      <h1 class="title-section">Tooltips</h1>
      <p class="m-40" style="font-size: 1.5rem">Easily add tooltips to elements or components via the <code class="text-nowrap" translate="no">&lt;b-tooltip&gt;</code> component or v-b-tooltip directive (preferred method).</p>
      
      <h2>Positioning</h2>
      <p class="m-30">Four options are available for positioning: top, bottom, right and left. Positioning is relative to the trigger element.</p>

      <b-row>
        <b-col>

          <div class="trendy-example">
              <b-button class="pull-right"  v-b-tooltip.hover.left="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on left</b-button>
              <b-button class="pull-left"  v-b-tooltip.hover.right="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on right</b-button>
          </div>

         </b-col>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-button class="pull-right"  v-b-tooltip.click.left="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on left</b-button>
  <b-button class="pull-left"  v-b-tooltip.hover.right="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on right</b-button>
</div></script></code></pre>  

      <b-row>
        <b-col>

          <div class="trendy-example">
              <b-button class="pull-left"  v-b-tooltip.hover.top="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on top</b-button>
              <b-button class="pull-right"  v-b-tooltip.hover.bottom="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"  variant="primary">Tooltip on bottom</b-button>
          </div>
        </b-col>
      </b-row>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
 <b-button class="pull-left" v-b-tooltip.hover.top="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'" variant="primary">Tooltip on top</b-button>
 <b-button class="pull-right" v-b-tooltip.hover.bottom="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'" variant="primary">Tooltip on bottom</b-button>
</div></script></code></pre>  


    <b-row>
      <b-col>
        <h2>Overview</h2>
        <p>Things to know when using popover component:</p>
        <ul class="m-40"> 
          <li>
            Popovers rely on the 3rd party library <a href="https://popper.js.org/" target="_blank" rel="noopener">Popper.js</a> for positioning.
          </li>
          <li>
            Popovers require BootstrapVue's custom SCSS/CSS in order to function correctly, and for variants.
          </li> 
          <li>
            Specify <code class="text-nowrap" translate="no">container</code> as <code class="text-nowrap" translate="no">null</code> (default, appends to <code class="text-nowrap" translate="no">&lt;body&gt;</code>) to avoid rendering problems in more complex components (like input groups, button groups, etc.). You can use <code class="text-nowrap" translate="no">container</code> to optionally specify a different element to append the rendered popover to.
          </li> 
          <li>
            Triggering popovers on hidden elements will not work.
          </li> 
          <li>
            Popovers for <code class="text-nowrap" translate="no">disabled</code> elements must be triggered on a wrapper element.
          </li>
          <li>
            When triggered from hyperlinks that span multiple lines, popovers will be centered. Use <code class="text-nowrap" translate="no">white-space: nowrap;</code> on your <code class="text-nowrap" translate="no">&lt;a&gt;</code>s, <code class="text-nowrap" translate="no">&lt;b-link&gt;</code>s and <code class="text-nowrap" translate="no">&lt;router-link&gt;</code>s to avoid this behavior.
          </li> 
        </ul>

        <h2>Targets</h2>
        <p>
          The target is the <em>trigger</em> element (or component) that will trigger the tooltip. The target is specified via the 
          <code class="text-nowrap" translate="no">target</code> prop, and can be any of the following:
        </p>

        <ul> 
          <li>
            A string identifying the ID of the trigger element (or ID of the root element of a component)
          </li> 
          <li>
            A reference (ref) to an <code class="text-nowrap" translate="no">HTMLElement</code> or an 
            <code class="text-nowrap" translate="no">SVGElement</code> (e.g. <code class="text-nowrap" translate="no">this.$refs.refName</code>)
          </li> 
          <li>A reference (ref) to a component that has either an <code class="text-nowrap" translate="no">HTMLElement</code> or 
              <code class="text-nowrap" translate="no">SVGElement</code> as its root element (e.g. <code class="text-nowrap" translate="no">this.$refs.refName</code>)
          </li> 
          <li>
            A function (callback) that returns a reference to an <code class="text-nowrap" translate="no">HTMLElement</code> or 
            <code class="text-nowrap" translate="no">SVGElement</code>
          </li> 
        </ul>  
        <p><strong>Note:</strong></p>
        <p>
          The target element <strong>must</strong> exist in the document before <code class="text-nowrap" translate="no">&lt;b-tooltip&gt;</code> is mounted. 
          If the target element is not found during mount, the tooltip will never open. Always place your 
          <code class="text-nowrap" translate="no">&lt;b-tooltip&gt;</code> component lower in the DOM than your target element. 
          This rule also applies if a callback function is used as target element, since that callback is called only once on mount.
        </p>   

        <p class="m-40">
          <code class="text-nowrap" translate="no">HTMLElement</code> refers to standard HTML elements such as 
          <code class="text-nowrap" translate="no">&lt;div&gt;</code>, <code class="text-nowrap" translate="no">&lt;button&gt;</code>, 
          etc, while <code class="text-nowrap" translate="no">SVGElement</code> refers to <code class="text-nowrap" translate="no">&lt;svg&gt;</code>
         or supported child elements of SVGs.
        </p> 

        <h2>Positioning</h2>
        <p class="m-40">
          Twelve options are available for positioning: <code class="text-nowrap" translate="no">top</code>, 
          <code class="text-nowrap" translate="no">topleft</code>, <code class="text-nowrap" translate="no">topright</code>, 
          <code class="text-nowrap" translate="no">right</code>, <code class="text-nowrap" translate="no">righttop</code>, 
          <code class="text-nowrap" translate="no">rightbottom</code>, <code class="text-nowrap" translate="no">bottom</code>, 
          <code class="text-nowrap" translate="no">bottomleft</code>, <code class="text-nowrap" translate="no">bottomright</code>, 
          <code class="text-nowrap" translate="no">left</code>, <code class="text-nowrap" translate="no">lefttop</code>, and 
          <code class="text-nowrap" translate="no">leftbottom</code> aligned. The default position is <code class="text-nowrap" translate="no">top</code>. 
          Positioning is relative to the trigger element.
        </p>

        <h2>Triggers</h2>

        <p>
          Tooltips can be triggered (opened/closed) via any combination of <code class="text-nowrap" translate="no">click</code>, 
          <code class="text-nowrap" translate="no">hover</code> and <code class="text-nowrap" translate="no">focus</code>. 
          The default trigger is <code class="text-nowrap" translate="no">hover focus</code>. 
          Or a trigger of <code class="text-nowrap" translate="no">manual</code> can be specified, where the popover can only be opened or closed 
          <a href="#programmatically-disabling-tooltip" class="font-weight-bold">programmatically</a>.
        </p>

        <p class="m-40">
          If a tooltip has more than one trigger, then all triggers must be cleared before the tooltip will close. 
          I.e. if a tooltip has the trigger <code class="text-nowrap" translate="no">focus click</code>, 
          and it was opened by <code class="text-nowrap" translate="no">focus</code>, and the user then clicks the 
          trigger element, they must click it again <strong>and</strong> move focus to close the tooltip.
        </p>

        <h3>Caveats with focus trigger on button elements</h3>

        <p>
          For proper cross-browser and cross-platform behavior when using only the <code class="text-nowrap" translate="no">focus</code> trigger, 
          you must use an element that renders the <code class="text-nowrap" translate="no">&lt;a&gt;</code> tag, 
          not the <code class="text-nowrap" translate="no">&lt;button&gt;</code> tag, and you also must include a 
          <code class="text-nowrap" translate="no">tabindex="0"</code> attribute.
        </p>
        <p class="m-30">The following will generate an <code class="text-nowrap" translate="no">&lt;a&gt;</code> that looks like a button:</p>
        
        <div class="trendy-example">
            <app-button
                href="#"
                tabindex="0"
                v-b-tooltip.focus
                title="Tooltip title"
                variant="primary"
              >
                Link button with tooltip directive
              </app-button>

              <app-button id="link-button" href="#" tabindex="0" variant="primary">
                Link button with tooltip component
              </app-button>
        </div>

          <b-tooltip target="link-button" title="Tooltip title" triggers="focus" variant="primary">
            Tooltip title
          </b-tooltip>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <app-button
    href="#"
    tabindex="0"
    v-b-tooltip.focus
    title="Tooltip title"
    variant="primary"
  >
    Link button with tooltip directive
  </app-button>

  <app-button id="link-button" href="#" tabindex="0" variant="primary">
    Link button with tooltip component
  </app-button>
  <b-tooltip target="link-button" title="Tooltip title" triggers="focus">
    Tooltip title
  </b-tooltip>
</div></script></code></pre>

            <h3>Making tooltips work for keyboard and assistive tehnology users</h3>
            <p>
              You should only add tooltips to HTML elements that are traditionally keyboard-focusable and interactive 
              (such as links, buttons, or form controls). Although arbitrary HTML elements 
              (such as <code class="text-nowrap" translate="no">&lt;span&gt;</code>s) can be made focusable by adding the 
              <code class="text-nowrap" translate="no">tabindex="0"</code> attribute, this will add potentially annoying and 
              confusing tab stops on non-interactive elements for keyboard users. In addition, most assistive technologies 
              currently do not announce the tooltip in this situation.
            </p>

            <p class="m-40">
              Additionally, do not rely solely on <code class="text-nowrap" translate="no">hover</code> as the trigger for your tooltip, 
              as this will make your tooltips <em>impossible to trigger for keyboard-only users</em>.
            </p>

            <h3>Disabled elements</h3>
            <p class="m-30">
              Elements with the <code class="text-nowrap" translate="no">disabled</code> attribute aren’t interactive, 
              meaning users cannot focus, hover, or click them to trigger a tooltip (or popover). 
              As a workaround, you’ll want to trigger the tooltip from a wrapper <code class="text-nowrap" translate="no">&lt;div&gt;</code> or 
              <code class="text-nowrap" translate="no">&lt;span&gt;</code>, ideally made keyboard-focusable using 
              <code class="text-nowrap" translate="no">tabindex="0"</code>, and override the <code class="text-nowrap" translate="no">pointer-events</code> on the disabled element.
            </p>



            <div class="m-40">
              <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                <app-button variant="primary" style="pointer-events: none;" disabled>Disabled button</app-button>
              </span>
              <b-tooltip target="disabled-wrapper">Disabled tooltip</b-tooltip>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="m-40">
    <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
      <app-button variant="primary" style="pointer-events: none;" disabled>Disabled button</app-button>
    </span>
    <b-tooltip target="disabled-wrapper">Disabled tooltip</b-tooltip>
</div>
  </script></code></pre>

            <h2 id="b-tooltip-component-usage" class="bv-no-focus-ring"><span class="bd-content-title"><code class="text-nowrap" translate="no">&lt;b-tooltip&gt;</code> component usage<a class="anchorjs-link" href="#b-tooltip-component-usage" aria-labelledby="b-tooltip-component-usage"></a></span></h2>
              <b-container fluid>
                <b-row>
                  <b-col md="4" class="py-4">
                    <app-button id="button-1" variant="outline-success">Live chat</app-button>
                  </b-col>
                  <b-col md="4" class="py-4">
                    <app-button id="button-2" variant="outline-success">Html chat</app-button>
                  </b-col>
                  <b-col md="4" class="py-4">
                    <app-button id="button-3" variant="outline-success">Alternative chat</app-button>
                  </b-col>
                </b-row>

                <!-- Tooltip title specified via prop title -->
                <b-tooltip target="button-1" title="Online!"></b-tooltip>

                <!-- HTML title specified via default slot -->
                <b-tooltip target="button-2" placement="bottom">
                  Hello <strong>World!</strong>
                </b-tooltip>

                <!-- Tooltip for an element identified by ref -->
                <b-tooltip  target="button-3" title="Alternative!"></b-tooltip>
              </b-container>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><b-container fluid>
    <b-row>
      <b-col md="4" class="py-4">
        <app-button id="button-1" variant="outline-success">Live chat</app-button>
      </b-col>
      <b-col md="4" class="py-4">
        <app-button id="button-2" variant="outline-success">Html chat</app-button>
      </b-col>
      <b-col md="4" class="py-4">
        <app-button id="button-3" variant="outline-success">Alternative chat</app-button>
      </b-col>
    </b-row>

    <!-- Tooltip title specified via prop title -->
    <b-tooltip target="button-1" title="Online!"></b-tooltip>

    <!-- HTML title specified via default slot -->
    <b-tooltip target="button-2" placement="bottom">
      Hello <strong>World!</strong>
    </b-tooltip>

    <!-- Tooltip for an element identified by ref -->
    <b-tooltip target="button-3" title="Alternative!"></b-tooltip>
</b-container></script></code></pre>


            <h3>Noninteractive tooltips</h3>
            <p class="m-30">
              BootstrapVue's tooltips are user-interactive by default for accessibility reasons. 
              To restore Bootstraps default behavior apply the <code class="text-nowrap" translate="no">noninteractive</code> prop:
            </p>

            <div class="text-center">
              <div class="trendy-example">
                <app-button variant="primary" id="tooltip-button-interactive">My tooltip is interactive</app-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div>

              <div class="mt-3">
                <app-button variant="primary" id="tooltip-button-not-interactive">Mine is not...</app-button>
                <b-tooltip target="tooltip-button-not-interactive" noninteractive>Catch me if you can!</b-tooltip>
              </div>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
    <div>
      <app-button variant="primary" id="tooltip-button-interactive">My tooltip is interactive</app-button>
      <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
    </div>

    <div class="mt-3">
      <app-button variant="primary" id="tooltip-button-not-interactive">Mine is not...</app-button>
      <b-tooltip target="tooltip-button-not-interactive" noninteractive>Catch me if you can!</b-tooltip>
    </div>
</div></script></code></pre>

            <h3>Variants and custom class</h3>
            <p class="m-30">BootstrapVue's tooltips support contextual color variants via our custom CSS, via the <code class="text-nowrap" translate="no">variant</code> prop:</p>
            
            <div class="text-center">
              <app-button variant="primary" id="tooltip-button-variant">Button</app-button>
              <b-tooltip target="tooltip-button-variant" variant="danger">Danger variant tooltip</b-tooltip>
            </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
    <app-button variant="primary" id="tooltip-button-variant">Button</app-button>
    <b-tooltip target="tooltip-button-variant" variant="danger">Danger variant tooltip</b-tooltip>
</div></script></code></pre>

            <p>
              Bootstrap default theme variants are: <code class="text-nowrap" translate="no">danger</code>, 
              <code class="text-nowrap" translate="no">warning</code>, <code class="text-nowrap" translate="no">success</code>, 
              <code class="text-nowrap" translate="no">primary</code>, <code class="text-nowrap" translate="no">secondary</code>, 
              <code class="text-nowrap" translate="no">info</code>, <code class="text-nowrap" translate="no">light</code>, and 
              <code class="text-nowrap" translate="no">dark</code>. You can change or add additional variants via Bootstrap 
              <a href="/docs/reference/theming" class="font-weight-bold">SCSS variables</a>
            </p>
            <p class="m-30">
              A custom class can be applied to the tooltip outer wrapper <code class="text-nowrap" translate="no">&lt;div&gt;</code> 
              by using the <code class="text-nowrap" translate="no">custom-class</code> prop:
            </p>

            <div class="text-center">
              <app-button variant="primary" id="my-button">Button</app-button>
              <b-tooltip target="my-button" custom-class="my-tooltip-class">Tooltip Title</b-tooltip>
            </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
  <app-button variant="primary" id="my-button">Button</app-button>
  <b-tooltip target="my-button" custom-class="my-tooltip-class">Tooltip Title</b-tooltip>
</div></script></code></pre>

            <p>
              <code class="text-nowrap" translate="no">variant</code> and <code class="text-nowrap" translate="no">custom-class</code> 
              are reactive and can be changed while the tooltip is open.
            </p>

            <p class="m-40">
              Refer to the <a href="/docs/directives/tooltip" class="font-weight-bold">tooltip directive</a> docs on applying variants and custom class to the directive version.
            </p>

            <h3>Programmatically show and hide tooltip</h3>
            <p class="m-40">
              You can manually control the visibility of a tooltip via the syncable Boolean <code class="text-nowrap" translate="no">show</code> prop. 
              Setting it to <code class="text-nowrap" translate="no">true</code> will show the tooltip, while setting it to 
              <code class="text-nowrap" translate="no">false</code> will hide the tooltip.
            </p>

              <div class="text-center">
                <div class="trendy-example">
                  <app-button id="tooltip-button-1" variant="primary">I have a tooltip</app-button>
                  <app-button @click="show = !show">Toggle Tooltip</app-button>
                </div>

                <b-tooltip :show.sync="show" target="tooltip-button-1" placement="top">
                  Hello <strong>World!</strong>
                </b-tooltip>
              </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="text-center">
  <div>
    <app-button id="tooltip-button-1" variant="primary">I have a tooltip</app-button>
  </div>

  <div class="mt-3">
    <b-button @click="show = !show">Toggle Tooltip</b-button>
  </div>

  <b-tooltip :show.sync="show" target="tooltip-button-1" placement="top">
    Hello <strong>World!</strong>
  </b-tooltip>
</div></script></code></pre>

              <p class="m-30">
                Programmatic control can also be affected by submitting <code class="text-nowrap" translate="no">'open'</code> and 
                <code class="text-nowrap" translate="no">'close'</code> events to the tooltip by reference.
              </p>

              <div class="d-flex flex-column text-md-center">
                <div class="p-2">
                    <app-button id="tooltip-button-show-event" variant="primary">I have a tooltip</app-button>
                </div>

                <div class="trendy-example">
                  <app-button @click="onOpen">Open</app-button>
                  <app-button @click="onClose">Close</app-button>
                </div>

                <b-tooltip ref="tooltipss" target="tooltip-button-show-event">
                    Hello <strong>World!</strong>
                </b-tooltip>
              </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="d-flex flex-column text-md-center">
    <div class="p-2">
      <app-button id="tooltip-button-show-event" variant="primary">I have a tooltip</app-button>
    </div>

    <div class="p-2">
      <app-button class="px-1" @click="onOpen()">Open</app-button>
      <app-button class="px-1" @click="onClose()">Close</app-button>
    </div>

    <b-tooltip ref="tooltip" target="tooltip-button-show-event">
      Hello <strong>World!</strong>
    </b-tooltip>
</div></script></code></pre>      

              <p class="m-40">
                You can also use <code class="text-nowrap" translate="no">$root</code> events to trigger the showing and hiding of tooltip(s).
                See the <strong>Hiding and showing tooltips via $root events</strong> section below for details.
              </p>

              <h3>Programmatically disabling tooltip</h3>
              <p class="m-30">
                You can disable tooltip via the syncable Boolean prop <code class="text-nowrap" translate="no">disabled</code> 
                (default is <code class="text-nowrap" translate="no">false</code>) Setting it to <code class="text-nowrap" translate="no">true</code> 
                will disable the tooltip. If the tooltip is currently visible when disabled is set to <code class="text-nowrap" translate="no">false</code>, 
                the tooltip will remain visible until it is enabled or programmatically closed. If the tooltip is disabled/enabled via $root events (see below), your 
                <code class="text-nowrap" translate="no">disabled</code> value will be updated as long as you have provided the <code class="text-nowrap" translate="no">.sync</code> prop modifier.
              </p>

              <div class="d-flex flex-column text-md-center">
                <div class="p-2">
                  <app-button id="tooltip-button-disable" variant="primary">I have a tooltip</app-button>
                </div>

                <div class="trendy-example">
                  <app-button @click="disabled = !disabled">
                    {{ disabled ? 'Enable' : 'Disable' }} Tooltip by prop
                  </app-button>
                  <app-button @click="disableByRef">
                    {{ disabled ? 'Enable' : 'Disable' }} Tooltip by $ref event
                  </app-button>

                  <b-tooltip :disabled.sync="disabled" ref="tooltip" target="tooltip-button-disable">
                    Hello <strong>World!</strong>
                  </b-tooltip>
                </div>
              </div>
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div class="d-flex flex-column text-md-center">
    <div class="p-2">
      <app-button id="tooltip-button-disable" variant="primary">I have a tooltip</app-button>
    </div>

    <div class="p-2">
      <app-button @click="disabled = !disabled">
        {{ disabled ? 'Enable' : 'Disable' }} Tooltip by prop
      </app-button>
      <app-button @click="disableByRef">
        {{ disabled ? 'Enable' : 'Disable' }} Tooltip by $ref event
      </app-button>

      <b-tooltip :disabled.sync="disabled" ref="tooltip" target="tooltip-button-disable">
        Hello <strong>World!</strong>
      </b-tooltip>
    </div>
</div></script></code></pre> 


              <p>
                <strong>Note:</strong> <em>In the above example, since we are using the default tooltip triggers of 
                  <code class="text-nowrap" translate="no">focus hover</code>, the tooltip will close before it is disabled due to losing focus (and hover) to the toggle button.</em>
              </p>
              <p>
                You can also emit <code class="text-nowrap" translate="no">$root</code> events to trigger disabling and enabling of tooltip(s). 
                See the <strong>Disabling and enabling tooltips via $root events</strong> section below for details.
              </p>
              <p class="m-40">
                You can also emit <code class="text-nowrap" translate="no">$root</code> events to trigger disabling and enabling of popover(s). 
                See the <strong>Disabling and enabling tooltips via $root events</strong> section below for details.
              </p>

              <h2 id="v-b-tooltip-directive-usage" class="bv-no-focus-ring"><span class="bd-content-title"><code class="text-nowrap" translate="no">v-b-tooltip</code> directive usage<a class="anchorjs-link" href="#v-b-tooltip-directive-usage" aria-labelledby="v-b-tooltip-directive-usage"></a></span></h2>
              <p>
                The <code class="text-nowrap" translate="no">v-b-tooltip</code> directive makes adding tooltips even easier, without additional placeholder markup:
              </p>

              <b-container fluid>
                <b-row>
                  <b-col md="6" class="py-4">
                    <app-button v-b-tooltip title="Online!" variant="outline-success">Live chat</app-button>
                  </b-col>

                  <b-col md="6" class="py-4">
                    <b-button
                      v-b-tooltip.html
                      title="Hello <strong>World!</strong>"
                      variant="outline-success"
                    >
                      Html chat
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><b-container fluid>
    <b-row>
      <b-col md="6" class="py-4">
        <app-button v-b-tooltip title="Online!" variant="outline-success">Live chat</app-button>
      </b-col>

      <b-col md="6" class="py-4">
        <b-button
          v-b-tooltip.html
          title="Hello <strong>World!</strong>"
          variant="outline-success"
        >
          Html chat
        </b-button>
      </b-col>
    </b-row>
</b-container></script></code></pre> 

              <h2>'Global' $root instance events</h2>  
              <p class="m-40">
                Using <code class="text-nowrap" translate="no">$root</code> instance it is possible to emit and listen events somewhere out of a component, 
                where <code class="text-nowrap" translate="no">&lt;b-collapse&gt;</code> is used. In short, <code class="text-nowrap" translate="no">$root</code> 
                behaves like a global event emitters and listener. Details about <code class="text-nowrap" translate="no">$root</code> instance can be found in 
                <a href="https://vuejs.org/v2/guide/components-edge-cases.html#Accessing-the-Root-Instance" target="_blank" rel="noopener">the official Vue docs</a>.
              </p>

              <h3>Hiding and showing tooltips via $root events</h3>
              <p class="m-30">You can close (hide) <strong>all open tooltips</strong> by emitting the <code class="text-nowrap" translate="no">bv::hide::tooltip</code> event on $root:</p>
<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::hide::tooltip')
</script></code></pre> 

              <p class="m-30">
                To close a <strong>specific tooltip</strong>, pass the trigger element's <code class="text-nowrap" translate="no">id</code>, 
                or the <code class="text-nowrap" translate="no">id</code> of the tooltip (if one was provided via the 
                <code class="text-nowrap" translate="no">id</code> prop), as the argument:
              </p>

<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::hide::tooltip', 'my-trigger-button-id')
</script></code></pre> 

              <p class="m-30">
                To open a <strong>specific tooltip</strong>, pass the trigger element's <code class="text-nowrap" translate="no">id</code>, 
                or the <code class="text-nowrap" translate="no">id</code> of the tooltip (if one was provided via the 
                <code class="text-nowrap" translate="no">id</code> prop), as the argument when emitting the 
                <code class="text-nowrap" translate="no">bv::show::tooltip</code> $root event:
              </p>

              
<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::show::tooltip', 'my-trigger-button-id')
</script></code></pre> 

              <p>
                To open all tooltips simultaneously, omit the <code class="text-nowrap" translate="no">id</code> argument when emitting the 
                <code class="text-nowrap" translate="no">bv::show::tooltip</code> event.
              </p>
              <p>These events work for both the component <strong>and</strong> directive versions of tooltip.</p>
              <p class="m-40"><strong>Note:</strong> <em>the <strong>trigger element</strong> must exist in the DOM and be in a visible state in order for the tooltip to show.</em></p>

              <h3>Disabling and enabling tooltips via $root events</h3>
              <p class="m-30">
                You can disable <strong>all open tooltips</strong> by emitting the <code class="text-nowrap" translate="no">bv::disable::tooltip</code> 
                event on $root:
              </p>

<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::disable::tooltip')
</script></code></pre> 

              <p class="m-30">
                To disable a <strong>specific tooltip</strong>, pass the trigger element's <code class="text-nowrap" translate="no">id</code>, 
                or the <code class="text-nowrap" translate="no">id</code> of the tooltip (if one was provided via the 
                <code class="text-nowrap" translate="no">id</code> prop), as the argument:
              </p>
<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::disable::tooltip', 'my-trigger-button-id')
</script></code></pre> 

              <p class="m-30">
                To enable a <strong>specific tooltip</strong>, pass the trigger element's <code class="text-nowrap" translate="no">id</code>, or the 
                <code class="text-nowrap" translate="no">id</code> of the tooltip (if one was provided via the 
                <code class="text-nowrap" translate="no">id</code> prop), as the argument when emitting the 
                <code class="text-nowrap" translate="no">bv::enable::tooltip</code> $root event:
              </p>
<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">this.$root.$emit('bv::enable::tooltip', 'my-trigger-button-id')
</script></code></pre> 

              <p>
                To enable all tooltips simultaneously, omit the <code class="text-nowrap" translate="no">id</code> argument when emitting the 
                <code class="text-nowrap" translate="no">bv::enable::tooltip</code> event.
              </p>
              <p>These events work for both the component <strong>and</strong> directive versions of tooltip.</p>
              <p class="m-40"><strong>Note:</strong> <em>The <strong>trigger element</strong> must exist in the DOM in order for the tooltip to be enabled or disabled.</em></p>

              <h3>Listening to tooltip changes via $root events</h3>
              <p class="m-30">To listen to any tooltip opening, use:</p>
<pre><code class="language-javascript" data-label="Copy"><script type="prism-javascript">export default {
  mounted() {
    this.$root.$on('bv::tooltip::show', bvEvent => {
      console.log('bvEvent:', bvEvent)
    })
  }
}
</script></code></pre> 

              <h2 >Accessibility</h2>
              <p>
                The trigger element, when the tooltip is showing, will have the attribute 
                <code class="text-nowrap" translate="no">aria-describedby</code> set with the auto generated ID of the tooltip.
              </p>
              <p>
                <strong>Note:</strong> The animation effect of this component is dependent on the 
                <code class="text-nowrap" translate="no">prefers-reduced-motion</code> media query. 
                See the <a href="/docs/reference/accessibility" class="font-weight-bold">reduced motion section of our accessibility documentation</a> 
                for additional details.
              </p>
      </b-col>
    </b-row>

    </b-container>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
  name: "Tooltip",
  data() {
    return{
    show: true,
    disabled: false
    }
  },
  components: {
    AppButton

  },
  mounted() {
    document.title = "Tooltip";
    Prism.highlightAll(); // highlight your code on mount
  },

  methods: {
    onOpen() {
      this.$refs.tooltipss.$emit('open')
    },
    onClose() {
        this.$refs.tooltipss.$emit('close')
      },
    disableByRef() {
        if (this.disabled) {
          this.$refs.tooltip.$emit('enable')
        } else {
          this.$refs.tooltip.$emit('disable')
        }
      }
  }
}
</script>

<style scoped>

</style>
