import { render, staticRenderFns } from "./PieChart.vue?vue&type=template&id=d109c0f8&scoped=true&"
import script from "./PieChart.vue?vue&type=script&lang=js&"
export * from "./PieChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d109c0f8",
  null
  
)

export default component.exports