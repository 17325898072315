<template>

<div class="wrapper">
      <title>Dropdowns</title>

      <h1 class="title-section">Dropdowns</h1>
      <p style="font-size: 1.5rem">Dropdowns are toggleable, contextual overlays for displaying lists of links and actions in a dropdown menu format.</p>
      <p class="m-40">Custom <code class="text-nowrap" translate="no">&lt;drop-down&gt;</code> components are toggleable, contextual overlays for displaying lists of links and more. <br>
        They're toggled by clicking (or pressing space or enter when focused), not by hovering; this is an intentional design decision.</p>

      <h2>Button Content</h2>
      <p class="m-30">You can customize the text of the dropdown button by using either the text prop (shown in previous examples), or use the button-content slot instead of the text prop. <br>
         The button-content slot allows you to use basic HTML and icons in the button content.</p>
       
      <div class="m-40">
        <drop-down text="Button text via Prop" class="mr-5" :items="items">
        </drop-down>

        <drop-down :items="items" text="Custom Content with HTML via slot">
          <template #button-content>
            Custom <strong>Content</strong> with <em>HTML</em> via Slot
          </template>
        </drop-down>
      </div>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down text="Button text via Prop" class="mr-5" :items="items">
  </drop-down>

  <drop-down :items="items" text="Custom Content with HTML via slot">
    <template #button-content>
      Custom <strong>Content</strong> with <em>HTML</em> via Slot
    </template>
  </drop-down>
</div></script></code></pre>

      <h2>Sizing</h2>
      <p>Dropdowns work with trigger buttons of all sizes, including default and split dropdown buttons.</p>
      <p class="m-40">Set the size prop to xs for extra small, sm for small, lg for large and xl for extra large button(s).</p>

      <h2>Default extra large</h2>
      <p class="m-30">The dropdown toggle button can have one of the standard contextual variants applied by setting the prop variant to success, primary, info, danger, link, outline-dark, etc. (or custom variants, if defined). <br>
         The default variant is secondary.</p>

         <div class="trendy-example">
            <drop-down :items="items" size="xl" variant="primary"></drop-down>
            <drop-down :items="items" size="xl" variant="secondary" text="Secondary"></drop-down>
            <drop-down :items="items" size="xl" variant="success" text="Success"></drop-down>
            <drop-down :items="items" size="xl" variant="danger" text="Danger"></drop-down>
            <drop-down :items="items" size="xl" variant="warning" text="Warning"></drop-down>
            <drop-down :items="items" size="xl" variant="info" text="Info"></drop-down>
            <drop-down :items="items" size="xl" variant="light" text="Light"></drop-down>
            <drop-down :items="items" size="xl" variant="dark" text="Dark"></drop-down>
         </div>



<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down :items="items" size="xl" variant="primary"></drop-down>
  <drop-down :items="items" size="xl" variant="secondary" text="Secondary"></drop-down>
  <drop-down :items="items" size="xl" variant="success" text="Success"></drop-down>
  <drop-down :items="items" size="xl" variant="danger" text="Danger"></drop-down>
  <drop-down :items="items" size="xl" variant="warning" text="Warning"></drop-down>
  <drop-down :items="items" size="xl" variant="info" text="Info"></drop-down>
  <drop-down :items="items" size="xl" variant="light" text="Light"></drop-down>
  <drop-down :items="items" size="xl" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>

      <h2>Default large</h2>

      <div class="trendy-example">
          <drop-down :items="items" size="lg" variant="primary"></drop-down>
          <drop-down :items="items" size="lg" variant="secondary" text="Secondary"></drop-down>
          <drop-down :items="items" size="lg" variant="success" text="Success"></drop-down>
          <drop-down :items="items" size="lg" variant="danger" text="Danger"></drop-down>
          <drop-down :items="items" size="lg" variant="warning" text="Warning"></drop-down>
          <drop-down :items="items" size="lg" variant="info" text="Info"></drop-down>
          <drop-down :items="items" size="lg" variant="light" text="Light"></drop-down>
          <drop-down :items="items" size="lg" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down :items="items" size="lg" variant="primary"></drop-down>
  <drop-down :items="items" size="lg" variant="secondary" text="Secondary"></drop-down>
  <drop-down :items="items" size="lg" variant="success" text="Success"></drop-down>
  <drop-down :items="items" size="lg" variant="danger" text="Danger"></drop-down>
  <drop-down :items="items" size="lg" variant="warning" text="Warning"></drop-down>
  <drop-down :items="items" size="lg" variant="info" text="Info"></drop-down>
  <drop-down :items="items" size="lg" variant="light" text="Light"></drop-down>
  <drop-down :items="items" size="lg" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


      <h2>Default</h2>

      <div class="trendy-example">
          <drop-down :items="items" variant="primary"></drop-down>
          <drop-down :items="items" variant="secondary" text="Secondary"></drop-down>
          <drop-down :items="items" variant="success" text="Success"></drop-down>
          <drop-down :items="items" variant="danger" text="Danger"></drop-down>
          <drop-down :items="items" variant="warning" text="Warning"></drop-down>
          <drop-down :items="items" variant="info" text="Info"></drop-down>
          <drop-down :items="items" variant="light" text="Light"></drop-down>
          <drop-down :items="items" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down :items="items" variant="primary"></drop-down>
  <drop-down :items="items" variant="secondary" text="Secondary"></drop-down>
  <drop-down :items="items" variant="success" text="Success"></drop-down>
  <drop-down :items="items" variant="danger" text="Danger"></drop-down>
  <drop-down :items="items" variant="warning" text="Warning"></drop-down>
  <drop-down :items="items" variant="info" text="Info"></drop-down>
  <drop-down :items="items" variant="light" class="mr-3 mb-4" text="Light"></drop-down>
  <drop-down :items="items" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


      <h2>Small</h2>

      <div class="trendy-example">
          <drop-down :items="items" size="sm" variant="primary"></drop-down>
          <drop-down :items="items" size="sm" variant="secondary" text="Secondary"></drop-down>
          <drop-down :items="items" size="sm" variant="success" text="Success"></drop-down>
          <drop-down :items="items" size="sm" variant="danger" text="Danger"></drop-down>
          <drop-down :items="items" size="sm" variant="warning" text="Warning"></drop-down>
          <drop-down :items="items" size="sm" variant="info" text="Info"></drop-down>
          <drop-down :items="items" size="sm" variant="light" text="Light"></drop-down>
          <drop-down :items="items" size="sm" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down :items="items" size="sm" variant="primary"></drop-down>
  <drop-down :items="items" size="sm" variant="secondary" text="Secondary"></drop-down>
  <drop-down :items="items" size="sm" variant="success" text="Success"></drop-down>
  <drop-down :items="items" size="sm" variant="danger" text="Danger"></drop-down>
  <drop-down :items="items" size="sm" variant="warning" text="Warning"></drop-down>
  <drop-down :items="items" size="sm" variant="info" text="Info"></drop-down>
  <drop-down :items="items" size="sm" variant="light" text="Light"></drop-down>
  <drop-down :items="items" size="sm" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


      <h2>Extra Small</h2>

      <div class="trendy-example">
          <drop-down :items="items" size="xs" variant="primary"></drop-down>
          <drop-down :items="items" size="xs" variant="secondary" text="Secondary"></drop-down>
          <drop-down :items="items" size="xs"  variant="success" text="Success"></drop-down>
          <drop-down :items="items" size="xs"  variant="danger" text="Danger"></drop-down>
          <drop-down :items="items" size="xs"  variant="warning" text="Warning"></drop-down>
          <drop-down :items="items" size="xs"  variant="info" text="Info"></drop-down>
          <drop-down :items="items" size="xs"  variant="light" text="Light"></drop-down>
          <drop-down :items="items" size="xs"  variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down :items="items" size="xs" variant="primary"></drop-down>
  <drop-down :items="items" size="xs" variant="secondary" text="Secondary"></drop-down>
  <drop-down :items="items" size="xs" variant="success" text="Success"></drop-down>
  <drop-down :items="items" size="xs" variant="danger" text="Danger"></drop-down>
  <drop-down :items="items" size="xs" variant="warning" text="Warning"></drop-down>
  <drop-down :items="items" size="xs" variant="info" text="Info"></drop-down>
  <drop-down :items="items" size="xs" variant="light" text="Light"></drop-down>
  <drop-down :items="items" size="xs" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


  <!-- Rounded -->

      <h2>Rounded Extra large</h2>
      <p>Prefer dropdown buttons with a more rounded-pill style? Just add the rounded property to achieve that.</p>
      <p class="m-30">Rounded buttons are available in all sizes (xs, sm, default, lg, xl)</p>

      <div class="trendy-example">
          <drop-down rounded :items="items" size="xl" variant="primary"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="secondary" text="Secondary"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="success" text="Success"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="danger" text="Danger"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="warning" text="Warning"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="info" text="Info"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="light" text="Light"></drop-down>
          <drop-down rounded :items="items" size="xl" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down rounded :items="items" size="xl" variant="primary" ></drop-down>
  <drop-down rounded :items="items" size="xl" variant="secondary" text="Secondary"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="success" text="Success"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="danger" text="Danger"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="warning" text="Warning"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="info" text="Info"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="light" text="Light"></drop-down>
  <drop-down rounded :items="items" size="xl" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>



      <h2>Rounded large</h2>

      <div class="trendy-example">
          <drop-down rounded :items="items" size="lg" variant="primary"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="secondary" text="Secondary"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="success" text="Success"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="danger" text="Danger"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="warning" text="Warning"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="info" text="Info"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="light" text="Light"></drop-down>
          <drop-down rounded :items="items" size="lg" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down rounded :items="items" size="lg" variant="primary"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="secondary" text="Secondary"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="success" text="Success"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="danger" text="Danger"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="warning" text="Warning"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="info" text="Info"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="light" text="Light"></drop-down>
  <drop-down rounded :items="items" size="lg" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


      <h2>Rounded</h2>

      <div class="trendy-example">
          <drop-down rounded :items="items" variant="primary"></drop-down>
          <drop-down rounded :items="items" variant="secondary" text="Secondary"></drop-down>
          <drop-down rounded :items="items" variant="success" text="Success"></drop-down>
          <drop-down rounded :items="items" variant="danger" text="Danger"></drop-down>
          <drop-down rounded :items="items" variant="warning" text="Warning"></drop-down>
          <drop-down rounded :items="items" variant="info" text="Info"></drop-down>
          <drop-down rounded :items="items" variant="light" text="Light"></drop-down>
          <drop-down rounded :items="items" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down rounded :items="items" variant="primary"></drop-down>
  <drop-down rounded :items="items" variant="secondary" text="Secondary"></drop-down>
  <drop-down rounded :items="items" variant="success" text="Success"></drop-down>
  <drop-down rounded :items="items" variant="danger" text="Danger"></drop-down>
  <drop-down rounded :items="items" variant="warning" text="Warning"></drop-down>
  <drop-down rounded :items="items" variant="info" text="Info"></drop-down>
  <drop-down rounded :items="items" variant="light" text="Light"></drop-down>
  <drop-down rounded :items="items" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


      <h2>Rounded small</h2>

      <div class="trendy-example">
          <drop-down rounded :items="items" size="sm"  variant="primary"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="secondary" text="Secondary"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="success" text="Success"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="danger" text="Danger"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="warning" text="Warning"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="info" text="Info"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="light" text="Light"></drop-down>
          <drop-down rounded :items="items" size="sm"  variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down rounded :items="items" size="sm" variant="primary"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="secondary" text="Secondary"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="success" text="Success"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="danger" text="Danger"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="warning" text="Warning"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="info" text="Info"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="light" text="Light"></drop-down>
  <drop-down rounded :items="items" size="sm" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>

  

      <h2>Rounded Extra small</h2>

      <div class="trendy-example">
          <drop-down rounded :items="items" size="xs" variant="primary"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="secondary" text="Secondary"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="success" text="Success"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="danger" text="Danger"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="warning" text="Warning"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="info" text="Info"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="light" text="Light"></drop-down>
          <drop-down rounded :items="items" size="xs" variant="dark" text="Dark"></drop-down>
      </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down rounded :items="items" size="xs" variant="primary"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="secondary" text="Secondary"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="success" text="Success"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="danger" text="Danger"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="warning" text="Warning"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="info" text="Info"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="light" text="Light"></drop-down>
  <drop-down rounded :items="items" size="xs" variant="dark" text="Dark"></drop-down>
</div></script></code></pre>


    <h2>Split button support</h2>
    <p class="m-30">Create a split dropdown button, where the left button provides standard click event and link support, while the right hand side is the dropdown menu toggle button.</p>
    
    <drop-down split :items="items" variant="primary" class="split-dropdown" text="Split"></drop-down>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down split :items="items" variant="primary"></drop-down>
</div></script></code></pre>

    <h3>Split button link support</h3>
    <p class="m-30">
      The left split button defaults to an element of type <code class="text-nowrap" translate="no">&lt;button&gt;</code> 
      (a <code class="text-nowrap" translate="no">&lt;app-button&gt;</code> to be exact). 
      To convert this button into a link or <code class="text-nowrap" translate="no">&lt;router-link&gt;</code>, 
      specify the href via the <code class="text-nowrap" translate="no">split-href</code> prop or a router link 
      <code class="text-nowrap" translate="no">to</code> value via the <code class="text-nowrap" translate="no">split-to</code> prop, while maintaining the look of a button.
    </p>

    <drop-down split :items="items" variant="primary" class="m-40 split-dropdown" text="Split"></drop-down>

   

    <h3>Split button type</h3>
    <p>
      The split button defaults to a button <code class="text-nowrap" translate="no">type</code> of 
      <code class="text-nowrap" translate="no">'button'</code>. You can specify an alternate type via the 
      <code class="text-nowrap" translate="no">split-button-type</code> prop. Supported values are: 
      <code class="text-nowrap" translate="no">'button'</code>, <code class="text-nowrap" translate="no">'submit'</code> and 
      <code class="text-nowrap" translate="no">'reset'</code>.
    </p>

    <p class="m-40">
      If props <code class="text-nowrap" translate="no">split-to</code> or <code class="text-nowrap" translate="no">split-href</code> are set, the 
      <code class="text-nowrap" translate="no">split-button-type</code> prop will be ignored.
    </p>

    <h2>Styling options</h2>
    <p class="m-40">
      If props <code class="text-nowrap" translate="no">split-to</code> or <code class="text-nowrap" translate="no">split-href</code> are set, the 
      <code class="text-nowrap" translate="no">split-button-type</code> prop will be ignored.
    </p>


    <h2>Positioning</h2>
    <p class="m-40">
      Dropdown supports various positioning such as left and right aligned, dropdown and dropup, 
      and supports auto-flipping (dropdown to dropup, and vice-versa) when the menu would overflow off of the visible screen area.
    </p>


    <h2>Dropup and split dropup</h2>
    <p class="m-40">Turn your dropdown menu into a drop-up menu by setting the dropup prop.</p>

    <div class="trendy-example">
        <drop-down dropup :items="items" variant="primary"></drop-down>
        <drop-down split dropup :items="items" variant="primary" class="split-dropup"></drop-down>
    </div>



<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down dropup :items="items" variant="primary"></drop-down>
  <drop-down split dropup :items="items" variant="primary"></drop-down>
</div></script></code></pre>

    <h2>Drop right and drop left</h2>
    <p>Turn your dropdown menu into a drop-right menu by setting the dropright prop. Or, turn it into a drop-left menu by setting the dropleft right prop to true.</p>
    <p class="m-40">dropright takes precedence over dropleft. Neither dropright or dropleft have any effect if dropup is set.</p>

    <div class="trendy-example">
        <drop-down dropright :items="items" variant="primary"></drop-down>
        <drop-down dropleft :items="items" variant="primary"></drop-down>
    </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down dropright :items="items" variant="primary"></drop-down>
  <drop-down dropleft :items="items" variant="primary"></drop-down>
</div></script></code></pre>

    <h2> Split Drop right and drop left</h2>
    <p>Turn your dropdown menu into a drop-right menu by setting the dropright prop. Or, turn it into a drop-left menu by setting the dropleft right prop to true.</p>
    <p class="m-40">dropright takes precedence over dropleft. Neither dropright or dropleft have any effect if dropup is set.</p>

    <div class="trendy-example">
        <drop-down split dropright :items="items" variant="primary"></drop-down>
        <drop-down split dropleft :items="items" variant="primary"></drop-down>
    </div>


<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down split dropright :items="items" variant="primary"></drop-down>
  <drop-down split dropleft :items="items" variant="primary"></drop-down>
</div></script></code></pre>


    <h3>Auto "flipping"</h3>
    <p class="m-40">
      By default, dropdowns may flip to the top, or to the bottom, based on their current position in the viewport. 
      To disable this auto-flip feature, set the <code class="text-nowrap" translate="no">no-flip</code> prop.
    </p>

    <h3>Menu offset</h3>
    <p>
      Like to move your menu away from the toggle buttons a bit? Then use the <code class="text-nowrap" translate="no">offset</code> 
      prop to specify the number of pixels to push right (or left when negative) from the toggle button:
    </p>

    <ul class="m-40"> 
      <li>
        Specified as a number of pixels: positive for right shift, negative for left shift.
      </li> 
      <li>
        Specify the distance in CSS units (i.e. <code class="text-nowrap" translate="no">0.3rem</code>, 
        <code class="text-nowrap" translate="no">4px</code>, <code class="text-nowrap" translate="no">1.2em</code>, etc.) passed as a string.
      </li> 
    </ul>

    <h3>Boundary constraint</h3>
    <p>
      By default, dropdowns are visually constrained to its scroll parent, which will suffice in most situations. 
      However, if you place a dropdown inside an element that has <code class="text-nowrap" translate="no">overflow: scroll</code> 
      (or similar) set, the dropdown menu may - in some situations - get cut off. 
      To get around this, you can specify a boundary element via the <code class="text-nowrap" translate="no">boundary</code> prop. 
      Supported values are <code class="text-nowrap" translate="no">'scrollParent'</code> (the default), 
      <code class="text-nowrap" translate="no">'viewport'</code>, <code class="text-nowrap" translate="no">'window'</code> or a reference to an HTML element. 
      The boundary value is passed directly to Popper.js's <code class="text-nowrap" translate="no">boundariesElement</code> configuration option.
    </p>
    <p  class="m-40">
      <strong>Note:</strong> When <code class="text-nowrap" translate="no">boundary</code> is any value other than the default of 
      <code class="text-nowrap" translate="no">'scrollParent'</code>, the style <code class="text-nowrap" translate="no">position: static</code> 
      is applied to to the dropdown component's root element in order to allow the menu to "break-out" of its scroll container. 
      In some situations this may affect your layout or positioning of the dropdown trigger button. In these cases you may need to wrap your dropdown inside another element.
    </p>

    <h3>Advanced Popper.js configuration</h3>
    <p>
      If you need some advanced Popper.js configuration to make dropdowns behave to your needs, you can use the 
      <code class="text-nowrap" translate="no">popper-opts</code> prop to pass down a custom configuration object which will be deeply merged with the BootstrapVue defaults.
    </p>
    <p>
      Head to the <a href="https://popper.js.org/docs/v1/" target="_blank" rel="noopener">Popper.js docs</a> to see all the configuration options.
    </p>
    <p class="m-40">
      <strong>Note</strong>: The props <code class="text-nowrap" translate="no">offset</code>, <code class="text-nowrap" translate="no">boundary</code> and 
      <code class="text-nowrap" translate="no">no-flip</code> may loose their effect when you overwrite the Popper.js configuration.
    </p>
 

  
    <h2>Block level dropdowns</h2>
    <p class="m-30">
      By default dropdowns act like buttons and are displayed inline. To create block level dropdowns (that span to the full width of a parent) set the 
      <code class="text-nowrap" translate="no">block</code> prop. Both, regular and split button dropdowns are supported.
    </p>

    <div class="m-40">    
      
      <div class="trendy-example">
          <drop-down :divider="divider" :items="items" variant="success" block text="Success"></drop-down>
          <drop-down  :items="items" variant="outline-success" block text="Success"></drop-down>
      </div>

    </div>


    <h3>Dropdown sub-component color variants</h3>
    <p class="m-40">
      Many of the supported dropdown <a href="#dropdown-supported-sub-components" class="font-weight-bold">sub-components</a> 
      provide a <code class="text-nowrap" translate="no">variant</code> prop for controlling their text color.
    </p>

    <h3>Hidden caret</h3>
    <p class="m-40">
      Many of the supported dropdown <a href="#dropdown-supported-sub-components" class="font-weight-bold">sub-components</a> provide a 
      <code class="text-nowrap" translate="no">variant</code> prop for controlling their text color.
    </p>

    <h3>Lazy dropdown</h3>
    <p class="m-40">
      By default, <code class="text-nowrap" translate="no">&lt;b-dropdown&gt;</code> renders the menu contents in the DOM even when the menu is not shown. 
      When there are a large number of dropdowns rendered on the same page, performance could be impacted due to larger overall memory utilization. 
      You can instruct <code class="text-nowrap" translate="no">&lt;b-dropdown&gt;</code> to render the menu contents only when it is shown by setting the 
      <code class="text-nowrap" translate="no">lazy</code> prop to true.
    </p>

    <h3>Dropdown supported sub-components</h3>
    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code></h3>
    <p>
      The <code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code> is typically used to create a navigation link inside your menu. 
      Use either the <code class="text-nowrap" translate="no">href</code> prop or the <code class="text-nowrap" translate="no">to</code> prop 
      (for router link support) to generate the appropriate navigation link. If neither <code class="text-nowrap" translate="no">href</code> nor 
      <code class="text-nowrap" translate="no">to</code> are provided, a standard <code class="text-nowrap" translate="no">&lt;a&gt;</code> 
      link will be generated with an <code class="text-nowrap" translate="no">href</code> of <code class="text-nowrap" translate="no">#</code> 
      (with an event handler that will prevent scroll to top behaviour by preventing the default link action).
    </p>
    <p class="m-40">
      Disabled the dropdown item by setting the <code class="text-nowrap" translate="no">disabled</code> prop.
    </p>

    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-item-button&gt;</code></h3>
    <p>
      Historically dropdown menu contents had to be links (<code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code>), 
      but that's no longer the case with Bootstrap v4. Now you can optionally create <code class="text-nowrap" translate="no">&lt;button&gt;</code> 
      elements in your dropdowns by using the <code class="text-nowrap" translate="no">&lt;b-dropdown-item-button&gt;</code> sub-component. 
      <code class="text-nowrap" translate="no">&lt;b-dropdown-item-button&gt;</code> does not support the <code class="text-nowrap" translate="no">href</code> or
       <code class="text-nowrap" translate="no">to</code> props.
    </p>
    <p class="m-30">Disabled the dropdown item button by setting the <code class="text-nowrap" translate="no">disabled</code> prop.</p>

        <drop-down menu-right  variant="primary" text="Dropdown using buttons as menu items">
          <template v-slot:items>
              <b-dropdown-item-button>I'm a button</b-dropdown-item-button>
              <b-dropdown-item-button active>I'm a active button</b-dropdown-item-button>
              <b-dropdown-item-button disabled>I'm a button, but disabled!</b-dropdown-item-button>
              <b-dropdown-item-button>I don't look like a button, but I am!</b-dropdown-item-button>
          </template>
        </drop-down>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" text="Dropdown using buttons as menu items">
    <template v-slot:items>
        <b-dropdown-item-button>I'm a button</b-dropdown-item-button>
        <b-dropdown-item-button active>I'm a active button</b-dropdown-item-button>
        <b-dropdown-item-button disabled>I'm a button, but disabled!</b-dropdown-item-button>
        <b-dropdown-item-button>I don't look like a button, but I am!</b-dropdown-item-button>
    </template>
  </drop-down>
</div></script></code></pre>

    <p class="m-40">When the menu item doesn't trigger navigation, it is recommended to use the <code class="text-nowrap" translate="no">&lt;b-dropdown-item-button&gt;</code> sub-component.</p>

    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-divider&gt;</code></h3>
    <p class="m-30">Separate groups of related menu items with <code class="text-nowrap" translate="no">&lt;b-dropdown-divider&gt;</code>.</p>

        <drop-down menu-right  variant="primary" id="dropdown-divider" text="Dropdown with divider">
          <template v-slot:items>
              <b-dropdown-item-button>First item</b-dropdown-item-button>
              <b-dropdown-item-button>Second item</b-dropdown-item-button>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button>Separated Item</b-dropdown-item-button>
          </template>
        </drop-down>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" id="dropdown-divider" text="Dropdown with divider">
    <template v-slot:items>
        <b-dropdown-item-button>First item</b-dropdown-item-button>
        <b-dropdown-item-button>Second item</b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button>Separated Item</b-dropdown-item-button>
    </template>
  </drop-down>
</div></script></code></pre>

    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-text&gt;</code></h3>
    <p class="m-30">
      Place any freeform text within a dropdown menu using the <code class="text-nowrap" translate="no">&lt;b-dropdown-text&gt;</code> 
      sub component or use text and use spacing utilities. Note that you'll likely need additional sizing styles to constrain/set the menu width.
    </p>

        <drop-down menu-right  variant="primary" id="dropdown-text" text="Dropdown with text">
          <template v-slot:items>
              <b-dropdown-text style="width: 240px;">
                Some example text that's free-flowing within the dropdown menu.
              </b-dropdown-text>
              <b-dropdown-text>And this is more example text.</b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button>First item</b-dropdown-item-button>
              <b-dropdown-item-button>Second Item</b-dropdown-item-button>
          </template>
        </drop-down>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" id="dropdown-text" text="Dropdown with text">
    <template v-slot:items>
        <b-dropdown-text style="width: 240px;">
          Some example text that's free-flowing within the dropdown menu.
        </b-dropdown-text>
        <b-dropdown-text>And this is more example text.</b-dropdown-text>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button>First item</b-dropdown-item-button>
        <b-dropdown-item-button>Second Item</b-dropdown-item-button>
    </template>
  </drop-down>
</div></script></code></pre>

    <p>
      <code class="text-nowrap" translate="no">&lt;b-dropdown-text&gt;</code> has the BootstrapVue custom class 
      <code class="text-nowrap" translate="no">.b-dropdown-text</code> applied to it which sets some basic styles which are suitable in most situations. 
      By default its width will be the same as the widest <code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code> content. 
      You may need to place additional styles or helper classes on the component.
    </p>
    <p class="m-40">
      By default <code class="text-nowrap" translate="no">&lt;b-dropdown-text&gt;</code> renders using tag 
      <code class="text-nowrap" translate="no">&lt;p&gt;</code>. You can change the rendered tag by setting the 
      <code class="text-nowrap" translate="no">tag</code> prop to any valid HTML5 tag on the <code class="text-nowrap" translate="no">&lt;b-dropdown-text&gt;</code> 
      sub-component.
    </p>

    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code></h3>
    <p class="m-30">Dropdowns support basic forms. Put a <code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code> within a dropdown menu and place form controls within the <code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code>. The <code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code> is based on the <a href="/docs/components/form" class="font-weight-bold"><code class="text-nowrap" translate="no">&lt;b-form&gt;</code></a> component, and supports the same props and attributes as a regular form.</p>

        <drop-down menu-right  variant="primary" id="dropdown-form" text="Dropdown with form" ref="dropdownform" >
          <template v-slot:items>
              <b-dropdown-form>
                <b-form-group label="Email" label-for="dropdown-form-email" @submit.stop.prevent>
                  <b-form-input
                    id="dropdown-form-email"
                    size="sm"
                    placeholder="email@example.com"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Password" label-for="dropdown-form-password">
                  <b-form-input
                    id="dropdown-form-password"
                    type="password"
                    size="sm"
                    placeholder="Password"
                  ></b-form-input>
                </b-form-group>

                <b-form-checkbox class="mb-3">Remember me</b-form-checkbox>
                <b-button variant="primary" size="sm" @click="onClick()">Sign In</b-button>
              </b-dropdown-form>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button>New around here? Sign up</b-dropdown-item-button>
              <b-dropdown-item-button>Forgot Password?</b-dropdown-item-button>
          </template>
        </drop-down> 
    
<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" id="dropdown-form" text="Dropdown with form" ref="dropdownform" >
    <template v-slot:items>
        <b-dropdown-form>
          <b-form-group label="Email" label-for="dropdown-form-email" @submit.stop.prevent>
            <b-form-input
              id="dropdown-form-email"
              size="sm"
              placeholder="email@example.com"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Password" label-for="dropdown-form-password">
            <b-form-input
              id="dropdown-form-password"
              type="password"
              size="sm"
              placeholder="Password"
            ></b-form-input>
          </b-form-group>

          <b-form-checkbox class="mb-3">Remember me</b-form-checkbox>
          <b-button variant="primary" size="sm" @click="onClick()">Sign In</b-button>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button>New around here? Sign up</b-dropdown-item-button>
        <b-dropdown-item-button>Forgot Password?</b-dropdown-item-button>
    </template>
  </drop-down> 
</div></script></code></pre>

        <p class="m-40">
          <code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code> has the BootstrapVue custom class 
          <code class="text-nowrap" translate="no">.b-dropdown-form</code> applied to it which sets some basic styles which are suitable in most situations. 
          By default its width will be the same as the widest <code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code> content. 
          You may need to place additional styles or helper classes on the component.
        </p>

        <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-group&gt;</code></h3>
        <p class="m-30">
          Group a set of dropdown sub components with an optional associated header. Place a 
          <code class="text-nowrap" translate="no">&lt;b-dropdown-divider&gt;</code> between your 
          <code class="text-nowrap" translate="no">&lt;b-dropdown-group&gt;</code> and other groups or non-grouped dropdown contents
        </p>

        <drop-down menu-right  variant="primary" id="dropdown-grouped" text="Dropdown with group" >
          <template v-slot:items>
              <b-dropdown-item-button>
                Non-grouped Item
              </b-dropdown-item-button>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group id="dropdown-group-1" header="Group 1">
                <b-dropdown-item-button>First Grouped item</b-dropdown-item-button>
                <b-dropdown-item-button>Second Grouped Item</b-dropdown-item-button>
              </b-dropdown-group>
              <b-dropdown-group id="dropdown-group-2" header="Group 2">
                <b-dropdown-item-button>First Grouped item</b-dropdown-item-button>
                <b-dropdown-item-button>Second Grouped Item</b-dropdown-item-button>
              </b-dropdown-group>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button>
                Another Non-grouped Item
              </b-dropdown-item-button>
          </template>
        </drop-down> 

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" id="dropdown-grouped" text="Dropdown with group" >
    <template v-slot:items>
        <b-dropdown-item-button>
          Non-grouped Item
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-group id="dropdown-group-1" header="Group 1">
          <b-dropdown-item-button>First Grouped item</b-dropdown-item-button>
          <b-dropdown-item-button>Second Grouped Item</b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-group id="dropdown-group-2" header="Group 2">
          <b-dropdown-item-button>First Grouped item</b-dropdown-item-button>
          <b-dropdown-item-button>Second Grouped Item</b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button>
          Another Non-grouped Item
        </b-dropdown-item-button>
    </template>
  </drop-down> 
</div></script></code></pre>

    <p class="m-40">
      Using <code class="text-nowrap" translate="no">&lt;b-dropdown-group&gt;</code> instead of 
      <code class="text-nowrap" translate="no">&lt;b-dropdown-header&gt;</code> is the recommended method for providing accessible grouped items with a header.
    </p>

    <h3><code class="text-nowrap" translate="no">&lt;b-dropdown-header&gt;</code></h3>
    <p class="m-30">Add a header to label sections of actions in any dropdown menu.</p>

         <drop-down menu-right  variant="primary" id="dropdown-header" text="Dropdown with header">
          <template v-slot:items>
              <b-dropdown-header id="dropdown-header-label">
                Dropdown header
              </b-dropdown-header>
              <b-dropdown-item-button aria-describedby="dropdown-header-label">
                First item
              </b-dropdown-item-button>
              <b-dropdown-item-button aria-describedby="dropdown-header-label">
                Second Item
              </b-dropdown-item-button>
          </template>
        </drop-down>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <drop-down menu-right  variant="primary" id="dropdown-header" text="Dropdown with header">
  <template v-slot:items>
      <b-dropdown-header id="dropdown-header-label">
        Dropdown header
      </b-dropdown-header>
      <b-dropdown-item-button aria-describedby="dropdown-header-label">
        First item
      </b-dropdown-item-button>
      <b-dropdown-item-button aria-describedby="dropdown-header-label">
        Second Item
      </b-dropdown-item-button>
  </template>
</drop-down>
</div></script></code></pre>

        <p class="m-40">
          Using the <code class="text-nowrap" translate="no">&lt;b-dropdown-group&gt;</code> sub-component simplifies creating accessible grouped 
          dropdown items with an associated header.
        </p> 

        <h3>Closing the menu via form interaction</h3>

        <p>
          Clicking buttons inside of a <code class="text-nowrap" translate="no">&lt;b-dropdown-form&gt;</code> will not automatically close the menu. 
          If you need to close the menu using a button (or via the form submit event), call the <code class="text-nowrap" translate="no">hide()</code> 
          method on the <code class="text-nowrap" translate="no">&lt;b-dropdown&gt;</code> instance, as is shown in the above example.
        </p>
        <p class="m-40">
          The <code class="text-nowrap" translate="no">hide()</code> method accepts a single boolean argument. If the argument is 
          <code class="text-nowrap" translate="no">true</code>, then focus will be returned to the dropdown toggle button after the menu has closed. 
          Otherwise the document will gain focus once the menu is closed.
        </p>

        <h3>Listening to dropdown changes via $root events</h3>
        <p>To listen to any dropdown opening, use:</p>
<pre><code class="language-javascript" data-label="Copy">&lt;script&gt;
  export default {
    mounted() {
      this.$root.$on('bv::dropdown::show', bvEvent => {
        console.log('Dropdown is about to be shown', bvEvent)
      })
    }
  }
&lt;/script&gt;
</code></pre>

        <h3>Optionally scoped default slot</h3>
        <p class="m-30">The default slot is optionally scoped with the following scope available:</p>
        <div class="m-40">
          <b-table-simple hover responsive>
            <b-thead>
              <b-tr>
                <b-th>Property/Method</b-th>
                <b-th>Description</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td><code class="text-nowrap" translate="no">hide()</code></b-td>
                <b-td>Can be used to close the dropdown menu. Accepts an optional boolean argument, which if <code class="text-nowrap" translate="no">true</code> returns focus to the toggle button</b-td>
              </b-tr>
            </b-tbody>

          </b-table-simple>
       </div>

       <h3>Accessibility</h3>
       <p>Providing a unique <code class="text-nowrap" translate="no">id</code> prop ensures ARIA compliance by automatically adding the appropriate <code class="text-nowrap" translate="no">aria-*</code> attributes in the rendered markup.</p>
       <p>The default ARIA role is set to <code class="text-nowrap" translate="no">menu</code>, but you can change this default to another role (such as <code class="text-nowrap" translate="no">navigation</code>) via the <code class="text-nowrap" translate="no">role</code> prop, depending on your user case.</p>
       <p class="m-40">When a menu item doesn't trigger navigation, it is recommended to use the <code class="text-nowrap" translate="no">&lt;b-dropdown-item-button&gt;</code> sub-component (which is not announced as a link) instead of <code class="text-nowrap" translate="no">&lt;b-dropdown-item&gt;</code> (which is presented as a link to the user).</p>
      

       <h3>Headers and accessibility</h3>
       <p class="m-40">
         When using <code class="text-nowrap" translate="no">&lt;b-dropdown-header&gt;</code> components in the dropdown menu, it is recommended to add an 
         <code class="text-nowrap" translate="no">id</code> attribute to each of the headers, and then set the 
         <code class="text-nowrap" translate="no">aria-describedby</code> attribute 
         (set to the <code class="text-nowrap" translate="no">id</code> value of the associated header) on each 
         following dropdown items under that header.
       </p>
    
    </div>
</template>

<script>

import DropDown from "@/components/DropDown";
import CaretDown from '@/assets/images/icons/caret-down.svg'
import InlineSvg from "vue-inline-svg";



export default {
  name: "DropdownView",
  data(){
    return {
      CaretDown,
      items:[
       'First action',
       'Second action',
       'Third action'
      ],

      divider: true,

      text: 'Primary',
      text2: 'aaaaaaaaaaaa',
    }
  },
  components:{
    DropDown,
    InlineSvg
  },

  methods: {
    onClick() {
        // Close the menu and (by passing true) return focus to the toggle button
        this.$refs.dropdownform.$children[0].hide()
    }
  },
  mounted() {
    document.title = "Dropdown";
    Prism.highlightAll(); // highlight your code on mount
  }
}
</script>

<style scoped>
.rounded-wrapper{
  margin-top: 6rem;
}
.section-dropdown-menu{
  margin-bottom: 15rem;
}
</style>
