<template>
  <div>
    <LineChart v-bind="barChartProps" :width="width" :height="height" />
  </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
import { LineChart, useBarChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';

Chart.register(...registerables);

export default defineComponent ({
  name: "App",
  components: {
    LineChart
  },

    data(){
    return {
      height: 215,
      width: 403
    }
  },

  setup() {

    const data = ref([ 0, 50, -50, 2, -40, 95, -10, 0]);

    const chartData = computed(() => ({
      labels: ['02 JAN', '03 JAN', '04 JAN', '05 JAN', '06 JAN', '07 JAN', '08 JAN'],

      datasets: [
        {
          data: data.value,
          backgroundColor: '#5561B3',
          borderColor: '#ffff',
          lineTension: 0.3,
          borderWidth: 6,
          fill: false,
          legend: {
              display: false
          },
          responsive: true,
          maintainAspectRatio: false

        }

      ],


    }));

    const options = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0
       }
      },
      scales: {
        x: {
          display: false,
          beginAtZero: false,
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          grid: {
            display: false
          }
        }
      },
      plugins: {
          legend: {
            display: false
          },

        //   subtitle: {
        //     display: true,
        //     text: 'ACTIVE USERS',
        //     align: 'start',
        //     color: '#161F5E',
        //     font: {
        //         family: "'Poppins', sans-serif",
        //         size: 18,
        //         weight: 500,
        //     },
        //     padding: {
        //         left: 30,
        //         //top:25,
        //         bottom: 30
        //     },


        // }
        },

    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
      options
    });

    return {
      barChartProps,
      barChartRef
    };
  }


})
</script>

<style scoped>

</style>
