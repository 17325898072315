<template>
  <div class="wrapper">
        <title>Jumbotron</title>

    <h1 class="title-section">Jumbotron</h1>
    <p style="font-size: 1.5rem">A lightweight, flexible component that can optionally extend the entire viewport to showcase key marketing messages on your site.</p>
    <p class="m-40">You can set the heading and lead text via the header and lead props, or use the named slots header and lead if you need HTML support.
          Anything else between the opening and closing tags will be rendered at the bottom of the jumbotron.
    </p>

    <h4>Usage with props</h4>
    <b-jumbotron header="Hello World">
      <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
      <hr>
      <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
      <app-button>Learn more</app-button>
  </b-jumbotron>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-jumbotron header="Hello World">
    <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr>
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <app-button>Learn more</app-button>
  </b-jumbotron>
</div></script></code></pre>

    <h4>Usage with slots</h4>

    <b-jumbotron>
      <template v-slot:header>
        Hello, world!
      </template>
      <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
      <hr>
      <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
      <app-button>Learn more</app-button>
    </b-jumbotron>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-jumbotron>
    <template v-slot:header>
      Hello, world!
    </template>
    <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
    <hr>
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <app-button>Learn more</app-button>
  </b-jumbotron>
</div></script></code></pre>


<h2 class="m-30">Options</h2>
<h3>Header</h3>
<p>
  Control which tag is rendered for the header by setting the header-tag to the appropriate HTML element. 
  The default is h1. Both the prop header and slot header will be rendered inside this tag.
  If both the prop and the slot are specified, the slot will be shown.</p>
<p class="m-40">
  Control the overall size of the header text by setting the header-level prop to a value between 1 and 4 - with 1 being the largest and 4 being smallest.
  The default value is 3.</p>

<h3>Lead text</h3>
<p class="m-40">Control which tag is rendered for the lead text by setting the lead-tag to the desired HTML element. 
   The default is p. Both the prop lead and slot lead will be rendered inside this tag. 
   If both the prop and the slot are specified, the slot will be shown.</p>

<h2>Fluid width</h2>
<p class="m-30">To make <code class="text-nowrap" translate="no">&lt;b-jumbotron&gt;</code> full width, and without rounded corners, set the fluid prop.
   The inner content will automatically be placed into a <code class="text-nowrap" translate="no">&lt;b-container&gt;</code> (fixed width at the various breakpoints).
  To change this to a fluid container, set the container-fluid prop. The container-fluid prop has no effect if the fluid prop is not set</p>


    <b-jumbotron fluid>
      <template v-slot:header>
        Fluid jumbotron
      </template>
      <p>This is a modified jumbotron that occupies the entire horizontal space of its parent..</p>

    </b-jumbotron>

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-jumbotron fluid>
    <template v-slot:header>
      Fluid jumbotron
    </template>
    <p>This is a modified jumbotron that occupies the entire horizontal space of its parent..</p>
  </b-jumbotron>
</div></script></code></pre>

<h2>Component tag</h2>
<p class="m-40">By default, <code class="text-nowrap" translate="no">&lt;b-jumbotron&gt;</code> will render its root element as a div. 
   Change the element tag to any other appropriate element by setting the tag prop to the desired element tag name.</p>

<h2>Variants</h2>
<p class="m-30">Control the overall background variant with the bg-variant prop ( set to info, danger, warning, light, dark, etc.), 
   the border variant with the border-variant prop, and the text variant with text-variant prop. 
   All three props default to null, which will instruct the jumbotron to use the default styling.</p>

  <b-jumbotron header="Hello World" bg-variant="primary" text-variant="white" >
      <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
      <hr>
      <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  </b-jumbotron> 

<pre><code class="language-markup" data-label="Copy"><script type="prism-html-markup"><div>
  <b-jumbotron header="Hello World" bg-variant="primary" text-variant="white" >
      <p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
      <hr>
      <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  </b-jumbotron>
</div></script></code></pre>

  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
  name: "JumbotronView",
  components: {
    AppButton
    },
    mounted() {
    document.title = "Jumbotron";
    Prism.highlightAll(); // highlight your code on mount
  }

}
</script>

<style scoped>

</style>
