<template>
<div class="wrapper">
      <title>Chats</title>

    <h1 class="title-section">Chat</h1>

     <div class="chat">
       <div class="chat-wrapper">
                         <div class="chat-body">
                    <div class="chat-date">
                        March 6, 2020 at 8:21am
                    </div>
                    <div class="chat-messages">
                      <div v-bind:class="'chat ' + (message.user_id==1 ? 'send' : 'received')" v-for="message in messages" :key="message.id">{{message.content}}</div>
                    </div>
                   
                </div>
                <div class="input-field">

            <button class="btn-attach"><img src="../assets/images/chat-component/attach.svg" type=""></button>
            <button class="btn-send"><img src="../assets/images/chat-component/arrow-right-white.svg" type="" @click="addMessage()"></button>
            <b-input type="text" placeholder="Message..." v-model="newMessageContent" v-on:keyup.enter="addMessage"/>

                </div>

       </div>

            </div>
    </div>

</template>

<script>


export default {
  name: "ChatView",
    data() {
      return {
         newMessageContent: '',
         messages: [
          {user_id: 2, content:'Hello, my name is Pete. Check out your documents for the tour.'},
          {user_id: 2, content:'Have a nice trip!'}, 
          {user_id: 1, content:"Thank you so much. If we'll have any questions, we'll contact you."}, 


]

      }
    },



    methods: {
        addMessage() {
          // 'this' refers to 'app'.
          this.messages.push({user_id: 1, content: this.newMessageContent});
          this.newMessageContent = '';
        },
  },

  
    mounted() {
    document.title = "Chat";
    Prism.highlightAll();
  }
}
</script>

<style scoped>

</style>
